let wordList = [
    "Aa-Gesicht",
"Aa-Kopf",
"Aa-Loch",
"Aalficker",
"Aalfresse",
"Aasgeier",
"Abfall",
"Abfalltonnenvollscheißer",
"Abficker",
"Abflussrohrgucker",
"Abflussrohrsauger",
"Abschaum",
"Abscheißer",
"Abschiedswinker",
"Abschmierer",
"Abschreiber",
"Abseiler",
"Abspritzer",
"Absturztorte",
"Abtrittfeger",
"Abtrünniger",
"Abtörner",
"Abwichshure",
"Achselbaron",
"Achselficker",
"Achselgrind",
"Achselhaarratte",
"Achselhaarspalter",
"Achselträger",
"Achterbahnindermittesitzer",
"Achterbahnkotzer",
"Ackerarsch",
"Ackerfresse",
"Affenarsch",
"Affenarschficker",
"Affenarschfurchenfetischist",
"Affenarschgesicht",
"Affenarschloch",
"Affenbesamer",
"Affenbumser",
"Affeneichellecker",
"Affenfehlfick",
"Affenficker",
"Affenfotze",
"Affenfresse",
"Affengesicht",
"Affenkacke",
"Affensack",
"Affenschwanz",
"Affenschwanzlutscher",
"Affenschänder",
"Afterarzt",
"Afterforscher",
"Aftergelehrter",
"Afterhöhlenforscher",
"Afterkind",
"Afterkritiker",
"Afterlecker",
"Afterlehrer",
"Afterprophet",
"Afterredner",
"Afterwissenschaftler",
"Aktendrescher",
"Aktenfresser",
"Aktenkrämer",
"Aktenreiter",
"Aktenwurm",
"Alfanzer",
"Alki",
"Allerweltsraffel",
"Allerweltsranke",
"Allerweltsräuber",
"Allmannshure",
"Alltagslaffe",
"Almosenfresser",
"Altenheimnutte",
"Altenheimschlampe",
"Altflicker",
"Altkluger",
"Altweiberficker",
"Alufolienglattstreicher",
"Ameisenficker",
"Ammenmacher",
"Ampelgelbbremser",
"Analakrobat",
"Analatmer",
"Analbaron",
"Analbergsteiger",
"Analbesamer",
"Analbohrer",
"Analfetischist",
"Analficker",
"Analfisch",
"Analfissur",
"Analfotze",
"Analfürst",
"Analgeburt",
"Analgesicht",
"Analglocke",
"Analkopf",
"Analphabet",
"Analpilot",
"Analpirat",
"Analrakete",
"Analratte",
"Analraupe",
"Analreiniger",
"Analreiter",
"Analritter",
"Analrosine",
"Analruine",
"Analrülpser",
"Analtranse",
"Analtrompete",
"Anarchist",
"Andächtler",
"Anekdotenkrämer",
"Anisbrenner",
"Anpinsler",
"Anthropophag",
"Anuslecker",
"Apostat",
"Arkanenkrämer",
"Armleuchter",
"Arsch",
"Arschbacke",
"Arschbackenjäger",
"Arschbohrer",
"Arschbombe",
"Arschbratze",
"Arschbumser",
"Arschbär",
"Arschfalte",
"Arschficker",
"Arschfickerlehrling",
"Arschflöte",
"Arschfotze",
"Arschfresse",
"Arschgeburt",
"Arschgeier",
"Arschgeige",
"Arschgeiger",
"Arschgesicht",
"Arschgranate",
"Arschgucker",
"Arschgurke",
"Arschhaar",
"Arschi",
"Arschkacker",
"Arschkante",
"Arschkeks",
"Arschkind",
"Arschkipf",
"Arschkopf",
"Arschkrampe",
"Arschkrapfen",
"Arschkrebs",
"Arschkriecher",
"Arschkrämer",
"Arschkröte",
"Arschkrümel",
"Arschkuh",
"Arschlappen",
"Arschlecker",
"Arschloch",
"Arschlochficker",
"Arschlochkind",
"Arschlochlecker",
"Arschlutscher",
"Arschmade",
"Arschmonarch",
"Arschnase",
"Arschnutte",
"Arschpauker",
"Arschpenner",
"Arschpfeife",
"Arschpickel",
"Arschpilzsammler",
"Arschpirat",
"Arschpisser",
"Arschpopper",
"Arschrammler",
"Arschratte",
"Arschsack",
"Arschscheißer",
"Arschtrampe",
"Arschvieh",
"Arschvotze",
"Arschwarmer",
"Arschwassertrinker",
"Arschwichser",
"Arschwurst",
"Aschenbrödel",
"Aslak",
"Asmodi",
"Asozialer",
"Assel",
"Astloch",
"Astlochbumser",
"Astlochficker",
"Atomschlumpf",
"Attrappe",
"Auflaurer",
"Aufmerker",
"Aufruhrprediger",
"Aufrührer",
"Aufschneider",
"Aufschößling",
"Aufwiegler",
"Augendiener",
"Ausbeuter",
"Ausbund",
"Ausgeburt",
"Ausgeburt",
"Ausgrübler",
"Aushilfsamöbe",
"Auskehricht",
"Ausklauber",
"Auskundschafter",
"Ausplätscher",
"Auspuffbefruchter",
"Aussauger",
"Ausschmierer",
"Ausschreiber",
"Ausschwätzer",
"Ausspäher",
"Ausspürer",
"Aussätziger",
"Auswuchs",
"Auswürfling",
"Autoficker",
"BAföG-Schmarotzer",
"Babybefruchter",
"Babyface",
"Babyficker",
"Bachant",
"Bachstelze",
"Bachus",
"Bachusbruder",
"Bachusknecht",
"Backblech",
"Backofenvorheizer",
"Badeknecht",
"Badstübler",
"Bagage",
"Bagalut",
"Bagatellenkrämer",
"Bajazzo",
"Bakterienschleuder",
"Baldober",
"Balg",
"Bambusratte",
"Bananenbieger",
"Bananenlutscher",
"Bankbein",
"Bankerottirer",
"Bankert",
"Bankrutscher",
"Baraber",
"Bartabnehmer",
"Barthans",
"Bartkerl",
"Bartkratzer",
"Bartloser",
"Bartputzer",
"Bartschaber",
"Bartscheerer",
"Bartschinder",
"Barüschnik",
"Batzenschmelzer",
"Bauchdiener",
"Bauchpfaffe",
"Bauernbengel",
"Bauernfiedler",
"Bauernflegel",
"Bauernfänger",
"Bauernfünfer",
"Bauerngesindel",
"Bauernhure",
"Bauernknoll",
"Bauernlümmel",
"Bauernmensch",
"Bauernpack",
"Bauernplaker",
"Baumficker",
"Baumschüler",
"Bazi",
"Beckenrandschwimmer",
"Beidl",
"Benzinpreisvergleicher",
"Berserker",
"Berufslesbe",
"Berufsopfer",
"Berufsschwuchtel",
"Berufsschwuler",
"Bescheißer",
"Besserwessi",
"Beutelschneider",
"Biedermann",
"Bierbruder",
"Bierdimpfel",
"Bierschlampe",
"Bildungsnutte",
"Blockflötengesicht",
"Blockwart",
"Blondchen",
"Bockwurstwassersäufer",
"Bonze",
"Bordsteinschwalbe",
"Boxenluder",
"Brandstifter",
"Bratze",
"Brillenschlange",
"Brontosaurier",
"Bubenhure",
"Bumskarnickel",
"Bumskopf",
"Bumskuh",
"Buschnutte",
"Busengrabscher",
"Bäffer",
"Bänkelsänger",
"Bärbeißer",
"Bärenführer",
"Bärenhäuter",
"Bärenjäger",
"Bösewicht",
"Bückling",
"Büttel",
"Camper",
"Canaille",
"Charakterschwein",
"Chefzäpfchen",
"Chilliverweigerer",
"Chromosomenfasching",
"Chromosomengepansche",
"Citypupser",
"Clearasilversuchsgelände",
"Clerasiltestgelände",
"Cloake",
"Clochar",
"Clochard",
"Clownficker",
"Cornflakesfresser",
"Crackhure",
"Cracknutte",
"Crackschlampe",
"Currywurst",
"Cyberschlampe",
"Dackelficker",
"Danebenpisser",
"Darmamöbe",
"Darmdübel",
"Darmquastenzorro",
"Darmwandgucker",
"Dauercamper",
"Dauerflenner",
"Dauerlutscher",
"Dauerwellenträger",
"Dauerwichser",
"Daumenlutscher",
"Deckschrubber",
"Delfinficker",
"Demagoge",
"Deppkopf",
"Dickarsch",
"Dickbauch",
"Dickschwein",
"Diebsgesindel",
"Dieselhandschuhtanker",
"Dieselhandschuhträger",
"Dildokopf",
"Dildolutscher",
"Dillo",
"Diplomverkehrsinselbepflanzer",
"Disconutte",
"Diskettenformatierer",
"Dissopfer",
"Dollbohrer",
"Donnerfotze",
"Dorffahrrad",
"Dorfkuh",
"Dorfmatratze",
"Dorfmoped",
"Dorfnutte",
"Dorfschlampe",
"Dorftrottel",
"Dreckhure",
"Dreckluder",
"Drecksack",
"Drecksau",
"Drecksbulle",
"Dreckschleuder",
"Drecksfotze",
"Drecksgesicht",
"Drecksgöre",
"Dreckskerl",
"Drecksmuschi",
"Drecksnutte",
"Drecksschwein",
"Dreckstück",
"Drehstuhlbesamer",
"Dreilochhure",
"Dreilochnutte",
"Dreilochschlampe",
"Dreilochstute",
"Dummfotze",
"Dummhirn",
"Dummsau",
"Dummschwätzer",
"Dunkelmann",
"Durchfallgurgler",
"Durchfalltrinker",
"Dämlack",
"Döbel",
"Dödelarsch",
"Döskopf",
"Düffeldoffel",
"Dünnbrettbohrer",
"Ehrabschneider",
"Eichelkäselutscher",
"Eierfeile",
"Eiernacken",
"Einsteinverschnitt",
"Ekelmann",
"Elefantenficker",
"Emporkömmling",
"Entenklemmer",
"Erzgauner",
"Erzhure",
"Eselarschficker",
"Eselficker",
"Eselpopper",
"Eselvergewaltiger",
"Eulenficker",
"Eulenkind",
"Eumel",
"Eumelkind",
"Eunuch",
"Euterlutscher",
"Evolutionsbremse",
"Evolutionsbremser",
"Falschmünzer",
"Fatzke",
"Feldhure",
"Ferkelficker",
"Ferkelwemser",
"Festnetztelefonierer",
"Fettarsch",
"Fettsack",
"Fettwanst",
"Fickel",
"Ficker",
"Ficksau",
"Fickschwein",
"Fickstück",
"Filzlaus",
"Fixer",
"Flachpfeife",
"Flachwichser",
"Flittchen",
"Flohficker",
"Folterknecht",
"Fotze",
"Fotzenkind",
"Fotzenknecht",
"Fotzenlecker",
"Fotzensau",
"Frauenversteher",
"Fregatte",
"Freudenmädchen",
"Frevler",
"Froschficker",
"Furie",
"Fuzzi",
"Fußabtreter",
"Fußfetischist",
"Fünferschüler",
"Fürst",
"Gabelreiter",
"Galgenhure",
"Galgenvogel",
"Garagenparker",
"Gaskopf",
"Gaukler",
"Gauklershure",
"Gebärmutterabfall",
"Geilspecht",
"Generalhure",
"Gernegroß",
"Geschmeiß",
"Gesichtsbaracke",
"Gesichtselfmeter",
"Gesindel",
"Gewitterhexe",
"Gezücht",
"Ghettoschlampe",
"Gimpel",
"Gipskopf",
"Gloifi",
"Glücksritter",
"Gossenpenner",
"Grabräuber",
"Grabschänder",
"Grattler",
"Greenhorn",
"Griesgram",
"Grobmotoriker",
"Grottenolm",
"Großkotz",
"Großmaul",
"Grüßaugust",
"Gummihund",
"Gummipuppe",
"Göre",
"Günstling",
"Haarspalter",
"Habenichts",
"Hackfresse",
"Haderlump",
"Hafennutte",
"Hafenschlampe",
"Halbaffe",
"Hallodri",
"Hammerwerfer",
"Hansdampf",
"Hanswurst",
"Hartgeldnutte",
"Hartgeldstricher",
"Hartz-IV-Schmarotzer",
"Hartzer",
"Haubentaucher",
"Hawak",
"Heckenbankert",
"Heckenpenner",
"Hehler",
"Heimscheißer",
"Heiratsschwindler",
"Henkershure",
"Heulboje",
"Heulsuse",
"Hinterlader",
"Hinterwäldler",
"Hodengesicht",
"Hodenschmuser",
"Hoink",
"Homopimmelpirat",
"Homoschlampe",
"Hool",
"Hormonarschloch",
"Hortkind",
"Hosenjodler",
"Hosenpinkler",
"Hosenpisser",
"Hosenscheißer",
"Hundelecker",
"Hundertfünfundsiebziger",
"Hundesohn",
"Hundsfott",
"Hundskrüppel",
"Hupfdohle",
"Hure",
"Hurenbastard",
"Hurenbock",
"Hurenficker",
"Hurenjäger",
"Hurenkind",
"Hurensohn",
"Hutsimpel",
"Hänfling",
"Hühner-KZ-Aufseher",
"Idi",
"Idiotenarsch",
"Idiotenfresse",
"Idiotengehilfe",
"Idiotenhäuptling",
"Idiotenkind",
"Ikonoklast",
"Impotenter",
"Impotentling",
"Innentaschenbügler",
"Inselkind",
"Insgeheimrülpser",
"Intelligenzallergiker",
"Intelligenzbolzen",
"Internetausdrucker",
"Invasor",
"Inzestgeburt",
"Inzuchtdepp",
"Inzuchtmeister",
"Inzüchtler",
"Jammergestalt",
"Jammerossi",
"Jammersack",
"Jammerspacken",
"Jammersuse",
"Jauchegrubentaucher",
"Jauchemaul",
"Jaucher",
"Jaucheschwimmer",
"Jauchetrinker",
"Jeansbügler",
"Jodelidiot",
"Jodelschnepfe",
"Joghurtbecherspüler",
"Jungenficker",
"Jungfer,",
"Jungspund",
"Kackbratze",
"Kackfresse",
"Kacklatte",
"Kackratte",
"Kackvogel",
"Kameradenschwein",
"Kampflesbe",
"Kampfzwerg",
"Kanalratte",
"Kanalrattenficker",
"Kanalrattenschwanzlutscher",
"Kassenpatient",
"Killer",
"Kinderbeckenstruller",
"Kinderficker",
"Kinderleichenficker",
"Kinderschreck",
"Kinderschänder",
"Kindervergewaltiger",
"Kirchenmaus",
"Klabautermann",
"Klassenkasper",
"Kleinhirn",
"Klofrau",
"Klugscheißer",
"Klöte",
"Knabenficker",
"Knallcharge",
"Knastbruder",
"Knilch",
"Kohlkopf",
"Kollaborateur",
"Kondomlutscher",
"Korinthenkacker",
"Kotfotze",
"Kotschlecker",
"Kotstecher",
"Kotzefresser",
"Krampfhenne",
"Kriegsverbrecher",
"Krokodilficker",
"Krämerseele",
"Kräutergärtner",
"Kröte",
"Krüppel",
"Kulturbanause",
"Kurpfuscher",
"Kuttenbrunzer",
"Käsegesicht",
"Käsevotze",
"Körnerfresser",
"Laberbacke",
"Laborunfall",
"Lachsack",
"Lachsnacken",
"Lackaffe",
"Lackel",
"Lackschuhpanter",
"Ladendieb",
"Laffe",
"Lahmarsch",
"Lakeiengrüßer",
"Lakritzschneckenabroller",
"Lallkopf",
"Lamettabügler",
"Lammfellsohlengeher",
"Lampensau",
"Landhure",
"Landlümmel",
"Landpomeranze",
"Landsknecht",
"Landungsklatscher",
"Langsamesser",
"Langschwanzpavian",
"Laternenficker",
"Laternenparker",
"Laternenschlampe",
"Latexallergievortäuscher",
"Lattendieb",
"Lauchkopf",
"Lausejunge",
"Lauser",
"Lavendelpflücker",
"Leberwurst,",
"Leibeigener",
"Leichenficker",
"Leichenfledderer",
"Leichenschänder",
"Lohnbetrüger",
"Luder",
"Luftikus",
"Luftpumpe",
"Lululutscher",
"Lump",
"Lumpenhund",
"Lumpenpack",
"Lumpensack",
"Lumpensammler",
"Lurch",
"Lurchfresse",
"Lurchi",
"Lurchkopf",
"Lusche",
"Luschenkopf",
"Luschi",
"Lustgreis",
"Lustknabe",
"Lustmolch",
"Lutscher",
"Lügenbold",
"Lügensau",
"Lügenschwein",
"Lümmeltütenlutscher",
"Macho",
"Macker",
"Mamaficker",
"Marktschreier",
"Massenmörder",
"Massenwichser",
"Mastdarmakrobat",
"Mastdarmgeburt",
"Mastdarmtourist",
"Mastsau",
"Mauerblümchen",
"Maulaffe",
"Maulficker",
"Maulheld",
"Maulhure",
"Maulscheißer",
"Maultier",
"Mautpreller",
"Menschenhändler",
"Metze",
"Meuchelmörder",
"Mickey-Maus-Detektiv",
"Mietnomade",
"Missgeburt",
"Mist",
"Mistgurke",
"Mistkerl",
"Miststück",
"Mistvieh",
"Mitgiftjäger",
"Mokkastecher",
"Mondkalb",
"Muffel",
"Murkser",
"Muschificker",
"Muschipilz",
"Muschischeißer",
"Muschkote",
"Muttiküsser",
"Mädchenzeuger",
"Mätresse",
"Möchtegernmacho",
"Möchtegernpunk",
"Müllkind",
"Mülltonnenschlucker",
"Napfsülze",
"Nassauer",
"Natter",
"Neidhammel",
"Neinsager",
"Nekromant",
"Nestbeschmutzer",
"Neureicher",
"Nichtsnutz",
"Nichtstuer",
"Nulpe",
"Nutte",
"Nuttenarsch",
"Nuttenbefruchter",
"Nuttenbengel",
"Nuttenbumser",
"Nuttenficker",
"Nuttenfresse",
"Nuttenjäger",
"Nuttenkind",
"Nutterich",
"Ochsenkind",
"Oimel",
"Olbel",
"Omaficker",
"Onanist",
"Opaficker",
"Opfer",
"Opportunist",
"Orgasmusbremse",
"Outlaw",
"Palmesel",
"Panscher",
"Pansenkind",
"Pantoffelheld",
"Papierficker",
"Papiertiger",
"Papist",
"Pappenheimer",
"Paragraphenreiter",
"Parfümschlampe",
"Parkplatzschwein",
"Parkscheibenweiterdreher",
"Partybumser",
"Partyficker",
"Partykotzunke",
"Partyluder",
"Partynutte",
"Partypuper",
"Partytypenrunterholflittchen",
"Penispumpe",
"Pfannkuchengesicht",
"Pfeifenkopf",
"Pfennigfuchser",
"Pferdeficker",
"Pflasterhirsch",
"Pflaumenaugust",
"Phantast",
"Philister",
"Pickelgesicht",
"Piefke",
"Pimmelficker",
"Pimmelfrau",
"Pimmellecker",
"Pimmelpirat",
"Piss-Sau",
"Pisser",
"Pissetrinker",
"Pisskind",
"Pisskröte",
"Pissnelke",
"Pissvogel",
"Plagiator",
"Plappermaul",
"Pleitegeier",
"Plärrer",
"Poperze",
"Popobumser",
"Pornoschlampe",
"Pottsau",
"Prahler",
"Prahlhans",
"Primadonna",
"Primitivling",
"Proll",
"Psychopat",
"Psychopath",
"Puffmutter",
"Punk",
"Päderast",
"Pöbel",
"Quacksalber",
"Quadratarschloch",
"Quadratdackel",
"Quadratdepp",
"Quadratfresse",
"Quadratlatschi",
"Quadratratschen",
"Quadratschädel",
"Quallenaußenrumschwimmer",
"Quallenficker",
"Quastenflosser",
"Querficker",
"Querflötenlutscher",
"Querfurzantilope",
"Querschwimmer",
"Querulant",
"Querzahnmolch",
"Quetschbirne",
"Quetschgeburt",
"Quickificker",
"Quisling",
"Quälgeist",
"Rabenaas",
"Raffel",
"Raffzahn",
"Rammel",
"Rattenarsch",
"Rattenfänger",
"Rattenkind",
"Raubritter",
"Reaktionär",
"Renegat",
"Rhinozeros",
"Riesenross",
"Rinderficker",
"Rosettenhengst",
"Rosstäuscher",
"Rübensau",
"Rübenschwein",
"Rüpelradler",
"Sackgesicht",
"Sacknase",
"Sackratte",
"Sacksau",
"Sackschmatzer",
"Saftkopf",
"Saftpresse",
"Saftschubse",
"Salbader",
"Saubazi",
"Saufotze",
"Sauhure",
"Sauschwein",
"Sautrottel",
"Schabracke",
"Schafskopf",
"Schandfleck",
"Schandgesicht",
"Schandmaul",
"Schattenparker",
"Scheißarsch",
"Scheißdreck",
"Scheiße",
"Scheißebatzen",
"Scheißeesser",
"Scheißeficker",
"Scheißefresser",
"Scheißehaufen",
"Scheißer",
"Scheißhaufen",
"Scheißhausfliege",
"Scheißhausfotze",
"Scheißhausnutte",
"Scheißhausratte",
"Scheißkerl",
"Scheißkind",
"Scheißkopf",
"Scheißvieh",
"Schelm",
"Scherge",
"Schickse",
"Schiffschaukelbremser",
"Schimmelmöse",
"Schimmelpimmel",
"Schinder",
"Schindluder",
"Schisser",
"Schisskind",
"Schitte",
"Schlachtschiff",
"Schlafmütze",
"Schlammsau",
"Schlampe",
"Schlampenficker",
"Schlampenfotze",
"Schlampenheini",
"Schlampenkind",
"Schlampenkuh",
"Schlampensau",
"Schlampenschwein",
"Schlampensohn",
"Schlampentochter",
"Schlampenvieh",
"Schlampenwichser",
"Schlamper",
"Schlapppisser",
"Schlappschwanz",
"Schlaukopf",
"Schleimpilz",
"Schleimscheißer",
"Schlepper",
"Schlepphoden",
"Schließmuskelbesamer",
"Schließmuskelfanatiker",
"Schluckluder",
"Schluckspecht",
"Schlüpferpilot",
"Schlüpferschnüffler",
"Schlüpferstürmer",
"Schmalspurwichser",
"Schmarrer",
"Schmierfink",
"Schmock",
"Schmutzfink",
"Schnabeltasse",
"Schnallentreiber",
"Schnellspanner",
"Schnepfe",
"Schnulli",
"Schrittschwitzer",
"Schrubber",
"Schrulle",
"Schwanzkäse",
"Schwanzlutscher",
"Schwarzfahrer",
"Schweineficker",
"Schweinepriester",
"Schweinesohn",
"Schwerverbrecher",
"Schwitzficker",
"Schwuchtel",
"Schwulette",
"Schwuli",
"Schwächling",
"Schwätzer",
"Schädling",
"Schürzenjäger",
"Seerosengießer",
"Sesselfurzer",
"Seuchenvogel",
"Sextourist",
"Siffkopp",
"Sittenstrolch",
"Sitzpinkler",
"Sklavenhure",
"Sklaventreiber",
"Sockenfalter",
"Sodomit",
"Soldatenhure",
"Sonntagsfahrer",
"Sozialparasit",
"Sozialschmarotzer",
"Spanner",
"Spargeltarzan",
"Spasti",
"Spermarutsche",
"Spermaschlucker",
"Spießbürger",
"Spießgeselle",
"Spinatwachtel",
"Spinnenficker",
"Steckdosenbefruchter",
"Stehaufmännchen",
"Stehpisser",
"Stiefellecker",
"Straßenkind",
"Straßenmädchen",
"Straßenräuber",
"Streithammel",
"Streithansel",
"Stricher",
"Strichjunge",
"Strichmädchen",
"Strichnutte",
"Strunz",
"Stubenhocker",
"Studentenhure",
"Störenfried",
"Stück",
"Stümper",
"Suppenkasper",
"Swinger",
"Systemnutte",
"Säufer",
"Tagedieb",
"Tattergreis",
"Taugenichts",
"Teufelshexe",
"Teufelshure",
"Tierquäler",
"Tierschänder",
"Toilettengeburt",
"Toilettenkiffer",
"Torfkopf",
"Torfnase",
"Tortenarsch",
"Totengräbershure",
"Totschwätzer",
"Trampel",
"Trampeltier",
"Transe",
"Transenkopf",
"Trantüte",
"Tratschtante",
"Trittbrettfahrer",
"Trotzkopf",
"Trulla",
"Tucke",
"Tunte",
"Turnbeutelvergesser",
"Tusse",
"Tussi",
"Tussie",
"Umstandskrämer",
"Umweltverschmutzer",
"Unflat",
"Ungustl",
"Unhold",
"Unke",
"Unterhaltsnutte",
"Urning",
"Vaginalwurm",
"Vaterficker",
"Venusfliegenfalle",
"Verbalerotiker",
"Vereinsmatratze",
"Vereinsmeier",
"Verfallsdatumspaniker",
"Vergewohltätiger",
"Volksschädling",
"Volksverräter",
"Vollassi",
"Vorstadtcasanova",
"Wadenbeißer",
"Waldfee",
"Waldschrat",
"Wanze",
"Warmduscher",
"Waschweib",
"Wasserkopf",
"Wechselbalg",
"Weiberheld",
"Weinpanscher",
"Wendehals",
"Wichsbirne",
"Wichser",
"Wichslappen",
"Wicht",
"Wichtigtuer",
"Widerspruchsgeist",
"Windelarsch",
"Windelfetischist",
"Winkeladvokat",
"Wotansbraten",
"Xanthippe",
"Xylakant",
"YPS-Spezialagent",
"Yakarsch",
"Yakuza-Fotze",
"Yetificker",
"Yogafotze",
"Yogaschlampe",
"Zahnarztspritzenbettler",
"Zahnbürstenborstenverbieger",
"Zahnspangenfresse",
"Zahnsteinlutscher",
"Zahnwichser",
"Zangengeburt",
"Zappelphilipp",
"Zaungaffer",
"Zaunpinkler",
"Zausel",
"Zechpreller",
"Zettelschlampe",
"Ziegenficker",
"Ziegenschänder",
"Zimmermädchenverführer",
"Zipfelschwinger",
"Zuagroaster",
"Zweitligist",
"Zyklopenfotze",
"Zystenfresse",
"Ölgötze"
];

let stringFilter = (string) => {
    let stringList = string.split(' ');

    for (let word of wordList) {
        for (let s of stringList) {
            if (s.toLowerCase() == word.toLowerCase()) {
                return word
            }
        }
    }
    return null;
}

export default stringFilter;
