import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Link,
    NavLink,
    Route,
    Redirect,
} from "react-router-dom";
import axios from "axios";
import Auth from "./../modules/Auth";
import { FavoriteBorder, StarBorder, Subject } from "@material-ui/icons";
import Reaptcha from "reaptcha";
import stringFilter from "./../modules/Filter";

class Login extends Component {
    state = {
        username: null,
        password: null,
        errorMsg: [],
        errorMsgReason: null,
        notVerifiedUser: null,
        displayErrorMsg: false,
        redirectToHome: false,
    };

    constructor(props) {
        super(props);
        this.handleLoginForm = this.handleLoginForm.bind(this);
    }

    async handleLoginForm() {
        let username = this.state.username.trim();
        let password = this.state.password.trim();

        this.state.displayErrorMsg = false;
        this.state.errorMsg = [];
        this.setState({ errorMsgReason: null });
        this.setState({ notVerifiedUser: null });

        let filterError = await stringFilter(username);

        if (filterError) {
            this.captcha.reset();
            this.setState({
                errorMsg: [
                    "Fehler! Wir haben ein verbotenes Wort in deiner Eingabe gefunden: " +
                        filterError.toUpperCase() +
                        ". Bitte entferne das Wort bevor du das Formular erneut absendest.",
                ],
            });
            this.setState({ displayErrorMsg: true });
            return;
        }

        if (username.length <= 0) {
            this.state.errorMsg.push("Bitte geben Sie einen Nutzernamen ein.");
        }
        if (password.length <= 0) {
            this.state.errorMsg.push("Bitte geben Sie ein Passwort ein.");
        }

        if (this.state.errorMsg.length > 0) {
            this.setState({ displayErrorMsg: true });
            this.captcha.reset();
        } else {
            Auth.checkRecaptcha()
                .then((res) => {
                    this.captcha.reset();
                    let { data } = res;

                    if (data.success) {
                        axios
                            .post("/auth/login", {
                                username: username,
                                password: password,
                            })
                            .then((response) => {
                                if (response.data.success === false) {
                                    this.setState({
                                        errorMsg: [response.data.message],
                                    });
                                    this.setState({ displayErrorMsg: true });
                                    if (
                                        response.data.reason &&
                                        response.data.reason ==
                                            "not_verified" &&
                                        response.data.username
                                    )
                                        this.setState({
                                            errorMsgReason: "not_verified",
                                        });
                                    this.setState({
                                        notVerifiedUser: response.data.username,
                                    });
                                } else if (response.data.success === true) {
                                    Auth.authenticateUser(response.data.token);
                                    this.props.handleAuthenticationChange(true);
                                    this.setState({ redirectToHome: true });
                                }
                            })
                            .catch((error) => {
                                this.setState({ errorMsg: ["Netzwerkfehler"] });
                                this.setState({ displayErrorMsg: true });
                            });
                    } else {
                        this.setState({
                            errorMsg: [
                                "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal.",
                            ],
                        });
                        this.setState({ displayErrorMsg: true });
                    }
                })
                .catch((err) => {
                    this.setState({
                        errorMsg: [
                            "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal.",
                        ],
                    });
                    this.setState({ displayErrorMsg: true });
                });
        }
    }

    handleVerifyAgain = (e) => {
        e.preventDefault();

        let user = this.state.notVerifiedUser;
        if (!user) return;

        axios
            .post("/auth/send-verify-link", { username: user })
            .then(alert("Wir haben dir soeben einen Link per Email gesendet."));
    };

    render() {
        if (this.state.redirectToHome) {
            return <Redirect to="/" />;
        }

        return (
            <div id="login-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Anmelden
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-lg-6 col-xl-7">
                            <h1 class="color-primary">
                                Du hast bereits ein Konto bei MyFeedingCheck?
                            </h1>
                            <p>Melde Dich hier bei MyFeedingCheck an:</p>
                            {this.state.displayErrorMsg && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    <ul>
                                        {this.state.errorMsg.map(
                                            (msg, msgI) => (
                                                <li key={msgI}>
                                                    {msg}{" "}
                                                    {this.state
                                                        .errorMsgReason ==
                                                        "not_verified" &&
                                                        this.state
                                                            .notVerifiedUser && (
                                                            <a
                                                                href="#"
                                                                style={{
                                                                    color:
                                                                        "#666",
                                                                    fontSize:
                                                                        "90%",
                                                                    textDecoration:
                                                                        "underline",
                                                                }}
                                                                onClick={
                                                                    this
                                                                        .handleVerifyAgain
                                                                }
                                                            >
                                                                Link erneut
                                                                anfordern
                                                            </a>
                                                        )}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            )}
                            <form
                                method="post"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this.captcha.execute();
                                }}
                            >
                                <div class="form-group">
                                    <label
                                        for="username-login"
                                        className="col-12 col-md-5 nopadding"
                                    >
                                        Benutzername/E-Mail
                                    </label>
                                    <input
                                        className="col-12 col-md-7 nopadding form-control"
                                        type="text"
                                        name="username-login"
                                        id="username-login"
                                        placeholder="Benutzername oder E-Mail"
                                        required
                                        onChange={(e) =>
                                            this.setState({
                                                username: e.target.value,
                                            })
                                        }
                                    ></input>
                                </div>
                                <div class="form-group">
                                    <label
                                        for="password-login"
                                        className="col-12 col-md-5 nopadding"
                                    >
                                        Passwort
                                    </label>
                                    <input
                                        className="col-12 col-md-7 nopadding form-control"
                                        type="password"
                                        name="password-login"
                                        id="password-login"
                                        placeholder="Passwort"
                                        required
                                        onChange={(e) =>
                                            this.setState({
                                                password: e.target.value,
                                            })
                                        }
                                    ></input>
                                </div>
                                <Reaptcha
                                    ref={(e) => (this.captcha = e)}
                                    size="invisible"
                                    sitekey="6LeEAaIUAAAAAHVNxEUt6ZjKHzvCA_6gPuwjjK0S"
                                    onVerify={this.handleLoginForm}
                                    onError={() => {
                                        this.setState({
                                            errorMsg: [
                                                "ReCAPTCHA-Fehler aufgetreten! Bitte lade die Seite erneut.",
                                            ],
                                        });
                                        this.setState({
                                            displayErrorMsg: true,
                                        });
                                    }}
                                />
                                <div>
                                    <input
                                        type="submit"
                                        value="Anmelden"
                                        className="btn btn-primary login-button"
                                        style={{width: '100%'}}
                                    ></input>
                                </div>
                                <div className="text-center">
                                    <Link
                                        to="/passwort-vergessen"
                                        class="btn btn-link"
                                    >
                                        Passwort vergessen?
                                    </Link>
                                    <Link
                                        to="/registrieren"
                                        class="btn btn-link"
                                    >
                                        Konto eröffnen
                                    </Link>
                                    <a
                                        href="/b2b"
                                        target="_blank"
                                        class="btn btn-link"
                                    >
                                        B2B Zugang
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <div className="register-info-box mt-30-md">
                                <div className="item">
                                    <span className="title">
                                        <FavoriteBorder /> Speichere Deine
                                        Favoriten
                                    </span>
                                    <p>
                                        Speichere ganz einfach Deine
                                        Lieblingsprodukte in der Favoritenliste
                                        ab.
                                    </p>
                                </div>
                                <div className="item">
                                    <span className="title">
                                        <StarBorder /> Bewerte Produkte
                                    </span>
                                    <p>
                                        Gib Bewertungen zu Produkten ab, die Du
                                        bereits ausprobiert hast.
                                    </p>
                                </div>
                                <div className="item">
                                    <span className="title">
                                        <Subject /> Schreibe Kommentare
                                    </span>
                                    <p>
                                        Sage uns Deine Meinung zu Produkte und
                                        teile diese mit der Community.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
