import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import noImage from "../img/no-image.jpg";
import StarRatingComponent from "react-star-rating-component";
import SC from "./../modules/StringConverter";
import functions from "./../modules/functions";
import ImageGallery from "react-image-gallery";
import $ from "jquery";
import "jquery-match-height/dist/jquery.matchHeight-min.js";
import { Helmet } from "react-helmet";
import DoubleScrollbar from "react-double-scrollbar";

class CompareList extends Component {
    state = {
        fetchedProducts: [],
        fetchingError: "",
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetchCompareListProducts();

        this.handleCompareListChange = this.handleCompareListChange.bind(this);
    }

    handleCompareListChange(e) {
        e.preventDefault();
        let { id } = e.target.dataset;
        if (id && this.props.compareList.includes(id)) {
            let arr = this.props.compareList;
            var index = arr.indexOf(id);
            if (index !== -1) arr.splice(index, 1);
            this.props.handleCompareListChange(arr);
        }
        this.fetchCompareListProducts();
    }

    fetchCompareListProducts = () => {
        var compareList = this.props.compareList;
        if (compareList.length <= 0) compareList = JSON.parse(localStorage.getItem("CompareList")) || [];

        if (compareList.length >= 1)
            axios
                .post("/api/compare-list", { ids: compareList })
                .then((res) => {
                    let { data } = res;
                    if (data.success === true) {
                        this.setState({ fetchedProducts: data.products_list });
                    } else {
                        this.setState({
                            fetchingError: "Fehler beim Abrufen der Produkte aufgetreten.",
                        });
                    }
                })
                .catch((err) =>
                    this.setState({
                        fetchingError: "Fehler beim Abrufen der Produkte aufgetreten.",
                    })
                );
    };

    thColumns = () => {
        let ths = [];
        if (this.state.fetchedProducts.length >= 1)
            this.state.fetchedProducts.map((p) => {
                let jsx;

                const images = [];
                if (p.imageFront || p.imageContent1 || p.imageContent2) {
                    let { imageFront, imageContent1, imageContent2 } = p;

                    if (imageFront) {
                        images.push({
                            original:
                                "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageFront.toLowerCase(),
                            thumbnail:
                                "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageFront.toLowerCase(),
                        });
                    }
                    if (imageContent1) {
                        images.push({
                            original:
                                "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageContent1.toLowerCase(),
                            thumbnail:
                                "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageContent1.toLowerCase(),
                        });
                    }
                    if (imageContent2) {
                        images.push({
                            original:
                                "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageContent2.toLowerCase(),
                            thumbnail:
                                "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageContent2.toLowerCase(),
                        });
                    }
                } else {
                    images.push({
                        original: "https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png",
                        thumbnail: "https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png",
                    });
                }

                let rating = 0;
                let rating_count = 0;
                if (p.reviews.length >= 1) {
                    p.reviews.forEach((review) => {
                        rating = rating + review.rating;
                        rating_count++;
                    });
                    rating = Math.round(rating / rating_count);
                }

                jsx = (
                    <React.Fragment>
                        {jsx}
                        <div className="react-image-gallery">
                            <ImageGallery
                                items={images}
                                showBullets={images.length >= 2 ? true : false}
                                showNav={false}
                                showPlayButton={false}
                                showFullscreenButton={false}
                                showThumbnails={images.length >= 2 ? true : false}
                            />
                        </div>
                        <React.Fragment>
                            {jsx}
                            <StarRatingComponent name="review-rating" starCount={5} value={rating} />
                            <span>({rating_count})</span>
                        </React.Fragment>
                        <div className="btn-wrapper">
                            <Link to={"/produkte/" + p.urlExtension} className="btn btn-primary btn-sm">
                                Produkt ansehen
                            </Link>
                        </div>
                    </React.Fragment>
                );

                jsx = (
                    <td key={p._id} className="remove-btn">
                        {jsx}
                        <a href="" onClick={this.handleCompareListChange} data-id={p._id}>
                            entfernen
                        </a>
                    </td>
                );

                ths.push(jsx);
            });
        return ths;
    };

    compareList = () => {
        if (this.state.fetchedProducts.length >= 1 && !this.state.fetchingError) {
            let html = (
                <div>
                    <div className="top-scrollbar-wrapper">
                        <div id="top-scrollbar">&nbsp;</div>
                    </div>
                    <table className="table table-striped table-responsive">
                        <DoubleScrollbar>
                            <thead id="thead">
                                <tr>
                                    <th scope="col"></th>
                                    {this.thColumns()}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Marke</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"brand-" + i}>{p.brand.name}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Serie</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"series-" + i}>{p.series}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Günstigster Preis</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"cheapestVar-" + i}>
                                            {functions.getCheapestVariant(p.variants) && functions.getCheapestVariant(p.variants).basePrice ? functions.getCheapestVariant(p.variants).basePrice.toFixed(2) + '€/100' : ''}
                                            {functions.getCheapestVariant(p.variants) && functions.getCheapestVariant(p.variants).unit ? functions.getCheapestVariant(p.variants).unit : ''}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Herkunftsland</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"brand-" + i}>
                                            {p.countryOfProduction ? p.countryOfProduction : "n/a"}
                                        </td>
                                    ))}
                                </tr>
                                {/* <tr>
                                    <th scope="row">Tierart</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"animal-" + i}>{SC.convert(p.animal).join(", ")}</td>
                                    ))}
                                </tr> */}
                                <tr>
                                    <th scope="row">Futtermittelart</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"type2-" + i}>{SC.convert(p.type2).join(", ")}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">NF/TF/R/F/NE</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"type1-" + i}>{SC.convert(p.type1)}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Altersgruppe</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"ageGroup-" + i}>{SC.convert(p.ageGroup).join(", ")}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Zusammensetzung</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"description-" + i}>{p.description}</td>
                                    ))}
                                </tr>
                                {/* <tr>
                                    <th scope="row">Verpackung</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"packaging-size-" + i}>
                                            {p.variants.map((size, i) => {
                                                if (i == p.variants.length - 1) {
                                                    if (
                                                        size.size &&
                                                        size.amount &&
                                                        size.unit &&
                                                        size.published &&
                                                        size.is_for_sale &&
                                                        size.in_stock
                                                    ) {
                                                        return (
                                                            <span>
                                                                {size.amount}x{size.size}
                                                                {size.unit} (
                                                                {size.packaging ? SC.convert(size.packaging) : "n/a"})
                                                            </span>
                                                        );
                                                    }
                                                } else {
                                                    if (
                                                        size.size &&
                                                        size.amount &&
                                                        size.unit &&
                                                        size.published &&
                                                        size.is_for_sale &&
                                                        size.in_stock
                                                    ) {
                                                        return (
                                                            <span>
                                                                {size.amount}x{size.size}
                                                                {size.unit} (
                                                                {size.packaging ? SC.convert(size.packaging) : "n/a"})
                                                                <br />
                                                            </span>
                                                        );
                                                    }
                                                }
                                            })}
                                        </td>
                                    ))}
                                </tr> */}
                                <tr>
                                    <th scope="row">Rohprotein</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"proteins-" + i}>{p.proteins}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Rohfett</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"fats-" + i}>{p.fats}</td>
                                    ))}
                                </tr>
                                {/* <tr>
                                    <th scope="row">Nfe-Gehalt [g/100g]</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"nfe-" + i}>{p.nfe}</td>
                                    ))}
                                </tr> */}
                                {/* <tr>
                                    <th scope="row">Nfe-Gehalt Trockenmasse [g/100g]</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"nfeTM-" + i}>{p.nfeTM}</td>
                                    ))}
                                </tr> */}
                                <tr>
                                    <th scope="row">Deklaration</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"declaration-" + i}>{SC.convert(p.declaration)}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Tierischer Anteil</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"animalShare-" + i}>{SC.convertAnimalShare(p.animalShare)}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Inhaltsstoffe</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"animalComponents-" + i}>
                                            {SC.convert(p.animalComponents).join(", ")}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Ernährungsphys. Zusatzstoffe</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"physiologicalAdditives-" + i}>
                                            {SC.convert(p.physiologicalAdditives)}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Techn. Zusatzstoffe</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"technologicalAdditives-" + i}>
                                            {SC.convert(p.technologicalAdditives)}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <th scope="row">Eigenschaften</th>
                                    {this.state.fetchedProducts.map((p, i) => (
                                        <td key={"attributes-" + i}>{SC.convert(p.attributes).join(", ")}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </DoubleScrollbar>
                    </table>
                </div>
            );

            return html;
        } else if (this.state.fetchingError) {
            return <p className="alert alert-danger">{this.state.fetchingError}</p>;
        }
        return (
            <p className="alert alert-secondary">
                Keine Produkte in der Vergleichsliste.
                <br />
                <Link to="/produkte">Produkte hinzufügen</Link>
            </p>
        );
    };

    render() {
        return (
            <div id="compare-list-view">
                <Helmet>
                    <meta
                        name="description"
                        content="Bei MyFeedingCheck ausgesuchte Produkte miteinander vergleichen."
                        id="meta-desc-compare"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Vergleichsliste
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-12">
                            {this.props.compareList.length >= 1 ? (
                                this.compareList()
                            ) : (
                                <p className="alert alert-secondary">
                                    Bitte füge zuerst Produkte zur Vergleichsliste hinzu.
                                    <br />
                                    <Link to="/produkte">Produkte durchsuchen</Link>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompareList;
