import React, { Component } from 'react';
import { Close } from '@material-ui/icons';
import axios from 'axios';

class AlertBar extends Component {
    state = {
        text: "",
        link: "",
        buttonText: "",
        color: "",
        show: true
    }

    componentDidMount() {
        axios.get('/api/alert-bar')
            .then(res => {
                let { success, abData } = res.data;

                if (!success) {
                    return;
                } else {
                    if (abData.text) this.setState({ text: abData.text });
                    if (abData.link && abData.buttonText) {
                        this.setState({ link: abData.link });
                        this.setState({ buttonText: abData.buttonText });
                    }
                    if (abData.color) {
                        this.setState({ color: abData.color })
                    } else {
                        this.setState({ color: '55AD59' })
                    }
                }
            })
            .catch(err => { return; });
    }

    render() {
        if (!this.state.text) return null;

        let styles = {};
        if (this.state.show) {
            styles.height = 'auto';
            styles.backgroundColor = '#' + this.state.color;
        } else {
            styles.height = '0';
            styles.overflow = 'hidden';
            styles.padding = '0';
        }

        return (
            <div id="alert-bar" style={styles}>
                <div className="close" onClick={() => { this.setState({ show: false }) }}><Close /></div>
                <div className="alert-text">
                    <span>{this.state.text}</span>
                    {this.state.link && this.state.buttonText && (
                        <a className="btn btn-link btn-sm" href={this.state.link} target='_blank'><nobr>{this.state.buttonText}</nobr></a>
                    )}
                </div>
            </div>
        )
    }
}

export default AlertBar;
