import React, { Component } from 'react';
import { BrowserRouter as Router, Link, NavLink, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { FavoriteBorder, StarBorder, Subject } from '@material-ui/icons';
import Auth from './../modules/Auth';
import Reaptcha from 'reaptcha';
import stringFilter from './../modules/Filter';



class Register extends Component {
    state = {
        username: null,
        email: null,
        confirmEmail: null,
        password: null,
        confirmPassword: null,
        acceptDSGVO: null,
        errorMsg: [],
        displayErrorMsg: false,
        redirectToHome: false
    };

    constructor(props) {
        super(props);
        this.handleRegisterForm = this.handleRegisterForm.bind(this);
    }

    async handleRegisterForm() {
        let username = this.state.username.trim();
        let email = this.state.email.trim();
        let confirmEmail = this.state.confirmEmail.trim();
        let password = this.state.password.trim();
        let confirmPassword = this.state.confirmPassword.trim();
        let acceptDSGVO = this.state.acceptDSGVO;

        this.state.displayErrorMsg = false;
        this.state.errorMsg = [];

        let filterError = await stringFilter(username);

        if (filterError) {
            this.captcha.reset();
            this.setState({ errorMsg: ['Fehler! Wir haben ein verbotenes Wort in deiner Eingabe gefunden: ' + filterError.toUpperCase() + '. Bitte entferne das Wort bevor du das Formular erneut absendest.'] });
            this.setState({ displayErrorMsg: true });
            return;
        }

        if (username.length <= 0) {
            this.state.errorMsg.push("Bitte geben Sie einen Nutzernamen ein.");
        }
        if (email.length <= 0) {
            this.state.errorMsg.push("Bitte geben Sie eine E-Mail Adresse ein.");
        }
        if (password.length <= 0) {
            this.state.errorMsg.push("Bitte geben Sie ein Passwort ein.");
        }
        if (password.length > 0 && password.length < 5) {
            this.state.errorMsg.push("Das Passwort muss mindestens 5 Zeichen lang sein.");
        }
        if (email !== confirmEmail) {
            this.state.errorMsg.push("Die E-Mail Adressen stimmen nicht überein.");
        }
        if (password !== confirmPassword) {
            this.state.errorMsg.push("Die Passwörter stimmen nicht überein.");
        }
        if (acceptDSGVO !== "on") {
            this.state.errorMsg.push("Bitte akzeptieren Sie unsere Datenschutzrichtlinien.");
        }

        if (this.state.errorMsg.length > 0) {
            this.setState({ displayErrorMsg: true });
            this.captcha.reset();
        } else {
            this.setState({ displayErrorMsg: false });

            Auth.checkRecaptcha()
                .then(res => {
                    this.captcha.reset();
                    let { data } = res;

                    if (data.success) {
                        axios.post('/auth/register', {
                            username: username,
                            email: email,
                            confirmEmail: confirmEmail,
                            password: password,
                            confirmPassword: confirmPassword
                        })
                            .then(response => {
                                if (response.data.success === false) {
                                    this.setState({ errorMsg: [response.data.message] });
                                    this.setState({ displayErrorMsg: true });
                                } else if (response.data.success === true) {
                                    this.props.handleInfoPopOpen('Vielen Dank!', 'Wir haben Dir einen Bestätigungslink per Email zugesendet.');
                                    this.setState({ redirectToHome: true });
                                }
                            })
                            .catch(error => {
                                this.setState({ errorMsg: ["Netzwerkfehler"] });
                                this.setState({ displayErrorMsg: true });
                            });
                    } else {
                        this.setState({ errorMsg: ["Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal."] });
                        this.setState({ displayErrorMsg: true });
                    }
                })
                .catch(err => {
                    this.setState({ errorMsg: ["Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal."] });
                    this.setState({ displayErrorMsg: true });
                });
        }
    }

    render() {
        if (this.state.redirectToHome === true) {
            return <Redirect to='/' />
        }

        return (
            <div id="register-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Registrieren</span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body d-flex h-100">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-7">
                            <h1 class="color-primary">Neu hier?</h1>
                            <p>
                                Registriere Dich hier bei MyFeedingCheck:
                            </p>
                            {this.state.displayErrorMsg && (
                                <div className="alert alert-danger" role="alert">
                                    <ul>
                                        {this.state.errorMsg.map((msg) => (
                                            <li key={msg.length}>{msg}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <form method="post" onSubmit={(e) => { e.preventDefault(); this.captcha.execute(); }}>
                                <div class="form-group">
                                    <label for="username" className="col-12 col-md-5 nopadding">Benutzername</label>
                                    <input type="text" name="username" className="col-12 col-md-7 nopadding form-control" id="username" placeholder="Benutzername" required onChange={e => this.setState({ username: e.target.value })}></input>
                                </div>
                                <div class="form-group">
                                    <label for="email" className="col-12 col-md-5 nopadding">Deine E-Mail Adresse</label>
                                    <input type="email" name="email" className="col-12 col-md-7 nopadding form-control" id="email" placeholder="E-Mail" required onChange={e => this.setState({ email: e.target.value })}></input>
                                </div>
                                <div class="form-group">
                                    <label for="confirm-email" className="col-12 col-md-5 nopadding">E-Mail bestätigen</label>
                                    <input type="email" name="confirm-email" className="col-12 col-md-7 nopadding form-control" id="confirm-email" placeholder="E-Mail bestätigen" required onChange={e => this.setState({ confirmEmail: e.target.value })}></input>
                                </div>
                                <div class="form-group">
                                    <label for="password" className="col-12 col-md-5 nopadding">Passwort</label>
                                    <input type="password" name="password" className="col-12 col-md-7 nopadding form-control" id="password" placeholder="Passwort" required onChange={e => this.setState({ password: e.target.value })}></input>
                                </div>
                                <div class="form-group">
                                    <label for="confirm-password" className="col-12 col-md-5 nopadding">Passwort wiederholen</label>
                                    <input type="password" name="confirm-password" className="col-12 col-md-7 nopadding form-control" id="confirm-password" placeholder="Passwort wiederholen" required onChange={e => this.setState({ confirmPassword: e.target.value })}></input>
                                </div>
                                <div class="form-group checkbox-group">
                                    <input type="checkbox" name="dsgvo" id="dsgvo" placeholder="Passwort" required onChange={e => this.setState({ acceptDSGVO: e.target.value })}></input>
                                    <label for="dsgvo">Die <a href="https://www.myfeedingcompass.at/datenschutz" target='_blank'>Datenschutzerklärung</a> und <Link to="/agb">Nutzungsbedingungen</Link> wurden gelesen und akzeptiert.</label>
                                </div>
                                <Reaptcha
                                    ref={e => (this.captcha = e)}
                                    size="invisible"
                                    sitekey="6LeEAaIUAAAAAHVNxEUt6ZjKHzvCA_6gPuwjjK0S"
                                    onVerify={this.handleRegisterForm}
                                    onError={() => { this.setState({ errorMsg: ["ReCAPTCHA-Fehler aufgetreten! Bitte versuche es erneut."] }); this.setState({ displayErrorMsg: true }); }}
                                />
                                <input type="submit" value="Jetzt Anmelden" className="btn btn-primary"></input>
                                <Link to='/login' class="btn btn-link">Bereits registriert?</Link>
                            </form>
                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <div className="register-info-box mt-30-md">
                                <div className="item">
                                    <span className="title"><FavoriteBorder /> Speichere Deine Favoriten</span>
                                    <p>Speichere ganz einfach Deine Lieblingsprodukte in der Favoritenliste ab.</p>
                                </div>
                                <div className="item">
                                    <span className="title"><StarBorder /> Bewerte Produkte</span>
                                    <p>Gib Bewertungen zu Produkten ab, die Du bereits ausprobiert hast.</p>
                                </div>
                                <div className="item">
                                    <span className="title"><Subject /> Schreibe Kommentare</span>
                                    <p>Sage uns Deine Meinung zu Produkte und teile diese mit der Community.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
