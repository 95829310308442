import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ads from './../img/werben-mfc.png';

class AboutAds extends Component {
    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <div id="about-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Werben auf MyFeedingCheck</span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <h1 className="color-primary">Werben auf MyFeedingCheck</h1>
                            <p>
                                Warum auf MyFeedingCheck werben?
                                <br /><b className="color-primary">Sei genau da präsent, wo deine Kunden ihre Kaufentscheidung treffen!</b>
                                <br />
                                <br />MyFeedingCheck ist ein einzigartiges Futtervergleichsportal mit besonderen Suchfiltern, 
                                die es sonst nirgends gibt. Unsere Produktvielfalt & Nutzeranzahl wächst täglich und umfasst 
                                genau deine Zielgruppe. Mit uns erreichst Du ohne großen Aufwand eine Vielzahl von Hunde- und 
                                Katzenliebhabern, die genau nach Deinen Produkten suchen.
                                <br />
                                <br />Du möchtest auf MyFeedingCheck werben?
                                <br />Du bist Markenproduzent? 
                                <br />Du führst einen dazugehörigen Onlineshop?
                                <br /><b className="color-primary">Dann schreib uns!</b>
                                <br />
                                <br />Kontaktiere uns unverbindlich über das Kontaktformular oder per E-Mail an <a href="mailto:info@myfeedingcheck.com">info@myfeedingcheck.com</a> mit dem Betreff "Werben auf" und wir schicken Dir alle Informationen & Details, die Du wissen musst.
                                <br />
                                <br />Wir freuen uns auf deine Nachricht!
                            </p>
                        </div>
                        <div className="col-lg-5 text-center">
                            <img src={Ads} alt="Werben auf MyFeedingCheck" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutAds;
