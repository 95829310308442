import React, { Component } from 'react';
import Auth from './../modules/Auth';
import {Redirect} from 'react-router';

let appDomain = "http://localhost:3001";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectToHome: false
        }
    }

    componentDidMount() {
        Auth.deauthenticateUser();
        this.props.handleAuthenticationChange(false);
        this.setState({redirectToHome: true});
    }

    render() {
        if (this.state.redirectToHome === true) {
            return <Redirect to='/' />
        }

        return (
            <h2>Abmelden...</h2>
        );
    }
}

export default Login;
