import React, {Component} from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { Close } from '@material-ui/icons';
import Reaptcha from 'reaptcha';
import Auth from './../modules/Auth';

class ReportProductPopup extends Component {
    state = {
        error: "",
        reason: "",
        formSuccess: false
    }

    handleReportSubmit = () => {
        this.setState({error: null});

        let {product} = this.props;
        let {reason} = this.state;

        if (!product) {
            this.setState({error: "Fehler beim Abrufen der Produktinformationen. Bitte versuche es später erneut."});
        } else if (!reason) {
            this.setState({error: "Bitte gib an warum du dieses Produkt melden möchtest."});
        } else {
            Auth.checkRecaptcha()
            .then(res => {
                this.captcha.reset();

                axios.post('/api/report-product', {reason: reason, product: product})
                .then(response => {
                    let {data} = response;

                    if (!data.success) {
                        this.setState({error: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut."});
                    } else {
                        this.setState({reason: ""});
                        this.setState({formSuccess: true});
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({error: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut."});
                });
            })
            .catch(err => {
                this.setState({error: "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal."});
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <CSSTransition
                    in={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit={true}>
                    <div className="popup-overlay">
                        <div className="popup-wrapper">
                            <div className="close-btn" onClick={this.props.handleReportPopClose}>
                                <Close />
                            </div>
                            {this.state.formSuccess ? (
                                <React.Fragment>
                                    <h3 className="color-primary">Vielen Dank!</h3>
                                    <p>Wir werden uns so bald wie möglich um deine Nachricht kümmern.</p>
                                    <span className="btn btn-primary" onClick={this.props.handleReportPopClose}>Fenster schließen</span>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <h3 className="color-primary">Produkt melden</h3>
                                    <p>Bitte teile uns kurz mit warum du dieses Produkt melden möchtest.</p>
                                    <form method="post" onSubmit={(e) => { e.preventDefault();this.captcha.execute(); }}>
                                        <div className="form-group">
                                            <textarea className="form-control" name="reason" rows="4" required onChange={e => this.setState({reason: e.target.value})} value={this.state.reason} />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Produkt melden</button>
                                        <Reaptcha
                                            ref={e => (this.captcha = e)}
                                            size="invisible"
                                            sitekey="6LeEAaIUAAAAAHVNxEUt6ZjKHzvCA_6gPuwjjK0S"
                                            onVerify={this.handleReportSubmit}
                                            onError={() => this.setState({error: "ReCAPTCHA-Fehler aufgetreten! Bitte lade die Seite neu."})}
                                        />
                                    </form>
                                    {this.state.error ? (<p className="alert alert-danger mt-20">{this.state.error}</p>) : null}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        );
    }
}

export default ReportProductPopup;
