import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Link,
    NavLink,
    Route,
} from "react-router-dom";
import logo from "../img/myfeedingcheck_logo.png";
import arrowDown from "../img/arrow-down.png";
import {
    Add,
    PersonOutlined,
    PowerSettingsNew,
    ExitToApp,
    PersonAdd,
} from "@material-ui/icons";
import { FiFacebook, FiInstagram, FiMail } from 'react-icons/fi';

class NavBarTop extends Component {
    render() {
        return (
            <nav className="navbar">
                <div className="nav-topbar">
                    <div className="topbar-left">
                        {/* <Link to="/produkte/neues-produkt-erstellen"><Add />Produkt hinzufügen</Link> */}
                    </div>

                    <div className="topbar-right">
                            <a href="https://www.facebook.com/myfeedingcheck/" target="_blank">
                                <div><FiFacebook /></div>
                            </a>
                            <a href="https://www.instagram.com/myfeedingcheck/" target="_blank">
                                <div><FiInstagram /></div>
                            </a>
                        {!this.props.isAuthenticated ? (
                            <>
                                <Link to="/login">
                                    <ExitToApp />
                                    Anmelden
                                </Link>
                                <Link to="/registrieren">
                                    <PersonAdd />
                                    Registrieren
                                </Link>
                            </>
                        ) : (
                            <>
                                <Link to="/profil">
                                    <PersonOutlined />
                                    Profil
                                </Link>
                                <Link to="/logout">
                                    <PowerSettingsNew />
                                    Abmelden
                                </Link>
                            </>
                        )}
                    </div>
                </div>
                <div className="logo-container container">
                    <Link to="/">
                        <img src={logo}></img>
                    </Link>
                </div>
            </nav>
        );
    }
}

export default NavBarTop;
