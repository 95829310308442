let functions = {
    getCheapestVariant: (variants) => {
        let bestVariant;
        if (variants && Array.isArray(variants) && variants.length > 0) {
            variants.forEach((v) => {
                let {
                    unit,
                    size,
                    amount,
                    price,
                    is_for_sale,
                    published,
                    in_stock,
                    updatedByBot,
                    merchant_name,
                    owner
                } = v;
                if (
                    unit &&
                    size &&
                    amount &&
                    price &&
                    is_for_sale &&
                    published &&
                    in_stock &&
                    updatedByBot &&
                    (merchant_name !== 'b2b' || (owner && owner.status && owner.status === 'active'))
                ) {
                    if (unit == "kg") {
                        size = size * 1000;
                        unit = "g";
                    } else if (unit == "l") {
                        size = size * 1000;
                        unit = "ml";
                    }

                    if (unit == "g" || unit == "ml") {
                        v.basePrice = Math.round((price / (amount * size)) * 10000) / 100;
                    }
                } else {
                    v.basePrice = undefined
                }
            });

            variants = variants.filter(v => v.published && v.is_for_sale && v.updatedByBot && v.in_stock && v.basePrice).sort((a, b) => {
                if (a.basePrice > b.basePrice) {
                    return 1;
                } else if (a.basePrice < b.basePrice) {
                    return -1;
                } else {
                    return 0;
                }
            });

            bestVariant = variants[0] && variants[0].basePrice ? variants[0] : undefined;
            return bestVariant;
        }

        return undefined
    }
};

export default functions;