import axios from 'axios';

class Auth {
    static authenticateUser(token) {
        localStorage.setItem('jwtToken', token);
    }

    static isUserAuthenticated() {
        return localStorage.getItem('jwtToken') !== null;
    }

    static deauthenticateUser() {
        localStorage.removeItem('jwtToken');
    }

    static getToken() {
        return localStorage.getItem('jwtToken');
    }

    static getUserFromToken() {
        let token = localStorage.getItem('jwtToken');

        if(!token || token === '') {
            return;
        }

        return fetch('/api/user-from-token', {
            headers: {
                token: token
            }
        })
        .then(response => response.json())
        .then(u => {
            if(u.user) {
                let user = u.user;
                return user;
            } else {
                return;
            }
        })
        .catch(error => {
            return;
        });
    }

    static getToken() {
        let token = localStorage.getItem('jwtToken');
        return token;
    }

    static checkRecaptcha() {
        let rcToken = document.querySelector('textarea.g-recaptcha-response').value;

        return new Promise((resolve, reject) => {
            if (rcToken) {

                axios.post('/auth/verify-recaptcha', {token: rcToken})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    resolve(err);
                });
            } else {
                reject();
            }
        });
    }
}

export default Auth;
