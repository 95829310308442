import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SC from './../modules/StringConverter';
import ReactTooltip from "react-tooltip";

class IodCalculator extends Component {
    state = {
        animal: null,
        weight: null,
        calcium: null,
        metaWeight: null,
        calciumPerDay: null,
        calciumPerWeek: null,
        foodPerDay: null,
        foodPerWeek: null,
        result: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    calculate() {
        let { animal, weight, calcium } = this.state;
        let metaWeight, calciumPerDay, calciumPerWeek, foodPerDay, foodPerWeek;

        this.setState({
            result: false
        });

        if (animal && weight && calcium) {
            weight = parseFloat(weight);

            if (animal == 'dog') {
                metaWeight = Math.pow(weight, 0.75);

                calciumPerDay = metaWeight * 29.6;
            } else if (animal == 'cat') {
                metaWeight = Math.pow(weight, 0.67);

                calciumPerDay = metaWeight * 35;
            }

            calciumPerWeek = calciumPerDay * 7;
            foodPerDay = calciumPerDay / calcium;
            foodPerWeek = calciumPerWeek / calcium;

            this.setState({result: true, metaWeight, calciumPerDay, calciumPerWeek, foodPerDay, foodPerWeek});
        }
    }

    handleValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });

        setTimeout(() => {
            this.calculate();
        }, 200);
    };

    render() {
        return (
            <div id="calculator-view" className="calcium">
                <Helmet>
                    <meta
                        name="description"
                        content="Diesen Jod - Rechner kannst du für alle Jod-Supplemente verwenden, wie zum Beispiel Seealgenmehl und Jodetten.					"
                        id="meta-desc-compare"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Jodbedarfs&shy;rechner
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">
                                Jodbedarfsrechner
                            </h1>
                            <p>
                            Jod ist ein essentieller Nährstoff für Hunde &amp; Katzen. Aus diesem Grund 
                            sollte immer sichergestellt sein, dass Jod bedarfsdeckend gefüttert wird. Deutliche Defizite 
                            oder Überschüsse sollten vermieden werden, um die natürliche Schilddrüsenfunktion nicht zu stören.						
                            <br /><br />
                            Diesen Jod-Rechner kannst du für alle Jod-Supplemente verwenden, wie zum Beispiel Seealgenmehl und Jodetten.					
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h2 className="color-primary">
                                Daten eingeben
                            </h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="animal">
                                        Tierart
                                    </label>
                                </div>
                                <div className="form-input">
                                    <select
                                        className="form-control"
                                        name="animal"
                                        onChange={this.handleValueChange}
                                    >
                                        <option disabled selected value>
                                            Tierart auswählen
                                        </option>
                                        <option
                                            value="dog"
                                            selected={
                                                this.state.animal ===
                                                "dog"
                                            }
                                        >
                                            Hund
                                        </option>
                                        <option
                                            value="cat"
                                            selected={
                                                this.state.animal ===
                                                "cat"
                                            }
                                        >
                                            Katze
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Gewicht deines Tieres [kg]</label>
                                </div>
                                <div className="form-input">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="weight"
                                        placeholder="Gewicht in KG"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.weight}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Jodgehalt im Präparat [mg/kg]</label>
                                </div>
                                <div className="form-input">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="calcium"
                                        placeholder="Jodgehalt in mg/kg"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.calcium}
                                    ></input>
                                </div>
                            </div>
                        </div>

                        {this.state.calciumPerDay && this.state.calciumPerWeek && this.state.foodPerDay && this.state.foodPerWeek ? (
                            <div className="col-lg-6 mt-30-md result-2">
                                <div className="flex">
                                    <h2 className="color-primary">
                                        Jodbedarf
                                    </h2>
                                    <div>
                                        <div><span>{SC.replaceDecimal(SC.round2Digits(this.state.calciumPerDay)) || 'FEHLER'} &micro;g</span><span>pro Tag</span></div>
                                        <div><span>{SC.replaceDecimal(SC.round2Digits(this.state.calciumPerWeek)) || 'FEHLER'} &micro;g</span><span>pro Woche</span></div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <h2 className="color-primary">
                                        Präparatmenge
                                    </h2>
                                    <div>
                                        <div><span>{SC.replaceDecimal(SC.round2Digits(this.state.foodPerDay)) || 'FEHLER'}{this.state.foodPerDay && ' g'}</span><span>pro Tag</span></div>
                                        <div><span>{SC.replaceDecimal(SC.round2Digits(this.state.foodPerWeek)) || 'FEHLER'}{this.state.foodPerWeek && ' g'}</span><span>pro Woche</span></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-6 text-center mt-30-md">
                                <span>Daten eingeben.</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default IodCalculator;
