import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Terms extends Component {
    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <div id="imprint-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Nutzungsbedingungen</span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="color-primary">Nutzungsbedingungen für MyFeedingCheck.com</h1>
                            <h2>§ 1 Geltungsbereich</h2>
                            <ol>
                                <li>Für die Nutzung von www.myfeedingcheck.com (nachfolgend "Anbieter" genannt) gelten nachfolgende Bedingungen.</li>
                                <li>MyFeedingCheck.com ist eine Internetplattform, welche den Benutzern kostenlos zur Verfügung steht. Es werden Informationen über kommerzielle Produkte für Hunde und Katzen angeboten, welche auch von Benutzern bewertet werden können.</li>
                                <li>Alle Angaben sind unverbindlich. Der Anbieter kann jeder Zeit, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung verändern, ergänzen, löschen oder die Veröffentlichung zeitweise oder endgültig einstellen.</li>
                            </ol>
                            <h2>§ 2 Benutzerkonto und Vertragsschluss </h2>
                            <ol>
                                <li>Durch Nutzung und Inanspruchnahme der Leistungen vom Anbieter erklärt sich der Nutzer vollständig einverstanden mit den gegebenen Nutzungsbedingungen. Sollten diese dem Nutzer nicht entsprechen, ist es Ihm nicht erlaubt die Leistungen zu verwenden.</li>
                                <li>Nutzer können sich kostenlos für weitere Funktionen (bewerten, kommentieren, Favoritenliste) registrieren und anmelden. Hierfür müssen ein Benutzername und eine Emailadresse sowie ein Passwort angegeben werden. Mit der Bestätigung über eine E-Mail werden die AGB akzeptiert.</li>
                                <li>Alle Dienste unterliegen den gleichen Einschränkungen.</li>
                                <li>Deutsch zählt als einzige Vertragssprache.</li>
                                <li>Eine Registrierung gilt als Geschäftsbeziehung zwischen Nutzer und Anbieter, das heißt bezogen auf diese darf der Anbieter den Nutzer auch über E-Mail jederzeit kontaktieren.</li>
                                <li>Das Nutzungsverhältnis zwischen dem Anbieter und dem Nutzer wird grundsätzlich auf unbestimmte Zeit eingegangen und kann von jeder Vertragspartei mit sofortiger Wirkung gekündigt werden. Es kann jeder Zeit ohne Angabe von Gründen der Zugang für Nutzer gesperrt werden. </li>
                            </ol>
                            <h2>§ 3 Beteiligung der Nutzer</h2>
                            <ol>
                                <li>
                                    Es besteht die Möglichkeit für Nutzer Kommentare zu Produkten hinzuzufügen.
                                    <br />Hier muss geltendes Recht (z.B. Straf-, Wettbewerbs- und Jugendschutzrecht) beachtet, und das Recht Dritter (z.B. Namens-, Marken-, Urheber- und Datenschutzrechte) darf nicht verletzt werden.
                                    <br />Ebenso müssen gute Sitten beachtet werden und der Inhalt darf keine Form von Spam oder Viren enthalten.
                                </li>
                                <li>Es darf keine Werbung auf der Website geschalten werden, welche zuvor nicht mit dem Anbieter abgeklärt wurde.</li>
                                <li>
                                    Der Anbieter ist nicht verpflichtet Inhalte zu entfernen oder zu bearbeiten, falls diese gegen die Bedingungen verstoßen, wird sich aber darum gekümmert, falls er darüber informiert wird.
                                    <br />Falls Inhalte gegen die Bedingungen verstoßen, soll dies bitte an info@myfeedingcheck.com gemeldet werden.
                                </li>
                                <li>Der Nutzer darf die Funktionsfähigkeit und Leistung der Website in keiner Weise beeinflussen und beispielsweise Abstürze oder Störungen des Betriebs hervorrufen.</li>
                                <li>Alle durch Dritte hinzugefügte Inhalte, dürfen vom Anbieter verwendet und weiterverwertet werden.</li>
                                <li>Inhalte der Website dürfen, ohne Abklärung mit dem Anbieter, von Dritten nicht weiterverwendet werden.</li>
                            </ol>
                            <h2>§ 4 Informationen</h2> 
                            <ol>
                                <li>Alle Produktdetails sind mit bestem Gewissen vom Anbieter oder Hersteller selbst manuell eingetragen, können jedoch Tippfehler enthalten. Produkte müssen auf der Verkaufsseite auf Richtigkeit zusätzlich kontrolliert werden. </li>
                                <li>Alle Marken- & Herstellerinformationen werden nicht vom Anbieter verfasst.</li>
                                <li>Alle Beiträge werden vom Anbieter selbst veröffentlich, Verfasser sind jedoch manchmal externe. Die Beiträge werden nur darauf kontrolliert, ob es keine Verstöße gegen die Anbieterregeln gibt (Hetze, Urheberrecht usw). Bezüglich Richtigkeit & Aktualität ist der Verfasser zu kontaktieren (Kontaktdaten beim Beitrag).</li>
                            </ol>
                            <h2>§ 5 Kein Onlineshop</h2>  
                            <ol>
                                <li>Der Anbieter ist kein Onlineshop, somit entsteht zwischen Nutzer und Anbieter nie ein Kaufvertrag.</li>
                                <li>Die Angebote und Informationen auf MyFeedingCheck.com entsprechen nicht immer der Vollständigkeit, vor allem nicht dann wenn sie von keinem B2B-FeedingCheck Konto bearbeitet werden. </li>
                                <li>Produkte die den Button „zum Shop“ aufweisen, werden über einen Affiliate Link vom Onlineshop für den Anbieter zur Verfügung gestellt.</li>
                                <li>Der Anbieter verantwortet sich nicht, dass hinter dem zur Verfügung gestellten Affiliate-Link der bestmögliche Preis steckt.</li>
                                <li>Als Amazon-Partner und Zusammenarbeit mit anderen Affiliate-Netzwerken verdient MyFeedingCompass e.U. bzw die Seite MyFeedingCheck.com an qualifizierten Verkäufen.</li>
                            </ol> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Terms;
