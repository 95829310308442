import React, {Component} from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { Close } from '@material-ui/icons';
import Reaptcha from 'reaptcha';
import Auth from '../modules/Auth';
import { Link } from "react-router-dom";

class PopupContactBrand extends Component {
    state = {
        error: "",
        email: "",
        name: "",
        message: "",
        formSuccess: false
    }

    handleContactSubmit = () => {
        this.setState({error: null});

        let {_id} = this.props.product;
        let {email, name, message} = this.state;

        if (!_id) {
            this.setState({error: "Fehler beim Abrufen der Produktinformationen. Bitte versuche es später erneut."});
        } else if (!email || !name || !message) {
            this.setState({error: "Bitte fülle alle Felder richtig aus."});
        } else {
            Auth.checkRecaptcha()
            .then(res => {
                this.captcha.reset();

                let data = {product: _id, email, name, message};

                axios.post('/api/contact-brand', data)
                .then(response => {
                    let {data} = response;

                    if (!data.success) {
                        this.setState({error: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut."});
                    } else {
                        this.setState({reason: ""});
                        this.setState({formSuccess: true});
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({error: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut."});
                });
            })
            .catch(err => {
                this.setState({error: "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal."});
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <CSSTransition
                    in={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit={true}>
                    <div className="popup-overlay">
                        <div className="popup-wrapper contact-brand-pop">
                            <div className="close-btn" onClick={this.props.handleContactPopClose}>
                                <Close />
                            </div>
                            {this.state.formSuccess ? (
                                <React.Fragment>
                                    <h3 className="color-primary">Vielen Dank!</h3>
                                    <p>Wir werden uns so bald wie möglich um deine Nachricht kümmern.</p>
                                    <span className="btn btn-primary" onClick={this.props.handleContactPopClose}>Fenster schließen</span>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <h3 className="color-primary">Stelle eine Frage zu {this.props.product.brand.name} - {this.props.product.series}</h3>
                                    <p>Über dieses Kontaktformular erreichst du direkt jemanden aus dem Kundendienst der Marke selbst und kannst so noch mehr Details zu einem Produkt erfragen.<br /><br />Beispiele:</p>
                                    <ul>
                                        <li>Wurde eine bestimmte Komponente verarbeitet?</li>
                                        <li>Ist das Produkt bei einer bestimmten Erkrankung geeignet?</li>
                                        <li>Wurde die Rezeptur verändert?</li>
                                    </ul>
                                    <p style={{margin: '30px auto 20px', textAlign: 'center'}}>Du hast Fragen zu MyFeedingCheck.com?<br />Dann stelle sie bitte über dieses <Link to="/kontakt">Kontaktformular</Link>.</p>
                                    <form method="post" onSubmit={(e) => { e.preventDefault();this.captcha.execute(); }}>
                                        <div className='flex-row'>
                                            <div className='form-group flex-column'>
                                                <label>Dein Name</label>
                                                <input className="form-control" name="name" type='text' required onChange={e => this.setState({name: e.target.value})} value={this.state.name} />
                                            </div>
                                            <div className='form-group flex-column'>
                                                <label>Deine Emailadresse</label>
                                                <input className="form-control" name="email" type='email' required onChange={e => this.setState({email: e.target.value})} value={this.state.email} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Deine Nachricht</label>
                                            <textarea className="form-control" name="message" rows="4" required onChange={e => this.setState({message: e.target.value})} value={this.state.message} />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Frage senden!</button>
                                        <Reaptcha
                                            ref={e => (this.captcha = e)}
                                            size="invisible"
                                            sitekey="6LeEAaIUAAAAAHVNxEUt6ZjKHzvCA_6gPuwjjK0S"
                                            onVerify={this.handleContactSubmit}
                                            onError={() => this.setState({error: "ReCAPTCHA-Fehler aufgetreten! Bitte lade die Seite neu."})}
                                        />
                                    </form>
                                    {this.state.error ? (<p className="alert alert-danger mt-20">{this.state.error}</p>) : null}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </CSSTransition>
            </React.Fragment>
        );
    }
}

export default PopupContactBrand;
