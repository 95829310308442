import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SC from './../modules/StringConverter';
import ReactTooltip from "react-tooltip";

class NaehrstoffbedarfCalculator extends Component {
    state = {
        animal: null,
        weight: null,
        metaWeight: null,
        proteinsNeed: null,
        proteinsDaily: null,
        proteinsWeekly: null,
        fatsNeed: null,
        fatsDaily: null,
        fatsWeekly: null,
        linolNeed: null,
        linolDaily: null,
        linolWeekly: null,
        alphaLinolNeed: null,
        alphaLinolDaily: null,
        alphaLinolWeekly: null,
        epaNeed: null,
        epaDaily: null,
        epaWeekly: null,
        calciumNeed: null,
        calciumDaily: null,
        calciumWeekly: null,
        chloridNeed: null,
        chloridDaily: null,
        chloridWeekly: null,
        eisenNeed: null,
        eisenDaily: null,
        eisenWeekly: null,
        iodNeed: null,
        iodDaily: null,
        iodWeekly: null,
        kaliumNeed: null,
        kaliumDaily: null,
        kaliumWeekly: null,
        kupferNeed: null,
        kupferDaily: null,
        kupferWeekly: null,
        magnesiumNeed: null,
        magnesiumDaily: null,
        magnesiumWeekly: null,
        manganNeed: null,
        manganDaily: null,
        manganWeekly: null,
        natriumNeed: null,
        natriumDaily: null,
        natriumWeekly: null,
        phosphorNeed: null,
        phosphorDaily: null,
        phosphorWeekly: null,
        selenNeed: null,
        selenDaily: null,
        selenWeekly: null,
        zinkNeed: null,
        zinkDaily: null,
        zinkWeekly: null,
        vitANeed: null,
        vitADaily: null,
        vitAWeekly: null,
        vitB1Need: null,
        vitB1Daily: null,
        vitB1Weekly: null,
        vitB2Need: null,
        vitB2Daily: null,
        vitB2Weekly: null,
        vitB3Need: null,
        vitB3Daily: null,
        vitB3Weekly: null,
        vitB4Need: null,
        vitB4Daily: null,
        vitB4Weekly: null,
        vitB5Need: null,
        vitB5Daily: null,
        vitB5Weekly: null,
        vitB6Need: null,
        vitB6Daily: null,
        vitB6Weekly: null,
        vitB9Need: null,
        vitB9Daily: null,
        vitB9Weekly: null,
        vitB12Need: null,
        vitB12Daily: null,
        vitB12Weekly: null,
        vitDNeed: null,
        vitDDaily: null,
        vitDWeekly: null,
        vitENeed: null,
        vitEDaily: null,
        vitEWeekly: null,
        vitHNeed: null,
        vitHDaily: null,
        vitHWeekly: null,
        vitKNeed: null,
        vitKDaily: null,
        vitKWeekly: null,
        argininNeed: null,
        argininDaily: null,
        argininWeekly: null,
        histidinNeed: null,
        histidinDaily: null,
        histidinWeekly: null,
        isoleucinNeed: null,
        isoleucinDaily: null,
        isoleucinWeekly: null,
        leucinNeed: null,
        leucinDaily: null,
        leucinWeekly: null,
        lysinNeed: null,
        lysinDaily: null,
        lysinWeekly: null,
        methioninNeed: null,
        methioninDaily: null,
        methioninWeekly: null,
        methioninCystinNeed: null,
        methioninCystinDaily: null,
        methioninCystinWeekly: null,
        phenylalaninNeed: null,
        phenylalaninDaily: null,
        phenylalaninWeekly: null,
        phenylalaninTyrosinNeed: null,
        phenylalaninTyrosinDaily: null,
        phenylalaninTyrosinWeekly: null,
        taurinNeed: null,
        taurinDaily: null,
        taurinWeekly: null,
        threoninNeed: null,
        threoninDaily: null,
        threoninWeekly: null,
        tryptophanNeed: null,
        tryptophanDaily: null,
        tryptophanWeekly: null,
        valinNeed: null,
        valinDaily: null,
        valinWeekly: null,
        result: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    calculate() {
        let { animal, weight } = this.state;

        if (animal && weight) {
            weight = parseFloat(weight);

            this.setState({
                metaWeight: null,
                proteinsNeed: null,
                proteinsDaily: null,
                proteinsWeekly: null,
                fatsNeed: null,
                fatsDaily: null,
                fatsWeekly: null,
                linolNeed: null,
                linolDaily: null,
                linolWeekly: null,
                alphaLinolNeed: null,
                alphaLinolDaily: null,
                alphaLinolWeekly: null,
                epaNeed: null,
                epaDaily: null,
                epaWeekly: null,
                calciumNeed: null,
                calciumDaily: null,
                calciumWeekly: null,
                chloridNeed: null,
                chloridDaily: null,
                chloridWeekly: null,
                eisenNeed: null,
                eisenDaily: null,
                eisenWeekly: null,
                iodNeed: null,
                iodDaily: null,
                iodWeekly: null,
                kaliumNeed: null,
                kaliumDaily: null,
                kaliumWeekly: null,
                kupferNeed: null,
                kupferDaily: null,
                kupferWeekly: null,
                magnesiumNeed: null,
                magnesiumDaily: null,
                magnesiumWeekly: null,
                manganNeed: null,
                manganDaily: null,
                manganWeekly: null,
                natriumNeed: null,
                natriumDaily: null,
                natriumWeekly: null,
                phosphorNeed: null,
                phosphorDaily: null,
                phosphorWeekly: null,
                selenNeed: null,
                selenDaily: null,
                selenWeekly: null,
                zinkNeed: null,
                zinkDaily: null,
                zinkWeekly: null,
                vitANeed: null,
                vitADaily: null,
                vitAWeekly: null,
                vitAMicroNeed: null,
                vitAMicroDaily: null,
                vitAMicroWeekly: null,
                vitB1Need: null,
                vitB1Daily: null,
                vitB1Weekly: null,
                vitB2Need: null,
                vitB2Daily: null,
                vitB2Weekly: null,
                vitB3Need: null,
                vitB3Daily: null,
                vitB3Weekly: null,
                vitB4Need: null,
                vitB4Daily: null,
                vitB4Weekly: null,
                vitB5Need: null,
                vitB5Daily: null,
                vitB5Weekly: null,
                vitB6Need: null,
                vitB6Daily: null,
                vitB6Weekly: null,
                vitB9Need: null,
                vitB9Daily: null,
                vitB9Weekly: null,
                vitB12Need: null,
                vitB12Daily: null,
                vitB12Weekly: null,
                vitDNeed: null,
                vitDDaily: null,
                vitDWeekly: null,
                vitDMicroNeed: null,
                vitDMicroDaily: null,
                vitDMicroWeekly: null,
                vitENeed: null,
                vitEDaily: null,
                vitEWeekly: null,
                vitHNeed: null,
                vitHDaily: null,
                vitHWeekly: null,
                vitKNeed: null,
                vitKDaily: null,
                vitKWeekly: null,
                argininNeed: null,
                argininDaily: null,
                argininWeekly: null,
                histidinNeed: null,
                histidinDaily: null,
                histidinWeekly: null,
                isoleucinNeed: null,
                isoleucinDaily: null,
                isoleucinWeekly: null,
                leucinNeed: null,
                leucinDaily: null,
                leucinWeekly: null,
                lysinNeed: null,
                lysinDaily: null,
                lysinWeekly: null,
                methioninNeed: null,
                methioninDaily: null,
                methioninWeekly: null,
                methioninCystinNeed: null,
                methioninCystinDaily: null,
                methioninCystinWeekly: null,
                phenylalaninNeed: null,
                phenylalaninDaily: null,
                phenylalaninWeekly: null,
                phenylalaninTyrosinNeed: null,
                phenylalaninTyrosinDaily: null,
                phenylalaninTyrosinWeekly: null,
                taurinNeed: null,
                taurinDaily: null,
                taurinWeekly: null,
                threoninNeed: null,
                threoninDaily: null,
                threoninWeekly: null,
                tryptophanNeed: null,
                tryptophanDaily: null,
                tryptophanWeekly: null,
                valinNeed: null,
                valinDaily: null,
                valinWeekly: null,
                result: false
            });

            let metaWeight, proteinsNeed, proteinsDaily, proteinsWeekly, fatsNeed, fatsDaily, fatsWeekly, linolNeed, 
            linolDaily, linolWeekly, alphaLinolNeed, alphaLinolDaily, alphaLinolWeekly, epaNeed, epaDaily, epaWeekly, 
            calciumNeed, calciumDaily, calciumWeekly, chloridNeed, chloridDaily, chloridWeekly, eisenNeed, eisenDaily, 
            eisenWeekly, iodNeed, iodDaily, iodWeekly, kaliumNeed, kaliumDaily, kaliumWeekly, kupferNeed, kupferDaily, 
            kupferWeekly, magnesiumNeed, magnesiumDaily, magnesiumWeekly, manganNeed, manganDaily, manganWeekly, 
            natriumNeed, natriumDaily, natriumWeekly, phosphorNeed, phosphorDaily, phosphorWeekly, selenNeed, selenDaily, 
            selenWeekly, zinkNeed, zinkDaily, zinkWeekly, vitANeed, vitADaily, vitAWeekly, vitB1Need, vitB1Daily, 
            vitB1Weekly, vitB2Need, vitB2Daily, vitB2Weekly, vitB3Need, vitB3Daily, vitB3Weekly, vitB4Need, vitB4Daily, 
            vitB4Weekly, vitB5Need, vitB5Daily, vitB5Weekly, vitB6Need, vitB6Daily, vitB6Weekly, vitB9Need, vitB9Daily, 
            vitB9Weekly, vitB12Need, vitB12Daily, vitB12Weekly, vitDNeed, vitDDaily, vitDWeekly, vitENeed, vitEDaily, 
            vitEWeekly, vitHNeed, vitHDaily, vitHWeekly, vitKNeed, vitKDaily, vitKWeekly, argininNeed, argininDaily, 
            argininWeekly, histidinNeed, histidinDaily, histidinWeekly, isoleucinNeed, isoleucinDaily, isoleucinWeekly, 
            leucinNeed, leucinDaily, leucinWeekly, lysinNeed, lysinDaily, lysinWeekly, methioninNeed, methioninDaily, 
            methioninWeekly, methioninCystinNeed, methioninCystinDaily, methioninCystinWeekly, phenylalaninNeed, 
            phenylalaninDaily, phenylalaninWeekly, phenylalaninTyrosinNeed, phenylalaninTyrosinDaily, 
            phenylalaninTyrosinWeekly, taurinNeed, taurinDaily, taurinWeekly, threoninNeed, threoninDaily, threoninWeekly, 
            tryptophanNeed, tryptophanDaily, tryptophanWeekly, valinNeed, valinDaily, valinWeekly, vitDMicroNeed,
            vitDMicroDaily, vitDMicroWeekly, vitAMicroNeed, vitAMicroDaily, vitAMicroWeekly;

            if (animal == 'dog') {
                metaWeight = Math.pow(weight, 0.75);

                proteinsNeed = 3.28;
                fatsNeed = 1.8;
                linolNeed = 0.36;
                alphaLinolNeed = 0.014;
                epaNeed = 0.03;
                calciumNeed = 0.13;
                chloridNeed = 40;
                eisenNeed = 1;
                iodNeed = 29.6;
                kaliumNeed = 0.14;
                kupferNeed = 0.2;
                magnesiumNeed = 19.7;
                manganNeed = 0.16;
                natriumNeed = 26.2;
                phosphorNeed = 0.1;
                selenNeed = 11.8;
                zinkNeed = 1;
                vitANeed = 167;
                vitAMicroNeed = 50;
                vitB1Need = 0.074;
                vitB2Need = 0.171;
                vitB3Need = 0.57;
                vitB4Need = 56;
                vitB5Need = 0.49;
                vitB6Need = 0.049;
                vitB9Need = 8.9;
                vitB12Need = 1.15;
                vitDNeed = 18;
                vitDMicroNeed = 0.45;
                vitENeed = 1;
                vitKNeed = 0.054;
                argininNeed = 110;
                histidinNeed = 62;
                isoleucinNeed = 120;
                leucinNeed = 220;
                lysinNeed = 110;
                methioninNeed = 110;
                methioninCystinNeed = 210;
                phenylalaninNeed = 150;
                phenylalaninTyrosinNeed = 240;
                threoninNeed = 140;
                tryptophanNeed = 46;
                valinNeed = 160;
            } else if (animal == 'cat') {
                metaWeight = Math.pow(weight, 0.67);

                proteinsNeed = 4.96;
                fatsNeed = 2.2;
                linolNeed = 0.14;
                alphaLinolNeed = 0.0015;
                epaNeed = 0.0025;
                calciumNeed = 0.071;
                chloridNeed = 23.7;
                eisenNeed = 1.98;
                iodNeed = 35;
                kaliumNeed = 0.13;
                kupferNeed = 0.119;
                magnesiumNeed = 9.5;
                manganNeed = 0.119;
                natriumNeed = 16.7;
                phosphorNeed = 0.063;
                selenNeed = 6.95;
                zinkNeed = 1.9;
                vitANeed = 82.3;
                vitAMicroNeed = 24.7;
                vitB1Need = 0.14;
                vitB2Need = 0.099;
                vitB3Need = 0.99;
                vitB4Need = 63;
                vitB5Need = 0.14;
                vitB6Need = 0.06;
                vitB9Need = 19;
                vitB12Need = 0.56;
                vitDNeed = 6.8;
                vitDMicroNeed = 0.17;
                vitENeed = 0.94;
                vitHNeed = 1.9;
                vitKNeed = 0.025;
                argininNeed = 190;
                histidinNeed = 64;
                isoleucinNeed = 110;
                leucinNeed = 250;
                lysinNeed = 84;
                methioninNeed = 42;
                methioninCystinNeed = 84;
                phenylalaninNeed = 99;
                phenylalaninTyrosinNeed = 380;
                taurinNeed = 9.9;
                threoninNeed = 130;
                tryptophanNeed = 32;
                valinNeed = 130;
            }

            proteinsDaily = (proteinsNeed * metaWeight);
            fatsDaily = (fatsNeed * metaWeight);
            linolDaily = (linolNeed * metaWeight);
            alphaLinolDaily = (alphaLinolNeed * metaWeight);
            epaDaily = (epaNeed * metaWeight);
            calciumDaily = (calciumNeed * metaWeight);
            chloridDaily = (chloridNeed * metaWeight);
            eisenDaily = (eisenNeed * metaWeight);
            iodDaily = (iodNeed * metaWeight);
            kaliumDaily = (kaliumNeed * metaWeight);
            kupferDaily = (kupferNeed * metaWeight);
            magnesiumDaily = (magnesiumNeed * metaWeight);
            manganDaily = (manganNeed * metaWeight);
            natriumDaily = (natriumNeed * metaWeight);
            phosphorDaily = (phosphorNeed * metaWeight);
            selenDaily = (selenNeed * metaWeight);
            zinkDaily = (zinkNeed * metaWeight);
            vitADaily = (vitANeed * metaWeight);
            vitAMicroDaily = (vitAMicroNeed * metaWeight);
            vitB1Daily = (vitB1Need * metaWeight);
            vitB2Daily = (vitB2Need * metaWeight);
            vitB3Daily = (vitB3Need * metaWeight);
            vitB4Daily = (vitB4Need * metaWeight);
            vitB5Daily = (vitB5Need * metaWeight);
            vitB6Daily = (vitB6Need * metaWeight);
            vitB9Daily = (vitB9Need * metaWeight);
            vitB12Daily = (vitB12Need * metaWeight);
            vitDDaily = (vitDNeed * metaWeight);
            vitDMicroDaily = (vitDMicroNeed * metaWeight);
            vitEDaily = (vitENeed * metaWeight);
            if (vitHNeed) vitHDaily = (vitHNeed * metaWeight);
            vitKDaily = (vitKNeed * metaWeight);
            argininDaily = (argininNeed * metaWeight);
            histidinDaily = (histidinNeed * metaWeight);
            isoleucinDaily = (isoleucinNeed * metaWeight);
            leucinDaily = (leucinNeed * metaWeight);
            lysinDaily = (lysinNeed * metaWeight);
            methioninDaily = (methioninNeed * metaWeight);
            methioninCystinDaily = (methioninCystinNeed * metaWeight);
            phenylalaninDaily = (phenylalaninNeed * metaWeight);
            phenylalaninTyrosinDaily = (phenylalaninTyrosinNeed * metaWeight);
            if (taurinNeed) taurinDaily = (taurinNeed * metaWeight);
            threoninDaily = (threoninNeed * metaWeight);
            tryptophanDaily = (tryptophanNeed * metaWeight);
            valinDaily = (valinNeed * metaWeight);

            proteinsWeekly = (proteinsDaily * 7);
            fatsWeekly = (fatsDaily * 7);
            linolWeekly = (linolDaily * 7);
            alphaLinolWeekly = (alphaLinolDaily * 7);
            epaWeekly = (epaDaily * 7);
            calciumWeekly = (calciumDaily * 7);
            chloridWeekly = (chloridDaily * 7);
            eisenWeekly = (eisenDaily * 7);
            iodWeekly = (iodDaily * 7);
            kaliumWeekly = (kaliumDaily * 7);
            kupferWeekly = (kupferDaily * 7);
            magnesiumWeekly = (magnesiumDaily * 7);
            manganWeekly = (manganDaily * 7);
            natriumWeekly = (natriumDaily * 7);
            phosphorWeekly = (phosphorDaily * 7);
            selenWeekly = (selenDaily * 7);
            zinkWeekly = (zinkDaily * 7);
            vitAWeekly = (vitADaily * 7);
            vitAMicroWeekly = (vitAMicroDaily * 7);
            vitB1Weekly = (vitB1Daily * 7);
            vitB2Weekly = (vitB2Daily * 7);
            vitB3Weekly = (vitB3Daily * 7);
            vitB4Weekly = (vitB4Daily * 7);
            vitB5Weekly = (vitB5Daily * 7);
            vitB6Weekly = (vitB6Daily * 7);
            vitB9Weekly = (vitB9Daily * 7);
            vitB12Weekly = (vitB12Daily * 7);
            vitDWeekly = (vitDDaily * 7);
            vitDMicroWeekly = (vitDMicroDaily * 7);
            vitEWeekly = (vitEDaily * 7);
            if (vitHNeed) vitHWeekly = (vitHDaily * 7);
            vitKWeekly = (vitKDaily * 7);
            argininWeekly = (argininDaily * 7);
            histidinWeekly = (histidinDaily * 7);
            isoleucinWeekly = (isoleucinDaily * 7);
            leucinWeekly = (leucinDaily * 7);
            lysinWeekly = (lysinDaily * 7);
            methioninWeekly = (methioninDaily * 7);
            methioninCystinWeekly = (methioninCystinDaily * 7);
            phenylalaninWeekly = (phenylalaninDaily * 7);
            phenylalaninTyrosinWeekly = (phenylalaninTyrosinDaily * 7);
            if (taurinNeed) taurinWeekly = (taurinDaily * 7);
            threoninWeekly = (threoninDaily * 7);
            tryptophanWeekly = (tryptophanDaily * 7);
            valinWeekly = (valinDaily * 7);

            let data = {
                metaWeight, proteinsNeed, proteinsDaily, proteinsWeekly, fatsNeed, fatsDaily, fatsWeekly, linolNeed, 
                linolDaily, linolWeekly, alphaLinolNeed, alphaLinolDaily, alphaLinolWeekly, epaNeed, epaDaily, epaWeekly, 
                calciumNeed, calciumDaily, calciumWeekly, chloridNeed, chloridDaily, chloridWeekly, eisenNeed, eisenDaily, 
                eisenWeekly, iodNeed, iodDaily, iodWeekly, kaliumNeed, kaliumDaily, kaliumWeekly, kupferNeed, kupferDaily, 
                kupferWeekly, magnesiumNeed, magnesiumDaily, magnesiumWeekly, manganNeed, manganDaily, manganWeekly, 
                natriumNeed, natriumDaily, natriumWeekly, phosphorNeed, phosphorDaily, phosphorWeekly, selenNeed, selenDaily, 
                selenWeekly, zinkNeed, zinkDaily, zinkWeekly, vitANeed, vitADaily, vitAWeekly, vitB1Need, vitB1Daily, 
                vitB1Weekly, vitB2Need, vitB2Daily, vitB2Weekly, vitB3Need, vitB3Daily, vitB3Weekly, vitB4Need, vitB4Daily, 
                vitB4Weekly, vitB5Need, vitB5Daily, vitB5Weekly, vitB6Need, vitB6Daily, vitB6Weekly, vitB9Need, vitB9Daily, 
                vitB9Weekly, vitB12Need, vitB12Daily, vitB12Weekly, vitDNeed, vitDDaily, vitDWeekly, vitENeed, vitEDaily, 
                vitEWeekly, vitHNeed, vitHDaily, vitHWeekly, vitKNeed, vitKDaily, vitKWeekly, argininNeed, argininDaily, 
                argininWeekly, histidinNeed, histidinDaily, histidinWeekly, isoleucinNeed, isoleucinDaily, isoleucinWeekly, 
                leucinNeed, leucinDaily, leucinWeekly, lysinNeed, lysinDaily, lysinWeekly, methioninNeed, methioninDaily, 
                methioninWeekly, methioninCystinNeed, methioninCystinDaily, methioninCystinWeekly, phenylalaninNeed, 
                phenylalaninDaily, phenylalaninWeekly, phenylalaninTyrosinNeed, phenylalaninTyrosinDaily, 
                phenylalaninTyrosinWeekly, taurinNeed, taurinDaily, taurinWeekly, threoninNeed, threoninDaily, threoninWeekly, 
                tryptophanNeed, tryptophanDaily, tryptophanWeekly, valinNeed, valinDaily, valinWeekly, result: true,
                vitAMicroNeed, vitAMicroDaily, vitAMicroWeekly, vitDMicroNeed, vitDMicroDaily, vitDMicroWeekly
            };

            for (var x in data) {
                console.log(x);
                if (x == 'alphaLinolDaily' || x == 'alphaLinolWeekly' || x == 'alphaLinolNeed' || x == 'epaNeed' || x == 'epaWeekly' || x == 'epaDaily') data[x] = SC.replaceDecimal(SC.round3Digits(data[x]))
                else data[x] = SC.replaceDecimal(SC.round2Digits(data[x]))
                this.setState({[x]: data[x]})
            }
        }
    }

    handleValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });

        setTimeout(() => {
            this.calculate();
        }, 200);
    };

    render() {
        return (
            <div id="calculator-view" className="naehrstoff">
                <Helmet>
                    <meta
                        name="description"
                        content="Das National Research Council (kurz NRC) ist eine in Amerika gegründete Organisation, welche sich mit Bedarfswerten für Tiere beschäftigt. Die ermittelten Werte sind für Fertigfutterprodukte ausgelegt, welche mit einer selbsterstellten rohen/gekochten Fütterung meist nicht gedeckt werden können. Trotzdem können sie bei einer selbsterstellten Ration als Richtwert dienen."
                        id="meta-desc-compare"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Nährstoffbedarfs&shy;rechner
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">
                                Nährstoff-Bedarfsberechnung nach NRC
                            </h1>
                            <p>
                                Das National Research Council (kurz NRC) ist eine in Amerika gegründete Organisation, welche sich mit Bedarfswerten für Tiere beschäftigt. 
                                <br />Die ermittelten Werte sind für Fertigfutterprodukte ausgelegt, welche mit einer selbsterstellten rohen/gekochten Fütterung meist nicht gedeckt werden können. Trotzdem können sie bei einer selbsterstellten Ration als Richtwert dienen.
                                <br />
                                <br />Der NRC Bedarfsrechner bezieht sich auf ausgewachsene und gesunde Tiere.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h2 className="color-primary">
                                Informationen über dein Haustier
                            </h2>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-label">
                                <label for="animal">
                                    Tierart
                                </label>
                            </div>
                            <div className="form-input">
                                <select
                                    className="form-control"
                                    name="animal"
                                    onChange={this.handleValueChange}
                                >
                                    <option disabled selected value>
                                        Tierart auswählen
                                    </option>
                                    <option
                                        value="dog"
                                        selected={
                                            this.state.animal ===
                                            "dog"
                                        }
                                    >
                                        Hund
                                    </option>
                                    <option
                                        value="cat"
                                        selected={
                                            this.state.animal ===
                                            "cat"
                                        }
                                    >
                                        Katze
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Gewicht [kg]</label>
                                </div>
                                <div className="form-input">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="weight"
                                        placeholder="Gewicht in KG"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.weight}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`row result ${this.state.result && 'active'}`}>
                        <div className="col-12">
                            <h2 className="color-primary" style={{marginTop:20}}>
                                Metabolisches Gewicht
                            </h2>
                            <div>Das metabolische Gewicht (mG) deines Haustieres beträgt <b>{this.state.metaWeight || 'FEHLER'} kg</b>.</div>
                            <h2 className="color-primary">
                                Nährstoffbedarf
                            </h2>
                            <div className="my_table">
                                <div className="my_thead">
                                    <div className="tr">
                                        <div className="td name">Nährstoff</div>
                                        <div className="td unit">Einheit</div>
                                        <div className="td need">Bedarf/mG</div>
                                        <div className="td daily">pro Tag</div>
                                        <div className="td weekly">pro Woche</div>
                                    </div>
                                </div>
                                <div className="my_tbody">
                                    <div className="tr">
                                        <div className="td name">Rohprotein</div>
                                        <div className="td unit">g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.proteinsNeed || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.proteinsDaily || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.proteinsWeekly || 'FEHLER'}<span className="mobile-only">g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Fett</div>
                                        <div className="td unit">g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.fatsNeed || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.fatsDaily || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.fatsWeekly || 'FEHLER'}<span className="mobile-only">g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Linolsäure</div>
                                        <div className="td unit">g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.linolNeed || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.linolDaily || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.linolWeekly || 'FEHLER'}<span className="mobile-only">g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">{this.state.animal == 'cat' ? 'Arachidonsäure' : <>&alpha;-Linolsäure</>}</div>
                                        <div className="td unit">g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.alphaLinolNeed || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.alphaLinolDaily || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.alphaLinolWeekly || 'FEHLER'}<span className="mobile-only">g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">EPA + DHA</div>
                                        <div className="td unit">g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.epaNeed || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.epaDaily || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.epaWeekly || 'FEHLER'}<span className="mobile-only">g</span></div>
                                    </div>
                                </div>
                            </div>

                            <div className="my_table">
                                <div className="my_thead">
                                    <div className="tr">
                                        <div className="td name">Mineralien</div>
                                        <div className="td unit">Einheit</div>
                                        <div className="td need">Bedarf/mG</div>
                                        <div className="td daily">pro Tag</div>
                                        <div className="td weekly">pro Woche</div>
                                    </div>
                                </div>
                                <div className="my_tbody">
                                    <div className="tr">
                                        <div className="td name">Calcium</div>
                                        <div className="td unit">g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.calciumNeed || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.calciumDaily || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.calciumWeekly || 'FEHLER'}<span className="mobile-only">g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Chlorid</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.chloridNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.chloridDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.chloridWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Eisen</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.eisenNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.eisenDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.eisenWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Iod</div>
                                        <div className="td unit">&micro;g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.iodNeed || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.iodDaily || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.iodWeekly || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Kalium</div>
                                        <div className="td unit">g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.kaliumNeed || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.kaliumDaily || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.kaliumWeekly || 'FEHLER'}<span className="mobile-only">g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Kupfer</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.kupferNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.kupferDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.kupferWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Magnesium</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.magnesiumNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.magnesiumDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.magnesiumWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Mangan</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.manganNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.manganDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.manganWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Natrium</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.natriumNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.natriumDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.natriumWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Phosphor</div>
                                        <div className="td unit">g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.phosphorNeed || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.phosphorDaily || 'FEHLER'}<span className="mobile-only">g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.phosphorWeekly || 'FEHLER'}<span className="mobile-only">g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Selen</div>
                                        <div className="td unit">&micro;g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.selenNeed || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.selenDaily || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.selenWeekly || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Zink</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.zinkNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.zinkDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.zinkWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                </div>
                            </div>

                            <div className="my_table">
                                <div className="my_thead">
                                    <div className="tr">
                                        <div className="td name">Vitamine</div>
                                        <div className="td unit">Einheit</div>
                                        <div className="td need">Bedarf/mG</div>
                                        <div className="td daily">pro Tag</div>
                                        <div className="td weekly">pro Woche</div>
                                    </div>
                                </div>
                                <div className="my_tbody">
                                    <div className="tr" data-tip data-for="tip-1">
                                        <div className="td name">Vitamin A</div>
                                        <div className="td unit">IE</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitANeed || 'FEHLER'}<span className="mobile-only">IE</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitADaily || 'FEHLER'}<span className="mobile-only">IE</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitAWeekly || 'FEHLER'}<span className="mobile-only">IE</span></div>
                                    </div>
                                    <ReactTooltip id="tip-1" place="top" type="dark" effect="float" className="react-tooltip">
                                        <span>
                                            bzw. {this.state.vitAMicroNeed || 'FEHLER'} &micro;g =
                                            <br />{this.state.vitAMicroDaily || 'FEHLER'} &micro;g/Tag =
                                            <br />{this.state.vitAMicroWeekly || 'FEHLER'} &micro;g/Woche =
                                        </span>
                                    </ReactTooltip>
                                    <div className="tr">
                                        <div className="td name">Vitamin B1 Thiamin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitB1Need || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitB1Daily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitB1Weekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Vitamin B2 Riboflavin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitB2Need || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitB2Daily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitB2Weekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Vitamin B3 Niacin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitB3Need || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitB3Daily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitB3Weekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Vitamin B4 Cholin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitB4Need || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitB4Daily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitB4Weekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Vitamin B5 Pantothensäure</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitB5Need || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitB5Daily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitB5Weekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Vitamin B6 Pyridoxin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitB6Need || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitB6Daily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitB6Weekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Vitamin B9 Folsäure</div>
                                        <div className="td unit">&micro;g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitB9Need || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitB9Daily || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitB9Weekly || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Vitamin B12</div>
                                        <div className="td unit">&micro;g</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitB12Need || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitB12Daily || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitB12Weekly || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                    </div>
                                    <div className="tr" data-tip data-for="tip-2">
                                        <div className="td name">Vitamin D</div>
                                        <div className="td unit">IE</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitDNeed || 'FEHLER'}<span className="mobile-only">IE</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitDDaily || 'FEHLER'}<span className="mobile-only">IE</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitDWeekly || 'FEHLER'}<span className="mobile-only">IE</span></div>
                                    </div>
                                    <ReactTooltip id="tip-2" place="top" type="dark" effect="float" className="react-tooltip">
                                        <span>
                                            bzw. {this.state.vitDMicroNeed || 'FEHLER'} &micro;g =
                                            <br />{this.state.vitDMicroDaily || 'FEHLER'} &micro;g/Tag =
                                            <br />{this.state.vitDMicroWeekly || 'FEHLER'} &micro;g/Woche =
                                        </span>
                                    </ReactTooltip>
                                    <div className="tr">
                                        <div className="td name">Vitamin E</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitENeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitEDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitEWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    {this.state.animal == 'cat' && (
                                        <div className="tr">
                                            <div className="td name">Vitamin H Biotin</div>
                                            <div className="td unit">&micro;g</div>
                                            <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitHNeed || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                            <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitHDaily || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                            <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitHWeekly || 'FEHLER'}<span className="mobile-only">&micro;g</span></div>
                                        </div>
                                    )}
                                    <div className="tr">
                                        <div className="td name">Vitamin K</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.vitKNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.vitKDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.vitKWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                </div>
                            </div>

                            <div className="my_table">
                                <div className="my_thead">
                                    <div className="tr">
                                        <div className="td name">Aminosäuren</div>
                                        <div className="td unit">Einheit</div>
                                        <div className="td need">Bedarf/mG</div>
                                        <div className="td daily">pro Tag</div>
                                        <div className="td weekly">pro Woche</div>
                                    </div>
                                </div>
                                <div className="my_tbody">
                                    <div className="tr">
                                        <div className="td name">Arginin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.argininNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.argininDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.argininWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Histidin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.histidinNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.histidinDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.histidinWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Isoleucin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.isoleucinNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.isoleucinDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.isoleucinWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Leucin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.leucinNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.leucinDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.leucinWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Lysin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.lysinNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.lysinDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.lysinWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Methionin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.methioninNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.methioninDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.methioninWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Methionin/Cystin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.methioninCystinNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.methioninCystinDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.methioninCystinWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Phenylalanin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.phenylalaninNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.phenylalaninDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.phenylalaninWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Phenylalanin/Tyrosin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.phenylalaninTyrosinNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.phenylalaninTyrosinDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.phenylalaninTyrosinWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    {this.state.animal == 'cat' && (
                                        <div className="tr">
                                            <div className="td name">Taurin</div>
                                            <div className="td unit">mg</div>
                                            <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.taurinNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                            <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.taurinDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                            <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.taurinWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        </div>
                                    )}
                                    <div className="tr">
                                        <div className="td name">Threonin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.threoninNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.threoninDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.threoninWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Tryptophan</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.tryptophanNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.tryptophanDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.tryptophanWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                    <div className="tr">
                                        <div className="td name">Valin</div>
                                        <div className="td unit">mg</div>
                                        <div className="td need"><span className="mobile-only">Bedarf/mG</span>{this.state.valinNeed || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td daily"><span className="mobile-only">pro Tag</span>{this.state.valinDaily || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                        <div className="td weekly"><span className="mobile-only">pro Woche</span>{this.state.valinWeekly || 'FEHLER'}<span className="mobile-only">mg</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NaehrstoffbedarfCalculator;
