let vars = {};

vars.bucketUrl = 'https://bucket-mfc-wallride67.storage.googleapis.com/';

vars.diseases = [
    { value: "allergic", label: "Allergie/Unverträglichkeit" },
    { value: "respiratory", label: "Atemwege" },
    { value: "bauchspeicheldruese", label: "Bauchspeicheldrüse" },
    { value: "detoxification", label: "Entgiftung & Entschlackung" },
    { value: "diabetes", label: "Diabetes mellitus" },
    { value: "gelenke", label: "Gelenke & Knochen" },
    { value: "harnweg", label: "Harnweg" },
    { value: "hauthaar", label: "Haut & Fell" },
    { value: "herzkreislauf", label: "Herz & Kreislauf" },
    { value: "immunsystem", label: "Immunsystem" },
    { value: "leber", label: "Leber" },
    { value: "magendarm", label: "Magen-Darm" },
    { value: "muskeln", label: "Muskeln" },
    { value: "niere", label: "Niere" },
    { value: "parasiten", label: "Parasiten" },
    { value: "purinarm", label: "purinarm" },
    { value: "schonkost", label: "Schonkost" },
    { value: "sport", label: "Sport & Aktiv" },
    { value: "stoffwechsel", label: "Stoffwechsel" },
    { value: "stress", label: "Psyche" },
    { value: "zucht", label: "Trächtigkeit & Aufzucht" },
    { value: "uebergewicht", label: "Übergewicht" },
    { value: "untergewicht", label: "Untergewicht" },
    { value: "zahnpflege", label: "Zahn- & Maulpflege" }
]

export default vars;