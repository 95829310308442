import React, { Component } from 'react';
import { BrowserRouter as Router, Link, NavLink, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Auth from './../modules/Auth';

class ResetPassword extends Component {
    state = {
        newPassword: "",
        newPasswordConfirm: "",
        displayErrorMsg: false,
        errorMsg: [],
        redirectToLogin: false
    }

    constructor(props) {
        super(props);

        this.handlePasswordResetForm = this.handlePasswordResetForm.bind(this);
    }



    handlePasswordResetForm(event) {
        event.preventDefault();

        function getQueryStringValue (key) {
            return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
        }

        let token = getQueryStringValue('token');

        let newPassword = this.state.newPassword.trim();
        let newPasswordConfirm = this.state.newPasswordConfirm.trim();

        this.state.errorMsg = [];
        this.setState({displayErrorMsg: false});

        if (newPassword.length <= 0) {
            this.state.errorMsg.push("Bitte geben Sie eine neues Passwort ein.");
        }
        if (newPasswordConfirm.length <= 0) {
            this.state.errorMsg.push("Bitte das Passwort bestätigen.");
        }
        if (newPasswordConfirm !== newPassword) {
            this.state.errorMsg.push("Die Passwörter sind nicht identisch.");
        }
        if (!token || token.length < 10) {
            this.state.errorMsg.push("Bitte fordern Sie einen neuen Link zum Zurücksetzen des Passworts an.");
        }

        if (this.state.errorMsg.length > 0) {
            this.setState({displayErrorMsg: true});
        } else {
            this.setState({displayErrorMsg: false});

            axios.post('auth/reset-password', {
                newPassword: newPassword,
                newPasswordConfirm: newPasswordConfirm,
                token: token
            })
            .then(response => {
                if (response.data.success === true) {
                    this.setState({redirectToLogin: true});
                } else {
                    this.state.errorMsg.push(response.data.message);
                    this.setState({displayErrorMsg: true});
                }
            })
            .catch(error => {
                this.setState({errorMsg: ["Ein Fehler ist aufgetreten und Ihr Passwort wurde nicht zurückgesetzt!"]});
                this.setState({displayErrorMsg: true});
            });
        }
    }

    render() {
        if (this.state.redirectToLogin === true) {
            return <Redirect to='/login' />
        }

        return (
            <div id="login-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Passwort zurücksetzen</span>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-12 col-lg-8 col-md-10">
                            <h1 class="color-primary">Passwort zurücksetzen</h1>
                            <p>
                                    Gib hier dein neues Passwort ein.
                            </p>
                            {this.state.displayErrorMsg && (
                                <p className="alert alert-danger">{this.state.errorMsg[0]}</p>
                            )}
                            <form method="post" onSubmit={this.handlePasswordResetForm}>
                                <div class="form-group">
                                    <label for="newPassword" className="nopadding col-12 col-md-5">Neues Passwort</label>
                                    <input className="nopadding col-12 col-md-7 form-control" type="password" name="newPassword" id="newPassword" placeholder="Passwort" required onChange={e => this.setState({newPassword: e.target.value})}></input>
                                </div>
                                <div class="form-group">
                                    <label for="newPasswordConfirm" className="nopadding col-12 col-md-5">Neues Passwort bestätigen</label>
                                    <input className="nopadding col-12 col-md-7 form-control" type="password" name="newPasswordConfirm" id="newPasswordConfirm" placeholder="Passwort bestätigen" required onChange={e => this.setState({newPasswordConfirm: e.target.value})}></input>
                                </div>
                                <input type="submit" value="Passwort speichern" className="btn btn-primary"></input>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;
