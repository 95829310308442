import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const localeStringOptions = { maximumSignificantDigits: 9 }

class CalculatorUnits extends Component {
    state = {
        massKg: null,
        massMg: null,
        massG: null,
        massMicro: null,
        energyMJ: null,
        energyKj: null,
        energyKcal: null,
        vitamineAIe: null,
        vitamineAMicro: null,
        vitamineDIe: null,
        vitamineDMicro: null,
        vitamineEIe: null,
        vitamineEMicro: null,
    };

    componentDidMount() {
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    handleValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });
    };

    render() {
        return (
            <div id="calculator-view" className="units">
                <Helmet>
                    <meta
                        name="description"
                        content="Ja das mit den Einheiten ist immer so eine Sache. Wer nicht regelmäßig damit zu tun hat, hat dann ab und zu Probleme mit den Umrechnungsfaktoren… Deshalb gibt es hier eine Unterstützung für dich!"
                        id="meta-desc-compare"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Einheiten&shy;rechner
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">
                                Einheiten Umrechner
                            </h1>
                            <p>
                                Ja das mit den Einheiten ist immer so eine Sache. Wer nicht regelmäßig 
                                damit zu tun hat, hat dann ab und zu Probleme mit den Umrechnungsfaktoren… 
                                Deshalb gibt es hier eine Unterstützung für dich!					
                            </p>
                            <small>Der Rechner wurde nach bestem Gewissen erstellt, aber ohne Gewähr.</small>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h2 className="color-primary">
                                Masse-Einheiten
                            </h2>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Masse in kg</label>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="massKg"
                                        placeholder="kg"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.massKg}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massKg ? (this.state.massKg * 1000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="g"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">g</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massKg ? (this.state.massKg * 1000000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="mg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">mg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massKg ? (this.state.massKg * 1000000000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="μg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Masse in g</label>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="massG"
                                        placeholder="g"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.massG}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">g</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massG ? (this.state.massG / 1000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="kg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massG ? (this.state.massG * 1000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="mg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">mg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massG ? (this.state.massG * 1000000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="μg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Masse in mg</label>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="massMg"
                                        placeholder="mg"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.massMg}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">mg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massMg ? (this.state.massMg / 1000000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="kg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massMg ? (this.state.massMg / 1000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="g"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">g</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massMg ? (this.state.massMg * 1000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="μg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Masse in μg</label>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="massMicro"
                                        placeholder="μg"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.massMicro}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massMicro ? (this.state.massMicro / 1000000000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="kg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massMicro ? (this.state.massMicro / 1000000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="g"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">g</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.massMicro ? (this.state.massMicro / 1000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="mg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">mg</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h2 className="color-primary">
                                Energie-Einheiten
                            </h2>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Energie in MJ</label>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="energyMJ"
                                        placeholder="MJ"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.energyMJ}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">MJ</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.energyMJ ? (this.state.energyMJ * 1000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="kJ"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kJ</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.energyMJ ? (this.state.energyMJ * 239).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="kcal"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kcal</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Energie in kJ</label>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="energyKj"
                                        placeholder="kJ"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.energyKj}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kJ</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.energyKj ? (this.state.energyKj * 1000).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="MJ"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">MJ</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.energyKj ? (this.state.energyKj * .239).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="kcal"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kcal</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Energie in kcal</label>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="energyKcal"
                                        placeholder="kcal"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.energyKcal}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kcal</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.energyKcal ? (this.state.energyKcal * .004184).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="MJ"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">MJ</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.energyKcal ? (this.state.energyKcal * 4.184).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="kJ"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">kJ</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h2 className="color-primary">
                                Vitamin A, D &amp; E
                            </h2>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Vitamin A</label>
                                    <p>
                                        = Retinol
                                        <br />1 µm = 3,333 IE
                                    </p>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="vitamineAIe"
                                        placeholder="IE"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.vitamineAIe}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">IE</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.vitamineAIe ? (this.state.vitamineAIe * .3).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="μg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{marginTop: 40}}>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="vitamineAMicro"
                                        placeholder="μg"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.vitamineAMicro}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.vitamineAMicro ? (this.state.vitamineAMicro / .3).toLocaleString('de-DE', localeStringOptions) : ''}
                                        placeholder="IE"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">IE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Vitamin D</label>
                                    <p>
                                        = Cholecalciferol
                                        <br />1 µm = 40 IE
                                    </p>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="vitamineDIe"
                                        placeholder="IE"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.vitamineDIe}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">IE</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.vitamineDIe ? (this.state.vitamineDIe / 40).toLocaleString('de-DE', { maximumSignificantDigits: 2 }) : ''}
                                        placeholder="μg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{marginTop: 40}}>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="vitamineDMicro"
                                        placeholder="μg"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.vitamineDMicro}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.vitamineDMicro ? (this.state.vitamineDMicro * 40).toLocaleString('de-DE', { maximumSignificantDigits: 2 }) : ''}
                                        placeholder="IE"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">IE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Vitamin E</label>
                                    <p>
                                        = α-Tocopherol
                                        <br />1 mg = 1,49 IE
                                    </p>
                                </div>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="vitamineEIe"
                                        placeholder="IE"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.vitamineEIe}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">IE</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.vitamineEIe ? (this.state.vitamineEIe / 1.49).toLocaleString('de-DE', { maximumSignificantDigits: 2 }) : ''}
                                        placeholder="μg"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{marginTop: 40}}>
                                <div className="form-input input-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="vitamineEMicro"
                                        placeholder="μg"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.vitamineEMicro}
                                    ></input>
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">μg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-input input-group">
                                    <input
                                        type='text'
                                        className="form-control"
                                        disabled
                                        value={this.state.vitamineEMicro ? (this.state.vitamineEMicro * 1.49).toLocaleString('de-DE', { maximumSignificantDigits: 2 }) : ''}
                                        placeholder="IE"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="basic-addon2">IE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <small>IE = internationale Einheiten</small>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CalculatorUnits;
