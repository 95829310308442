class SC {
    static convert(target) {
        if (Array.isArray(target)) {
            let arr = [];

            //Type1
            if (target.includes('wet')) arr.push('Nassfutter');
            if (target.includes('dried')) arr.push('Trockenfutter');
            if (target.includes('raw')) arr.push('Rohfutter');
            if (target.includes('flakes')) arr.push('Flocken & Co.');
            if (target.includes('suplement')) arr.push('Nahrungsergänzung');
            //Katze/Hund
            if (target.includes('cat')) arr.push('Katze');
            if (target.includes('dog')) arr.push('Hund');
            //Type2
            if (target.includes('complete')) arr.push('Alleinfutter');
            if (target.includes('supplement-pure')) arr.push('Reinfleisch');
            if (target.includes('supplement-snack')) arr.push('Ergänzungsfutter');
            if (target.includes('snack')) arr.push('Snack');
            if (target.includes('single-food')) arr.push('Einzelfuttermittel');
            if (target.includes('dry-barf')) arr.push('Trockenbarf');
            if (target.includes('driedFruit')) arr.push('getrocknetes Obst');
            if (target.includes('driedVegetables')) arr.push('getrocknetes Gemüse');
            if (target.includes('grainsAndPseudograins')) arr.push('Getreide & Pseudogetreide');
            if (target.includes('dryMix')) arr.push('Trockenmix');
            if (target.includes('singlePreparation')) arr.push('Einzelpräparat');
            if (target.includes('completePreparation')) arr.push('Komplettpräparat');
            if (target.includes('combiPreparation')) arr.push('Kombinationspräparat');
            if (target.includes('chews')) arr.push('Kauartikel');
            //AgeGroup
            if (target.includes('baby')) arr.push('Welpe/Kitten');
            if (target.includes('junior')) arr.push('Junior');
            if (target.includes('adult')) arr.push('Adult');
            if (target.includes('senior')) arr.push('Senior');
            //Packaging
            if (target.includes('box')) arr.push('Box');
            if (target.includes('can')) arr.push('Dose');
            if (target.includes('bag')) arr.push('Sack');
            if (target.includes('bowl')) arr.push('Schale');
            if (target.includes('pouch')) arr.push('Pouch');
            if (target.includes('glas')) arr.push('Glas');
            if (target.includes('tube')) arr.push('Tube');
            if (target.includes('pvcBottle')) arr.push('Kunststoffbehälter/-flasche');
            if (target.includes('tetrapack')) arr.push('Tetrapack');
            if (target.includes('sausage')) arr.push('Wurstform');
            //AnimalComponents
            if (target.includes('antilope')) arr.push('Antilope');
            if (target.includes('barsch')) arr.push('Barsch');
            if (target.includes('bison')) arr.push('Bison');
            if (target.includes('buffel')) arr.push('Büffel');
            if (target.includes('dorsch')) arr.push('Dorsch');
            if (target.includes('elch')) arr.push('Elch');
            if (target.includes('ente')) arr.push('Ente');
            if (target.includes('esel')) arr.push('Esel');
            if (target.includes('fasan')) arr.push('Fasan');
            if (target.includes('fisch')) arr.push('Fisch');
            if (target.includes('flunder')) arr.push('Flunder');
            if (target.includes('forelle')) arr.push('Forelle');
            if (target.includes('gans')) arr.push('Gans');
            if (target.includes('garnele')) arr.push('Garnele');
            if (target.includes('geflugel')) arr.push('Geflügel');
            if (target.includes('hase')) arr.push('Hase');
            if (target.includes('hering')) arr.push('Hering');
            if (target.includes('hirsch')) arr.push('Hirsch');
            if (target.includes('huhn')) arr.push('Huhn');
            if (target.includes('insekten')) arr.push('Insekten');
            if (target.includes('kabeljau')) arr.push('Kabeljau');
            if (target.includes('kalb')) arr.push('Kalb');
            if (target.includes('kamel')) arr.push('Kamel');
            if (target.includes('kanguru')) arr.push('Känguru');
            if (target.includes('kaninchen')) arr.push('Kaninchen');
            if (target.includes('krabbe')) arr.push('Krabbe');
            if (target.includes('krebs')) arr.push('Krebs');
            if (target.includes('krokodil')) arr.push('Krokodil');
            if (target.includes('lachs')) arr.push('Lachs');
            if (target.includes('lama')) arr.push('Lama');
            if (target.includes('lamm')) arr.push('Lamm');
            if (target.includes('lodde')) arr.push('Lodde');
            if (target.includes('makrelle')) arr.push('Makrelle');
            if (target.includes('meeresfruechte')) arr.push('Meeresfrüchte');
            if (target.includes('muschel')) arr.push('Muschel');
            if (target.includes('ochse')) arr.push('Ochse');
            if (target.includes('perlhuhn')) arr.push('Perlhuhn');
            if (target.includes('pferd')) arr.push('Pferd');
            if (target.includes('pute')) arr.push('Pute');
            if (target.includes('reh')) arr.push('Reh');
            if (target.includes('rentier')) arr.push('Rentier');
            if (target.includes('rind')) arr.push('Rind');
            if (target.includes('sardelle')) arr.push('Sardelle');
            if (target.includes('sardine')) arr.push('Sardine');
            if (target.includes('schaf')) arr.push('Schaf');
            if (target.includes('schwein')) arr.push('Schwein');
            if (target.includes('seelachs')) arr.push('Seelachs');
            if (target.includes('seewolf')) arr.push('Seewolf');
            if (target.includes('seezunge')) arr.push('Seezunge');
            if (target.includes('stint')) arr.push('Stint');
            if (target.includes('stoer')) arr.push('Stör');
            if (target.includes('strauß')) arr.push('Strauß');
            if (target.includes('thunfisch')) arr.push('Thunfisch');
            if (target.includes('truthahn')) arr.push('Truthahn');
            if (target.includes('unbekannt')) arr.push('Unbekannt');
            if (target.includes('wachtel')) arr.push('Wachtel');
            if (target.includes('wasserbuffel')) arr.push('Wasserbüffel');
            if (target.includes('wild')) arr.push('Wild');
            if (target.includes('wildlachs')) arr.push('Wildlachs');
            if (target.includes('wildschwein')) arr.push('Wildschwein');
            if (target.includes('zebra')) arr.push('Zebra');
            if (target.includes('ziege')) arr.push('Ziege');
            //Gesundheit
            if (target.includes('allergic')) arr.push('Allergie/Unverträglichkeit');
            if (target.includes('respiratory')) arr.push('Atemwege');
            if (target.includes('bauchspeicheldruese')) arr.push('Bauchspeicheldrüse');
            if (target.includes("detoxification")) arr.push("Entgiftung & Entschlackung");
            if (target.includes("diabetes")) arr.push("Diabetes mellitus");
            if (target.includes("gelenke")) arr.push("Gelenke & Knochen");
            if (target.includes("harnweg")) arr.push("Harnweg");
            if (target.includes("hauthaar")) arr.push("Haut & Fell");
            if (target.includes("herzkreislauf")) arr.push("Herz & Kreislauf");
            if (target.includes("immunsystem")) arr.push("Immunsystem");
            if (target.includes("leber")) arr.push("Leber");
            if (target.includes("magendarm")) arr.push("Magen-Darm");
            if (target.includes("muskeln")) arr.push("Muskeln");
            if (target.includes("niere")) arr.push("Niere");
            if (target.includes("parasiten")) arr.push("Parasiten");
            if (target.includes("purinarm")) arr.push("purinarm");
            if (target.includes("schonkost")) arr.push("Schonkost");
            if (target.includes("sport")) arr.push("Sport & Aktiv");
            if (target.includes("stoffwechsel")) arr.push("Stoffwechsel");
            if (target.includes("stress")) arr.push("Psyche");
            if (target.includes("zucht")) arr.push("Trächtigkeit & Aufzucht");
            if (target.includes("uebergewicht")) arr.push("Übergewicht");
            if (target.includes("untergewicht")) arr.push("Untergewicht");
            if (target.includes("zahnpflege")) arr.push("Zahn- & Maulpflege");
            //Attributes
            if (target.includes('biological')) arr.push('Bio-Qualität (Fleisch)');
            if (target.includes('extruded')) arr.push('extrudiert');
            if (target.includes('meatFirst')) arr.push('Fleisch an erster Stelle');
            if (target.includes('baked')) arr.push('gebacken');
            if (target.includes('freezedried')) arr.push('gefriergetrocknet');
            if (target.includes('gmfree')) arr.push('gentechnikfrei');
            if (target.includes('grainfree')) arr.push('getreidefrei');
            if (target.includes('glutenfree')) arr.push('glutenfrei');
            if (target.includes('hypoallergenic')) arr.push('hypoallergen');
            if (target.includes('bottledcold')) arr.push('kalt abgefüllt');
            if (target.includes('pressedcold')) arr.push('kaltgepresst');
            if (target.includes('lactosefree')) arr.push('laktosefrei');
            if (target.includes('foodquality')) arr.push('Lebensmittelqualität');
            if (target.includes('airdried')) arr.push('luftgetrocknet');
            if (target.includes('monoprotein')) arr.push('mono- / singleprotein');
            if (target.includes('natural')) arr.push('naturbelassen');
            if (target.includes('notextruded')) arr.push('nicht extrudiert');
            if (target.includes('woantioxidants')) arr.push('ohne Antioxidantien');
            if (target.includes('woflavors')) arr.push('ohne Aromen');
            if (target.includes('wochemicaladditives')) arr.push('ohne chem. / künstliche Zusatzstoffe');
            if (target.includes('woemulsifiers')) arr.push('ohne Emulgatoren');
            if (target.includes('wodyes')) arr.push('ohne Farbstoffe');
            if (target.includes('woflavorenhancers')) arr.push('ohne Geschmacksverstärker');
            if (target.includes('wobonemeal')) arr.push('ohne Knochenmehl');
            if (target.includes('wopreservativesubstances')) arr.push('ohne Konservierungsstoffe');
            if (target.includes('woanimalmeal')) arr.push('ohne Tiermehl');
            if (target.includes('woanimalexperiments')) arr.push('ohne Tierversuche');
            if (target.includes('cookedgently')) arr.push('schonend gegart / dampf gegart');
            if (target.includes('sensitive')) arr.push('sensitive');
            if (target.includes('soyfree')) arr.push('sojafrei');
            if (target.includes('wheatfree')) arr.push('weizenfrei');
            if (target.includes('sugarfree')) arr.push('zuckerfrei');
            if (target.includes('womeatflour')) arr.push('ohne Fleischmehle');
            if (target.includes('woattractants')) arr.push('ohne Lockstoffe');
            if (target.includes('wofillers')) arr.push('ohne Füllstoffe');
            if (target.includes('womoldmeat')) arr.push('ohne Formfleisch');
            if (target.includes('noaddedsugar')) arr.push('ohne Zuckerzusatz');
            if (target.includes('wobinder')) arr.push('ohne (künstliche) Bindemittel');
            if (target.includes('taurin')) arr.push('nur Taurin als Zusatzstoff');
            if (target.includes('vegan')) arr.push('vegan');
            if (target.includes('vegetarian')) arr.push('vegetarisch');
            if (target.includes('purinarm')) arr.push('purinarm');
            //countriesOfDelivery
            if (target.includes('austria')) arr.push('Österreich');
            if (target.includes('germany')) arr.push('Deutschland');
            if (target.includes('switzerland')) arr.push('Schweiz');
            //processings
            if (target.includes('throughout')) arr.push('im Ganzen');
            if (target.includes('lumpy')) arr.push('stückig');
            if (target.includes('minced')) arr.push('gewolft');
            if (target.includes('flakes')) arr.push('Flocken');
            if (target.includes('pellets')) arr.push('Pellets');
            if (target.includes('oil')) arr.push('Öl');
            if (target.includes('liquid')) arr.push('Flüssigkeit');
            if (target.includes('powder')) arr.push('Pulver');
            if (target.includes('tablet')) arr.push('Tablette');
            if (target.includes('capsule')) arr.push('Kapsel');
            if (target.includes('granules')) arr.push('Granulat');
            //elements
            if (target.includes('bareBones')) arr.push('blanke Knochen');
            if (target.includes('leafStomach')) arr.push('Blättermagen');
            if (target.includes('blood')) arr.push('Blut');
            if (target.includes('fat')) arr.push('Fett');
            if (target.includes('meat')) arr.push('Fleisch');
            if (target.includes('fleshyBonesAndCartilage')) arr.push('fleischige Knochen & Knorpeln');
            if (target.includes('wholeAnimal')) arr.push('ganzes Tier');
            if (target.includes('wholeAnimalWoHead')) arr.push('ganzes Tier ohne Kopf');
            if (target.includes('throat')) arr.push('Hals');
            if (target.includes('heart')) arr.push('Herz');
            if (target.includes('offal')) arr.push('Innereien');
            if (target.includes('carcass')) arr.push('Karkasse');
            if (target.includes('head')) arr.push('Kopf');
            if (target.includes('body')) arr.push('Körper');
            if (target.includes('liver')) arr.push('Leber');
            if (target.includes('lung')) arr.push('Lunge');
            if (target.includes('stomach')) arr.push('Magen');
            if (target.includes('spleen')) arr.push('Milz');
            if (target.includes('mixed')) arr.push('Mix');
            if (target.includes('notVisible')) arr.push('nicht erkennbar');
            if (target.includes('kidney')) arr.push('Niere');
            if (target.includes('rumen')) arr.push('Pansen');
            if (target.includes('tongue')) arr.push('Zunge');
            if (target.includes('alfalfaLucerne')) arr.push('Alfalfa/Luzerne');
            if (target.includes('amaranth')) arr.push('Amaranth');
            if (target.includes('apple')) arr.push('Apfel');
            if (target.includes('banana')) arr.push('Banane');
            if (target.includes('broccoli')) arr.push('Brokkoli');
            if (target.includes('buckwheat')) arr.push('Buchweizen');
            if (target.includes('chia')) arr.push('Chiasamen');
            if (target.includes('chicory')) arr.push('Chicoree');
            if (target.includes('peas')) arr.push('Erbsen');
            if (target.includes('potato')) arr.push('Erdäpfel / Kartoffel');
            if (target.includes('oats')) arr.push('Hafer');
            if (target.includes('millet')) arr.push('Hirse');
            if (target.includes('carrot')) arr.push('Karotte');
            if (target.includes('herbs')) arr.push('Kräuter-Mix');
            if (target.includes('corn')) arr.push('Mais');
            if (target.includes('notVisible')) arr.push('nicht erkennbar');
            if (target.includes('parsnip')) arr.push('Pastinake');
            if (target.includes('quinoa')) arr.push('Quinoa');
            if (target.includes('rice')) arr.push('Reis');
            if (target.includes('beetroot')) arr.push('rote Beete');
            if (target.includes('beets')) arr.push('rote Rüben');
            if (target.includes('celery')) arr.push('Sellerie');
            if (target.includes('spinach')) arr.push('Spinat ');
            if (target.includes('sweetPotato')) arr.push('Süßkartoffel');
            if (target.includes('zucchini')) arr.push('Zucchini');
            if (target.includes('seaweed')) arr.push('Algen-Mix');
            if (target.includes('brewersYeast')) arr.push('Bierhefe');
            if (target.includes('Bcomplex')) arr.push('B-Komplex');
            if (target.includes('blood')) arr.push('Blut');
            if (target.includes('nettles')) arr.push('Brennnesseln');
            if (target.includes('buckwheatGerm')) arr.push('Buchweizenkeime');
            if (target.includes('calciumCitrate')) arr.push('Calciumcitrat');
            if (target.includes('cbd')) arr.push('CBD');
            if (target.includes('enzymes')) arr.push('Pankreasenzyme');
            if (target.includes('fat')) arr.push('Fett');
            if (target.includes('fishOil')) arr.push('Fischöl');
            if (target.includes('meatAndBoneMeal')) arr.push('Fleisch- & Knochenmehl');
            if (target.includes('fleaSeedsAndHusks')) arr.push('Flohsamen & -schalen');
            if (target.includes('greenlippedMussel')) arr.push('Grünlippmuschel');
            if (target.includes('roseHip')) arr.push('Hagebutte');
            if (target.includes('honey')) arr.push('Honig');
            if (target.includes('humicSubstances')) arr.push('Huminstoffe');
            if (target.includes('catsClaw')) arr.push('Katzenkralle');
            if (target.includes('collagen')) arr.push('Kollagen');
            if (target.includes('herbs')) arr.push('Kräuter-Mix');
            if (target.includes('turmeric')) arr.push('Kurkuma');
            if (target.includes('codLiverOil')) arr.push('Lebertran');
            if (target.includes('minerals')) arr.push('Mineralien-Mix');
            if (target.includes('msm')) arr.push('MSM');
            if (target.includes('nutrientMix')) arr.push('Nährstoffmix');
            if (target.includes('notVisible')) arr.push('nicht erkennbar');
            if (target.includes('omegaFat')) arr.push('Omega 3');
            if (target.includes('symbiotics')) arr.push('Pro-/Prä-/Symbiotika');
            if (target.includes('devilsClaw')) arr.push('Teufelskralle');
            if (target.includes('jerusalemArtichoke')) arr.push('Topinambur');
            if (target.includes('vitamins')) arr.push('Vitamin-Mix');
            if (target.includes('bachFlowers')) arr.push('Bachblüten');
            if (target.includes('amminos')) arr.push('Amminosäuren');
            if (target.includes('taurin')) arr.push('Taurin');
            if (target.includes('mucilages')) arr.push('Eibischwurzel');
            if (target.includes('plantOils')) arr.push('Pflanzenöl-Mix');
            if (target.includes('acaciaFiber')) arr.push('Akazienfasern');
            if (target.includes('algaeOil')) arr.push('Algenöl');
            if (target.includes('basePowder')) arr.push('Basenpulver');
            if (target.includes('borageOil')) arr.push('Borretschöl');
            if (target.includes('bromelain')) arr.push('Bromelain');
            if (target.includes('calciumCarbonate')) arr.push('Calciumcarbonat');
            if (target.includes('chlorella')) arr.push('Chlorella');
            if (target.includes('chondroitin')) arr.push('Chondroitin (Knorpelmehl)');
            if (target.includes('safflowerOil')) arr.push('Distelöl');
            if (target.includes('eggshells')) arr.push('Eierschalen');
            if (target.includes('iron')) arr.push('Eisen');
            if (target.includes('em1')) arr.push('EM1');
            if (target.includes('emA')) arr.push('EM-A');
            if (target.includes('enzymeMix')) arr.push('Enzym-Mix');
            if (target.includes('fleaSeeds')) arr.push('Flohsamen');
            if (target.includes('psylliumHusks')) arr.push('Flohsamenschalen');
            if (target.includes('barleyGrass')) arr.push('Gerstengras');
            if (target.includes('glucosamine')) arr.push('Glucosamin');
            if (target.includes('bigBurdockRoot')) arr.push('Große Klettenwurzel');
            if (target.includes('hempOil')) arr.push('Hanföl');
            if (target.includes('potassium')) arr.push('Kalium');
            if (target.includes('garlic')) arr.push('Knoblauch');
            if (target.includes('coconutFlakes')) arr.push('Kokosflocken');
            if (target.includes('coconutOil')) arr.push('Kokosöl');
            if (target.includes('colloidalSilverWater')) arr.push('Kolloidalsilberwasser');
            if (target.includes('colostrum')) arr.push('Kolostrum');
            if (target.includes('krillOil')) arr.push('Krillöl');
            if (target.includes('copper')) arr.push('Kupfer');
            if (target.includes('lactulose')) arr.push('Lactulose');
            if (target.includes('lCarnitine')) arr.push('L-Carnitin');
            if (target.includes('linseedOil')) arr.push('Leinöl');
            if (target.includes('linseed')) arr.push('Leinsamen');
            if (target.includes('lGlutamine')) arr.push('L-Glutamin');
            if (target.includes('magnesium')) arr.push('Magnesium');
            if (target.includes('mctOil')) arr.push('MCT Öl');
            if (target.includes('eveningPrimroseOil')) arr.push('Nachtkerzenöl');
            if (target.includes('omega369')) arr.push('Omega 3 6 9');
            if (target.includes('papain')) arr.push('Papain');
            if (target.includes('pectin')) arr.push('Pektin');
            if (target.includes('phosphateBinders')) arr.push('Phosphatbinder');
            if (target.includes('propolis')) arr.push('Propolis');
            if (target.includes('quinoaSeedlings')) arr.push('Quinoa- Keimlingen');
            if (target.includes('blackSeedOil')) arr.push('Schwarzkümmelöl');
            if (target.includes('seaweed')) arr.push('Seealgen');
            if (target.includes('spirulina')) arr.push('Spirulina');
            if (target.includes('grapeSeed')) arr.push('Traubenkern');
            if (target.includes('elmBark')) arr.push('Ulmenrinde (Slippery Elm Bark)');
            if (target.includes('vitamineA')) arr.push('Vitamin A');
            if (target.includes('vitamineC')) arr.push('Vitamin C');
            if (target.includes('vitamineD')) arr.push('Vitamin D');
            if (target.includes('vitamineE')) arr.push('Vitamin E');
            if (target.includes('wheatgrass')) arr.push('Weizengras');
            if (target.includes('cellulose')) arr.push('Zellulose');
            if (target.includes('zinc')) arr.push('Zink');
            if (target.includes('caneMolasses')) arr.push('Zuckerrohrmelasse');
            if (target.includes('barley')) arr.push('Gerste');
            if (target.includes('pumpkin')) arr.push('Kürbis');
            if (target.includes('turnip')) arr.push('Steckrübe');
            if (target.includes('wheat')) arr.push('Weizen');
            // consultants
            if (target.includes('cooked')) arr.push('gekocht');
            if (target.includes('allergy')) arr.push('Futtermittelallergie');
            if (target.includes('joints')) arr.push('Gelenke');
            if (target.includes('cancer')) arr.push('Krebs');
            if (target.includes('organs')) arr.push('Organerkrankung');
            if (target.includes('lactating')) arr.push('säugend');
            if (target.includes('pregnant')) arr.push('trächtig');
            if (target.includes('environmental')) arr.push('Umweltallergie');
            if (target.includes('other')) arr.push('anderes');
            if (target.includes('aromaOil')) arr.push('Aromaöltherapie');
            if (target.includes('mycotherapy')) arr.push('Mykotherapie');
            if (target.includes('phytotherapy')) arr.push('Phytotherapie');
            if (target.includes('animalMedicine')) arr.push('Tierheilpraktik');
            if (target.includes('eb-senior')) arr.push('Senior');
            if (target.includes('eb-baby')) arr.push('Welpe & Junghund');
            if (target.includes('locally')) arr.push('vor Ort');
            if (target.includes('online')) arr.push('online');

            if (arr.length >= 1) {
                return arr;
            } else { return target; }
        } else if (typeof target === 'string') {
            //Type1
            if (target === 'wet') return 'Nassfutter';
            if (target === 'dried') return 'Trockenfutter';
            if (target === 'raw') return 'Rohfutter';
            if (target === 'flakes') return 'Flocken & Co.';
            if (target === 'suplement') return 'Nahrungsergänzung';
            //Katze/Hund
            if (target === 'cat') return 'Katze';
            if (target === 'dog') return 'Hund';
            //Type2
            if (target === 'complete') return 'Alleinfutter';
            if (target === 'supplement-pure') return 'Reinfleisch';
            if (target === 'supplement-snack') return 'Ergänzungsfutter';
            if (target === 'snack') return 'Snack';
            if (target === 'single-food') return 'Einzelfuttermittel';
            if (target === 'dry-barf') return 'Trockenbarf';
            if (target === 'driedFruit') return 'getrocknetes Obst';
            if (target === 'driedVegetables') return 'getrocknetes Gemüse';
            if (target === 'grainsAndPseudograins') return 'Getreide & Pseudogetreide';
            if (target === 'dryMix') return 'Trockenmix';
            if (target === 'singlePreparation') return 'Einzelpräparat';
            if (target === 'completePreparation') return 'Komplettpräparat';
            if (target === 'combiPreparation') return 'Kombinationspräparat';
            if (target === 'chews') return 'Kauartikel';
            //AgeGroup
            if (target === 'baby') return 'Welpe/Kitten';
            if (target === 'junior') return 'Junior';
            if (target === 'adult') return 'Adult';
            if (target === 'senior') return 'Senior';
            //Declaration
            if (target === 'na') return 'nicht angegeben';
            if (target === 'open-2') return 'offene Deklaration';
            if (target === 'open') return 'halboffene Deklaration';
            if (target === 'closed') return 'geschlossene Deklaration';
            if (target === 'partly') return 'Teildeklaration';
            //Packaging
            if (target === 'box') return 'Box';
            if (target === 'can') return 'Dose';
            if (target === 'bag') return 'Sack';
            if (target === 'bowl') return 'Schale';
            if (target === 'pouch') return 'Pouch';
            if (target === 'glas') return 'Glas';
            if (target === 'pvcBottle') return 'Kunststoffbehälter/-flasche';
            if (target === 'tube') return 'Tube';
            if (target === 'tetrapack') return 'Tetrapack';
            if (target === 'sausage') return 'Wurstform';
            //AnimalComponents
            if (target === 'antilope') return 'Antilope';
            if (target === 'barsch') return 'Barsch';
            if (target === 'bison') return 'Bison';
            if (target === 'buffel') return 'Büffel';
            if (target === 'dorsch') return 'Dorsch';
            if (target === 'esel') return 'Esel';
            if (target === 'elch') return 'Elch';
            if (target === 'ente') return 'Ente';
            if (target === 'fasan') return 'Fasan';
            if (target === 'fisch') return 'Fisch';
            if (target === 'flunder') return 'Flunder';
            if (target === 'forelle') return 'Forelle';
            if (target === 'gans') return 'Gans';
            if (target === 'garnele') return 'Garnele';
            if (target === 'geflugel') return 'Geflügel';
            if (target === 'hase') return 'Hase';
            if (target === 'hering') return 'Hering';
            if (target === 'hirsch') return 'Hirsch';
            if (target === 'huhn') return 'Huhn';
            if (target === 'insekten') return 'Insekten';
            if (target === 'kabeljau') return 'Kabeljau';
            if (target === 'kalb') return 'Kalb';
            if (target === 'kamel') return 'Kamel';
            if (target === 'kanguru') return 'Känguru';
            if (target === 'kaninchen') return 'Kaninchen';
            if (target === 'krabbe') return 'Krabbe';
            if (target === 'krebs') return 'Krebs';
            if (target === 'krokodil') return 'Krokodil';
            if (target === 'lachs') return 'Lachs';
            if (target === 'lama') return 'Lama';
            if (target === 'lamm') return 'Lamm';
            if (target === 'lodde') return 'Lodde';
            if (target === 'makrelle') return 'Makrelle';
            if (target === 'meeresfruechte') return 'Meeresfrüchte';
            if (target === 'muschel') return 'Muschel';
            if (target === 'ochse') return 'Ochse';
            if (target === 'perlhuhn') return 'Perlhuhn';
            if (target === 'pferd') return 'Pferd';
            if (target === 'pute') return 'Pute';
            if (target === 'reh') return 'Reh';
            if (target === 'rentier') return 'Rentier';
            if (target === 'rind') return 'Rind';
            if (target === 'sardelle') return 'Sardelle';
            if (target === 'sardine') return 'Sardine';
            if (target === 'schaf') return 'Schaf';
            if (target === 'schwein') return 'Schwein';
            if (target === 'seelachs') return 'Seelachs';
            if (target === 'seewolf') return 'Seewolf';
            if (target === 'seezunge') return 'Seezunge';
            if (target === 'stint') return 'Stint';
            if (target === 'stoer') return 'Stör';
            if (target === 'strauß') return 'Strauß';
            if (target === 'thunfisch') return 'Thunfisch';
            if (target === 'truthahn') return 'Truthahn';
            if (target === 'unbekannt') return 'Unbekannt';
            if (target === 'wachtel') return 'Wachtel';
            if (target === 'wasserbuffel') return 'Wasserbüffel';
            if (target === 'wild') return 'Wild';
            if (target === 'wildlachs') return 'Wildlachs';
            if (target === 'wildschwein') return 'Wildschwein';
            if (target === 'zebra') return 'Zebra';
            if (target === 'ziege') return 'Ziege';
            //Gesundheit
            if (target === 'allergic') return 'Allergie/Unverträglichkeit';
            if (target === 'respiratory') return 'Atemwege';
            if (target === 'bauchspeicheldruese') return 'Bauchspeicheldrüse';
            if (target === "detoxification") return "Entgiftung & Entschlackung";
            if (target === "diabetes") return "Diabetes mellitus";
            if (target === "gelenke") return "Gelenke & Knochen";
            if (target === "harnweg") return "Harnweg";
            if (target === "hauthaar") return "Haut & Fell";
            if (target === "herzkreislauf") return "Herz & Kreislauf";
            if (target === "immunsystem") return "Immunsystem";
            if (target === "leber") return "Leber";
            if (target === "magendarm") return "Magen-Darm";
            if (target === "muskeln") return "Muskeln";
            if (target === "niere") return "Niere";
            if (target === "parasiten") return "Parasiten";
            if (target === "purinarm") return "purinarm";
            if (target === "schonkost") return "Schonkost";
            if (target === "sport") return "Sport & Aktiv";
            if (target === "stoffwechsel") return "Stoffwechsel";
            if (target === "stress") return "Psyche";
            if (target === "zucht") return "Trächtigkeit & Aufzucht";
            if (target === "uebergewicht") return "Übergewicht";
            if (target === "untergewicht") return "Untergewicht";
            if (target === "zahnpflege") return "Zahn- & Maulpflege";
            //Attributes
            if (target === 'biological') return 'Bio-Qualität (Fleisch)';
            if (target === 'extruded') return 'extrudiert';
            if (target === 'meatFirst') return 'Fleisch an erster Stelle';
            if (target === 'baked') return 'gebacken';
            if (target === 'freezedried') return 'gefriergetrocknet';
            if (target === 'gmfree') return 'gentechnikfrei';
            if (target === 'grainfree') return 'getreidefrei';
            if (target === 'glutenfree') return 'glutenfrei';
            if (target === 'hypoallergenic') return 'hypoallergen';
            if (target === 'bottledcold') return 'kalt abgefüllt';
            if (target === 'pressedcold') return 'kaltgepresst';
            if (target === 'lactosefree') return 'laktosefrei';
            if (target === 'foodquality') return 'Lebensmittelqualität';
            if (target === 'airdried') return 'luftgetrocknet';
            if (target === 'monoprotein') return 'mono- / singleprotein';
            if (target === 'natural') return 'naturbelassen';
            if (target === 'notextruded') return 'nicht extrudiert';
            if (target === 'woantioxidants') return 'ohne Antioxidantien';
            if (target === 'woflavors') return 'ohne Aromen';
            if (target === 'wochemicaladditives') return 'ohne chem. / künstliche Zusatzstoffe';
            if (target === 'woemulsifiers') return 'ohne Emulgatoren';
            if (target === 'wodyes') return 'ohne Farbstoffe';
            if (target === 'woflavorenhancers') return 'ohne Geschmacksverstärker';
            if (target === 'wobonemeal') return 'ohne Knochenmehl';
            if (target === 'wopreservativesubstances') return 'ohne Konservierungsstoffe';
            if (target === 'woanimalmeal') return 'ohne Tiermehl';
            if (target === 'woanimalexperiments') return 'ohne Tierversuche';
            if (target === 'cookedgently') return 'schonend gegart / dampf gegart';
            if (target === 'sensitive') return 'sensitive';
            if (target === 'soyfree') return 'sojafrei';
            if (target === 'wheatfree') return 'weizenfrei';
            if (target === 'sugarfree') return 'zuckerfrei';
            if (target === 'womeatflour') return 'ohne Fleischmehle';
            if (target === 'woattractants') return 'ohne Lockstoffe';
            if (target === 'wofillers') return 'ohne Füllstoffe';
            if (target === 'womoldmeat') return 'ohne Formfleisch';
            if (target === 'noaddedsugar') return 'ohne Zuckerzusatz';
            if (target === 'wobinder') return 'ohne (künstliche) Bindemittel';
            if (target === 'taurin') return 'nur Taurin als Zusatzstoff';
            if (target === 'vegan') return 'vegan';
            if (target === 'vegetarian') return 'vegetarisch';
            if (target === 'purinarm') return 'purinarm';
            //countriesOfDelivery
            if (target === 'austria') return 'Österreich';
            if (target === 'germany') return 'Deutschland';
            if (target === 'switzerland') return 'Schweiz';
            //processings
            if (target === 'throughout') return 'im Ganzen';
            if (target === 'lumpy') return 'stückig';
            if (target === 'minced') return 'gewolft';
            if (target === 'flakes') return 'Flocken';
            if (target === 'pellets') return 'Pellets';
            if (target === 'oil') return 'Öl';
            if (target === 'liquid') return 'Flüssigkeit';
            if (target === 'powder') return 'Pulver';
            if (target === 'tablet') return 'Tablette';
            if (target === 'capsule') return 'Kapsel';
            if (target === 'granules') return 'Granulat';
            //elements
            if (target === 'bareBones') return 'blanke Knochen';
            if (target === 'leafStomach') return 'Blättermagen';
            if (target === 'blood') return 'Blut';
            if (target === 'fat') return 'Fett';
            if (target === 'meat') return 'Fleisch';
            if (target === 'fleshyBonesAndCartilage') return 'fleischige Knochen & Knorpeln';
            if (target === 'wholeAnimal') return 'ganzes Tier';
            if (target === 'wholeAnimalWoHead') return 'ganzes Tier ohne Kopf';
            if (target === 'throat') return 'Hals';
            if (target === 'heart') return 'Herz';
            if (target === 'offal') return 'Innereien';
            if (target === 'carcass') return 'Karkasse';
            if (target === 'head') return 'Kopf';
            if (target === 'body') return 'Körper';
            if (target === 'liver') return 'Leber';
            if (target === 'lung') return 'Lunge';
            if (target === 'stomach') return 'Magen';
            if (target === 'spleen') return 'Milz';
            if (target === 'mixed') return 'Mix';
            if (target === 'notVisible') return 'nicht erkennbar';
            if (target === 'kidney') return 'Niere';
            if (target === 'rumen') return 'Pansen';
            if (target === 'tongue') return 'Zunge';
            if (target === 'alfalfaLucerne') return 'Alfalfa/Luzerne';
            if (target === 'amaranth') return 'Amaranth';
            if (target === 'apple') return 'Apfel';
            if (target === 'banana') return 'Banane';
            if (target === 'broccoli') return 'Brokkoli';
            if (target === 'buckwheat') return 'Buchweizen';
            if (target === 'chia') return 'Chiasamen';
            if (target === 'chicory') return 'Chicoree';
            if (target === 'peas') return 'Erbsen';
            if (target === 'potato') return 'Erdäpfel / Kartoffel';
            if (target === 'oats') return 'Hafer';
            if (target === 'millet') return 'Hirse';
            if (target === 'carrot') return 'Karotte';
            if (target === 'herbs') return 'Kräuter-Mix';
            if (target === 'corn') return 'Mais';
            if (target === 'notVisible') return 'nicht erkennbar';
            if (target === 'parsnip') return 'Pastinake';
            if (target === 'quinoa') return 'Quinoa';
            if (target === 'rice') return 'Reis';
            if (target === 'beetroot') return 'rote Beete';
            if (target === 'beets') return 'rote Rüben';
            if (target === 'celery') return 'Sellerie';
            if (target === 'spinach') return 'Spinat ';
            if (target === 'sweetPotato') return 'Süßkartoffel';
            if (target === 'zucchini') return 'Zucchini';
            if (target === 'seaweed') return 'Algen-Mix';
            if (target === 'brewersYeast') return 'Bierhefe';
            if (target === 'Bcomplex') return 'B-Komplex';
            if (target === 'blood') return 'Blut';
            if (target === 'nettles') return 'Brennnesseln';
            if (target === 'buckwheatGerm') return 'Buchweizenkeime';
            if (target === 'calciumCitrate') return 'Calciumcitrat';
            if (target === 'cbd') return 'CBD';
            if (target === 'enzymes') return 'Pankreasenzyme';
            if (target === 'fat') return 'Fett';
            if (target === 'fishOil') return 'Fischöl';
            if (target === 'meatAndBoneMeal') return 'Fleisch- & Knochenmehl';
            if (target === 'fleaSeedsAndHusks') return 'Flohsamen & -schalen';
            if (target === 'greenlippedMussel') return 'Grünlippmuschel';
            if (target === 'roseHip') return 'Hagebutte';
            if (target === 'honey') return 'Honig';
            if (target === 'humicSubstances') return 'Huminstoffe';
            if (target === 'catsClaw') return 'Katzenkralle';
            if (target === 'collagen') return 'Kollagen';
            if (target === 'herbs') return 'Kräuter-Mix';
            if (target === 'turmeric') return 'Kurkuma';
            if (target === 'codLiverOil') return 'Lebertran';
            if (target === 'minerals') return 'Mineralien-Mix';
            if (target === 'msm') return 'MSM';
            if (target === 'nutrientMix') return 'Nährstoffmix';
            if (target === 'notVisible') return 'nicht erkennbar';
            if (target === 'omegaFat') return 'Omega 3';
            if (target === 'symbiotics') return 'Pro-/Prä-/Symbiotika';
            if (target === 'devilsClaw') return 'Teufelskralle';
            if (target === 'jerusalemArtichoke') return 'Topinambur';
            if (target === 'vitamins') return 'Vitamin-Mix';
            if (target === 'bachFlowers') return 'Bachblüten';
            if (target === 'amminos') return 'Amminosäuren';
            if (target === 'taurin') return 'Taurin';
            if (target === 'mucilages') return 'Eibischwurzel';
            if (target === 'plantOils') return 'Pflanzenöl-Mix';
            if (target === 'acaciaFiber') return 'Akazienfasern';
            if (target === 'algaeOil') return 'Algenöl';
            if (target === 'basePowder') return 'Basenpulver';
            if (target === 'borageOil') return 'Borretschöl';
            if (target === 'bromelain') return 'Bromelain';
            if (target === 'calciumCarbonate') return 'Calciumcarbonat';
            if (target === 'chlorella') return 'Chlorella';
            if (target === 'chondroitin') return 'Chondroitin (Knorpelmehl)';
            if (target === 'safflowerOil') return 'Distelöl';
            if (target === 'eggshells') return 'Eierschalen';
            if (target === 'iron') return 'Eisen';
            if (target === 'em1') return 'EM1';
            if (target === 'emA') return 'EM-A';
            if (target === 'enzymeMix') return 'Enzym-Mix';
            if (target === 'fleaSeeds') return 'Flohsamen';
            if (target === 'psylliumHusks') return 'Flohsamenschalen';
            if (target === 'barleyGrass') return 'Gerstengras';
            if (target === 'glucosamine') return 'Glucosamin';
            if (target === 'bigBurdockRoot') return 'Große Klettenwurzel';
            if (target === 'hempOil') return 'Hanföl';
            if (target === 'potassium') return 'Kalium';
            if (target === 'garlic') return 'Knoblauch';
            if (target === 'coconutFlakes') return 'Kokosflocken';
            if (target === 'coconutOil') return 'Kokosöl';
            if (target === 'colloidalSilverWater') return 'Kolloidalsilberwasser';
            if (target === 'colostrum') return 'Kolostrum';
            if (target === 'krillOil') return 'Krillöl';
            if (target === 'copper') return 'Kupfer';
            if (target === 'lactulose') return 'Lactulose';
            if (target === 'lCarnitine') return 'L-Carnitin';
            if (target === 'linseedOil') return 'Leinöl';
            if (target === 'linseed') return 'Leinsamen';
            if (target === 'lGlutamine') return 'L-Glutamin';
            if (target === 'magnesium') return 'Magnesium';
            if (target === 'mctOil') return 'MCT Öl';
            if (target === 'eveningPrimroseOil') return 'Nachtkerzenöl';
            if (target === 'omega369') return 'Omega 3 6 9';
            if (target === 'papain') return 'Papain';
            if (target === 'pectin') return 'Pektin';
            if (target === 'phosphateBinders') return 'Phosphatbinder';
            if (target === 'propolis') return 'Propolis';
            if (target === 'quinoaSeedlings') return 'Quinoa- Keimlingen';
            if (target === 'blackSeedOil') return 'Schwarzkümmelöl';
            if (target === 'seaweed') return 'Seealgen';
            if (target === 'spirulina') return 'Spirulina';
            if (target === 'grapeSeed') return 'Traubenkern';
            if (target === 'elmBark') return 'Ulmenrinde (Slippery Elm Bark)';
            if (target === 'vitamineA') return 'Vitamin A';
            if (target === 'vitamineC') return 'Vitamin C';
            if (target === 'vitamineD') return 'Vitamin D';
            if (target === 'vitamineE') return 'Vitamin E';
            if (target === 'wheatgrass') return 'Weizengras';
            if (target === 'cellulose') return 'Zellulose';
            if (target === 'zinc') return 'Zink';
            if (target === 'caneMolasses') return 'Zuckerrohrmelasse';
            if (target === 'barley') return 'Gerste';
            if (target === 'pumpkin') return 'Kürbis';
            if (target === 'turnip') return 'Steckrübe';
            if (target === 'wheat') return 'Weizen';    
            // consultants
            if (target === 'cooked') return 'gekocht';
            if (target === 'allergy') return 'Futtermittelallergie';
            if (target === 'joints') return 'Gelenke';
            if (target === 'cancer') return 'Krebs';
            if (target === 'organs') return 'Organerkrankung';
            if (target === 'lactating') return 'säugend';
            if (target === 'pregnant') return 'trächtig';
            if (target === 'environmental') return 'Umweltallergie';
            if (target === 'other') return 'anderes';
            if (target === 'aromaOil') return 'Aromaöltherapie';
            if (target === 'mycotherapy') return 'Mykotherapie';
            if (target === 'phytotherapy') return 'Phytotherapie';
            if (target === 'animalMedicine') return 'Tierheilpraktik';
            if (target === 'eb-senior') return 'Senior';
            if (target === 'eb-baby') return 'Welpe & Junghund';
            if (target === 'locally') return 'vor Ort';
            if (target === 'online') return 'online';

            //none of them
            return target;
        } else if (typeof target === 'boolean') {
            if (target === true) return 'Ja';
            if (target === false) return 'Nein';
            return target;
        } else if (typeof target === 'number') {
            return target;
        } else {
            return target;
        }
    }

    static convertAnimalShare(target) {
        if (target == 0) return 'nicht angegeben/erkennbar';
        if (target == 1) return '0%';
        if (target == 30) return '1 - 29%';
        if (target == 40) return '30 - 39%';
        if (target == 50) return '40 - 49%';
        if (target == 60) return '50 - 59%';
        if (target == 70) return '60% - 69%';
        if (target == 80) return '70% - 79%';
        if (target == 90) return '80% - 89%';
        if (target == 100) return '90 - 100%';
        return target;
    }

    static convertAnimalShareDried(target) {
        if (target == 0) return 'nicht angegeben/erkennbar';
        if (target == 1) return '0%';
        if (target == 20) return '1 - 19%';
        if (target == 30) return '20 - 29%';
        if (target == 40) return '30 - 39%';
        if (target == 50) return '40 - 49%';
        if (target == 60) return '50 - 59%';
        if (target == 70) return '60% - 69%';
        if (target == 80) return '70% - 79%';
        if (target == 90) return '80% - 89%';
        if (target == 100) return '90 - 100%';
        return target;
    }

    static decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            var element = document.createElement('div');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    static merchant(str) {
        if (str.toLowerCase().includes('fressnapf')) return 'Fressnapf';
        if (str.toLowerCase().includes('zooplus')) return 'Zooplus';
        if (str.toLowerCase().includes('zooroyal')) return 'Zooroyal';
        if (str.toLowerCase().includes('terracanis')) return 'Terra Canis DE';
        if (str.toLowerCase().includes('wildborn')) return 'wildborn Hundefutter DE';
        if (str.toLowerCase().includes('josera')) return 'Josera.de';
        if (str.toLowerCase().includes('alsa-hundewelt')) return 'alsa-hundewelt DE';
        if (str.toLowerCase().includes('hundeland')) return 'Hundeland DE';
        if (str.toLowerCase().includes('zoologo')) return 'ZOOLOGO DE';
        if (str.toLowerCase().includes('petspremium')) return 'pets Premium DE';
        if (str.toLowerCase().includes('wolfsblut')) return 'Wolfsblut DE';
        if (str.toLowerCase().includes('schecker')) return 'schecker.de';
        if (str.toLowerCase().includes('liebesgut')) return 'Liebesgut Tiernahrung DE';
        if (str.toLowerCase().includes('amazon')) return 'Amazon.de';
        return str;
    }

    static round2Digits(number) {
        if (typeof number != 'number') return number;

        return (Math.round(number * 100) / 100).toFixed(2);
    }

    static round3Digits(number) {
        if (typeof number != 'number') return number;

        return (Math.round(number * 1000) / 1000).toFixed(3);
    }

    static replaceDecimal(string) {
        try {
            return string.toString().replace('.', ',');
        } catch (e) {
            return string
        }
    }
}

export default SC;
