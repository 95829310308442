import React, {Component} from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

class VerificationPage extends Component {
    state = {
        redirectTo: ""
    }

    componentDidMount() {
        this.verifyEmail();
    }

    verifyEmail = () => {
        let parsed = queryString.parse(window.location.search);

        if (!parsed.token) {
            this.props.handleInfoPopOpen('Fehler!', 'Der Link scheint nicht zu funktionieren.');
            this.setState({redirectTo: '/'});
        } else {
            let {token} = parsed;

            axios.post('/auth/verify-email', {token: token})
            .then(res => {
                let {data} = res;

                if (!data.success) {
                    this.props.handleInfoPopOpen('Fehler!', data.message);
                    this.setState({redirectTo: '/'});
                } else {
                    this.props.handleInfoPopOpen('Email verifiziert!', 'Du kannst dich jetzt mit deinen Daten anmelden.');
                    this.setState({redirectTo: '/login'});
                }
            })
            .catch(err => {
                this.props.handleInfoPopOpen('Fehler!', 'Leider ist ein Fehler aufgetreten. Bitte versuche es erneut.');
                this.setState({redirectTo: '/'});
            })
        }
    }

    render() {
        if(this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

        return (
            <div className="text-center mt-50 mb-30">
                <h1 className="color-primary">Verifiziere Profil</h1>
                <p>Einen Moment bitte. Du wirst in Kürze weitergeleitet.</p>
            </div>
        );
    }
}

export default VerificationPage;
