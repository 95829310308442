import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from './../img/myfeedingcheck_logo.png';

class AboutUs extends Component {
    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <div id="about-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Über MyFeedingCheck</span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row align-items-center">
                        <div className="col-lg-5 text-center">
                            <img src={Logo} alt="MyFeedingCheck Logo" />
                        </div>
                        <div className="col-lg-7">
                            <h1 className="color-primary text-center">Suche, entdecke und vergleiche Futter-Produkte für Hunde & Katzen</h1>
                            <p>
                                MyFeedingCheck ist eine Suchmaschine für Hunde- & Katzenfutter.
                                <br />
                                Im Detail betrachtet ermöglicht die Seite eine umfangreiche Abfragemöglichkeit zu den Inhalten der Produkte.
                                <br /> <br />
                                MyFeedingCheck ist ein Produkt von <a href="https://www.myfeedingcompass.at" target="_blank">MyFeedingCompass</a>, einer Ernährungsberaterin die immer die gleichen Fragen gestellt bekommt
                                <ul className="colored-list">
                                    <li><span>Welches Fertigfutter ist qualitativ hochwertig?</span></li>
                                    <li><span>Welche Dose enthält dies oder jenes?</span></li>
                                    <li><span>Welche Exoten gibt es für eine Ausschlussdiät?</span></li>
                                    <li><span>Wo gibt es bestimmte rohe Fleischsorten?</span></li>
                                    <li><span>und viele mehr</span></li>
                                </ul>
                                MyFeedingCheck soll genau bei solchen alltäglichen Fragen von Hunde- & Katzenbesitzern Erleichterung schaffen.
                                <br /> <br />
                                Jeder Besitzer hat andere Vorstellungen zu seinem "richtigen" Futter und jedes Tier hat andere Bedürfnisse.
                                <br /> <br />
                                MyFeedingCheck möchte genau auf diese Vorstellungen & Bedürfnisse eingehen und so viele Produkte, wie möglich zur Auswahl vorstellen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;
