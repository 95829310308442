import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import vars from './../modules/vars';
import renderHTML from 'react-render-html';
import LoadingOverlay from './LoadingOverlay';
import queryString from 'query-string';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class TipSingle extends Component {
    state = {
        errorMsg: [],
        displayErrorMsg: false,
        postFetched: false,
        post: {},
        isLoading: true,
        numPages: null,
        pageNumber: 1
    }

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (parsed.id) {
            fetch('/api/post/' + parsed.id)
                .then(json => json.json())
                .then(response => {
                    if (response.success === true) {
                        this.setState({ post: response.post });
                        this.setState({ postFetched: true });
                        this.setState({ isLoading: false });
                    } else if (response.success === false) {
                        this.setState({ errorMsg: ["Leider konnten wir den Tipp nicht richtig laden. Bitte versuche es später erneut."] });
                        this.setState({ displayErrorMsg: true });
                        this.setState({ isLoading: false });
                    }
                })
                .catch(error => {
                    this.setState({ errorMsg: ["Netzwerkfehler"] });
                    this.setState({ displayErrorMsg: true });
                    this.setState({ isLoading: false });
                });
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const { pageNumber, numPages, post } = this.state;

        let pagination = [];
        for (let i = 1; i < numPages + 1; i++) {
            pagination.push(<span onClick={() => this.setState({ pageNumber: i })} style={pageNumber === i ?
                { 'margin': '0 3px', 'border-radius': '2px', 'background': '#55AD59', 'color': '#fff', 'padding': '3px 7px', 'cursor': 'pointer', 'transition': 'all .3s' } :
                { 'margin': '0 3px', 'border-radius': '2px', 'padding': '3px 7px', 'cursor': 'pointer', 'transition': 'all .3s' }
            }>{i}</span>)
        }

        return (
            <div id="posts-view">
                {this.state.isLoading && (<LoadingOverlay message="Lade Beitrag..." />)}
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / <Link to="/tipp-der-woche">Aktuelle Beiträge</Link> / {this.state.post.title}</span>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-12">
                            {this.state.displayErrorMsg && (
                                <p className="alert alert-danger">{this.state.errorMsg[0]}</p>
                            )}
                        </div>
                    </div>
                    {this.state.postFetched && (
                        <div className="row">
                            <div className="col-12">
                                {post && post.file && (
                                    <div>
                                        <Document
                                            file={"https://cors-anywhere.herokuapp.com/https://bucket-mfc-wallride67.storage.googleapis.com/" + post.file}
                                            onLoadSuccess={this.onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={pageNumber} />
                                        </Document>
                                        <div className="text-center">
                                            {pagination}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default TipSingle;
