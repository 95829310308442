/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PrivacyPolicy extends Component {
    componentDidMount() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    
    render() {
        return (
            <div id="imprint-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Datenschutzerklärung</span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="color-primary">Datenschutzerklärung</h1>
                                <h2 className="mt-30">Datenschutz</h2>
                                <p ><span >Wir haben diese Datenschutzerkl&auml;rung (Fassung 23.04.2019-121111728) verfasst, um Ihnen gem&auml;&szlig; der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und dem <u><span><a target="_blank" href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10001597&amp;tid=121111728">Datenschutzgesetz (DSG)</a></span></u> zu erkl&auml;ren, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsm&ouml;glichkeiten Sie als Besucher dieser Webseite haben.</span></p>
                                <p ><span >Leider liegt es in der Natur der Sache, dass diese Erkl&auml;rungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bem&uuml;ht die wichtigsten Dinge so einfach und klar wie m&ouml;glich zu beschreiben.</span></p>
                                <p ><strong><span >Automatische Datenspeicherung</span></strong></p>
                                <p ><span >Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.</span></p>
                                <p ><span >Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie</span></p>
                                <ul>
                                <li ><span >die Adresse (URL) der aufgerufenen Webseite</span></li>
                                <li ><span >Browser und Browserversion</span></li>
                                <li ><span >das verwendete Betriebssystem</span></li>
                                <li ><span >die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</span></li>
                                <li ><span >den Hostname und die IP-Adresse des Ger&auml;ts von welchem aus zugegriffen wird</span></li>
                                <li ><span >Datum und Uhrzeit</span></li>
                                </ul>
                                <p ><span >in Dateien (Webserver-Logfiles).</span></p>
                                <p ><span >In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gel&ouml;scht. Wir geben diese Daten nicht weiter, k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.<br />Die Rechtsgrundlage besteht nach <u><span><a target="_blank" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=121111728">Artikel 6&nbsp; Absatz 1 f DSGVO</a></span></u> (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass berechtigtes Interesse daran besteht, den fehlerfreien Betrieb dieser Webseite durch das Erfassen von Webserver-Logfiles zu erm&ouml;glichen.</span></p>
                                <h2 className="mt-30">Cookies</h2>
                                <p ><span >Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.<br />Im Folgenden erkl&auml;ren wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerkl&auml;rung besser verstehen.</span></p>
                                <h3>Was genau sind Cookies?</h3>
                                <p ><span >Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</span></p>
                                <p ><span >Eines ist nicht von der Hand zu weisen: Cookies sind echt n&uuml;tzliche Helferlein. Fast alle Webseiten verwenden&nbsp;Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch anderer Cookies f&uuml;r andere Anwendungsbereiche gibt. HTTP-Cookies&nbsp;sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &ldquo;Hirn&rdquo; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies m&uuml;ssen zus&auml;tzlich ein oder mehrere Attribute angegeben werden.</span></p>
                                <p ><span >Cookies&nbsp;speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder pers&ouml;nliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, &uuml;bermittelt Ihr Browser die &bdquo;userbezogenen&ldquo; Informationen an unsere Seite zur&uuml;ck. Dank der Cookies wei&szlig; unsere Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes&nbsp;Cookie&nbsp;eine eigene Datei, in anderen wie beispielsweise Firefox sind alle&nbsp;Cookies&nbsp;in einer einzigen Datei gespeichert.</span></p>
                                <p ><span >Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt.&nbsp;Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren.&nbsp;Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere &bdquo;Sch&auml;dlinge&ldquo;. Cookies k&ouml;nnen auch nicht auf Informationen Ihres PCs zugreifen.</span></p>
                                <p ><span >So k&ouml;nnen zum Beispiel Cookie-Daten aussehen:</span></p>
                                <ul>
                                <li ><span >Name: _ga</span></li>
                                <li ><span >Ablaufzeit: 2 Jahre</span></li>
                                <li ><span >Verwendung: Unterscheidung der Webseitenbesucher</span></li>
                                <li ><span >Beispielhafter Wert:&nbsp;GA1.2.1326744211.152121111728</span></li>
                                </ul>
                                <p ><span >Ein Browser sollte folgende Mindestgr&ouml;&szlig;en unterst&uuml;tzen:</span></p>
                                <ul>
                                <li ><span >Ein Cookie soll mindestens 4096 Bytes enthalten k&ouml;nnen</span></li>
                                <li ><span >Pro Domain sollen mindestens 50 Cookies gespeichert werden k&ouml;nnen</span></li>
                                <li ><span >Insgesamt sollen mindestens 3000 Cookies gespeichert werden k&ouml;nnen</span></li>
                                </ul>
                                <h3>Welche Arten von Cookies gibt es?</h3>
                                <p ><span >Die Frage welche Cookies wir im Speziellen verwenden, h&auml;ngt von den verwendeten Diensten ab und wird in der folgenden Abschnitten der Datenschutzerkl&auml;rung gekl&auml;rt. An dieser Stelle m&ouml;chten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</span></p>
                                <p ><span >Man kann 4 Arten von Cookies unterscheiden:</span></p>
                                <p ><strong><span >Unbedingt notwendige Cookies<br /></span></strong><span >Diese Cookies sind n&ouml;tig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und sp&auml;ter erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gel&ouml;scht, selbst wenn der User sein Browserfenster schlie&szlig;t.</span></p>
                                <p ><strong><span >Funktionelle Cookies<br /></span></strong><span >Diese Cookies sammeln Infos &uuml;ber das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</span></p>
                                <p ><strong><span >Zielorientierte Cookies<br /></span></strong><span >Diese Cookies sorgen f&uuml;r eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgr&ouml;&szlig;en oder Formulardaten gespeichert.</span></p>
                                <p ><strong><span >Werbe-Cookies<br /></span></strong><span >Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</span></p>
                                <p ><span >&Uuml;blicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen m&ouml;chten. Und nat&uuml;rlich wird diese Entscheidung auch in einem Cookie gespeichert.</span></p>
                                <h3>Wie kann ich Cookies l&ouml;schen?</h3>
                                <p ><span >Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabh&auml;ngig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die M&ouml;glichkeit&nbsp;Cookies zu l&ouml;schen, nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel k&ouml;nnen Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</span></p>
                                <p ><span >Wenn Sie feststellen m&ouml;chten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen &auml;ndern oder l&ouml;schen wollen, k&ouml;nnen Sie dies in Ihren Browser-Einstellungen finden:</span></p>
                                <p ><u><span><a target="_blank" href="https://support.google.com/chrome/answer/95647?tid=121111728"><span>Chrome: Cookies in Chrome l&ouml;schen, aktivieren und verwalten</span></a></span></u></p>
                                <p ><u><span><a target="_blank" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121111728"><span>Safari: Verwalten von Cookies und Websitedaten mit Safari</span></a></span></u></p>
                                <p ><u><span><a target="_blank" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121111728"><span>Firefox: Cookies l&ouml;schen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</span></a></span></u></p>
                                <p ><u><span><a target="_blank" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121111728"><span>Internet Explorer: L&ouml;schen und Verwalten von Cookies</span></a></span></u></p>
                                <p ><u><span><a target="_blank" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=121111728"><span>Microsoft Edge: L&ouml;schen und Verwalten von Cookies</span></a></span></u></p>
                                <p ><span >Falls Sie grunds&auml;tzlich keine Cookies haben wollen, k&ouml;nnen Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So k&ouml;nnen Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff &ldquo;Cookies l&ouml;schen Chrome&rdquo; oder &ldquo;Cookies deaktivieren Chrome&rdquo; im Falle eines Chrome Browsers oder tauschen das Wort &ldquo;Chrome&rdquo; gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.</span></p>
                                <h3>Wie sieht es mit meinem Datenschutz aus?</h3>
                                <p ><span >Seit 2009 gibt es die sogenannten &bdquo;Cookie-Richtlinien&ldquo;. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung des Website-Besuchers (also von Ihnen) verlangt. Innerhalb der EU-L&auml;nder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In &Ouml;sterreich erfolgte aber die Umsetzung dieser Richtlinie in &sect; 96 Abs. 3 des Telekommunikationsgesetzes (TKG).</span></p>
                                <p ><span >Wenn Sie mehr &uuml;ber Cookies wissen m&ouml;chten und vor technischen Dokumentationen nicht zur&uuml;ckscheuen, empfehlen wir&nbsp;<u><span><a target="_blank" href="https://tools.ietf.org/html/rfc6265">tools.ietf.org/html/rfc6265</a></span></u>, dem Request for Comments der Internet Engineering Task Force (IETF) namens &ldquo;HTTP State Management Mechanism&rdquo;.</span></p>
                                <h2 className="mt-30">Speicherung pers&ouml;nlicher Daten</h2>
                                <p ><span >Pers&ouml;nliche Daten, die Sie uns auf dieser Website elektronisch &uuml;bermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere pers&ouml;nlichen Angaben im Rahmen der &Uuml;bermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</span></p>
                                <p ><span >Wir nutzen Ihre pers&ouml;nlichen Daten somit nur f&uuml;r die Kommunikation mit jenen Besuchern, die Kontakt ausdr&uuml;cklich w&uuml;nschen und f&uuml;r die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre pers&ouml;nlichen Daten ohne Zustimmung nicht weiter, k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</span></p>
                                <p ><span >Wenn Sie uns pers&ouml;nliche Daten per E-Mail schicken &ndash; somit abseits dieser Webseite &ndash; k&ouml;nnen wir keine sichere &Uuml;bertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschl&uuml;sselt per E-Mail zu &uuml;bermitteln.</span></p>
                                <p ><span >Die Rechtsgrundlage besteht nach <u><span><a target="_blank" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=121111728">Artikel 6&nbsp; Absatz 1 a DSGVO</a></span></u> (Rechtm&auml;&szlig;igkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie k&ouml;nnen diesen Einwilligung jederzeit widerrufen &ndash; eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</span></p>
                                <h2 className="mt-30">Rechte laut Datenschutzgrundverordnung</h2>
                                <p ><span >Ihnen stehen laut den Bestimmungen der DSGVO und des &ouml;sterreichischen <u><span><a target="_blank" href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10001597&amp;tid=121111728">Datenschutzgesetzes (DSG)</a></span></u> grunds&auml;tzlich die folgende Rechte zu:</span></p>
                                <ul>
                                <li ><span >Recht auf Berichtung (Artikel 16 DSGVO)</span></li>
                                <li ><span >Recht auf L&ouml;schung (&bdquo;Recht auf Vergessenwerden&ldquo;) (Artikel 17 DSGVO)</span></li>
                                <li ><span >Recht auf Einschr&auml;nkung der Verarbeitung (Artikel 18 DSGVO)</span></li>
                                <li ><span >Recht auf Benachrichtigung &ndash; Mitteilungspflicht im Zusammenhang mit der Berichtigung oder L&ouml;schung personenbezogener Daten oder der Einschr&auml;nkung der Verarbeitung (Artikel 19 DSGVO)</span></li>
                                <li ><span >Recht auf Daten&uuml;bertragbarkeit (Artikel 20 DSGVO)</span></li>
                                <li ><span >Widerspruchsrecht (Artikel 21 DSGVO)</span></li>
                                <li ><span >Recht, nicht einer ausschlie&szlig;lich auf einer automatisierten Verarbeitung &mdash; einschlie&szlig;lich Profiling &mdash; beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</span></li>
                                </ul>
                                <p ><span >Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verst&ouml;&szlig;t oder Ihre datenschutzrechtlichen Anspr&uuml;che sonst in einer Weise verletzt worden sind, k&ouml;nnen Sie sich bei der Aufsichtsbeh&ouml;rde beschweren, welche in &Ouml;sterreich die Datenschutzbeh&ouml;rde ist, deren Webseite Sie unter <u><span><a target="_blank" href="https://www.dsb.gv.at/?tid=121111728">https://www.dsb.gv.at/</a></span></u> finden.</span></p>
                                <h2 className="mt-30">Auswertung des Besucherverhaltens</h2>
                                <p ><span >In der folgenden Datenschutzerkl&auml;rung informieren wir Sie dar&uuml;ber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir k&ouml;nnen von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schlie&szlig;en.</span></p>
                                <p ><span >Mehr &uuml;ber M&ouml;glichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden Datenschutzerkl&auml;rung.</span></p>
                                <h2 className="mt-30">TLS-Verschl&uuml;sselung mit https</h2>
                                <p ><span >Wir verwenden https um Daten abh&ouml;rsicher im Internet zu &uuml;bertragen (Datenschutz durch Technikgestaltung <u><span><a target="_blank" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=121111728">Artikel 25 Absatz 1 DSGVO</a></span></u>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschl&uuml;sselungsprotokoll zur sicheren Daten&uuml;bertragung im Internet k&ouml;nnen wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Daten&uuml;bertragung am kleinen Schlosssymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</span></p>
                                <h2 className="mt-30">Newsletter Datenschutzerkl&auml;rung</h2>
                                <p ><span >Wenn Sie sich f&uuml;r unseren Newsletter eintragen &uuml;bermitteln Sie die oben genannten pers&ouml;nlichen Daten und geben uns das Recht Sie per E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter gespeicherten Daten nutzen wir ausschlie&szlig;lich f&uuml;r unseren Newsletter und geben diese nicht weiter.</span></p>
                                <p ><span >Sollten Sie sich vom Newsletter abmelden &ndash; Sie finden den Link daf&uuml;r in jedem Newsletter ganz unten &ndash; dann l&ouml;schen wir alle Daten die mit der Anmeldung zum Newsletter gespeichert wurden.</span></p>
                                <h2 className="mt-30">MailChimp Datenschutzerkl&auml;rung</h2>
                                <p ><span >Wir versenden Newsletter mit MailChimp und verwenden auf dieser Webseite Funktionen des Newsletter Dienstes MailChimp der Firma&nbsp;The Rocket Science Group, LLC,&nbsp;675 Ponce de Leon Ave NE,&nbsp;Suite 5000,&nbsp;Atlanta, GA 30308 USA um Newsletter-Anmeldungen zu erfassen.</span></p>
                                <h3>Allgemeine Informationen zu MailChimp</h3>
                                <p ><span >Die Rocket Science Group LLC (MailChimp) unterh&auml;lt Online-Plattformen, die es unseren Nutzern erm&ouml;glichen, in Kontakt mit ihren Abonnenten zu bleiben, in erster Linie per E-Mail. Sie erlauben Benutzern, E-Mail-Adressen und andere Informationen zum Abonnentenprofil, wie z. B. Name, physische Adresse und andere demografische Informationen, in die MailChimp Datenbank hochzuladen. Diese Informationen werden verwendet, um E-Mails zu senden und die Verwendung bestimmter anderer MailChimp-Funktionen f&uuml;r diese Benutzer zu erm&ouml;glichen. In &Uuml;bereinstimmung mit den ver&ouml;ffentlichten Datenschutzrichtlinien teilt MailChimp einige Informationen mit Drittanbietern, um die Dienste bereitzustellen und zu unterst&uuml;tzen, die MailChimp den Nutzern anbietet. MailChimp teilt auch einige Informationen mit Werbepartnern von Drittanbietern, um die Bed&uuml;rfnisse und Interessen der Nutzer besser zu verstehen, damit relevantere Inhalte und zielgerichtete Werbung f&uuml;r diese Nutzer und andere Nutzer bereitgestellt werden k&ouml;nnen.</span></p>
                                <h3>Newsletter Anmeldung</h3>
                                <p ><span >Wenn Sie auf unserer Webseite f&uuml;r unseren Newsletter anmelden, werden die eingegebenen Daten bei MailChimp gespeichert.</span></p>
                                <h3>L&ouml;schung Ihrer Daten</h3>
                                <p ><span >Sie k&ouml;nnen Ihre Zustimmung f&uuml;r den Erhalt unseres Newsletters jederzeit innerhalb der empfangenen E-Mail per Klick auf den Link im unteren Bereich entziehen. Wenn Sie sich mittels Klick auf den Abmeldelink abgemeldet haben, werden Ihre Daten bei MailChimp gel&ouml;scht.</span></p>
                                <h3>Newsletter Auswertung</h3>
                                <p ><span >Wenn Sie einen Newsletter &uuml;ber MailChimp erhalten, werden Informationen wie IP-Adresse, Browsertyp und E-Mail-Programm gespeichert um uns Informationen zur Performance unseres Newsletters zu geben. MailChimp kann mittels den in den HTML-E-Mails integrierten Bildern namens Web Beacons (Details finden Sie auf&nbsp;<u><span><a target="_blank" href="https://kb.mailchimp.com/reports/about-open-tracking">kb.mailchimp.com/reports/about-open-tracking</a></span></u>) feststellen ob die E-Mail angekommen ist, ob sie ge&ouml;ffnet wurde und ob Links angeklickt wurden. All diese Informationen werden auf den Servern von MailChimp gespeichert, nicht auf dieser Webseite.</span></p>
                                <p ><span >MailChimp ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework wodurch der korrekte und sichere Datentransfer pers&ouml;nlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <u><span><a target="_blank" href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG">www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG</a></span></u>. Mehr &uuml;ber den Einsatz von Cookies bei MailChimp erfahren Sie auf&nbsp;<u><span><a target="_blank" href="https://mailchimp.com/legal/cookies/">https://mailchimp.com/legal/cookies/</a></span></u>, Informationen zum Datenschutz bei MailChimp (Privacy) k&ouml;nnen Sie auf&nbsp;<u><span><a target="_blank" href="https://mailchimp.com/legal/privacy/">https://mailchimp.com/legal/privacy/</a></span></u> nachlesen.</span></p>
                                <h2 className="mt-30">Google Analytics Datenschutzerkl&auml;rung</h2>
                                <p ><span >Wir verwenden auf dieser Website Google Analytics der Firma Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) um Besucherdaten statistisch auszuwerten. Dabei verwendet Google Analytics zielorientierte Cookies.</span></p>
                                <h3>Cookies von Google Analytics</h3>
                                <ul>
                                <li ><span >_ga </span></li>
                                <ul>
                                <li><span >Ablaufzeit: 2 Jahre</span></li>
                                <li><span >Verwendung: Unterscheidung der Webseitenbesucher</span></li>
                                <li><span >Beispielhafter Wert:&nbsp;GA1.2.1326744211.152121111728</span></li>
                                </ul>
                                <li ><span >_gid </span></li>
                                <ul>
                                <li><span >Ablaufzeit: 24 Stunden</span></li>
                                <li><span >Verwendung: Unterscheidung der Webseitenbesucher</span></li>
                                <li><span >Beispielhafter Wert: GA1.2.1687193234.152121111728</span></li>
                                </ul>
                                <li ><span >_gat_gtag_UA_&lt;property-id&gt; </span></li>
                                <ul>
                                <li><span >Ablaufzeit: 1 Minute</span></li>
                                <li><span >Verwendung:&nbsp;Wird zum Drosseln der Anforderungsrate verwendet. Wenn Google Analytics &uuml;ber den Google Tag Manager bereitgestellt wird, erh&auml;lt dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.</span></li>
                                <li><span >Beispielhafter Wert: 1</span></li>
                                </ul>
                                </ul>
                                <p ><span >N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <u><span><a target="_blank" href="http://www.google.com/analytics/terms/de.html">www.google.com/analytics/terms/de.html</a></span></u> bzw. unter <u><span><a target="_blank" href="https://support.google.com/analytics/answer/6004245?hl=de">support.google.com/analytics/answer/6004245?hl=de</a></span></u>.</span></p>
                                <h3>Pseudonymisierung</h3>
                                <p ><span >Unser Anliegen im Sinne der DSGVO ist die Verbesserung unseres Angebotes und unseres Webauftritts. Da uns die Privatsph&auml;re unserer Nutzer wichtig ist, werden die Nutzerdaten pseudonymisiert.&nbsp;Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des &sect; 96 Abs 3 TKG sowie des Art 6 EU-DSGVO Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.</span></p>
                                <h3>Deaktivierung der Datenerfassung durch Google Analytics</h3>
                                <p ><span >Mithilfe des <strong>Browser-Add-ons zur Deaktivierung</strong> von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) k&ouml;nnen Website-Besucher verhindern, dass Google Analytics ihre Daten verwendet.</span></p>
                                <p ><span >Sie k&ouml;nnen die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren: <u><span><a target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=de">tools.google.com/dlpage/gaoptout?hl=de</a></span></u></span></p>
                                <p ><span >&nbsp;</span></p>
                                <h2 className="mt-30">Google reCAPTCHA Datenschutzerkl&auml;rung</h2>
                                <p ><span >Wir verwenden Google reCAPTCHA der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) um Spam zu verhindern.</span></p>
                                <p ><span >Rechtsgrundlage f&uuml;r die Verwendung ist Artikel 6 (1) f (Rechtm&auml;&szlig;igkeit der Verarbeitung), denn es besteht ein berechtigtes Interesse diese Webseite vor Bots und Spam zu sch&uuml;tzen.</span></p>
                                <p ><span >reCAPTCHA ist ein kostenloser Dienst, der Webseiten vor Spam und Missbrauch sch&uuml;tzt. Es nutzt fortschrittliche Risikoanalysetechniken, um Menschen und Bots auseinander zu halten. Mit der neuen API wird eine signifikante Anzahl Ihrer g&uuml;ltigen menschlichen Benutzer die reCAPTCHA-Herausforderung bestehen, ohne ein CAPTCHA l&ouml;sen zu m&uuml;ssen.&nbsp; Wir nutzen reCAPTCHA f&uuml;r die Absicherung von Formularen.</span></p>
                                <p ><span >Durch die Nutzung von reCAPTCHA werden Daten an Google &uuml;bertragen die Google nutzt um festzustellen ob der Besucher ein Mensch oder ein (Spam)bot ist. Welche Daten von Google erfasst werden und wof&uuml;r diese Daten verwendet werden, k&ouml;nnen Sie auf <u><span><a target="_blank" href="https://policies.google.com/privacy?hl=de-AT">policies.google.com/privacy?hl=de-AT</a></span></u> nachlesen.<br />Die Nutzungsbedingungen f&uuml;r Dienste und Produkte von Google k&ouml;nnen Sie unter <u><span><a target="_blank" href="https://policies.google.com/terms?hl=de-AT">https://policies.google.com/terms?hl=de-AT</a></span></u> nachlesen.</span></p>
                                <h2 className="mt-30">Eingebettete Social Media Elemente Datenschutzerkl&auml;rung</h2>
                                <p ><span >Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte anzuzeigen.<br />Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen Social Media Dienst &uuml;bertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.<br />Die folgenden Links f&uuml;hren Sie zu den Seiten der jeweiligen Social Media Dienste wo erkl&auml;rt wird, wie diese mit Ihren Daten umgehen:</span></p>
                                <ul>
                                <li ><span >Instagram-Datenschutzrichtlinie: <u><span><a target="_blank" href="https://help.instagram.com/519522125107875?tid=121111728">help.instagram.com/519522125107875</a></span></u></span></li>
                                <li ><span >F&uuml;r YouTube gilt die Google Datenschutzerkl&auml;rung: <u><span><a target="_blank" href="https://policies.google.com/privacy?hl=de&amp;tid=121111728">policies.google.com/privacy?hl=de</a></span></u></span></li>
                                <li ><span >Facebook-Datenrichtline:&nbsp;<u><span><a target="_blank" href="https://www.facebook.com/about/privacy?tid=121111728">www.facebook.com/about/privacy</a></span></u></span></li>
                                <li ><span >Twitter Datenschutzrichtlinie:&nbsp;<u><span><a target="_blank" href="https://twitter.com/de/privacy?tid=121111728">twitter.com/de/privacy</a></span></u></span></li>
                                </ul>
                                <h2 className="mt-30">Facebook Datenschutzerkl&auml;rung</h2>
                                <p ><span >Wir verwenden auf dieser Webseite Funktionen von Facebook, einem Social Media Network der Firma Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.<br />Welche Funktionen (Soziale Plug-ins) Facebook bereitstellt, k&ouml;nnen Sie auf <u><span><a target="_blank" href="https://developers.facebook.com/docs/plugins/">developers.facebook.com/docs/plugins/</a></span></u> nachlesen.<br />Durch den Besuch unserer Webseite k&ouml;nnen Informationen an Facebook &uuml;bermittelt werden. Wenn Sie &uuml;ber ein Facebook-Konto verf&uuml;gen, kann Facebook diese Daten Ihrem pers&ouml;nlichen Konto zuordnen. Sollten Sie das nicht w&uuml;nschen, melden Sie sich bitte von Facebook ab.<br />Die Datenschutzrichtlinien, welche Informationen Facebook sammelt und wie sie diese verwenden finden Sie auf <u><span><a target="_blank" href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a></span></u>.</span></p>
                                <h2 className="mt-30">Instagram Datenschutzerkl&auml;rung</h2>
                                <p ><span >Wir verwenden auf unserer Webseite Funktionen des Social Media Netzwerks Instagram der Firma Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA.</span></p>
                                <p ><span >Mit den Funktionen zum Einbetten von Instagram-Inhalten (Embed-Funktion) k&ouml;nnen wir Bilder und Videos anzeigen.</span></p>
                                <p ><span >Durch den Aufruf von Seiten die solche Funktionen nutzen werden Daten (IP-Adresse, Browserdaten, Datum, Uhrzeit, Cookies) an Instagram &uuml;bermittelt, gespeichert und ausgewertet.</span></p>
                                <p ><span >Sollten Sie ein Instagram-Konto haben und angemeldet sein, werden diese Daten Ihrem pers&ouml;nlichen Konto und den darin gespeicherten Daten zugeordnet.</span></p>
                                <p ><span >Die Datenschutzrichtlinien, welche Informationen Instagram sammelt und wie sie diese verwenden finden Sie auf <u><span><a target="_blank" href="https://help.instagram.com/519522125107875?tid=121111728">help.instagram.com/519522125107875</a></span></u>.</span></p>
                                <h2 className="mt-30">Google AdSense Datenschutzerkl&auml;rung</h2>
                                <p ><span >Wir verwenden auf diese Webseite Google AdSense, dem Anzeigenprogramm der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA).</span></p>
                                <p ><span >Dabei werden Daten zu den angezeigten Werbemitteln und Ihre IP-Adresse m&ouml;glicherweise an Server in die USA &uuml;bermittelt und dort ausgewertet.</span></p>
                                <p ><span >In AdSense sollen Cookies bessere Werbung erm&ouml;glichen, die Cookies enthalten keine personalisierten Daten. Google AdSense sendet nach jeder Impression, jedem Klick und jeder anderen Aktivit&auml;t, die zu einem Aufruf der Google Adsense-Server f&uuml;hrt, ein Cookie an den Browser. Wenn der Browser das Cookie akzeptiert, wird es dort gespeichert.</span></p>
                                <p ><span >Drittanbieter k&ouml;nnen im Rahmen von AdSense unter Umst&auml;nden Cookies in Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der Website erhalten. Web Beacons sind kleine Grafiken, die eine Logdatei-Aufzeichnung und eine Logdateianalyse erm&ouml;glichen, die f&uuml;r statistische Auswertungen f&uuml;r das Online-Marketing verwendet werden.</span></p>
                                <h3>Cookies deaktivieren</h3>
                                <p ><span >Sie k&ouml;nnen jederzeit Cookies, die sich bereits auf Ihrem Computer befinden, l&ouml;schen oder Cookies deaktivieren. Die Vorgangsweise dazu ist nach Browser unterschiedlich, am besten Sie suchen die Anleitung in Google mit dem Suchbegriff &ldquo;cookies l&ouml;schen chrome&rdquo; oder &ldquo;cookies deaktivieren chrome&rdquo; im Falle eines Chrome Browsers oder tauschen das Wort &ldquo;chrome&rdquo; gegen den Namen Ihres Browsers, z. B. edge, firefox, safari aus.</span></p>
                                <p ><span >Wenn Sie uns generell nicht gestatten, Cookies zu nutzen, d.h. diese per Browsereinstellung deaktivieren, k&ouml;nnen manche &nbsp;Funktionen und Seiten nicht wie erwartet funktionieren.</span></p>
                                <h3>Personalisierte Werbung deaktivieren</h3>
                                <p ><span >Wenn Sie ein Google Konto haben, k&ouml;nnen Sie auf der Seite <u><span><a target="_blank" href="https://adssettings.google.com/authenticated">adssettings.google.com/authenticated</a></span></u> personalisierte Werbung deaktivieren. Auch im Falle der Deaktivierung der Personalisierung von Anzeigen sehen Sie m&ouml;glicherweise weiterhin Anzeigen auf der Grundlage von Faktoren wie Ihrem ungef&auml;hren Standort, der aus Ihrer IP-Adresse abgeleitet wird, dem Browsertyp sowie den k&uuml;rzlich verwendeten Suchbegriffen.</span></p>
                                <p ><span >Welche Daten von Google erfasst werden und wof&uuml;r diese Daten verwendet werden, k&ouml;nnen Sie auf <u><span><a target="_blank" href="https://www.google.com/intl/de/policies/privacy/">www.google.com/intl/de/policies/privacy/</a></span></u> nachlesen.</span></p>
                                <p><span >Quelle: Erstellt&nbsp;mit dem&nbsp;Datenschutz Generator von <u><span><a target="_blank" href="https://www.adsimple.at">adsimple.at</a></span></u>&nbsp;in Kooperation mit <u><span><a target="_blank" href="https://www.tirolalpin.at">tirolalpin.at</a></span></u></span></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
