import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Auth from "./../modules/Auth";

class EnergyConservationNeeds extends Component {
    state = {
        fProteins: "",
        fFats: "",
        fFibers: "",
        fAsh: "",
        fMoisture: "",
        fNfe: "",
        fNfeTM: "",
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleFoodValueChange = this.handleFoodValueChange.bind(this);
    }

    calculateNfe() {
        let { fAsh, fProteins, fMoisture, fFats, fFibers } = this.state;

        if (fProteins && fFats && fAsh && fFibers && fMoisture) {
            let proteins = parseFloat(fProteins);
            let ash = parseFloat(fAsh);
            let fibers = parseFloat(fFibers);
            let fats = parseFloat(fFats);
            let moisture = parseFloat(fMoisture);

            let nfe =
                Math.round(
                    (100 - (proteins + ash + fats + fibers + moisture)) * 100
                ) / 100;
            let tm = 100 - moisture;
            let nfeTM = Math.round((nfe / tm) * 10000) / 100;

            if (nfe) this.setState({ fNfe: nfe });
            if (nfeTM) this.setState({ fNfeTM: nfeTM });
        }
    }

    handleFoodValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });

        setTimeout(() => {
            this.calculateNfe();
        }, 200);
    };

    render() {
        return (
            <div id="calculator-view">
                <Helmet>
                    <meta
                        name="description"
                        content="Verwende den Nfe-Rechner von MyFeedingCheck um die Kohlenhydrate eines Futters auszurechnen. Besonders relevant für Tier mit Diabetes."
                        id="meta-desc-compare"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Nfe-Rechner
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">
                                Berechne den NfE-Anteil eines Fertigfutters
                            </h1>
                            <p>
                                NfE = nitrogen-free extractives =
                                stickstofffreie Extraktstoffe = Kohlenhydrate =
                                Zucker
                                <br />
                                Anhand der analytischen Bestandteile von dem
                                Futter kann der "Kohlenhydrat"-Anteil berechnet
                                werden.
                                <br />
                                <br />
                                Kleine Information: Katzen, die an Diabetes
                                erkrankt sind, sollten Futtersorten unter 10%
                                NfE in der Trockenmasse bekommen. Aber auch auf
                                die allgemeine Zusammensetzung (getreide- &
                                zuckerfrei) sollte geachtet werden.
                                <br />
                                Da Katzen allgemein keine Kohlenhydrate zur
                                Energiegewinnung verwenden, kann dieser
                                Richtwert eigentlich auch für gesunde Katzen
                                angenommen werden.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="">
                                <div className="">
                                    <h2 className="color-primary">
                                        Nahrungsmittel
                                    </h2>
                                </div>
                                <div className="form-group">
                                    <div className="form-label">
                                        <label for="proteins">Rohprotein</label>
                                    </div>
                                    <div className="form-input">
                                        <input
                                            className="form-control"
                                            type="number"
                                            step="0.01"
                                            name="fProteins"
                                            placeholder="Rohprotein"
                                            onChange={
                                                this.handleFoodValueChange
                                            }
                                            value={this.state.fProteins}
                                        ></input>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-label">
                                        <label for="fatsRohfaser">
                                            Rohfett
                                        </label>
                                    </div>
                                    <div className="form-input">
                                        <input
                                            className="form-control"
                                            type="number"
                                            step="0.01"
                                            name="fFats"
                                            placeholder="Rohfett"
                                            onChange={
                                                this.handleFoodValueChange
                                            }
                                            value={this.state.fFats}
                                        ></input>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-label">
                                        <label for="fibers">Rohfaser</label>
                                    </div>
                                    <div className="form-input">
                                        <input
                                            className="form-control"
                                            type="number"
                                            step="0.01"
                                            name="fFibers"
                                            placeholder="Rohfaser"
                                            onChange={
                                                this.handleFoodValueChange
                                            }
                                            value={this.state.fFibers}
                                        ></input>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-label">
                                        <label for="ash">Rohasche</label>
                                    </div>
                                    <div className="form-input">
                                        <input
                                            className="form-control"
                                            type="number"
                                            step="0.01"
                                            name="fAsh"
                                            placeholder="Rohasche"
                                            onChange={
                                                this.handleFoodValueChange
                                            }
                                            value={this.state.fAsh}
                                        ></input>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-label">
                                        <label for="moisture">
                                            Feuchtigkeit
                                        </label>
                                    </div>
                                    <div className="form-input">
                                        <input
                                            className="form-control"
                                            type="number"
                                            step="0.01"
                                            name="fMoisture"
                                            placeholder="Feuchtigkeit"
                                            onChange={
                                                this.handleFoodValueChange
                                            }
                                            value={this.state.fMoisture}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.fNfe && this.state.fNfeTM ? (
                            <div className="col-lg-6 mt-30-md">
                                <div className="nfe-result text-center">
                                    <strong>{this.state.fNfe}%</strong>
                                    <span>Nfe-Gehalt</span>
                                </div>
                                <div className="nfe-result text-center">
                                    <strong>{this.state.fNfeTM}%</strong>
                                    <span>Nfe-Gehalt in der Trockenmasse</span>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-6 text-center mt-30-md">
                                <span>Daten eingeben.</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default EnergyConservationNeeds;
