import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class CalculatorFats extends Component {
    state = {
        amountOne: null,
        fatsOne: null,
        amountTwo: null,
        fatsTwo: null,
        amountThree: null,
        fatsThree: null,
        amountFour: null,
        fatsFour: null,
        amountFive: null,
        fatsFive: null,
        amountSum: null,
        fatsSum: null,
        currentFats: null,
        fatsDesired: null,
        fatsDesiredAmount: null,
        fatsToAdd: null,
        meatFat: null,
        meatLean: null,
        meatAmount: null,
        meatFats: null,
        meatAmountFat: null,
        meatAmountLean: null,
    };

    componentDidMount() {
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    handleValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ 
            [name]: parseFloat(value),
        }, () => {
            this.setState({
                amountSum: (this.state.amountOne || 0) + (this.state.amountTwo || 0) + (this.state.amountThree || 0) + (this.state.amountFour || 0) + (this.state.amountFive || 0),
                fatsSum: (this.state.amountOne * this.state.fatsOne / 100 || 0) + (this.state.amountTwo * this.state.fatsTwo / 100 || 0) + (this.state.amountThree * this.state.fatsThree / 100 || 0) + (this.state.amountFour * this.state.fatsFour / 100 || 0) + (this.state.amountFive * this.state.fatsFive / 100 || 0)
            }, () => {
                this.setState({
                    currentFats: this.state.fatsSum / this.state.amountSum * 100 || 0
                }, () => {
                    this.setState({
                        fatsDesiredAmount: this.state.amountSum * this.state.fatsDesired / 100 || 0
                    }, () => {
                        if (this.state.fatsDesiredAmount) this.setState({
                            fatsToAdd: this.state.fatsDesiredAmount - (this.state.fatsSum || 0)
                        })
                    })
                })
            })
        });
    };

    handleValueChangeSec = (e) => {
        let { name, value } = e.target;
        this.setState({ 
            [name]: parseFloat(value),
        }, () => {
            if (this.state.meatFat && this.state.meatLean) {
                let meatDifference = this.state.meatFat - this.state.meatLean

                this.setState({meatDifference}, () => {
                    if (this.state.meatDifference && this.state.meatAmount && this.state.meatFats && this.state.meatLean) {
                        this.setState({
                            meatAmountFat: (this.state.meatAmount / this.state.meatDifference)*(this.state.meatFats - this.state.meatLean),
                            meatAmountLean: (this.state.meatAmount / this.state.meatDifference)*(this.state.meatDifference - (this.state.meatFats - this.state.meatLean)),
                        })
                    }
                })
            }
        });
    };

    render() {
        return (
            <div id="calculator-view" className="fats">
                <Helmet>
                    <meta
                        name="description"
                        content="Ja das mit den Einheiten ist immer so eine Sache. Wer nicht regelmäßig damit zu tun hat, hat dann ab und zu Probleme mit den Umrechnungsfaktoren… Deshalb gibt es hier eine Unterstützung für dich!"
                        id="meta-desc-compare"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Fettrechner
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">
                                Fettmenge
                            </h1>
                            <p>
                                Tierisches Fett ist ein wichtiger Energie- und essentieller Fettsäurenlieferant für Hunde und Katzen.				
                            </p>
                            <small>Der Rechner wurde nach bestem Gewissen erstellt, aber ohne Gewähr.</small>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h2 className="color-primary">
                                Fettzugabe berechnen
                            </h2>
                            <p>
                                Du verwendest verschiedene Fleischsorten mit unterschiedlichen Fettgehalt und weißt nicht, ob du noch etwas Fett ergänzen musst?
                            </p>
                        </div>
                        <div className="col-12 calculator-row strong mb-15 display-none-max-991">
                            <div className="table-col input-group">
                                Fleischsorten
                            </div>
                            <div className="table-col input-group">
                                Menge [g]
                            </div>
                            <div className="table-col input-group">
                                Fettgehalt [%]
                            </div>
                            <div className="table-col input-group">
                                Fettgehalt [g]
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                Produkt 1
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="amountOne"
                                    placeholder="Menge in g"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.amountOne || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fatsOne"
                                    placeholder="Fettgehalt in %"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.fatsOne || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    placeholder="Fettgehalt in g"
                                    disabled
                                    value={this.state.amountOne * this.state.fatsOne / 100 || '0'}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                Produkt 2
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="amountTwo"
                                    placeholder="Menge in g"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.amountTwo || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fatsTwo"
                                    placeholder="Fettgehalt in %"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.fatsTwo || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    placeholder="Fettgehalt in g"
                                    disabled
                                    value={this.state.amountTwo * this.state.fatsTwo / 100 || '0'}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                Produkt 3
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="amountThree"
                                    placeholder="Menge in g"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.amountThree || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fatsThree"
                                    placeholder="Fettgehalt in %"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.fatsThree || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    placeholder="Fettgehalt in g"
                                    disabled
                                    value={this.state.amountThree * this.state.fatsThree / 100 || '0'}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                Produkt 4
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="amountFour"
                                    placeholder="Menge in g"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.amountFour || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fatsFour"
                                    placeholder="Fettgehalt in %"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.fatsFour || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    placeholder="Fettgehalt in g"
                                    disabled
                                    value={this.state.amountFour * this.state.fatsFour / 100 || '0'}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                Produkt 5
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="amountFive"
                                    placeholder="Menge in g"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.amountFive || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fatsFive"
                                    placeholder="Fettgehalt in %"
                                    onChange={
                                        this.handleValueChange
                                    }
                                    value={this.state.fatsFive || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    placeholder="Fettgehalt in g"
                                    disabled
                                    value={this.state.amountFive * this.state.fatsFive / 100 || '0'}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                Summe
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    disabled
                                    placeholder="Menge in g"
                                    value={(this.state.amountSum || 0).toLocaleString('de-DE')}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                            <div className="table-col input-group display-none-max-991">
                                &nbsp;
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Fettgehalt in g"
                                    disabled
                                    value={(this.state.fatsSum || 0).toLocaleString('de-DE')}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mt-30">
                            <div className="table-col input-group">
                                momentaner Fettgehalt
                            </div>
                            <div className="table-col input-group"></div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    disabled
                                    value={Number(this.state.currentFats || 0).toLocaleString('de-DE')}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mt-30">
                            <div className="table-col input-group">
                                gewünschter Fettgehalt
                            </div>
                            <div className="table-col input-group"></div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fatsDesired"
                                    placeholder="Menge in %"
                                    onChange={this.handleValueChange}
                                    value={this.state.fatsDesired || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    disabled
                                    value={(this.state.fatsDesiredAmount || 0).toLocaleString('de-DE')}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        {(this.state.fatsToAdd || this.state.fatsToAdd === 0) && (
                            <div className="col-12 mt-30">
                                <div className="alert alert-secondary text-center">
                                    <span className="strong">{this.state.fatsToAdd.toLocaleString('de-DE')}g tierisches Fett müssen der Ration noch zugefügt werden.</span><br />
                                    Die zuergänzende Fettmenge wird vom Muskelfleisch abgezogen, damit die Futtermenge nicht erhöht wird.
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row align-items-center mt-50">
                        <div className="col-12">
                            <h2 className="color-primary">
                                Fettausgleich berechnen
                            </h2>
                            <p>
                                Du hast ein mageres und ein fettes Fleisch und möchtest diese im richtigen Fettverhältnis vermengen?
                            </p>
                        </div>
                        <div className="col-12 calculator-row strong mb-15 display-none-max-991">
                            <div className="table-col input-group">
                                Fleischsorten
                            </div>
                            <div className="table-col input-group">
                                Fettgehalt [%]
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                fett
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="meatFat"
                                    placeholder="Menge in %"
                                    onChange={
                                        this.handleValueChangeSec
                                    }
                                    value={this.state.meatFat || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                mager
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="meatLean"
                                    placeholder="Menge in %"
                                    onChange={
                                        this.handleValueChangeSec
                                    }
                                    value={this.state.meatLean || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-30">
                            <div className="table-col input-group">
                                Differenz
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    disabled
                                    value={(this.state.meatDifference || 0).toLocaleString('de-DE')}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-15">
                            <div>Fleischmenge, mageres + fettes Muskelfleisch</div>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="meatAmount"
                                    placeholder="Menge in g"
                                    onChange={
                                        this.handleValueChangeSec
                                    }
                                    value={this.state.meatAmount || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-15">
                            <div>gewünschter Fettgehalt in der Ration</div>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="meatFats"
                                    placeholder="Gehalt in %"
                                    onChange={
                                        this.handleValueChangeSec
                                    }
                                    value={this.state.meatFats || ''}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-15 mt-30">
                            <strong>Fleischverhältnis-Mischung</strong>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                fett
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    disabled
                                    value={(this.state.meatAmountFat || 0).toLocaleString('de-DE', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 calculator-row mb-15">
                            <div className="table-col input-group">
                                mager
                            </div>
                            <div className="table-col input-group">
                                <input
                                    className="form-control"
                                    disabled
                                    value={(this.state.meatAmountLean || 0).toLocaleString('de-DE', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">g</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CalculatorFats;
