import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import noImage from "../img/no-image.jpg";
import checkGreen from "../img/check-green.png";
import StarRatingComponent from "react-star-rating-component";
import { BookmarkBorder, FavoriteBorder, Favorite, CompareArrows, Check } from "@material-ui/icons";
import Auth from "./../modules/Auth";
import SC from "./../modules/StringConverter";

class ItemCard extends React.Component {
    handleListChange = (e) => {
        e.persist();
        let { dataset } = e.currentTarget;

        if (dataset) {
            let { list, product } = dataset;

            if (list && product) {
                if (list === "favorites") {
                    try {
                        Auth.getUserFromToken().then((user) => {
                            try {
                                user = user._id;
                            } catch {
                                alert("Ein Fehler ist aufgetreten. Bitte versuche es später erneut.");
                            }
                            let token = Auth.getToken();

                            let datas = {
                                user: user,
                                product: this.props.product._id,
                                listType: "favorites",
                            };

                            axios
                                .post("/api/lists-update", datas, {
                                    headers: { token: token },
                                })
                                .then((res) => {
                                    let { data } = res;
                                    if (data.success) {
                                        alert(data.message);
                                        this.props.handleFavoritesListChange(this.props.product._id);
                                    } else {
                                        alert("Ein Fehler ist aufgetreten. Bitte versuche es später erneut.");
                                    }
                                })
                                .catch((err) => {
                                    alert("Ein Fehler ist aufgetreten. Bitte versuche es später erneut.");
                                });
                        });
                    } catch (e) {
                        alert("Du musst eingeloggt sein, um diese Funktion nutzen zu können.");
                    }
                } else if (list === "compare") {
                    if (this.props.compareList.includes(product)) {
                        let arr = this.props.compareList;
                        var index = arr.indexOf(product);
                        if (index !== -1) arr.splice(index, 1);
                        this.props.handleCompareListChange(arr);
                    } else {
                        let arr = [...this.props.compareList, product];
                        this.props.handleCompareListChange(arr);
                    }
                }
            }
        }
    };

    render() {
        let styles = { backgroundColor: "#fff" };

        // calculate best variant
        let { variants } = this.props.product;
        let bestVariant;

        if (variants && Array.isArray(variants) && variants.length > 0) {
            variants.forEach((v) => {
                if (v && v != null) {
                    let { unit, size, amount, price, is_for_sale, published, in_stock, updatedByBot, merchant_name, owner } = v;

                    if (unit && size && amount && price && is_for_sale && published && in_stock && updatedByBot && (merchant_name !== 'b2b' || (owner && owner.status && owner.status === 'active'))) {
                        if (unit == "kg") {
                            size = size * 1000;
                            unit = "g";
                        } else if (unit == "l") {
                            size = size * 1000;
                            unit = "ml";
                        }

                        if (unit == "g" || unit == "ml") {
                            v.basePrice = (price / (amount * size)) * 100;
                        }
                    } else {
                        v.basePrice = undefined
                    }
                }
            });

            variants = variants.filter(v => v.published && v.is_for_sale && v.updatedByBot && v.in_stock && v.basePrice).sort((a, b) => {
                if (a && b && a != null && b != null) {
                    if (a.basePrice && b.basePrice && a.basePrice != null && b.basePrice != null) {
                        if (a.basePrice > b.basePrice) {
                            return 1;
                        } else if (a.basePrice < b.basePrice) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else { 
                        if (a.basePrice && !b.basePrice) return -1
                        else if (b.basePrice && !a.basePrice) return 1
                    }
                } 
                
                return 0
            });

            if (variants[0]) bestVariant = variants[0].basePrice ? variants[0] : undefined;

            if (bestVariant && bestVariant.network === 'b2b' && bestVariant.owner.url && !bestVariant.link.startsWith('http')) bestVariant.link = bestVariant.owner.url + bestVariant.link
        }

        return (
            <div className="item-card">
                <div className="ex-link">
                    <article className="card">
                        <div className="card__info-hover">
                            <div class="card__clock-info">
                                <div className="lists-wrapper">
                                    <div className="lists-dropdown">
                                        <ul>
                                            <li
                                                data-list="favorites"
                                                data-product={this.props.product._id}
                                                onClick={(e) => this.handleListChange(e)}
                                                className={this.props.favoritesList && this.props.favoritesList.includes(this.props.product._id) && 'color-primary'}
                                            >
                                                {this.props.favoritesList && !this.props.favoritesList.includes(this.props.product._id) ? <FavoriteBorder /> : <Favorite />}
                                            </li>
                                            <li
                                                data-list="compare"
                                                data-product={this.props.product._id}
                                                onClick={(e) => this.handleListChange(e)}
                                                className={this.props.compareList && this.props.compareList.includes(this.props.product._id) && 'color-primary'}
                                            >
                                                <CompareArrows />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href={"/produkte/" + this.props.product.urlExtension} target="_blank">
                            <div className="card__img" style={styles}></div>
                            <div className="card__img--hover" style={styles}>
                                <div className="inner" style={{position: 'absolute',top: 0,bottom: 0,left: 0,right: 0,textAlign: 'center',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                                    {this.props.product.imageFront
                                        ? <img style={{maxHeight: '100%'}} src={'https://bucket-mfc-wallride67.storage.googleapis.com/' + this.props.product.imageFront.toLowerCase()} onError={(e) => {e.target.onerror=null;e.target.src='https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png'}} />
                                        : <img style={{maxHeight: '100%'}} src="https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png" />
                                    }
                                </div>
                            </div>
                            <div className="card__info">
                                <span className="card__category">{this.props.product.brand.name}</span>
                                <span className="card__title">{this.props.product.series}</span>
                                <div className="card__rating">
                                    <span className="card__time">
                                        <StarRatingComponent
                                            name="review-rating"
                                            starCount={5}
                                            value={this.props.product.rating}
                                        />
                                    </span>
                                    {this.props.product.ratingCount ? (
                                        <span className="rating_count">({this.props.product.ratingCount})</span>
                                    ) : (
                                        <span className="rating_count">(0)</span>
                                    )}
                                    {this.props.product.brand.owner && this.props.product.brand.owner.status === 'active' && <div className="owned-icon"><img src={checkGreen} /></div>}
                                </div>
                            </div>
                        </a>
                    </article>
                    <a
                        href={"/produkte/" + this.props.product.urlExtension}
                        target="_blank"
                        className="card__cta-small"
                    >
                        <span>Alle Angebote</span>
                    </a>
                </div>
                {bestVariant && bestVariant.link && bestVariant.size && bestVariant.price && bestVariant.unit && bestVariant.amount && bestVariant.merchant_name && (
                    <div className="card__cta">
                        <a className="btn btn-light btn-sm" href={bestVariant.link ? bestVariant.link : '#'} target="_blank" style={bestVariant.link ? {} : {pointerEvents: 'none'}}>
                            zum besten Angebot
                            <br />
                            <small>{`${bestVariant.merchant_name === 'b2b' ? `${bestVariant.owner.shopName || ''}` : SC.merchant(bestVariant.merchant_name)} ${bestVariant.price
                                .toFixed(2)
                                .toString()
                                .replace(".", ",")}€ ${bestVariant.amount}x${bestVariant.size
                                .toString()
                                .replace(".", ",")}${bestVariant.unit}`}</small>
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default ItemCard;
