import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Link, NavLink, Redirect } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Helmet } from "react-helmet";
import HttpsRedirect from "react-https-redirect";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../style.css";
import InfoPop from "./InfoPop";
import NavBarTop from "./NavBarTop";
import NavBarMain from "./NavBarMain";
import VerificationPage from "./VerificationPage";
import Register from "./Register";
import Login from "./Login";
import Logout from "./Logout";
import ResetPassword from "./ResetPassword";
import ResetPasswordStepTwo from "./ResetPasswordStepTwo";
import Home from "./Home";
import Profile from "./Profile";
import TipOfTheWeek from "./TipOfTheWeek";
import TipSingle from "./TipSingle";
import CreateProductForm from "./CreateProductForm";
import EditProductForm from "./EditProductForm";
import Products from "./Products";
import ProductSingle from "./ProductSingle";
import CompareList from "./CompareList";
import EnergyConservationNeeds from "./EnergyConservationNeeds";
import NfeCalculator from "./NfeCalculator";
import NaehrstoffbedarfCalculator from "./NaehrstoffbedarfCalculator";
import TrockenmasseCalculator from "./TrockenmasseCalculator";
import CalciumCalculator from "./CalciumCalculator";
import IodCalculator from "./IodCalculator";
import Contact from "./Contact";
import Imprint from "./Imprint";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";
import MFCApp from "./MFCApp";
import AboutAds from "./AboutAds";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import SocialPop from "./SocialPop";
import AlertBar from "./AlertBar";
import Auth from "./../modules/Auth";
import { withStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Consultants from "./Consultants";
import CalculatorUnits from "./CalculatorUnits";
import CalculatorFats from "./CalculatorFats";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#55AD59",
        },
    },
    overrides: {
        MuiRadio: {
            colorSecondary: {
                '&$checked': {
                    color: 'green',
                },
            },
        },
    },
});

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            compareList: [],
            favoritesList: [],
            socialPopIsActive: true,
            infoPopHeading: "",
            infoPopMessage: "",
            showInfoPop: false
        };

        this.handleAuthenticationChange = this.handleAuthenticationChange.bind(this);
        this.handleCompareListChange = this.handleCompareListChange.bind(this);
        this.handleFavoritesListChange = this.handleFavoritesListChange.bind(this);
        this.removeFromFavorites = this.removeFromFavorites.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.changeSocialPopStatus = this.changeSocialPopStatus.bind(this);
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated()) {
            this.setState({ isAuthenticated: true });
        }
        $('[data-toggle="tooltip"]').tooltip({ html: true });

        var compareList = JSON.parse(localStorage.getItem("CompareList")) || [];
        this.setState({ compareList: compareList });

        try {
            Auth.getUserFromToken()
                .then((result) => {
                    if (result && result.username && result.email && result._id) {
                        if (result.status && result.status !== "blocked") {
                            let {favorites} = result;

                            if (favorites.length >= 1) this.setState({favoritesList: favorites});
                        } else {
                            Auth.deauthenticateUser();
                            this.handleAuthenticationChange(false);
                        }
                    } else {
                        Auth.deauthenticateUser();
                        this.handleAuthenticationChange(false);
                    }
                })
                .catch((err) => {
                    Auth.deauthenticateUser();
                    this.handleAuthenticationChange(false);
                });
        } catch (e) {
            this.handleAuthenticationChange(false);
        }
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip({ html: true });
    }

    handleAuthenticationChange(value) {
        this.setState({ isAuthenticated: value });
    }

    changeSocialPopStatus() {
        if (this.state.socialPopIsActive) {
            localStorage.setItem("mfc-so-pop", Date.now());
        }
        this.setState({ socialPopIsActive: !this.state.socialPopIsActive });
    }

    handleCompareListChange(value) {
        this.setState({ compareList: value });
        localStorage.setItem("CompareList", JSON.stringify(value));
    }

    handleFavoritesListChange(value) {
        if (!this.state.favoritesList.includes(value)) {
            this.setState({ favoritesList: [...this.state.favoritesList, value] });
        }
    }
    
    removeFromFavorites(value) {
        if (this.state.favoritesList.includes(value)) {
            let list = this.state.favoritesList;
            let index = list.indexOf(value);
            if (index > -1) {
                list.splice(index, 1);
            }
            this.setState({ favoritesList: list });
        }
    }

    handleInfoPopOpen = (heading, message) => {
        this.setState({ infoPopHeading: heading });
        this.setState({ infoPopMessage: message });
        this.setState({ showInfoPop: true });
    };

    handleInfoPopClose = () => {
        this.setState({ infoPopHeading: "" });
        this.setState({ infoPopMessage: "" });
        this.setState({ showInfoPop: false });
    };

    handleVersionChange = () => {
        this.setState({v: this.state.v + 1});
    }

    render() {
        return (
            <React.Fragment>
                <HttpsRedirect>
                    <MuiThemeProvider theme={theme}>
                        <Helmet>
                            <meta
                                name="description"
                                content="MyFeedingCheck, eine Suchmaschine für Hundefutter & Katzenfutter mit speziellem Filter."
                                id="meta-desc"
                            />
                        </Helmet>
                        {this.state.socialPopIsActive && (
                            <SocialPop changeSocialPopStatus={this.changeSocialPopStatus} />
                        )}
                        {this.state.showInfoPop ? (
                            <InfoPop
                                heading={this.state.infoPopHeading}
                                message={this.state.infoPopMessage}
                                handleInfoPopClose={this.handleInfoPopClose}
                            />
                        ) : null}
                        <Router>
                            <div className="site-wrapper">
                                <AlertBar />
                                <NavBarTop {...this.state} />
                                <NavBarMain />

                                <div id="content">
                                    <div className="container">
                                        <Switch>
                                            <Route path="/" exact>
                                                <Home
                                                    handleCompareListChange={this.handleCompareListChange}
                                                    handleFavoritesListChange={this.handleFavoritesListChange}
                                                    {...this.state}
                                                />
                                            </Route>
                                            <Route path="/produkte" exact>
                                                <Products
                                                    handleCompareListChange={this.handleCompareListChange}
                                                    handleVersionChange={this.handleVersionChange}
                                                    handleFavoritesListChange={this.handleFavoritesListChange}
                                                    {...this.state}
                                                />
                                            </Route>
                                            <Route path="/login">
                                                {Auth.isUserAuthenticated() ? (
                                                    <Redirect to="/profil" />
                                                ) : (
                                                    <Login
                                                        handleAuthenticationChange={this.handleAuthenticationChange}
                                                    />
                                                )}
                                            </Route>
                                            <Route path="/registrieren">
                                                <Register
                                                    handleAuthenticationChange={this.handleAuthenticationChange}
                                                    handleInfoPopOpen={this.handleInfoPopOpen}
                                                />
                                            </Route>
                                            <Route path="/verifizieren">
                                                <VerificationPage handleInfoPopOpen={this.handleInfoPopOpen} />
                                            </Route>
                                            <Route path="/logout">
                                                <Logout handleAuthenticationChange={this.handleAuthenticationChange} />
                                            </Route>
                                            <Route path="/passwort-vergessen">
                                                {Auth.isUserAuthenticated() ? (
                                                    <Redirect to="/profil" />
                                                ) : (
                                                    <ResetPassword />
                                                )}
                                            </Route>
                                            <Route path="/passwort-zuruecksetzen">
                                                {Auth.isUserAuthenticated() ? (
                                                    <Redirect to="/profil" />
                                                ) : (
                                                    <ResetPasswordStepTwo />
                                                )}
                                            </Route>
                                            <Route path="/profil" {...this.props}>
                                                {Auth.isUserAuthenticated() ? (
                                                    <Profile
                                                        handleAuthenticationChange={this.handleAuthenticationChange}
                                                        removeFromFavorites={this.removeFromFavorites}
                                                        {...this.state}
                                                    />
                                                ) : (
                                                    <Redirect to="/login" />
                                                )}
                                            </Route>
                                            {/* <Route path='/produkte/neues-produkt-erstellen'>
                                                {Auth.isUserAuthenticated() ? (
                                                    <CreateProductForm
                                                        handleAuthenticationChange={this.handleAuthenticationChange}
                                                        handleInfoPopOpen={this.handleInfoPopOpen}
                                                    />
                                                ) : (
                                                        <Redirect to='/login' />
                                                    )
                                                }
                                            </Route> */}
                                            {/* <Route path='/produkte/produkt-bearbeiten'>
                                        {Auth.isUserAuthenticated() ? (
                                            <EditProductForm
                                                handleAuthenticationChange={this.handleAuthenticationChange}
                                                handleInfoPopOpen={this.handleInfoPopOpen}
                                            />
                                        ) : (
                                                <Redirect to='/login' />
                                            )
                                        }
                                    </Route> */}
                                            {/* <Route path="/produkte/produkt">
                                        <ProductSingle
                                            handleAuthenticationChange={this.handleAuthenticationChange}
                                            handleCompareListChange={this.handleCompareListChange}
                                            {...this.state}
                                        />
                                    </Route> */}
                                            <Route
                                                exact
                                                path="/produkte/:urlExtension"
                                                render={(props) => (
                                                    <ProductSingle
                                                        handleAuthenticationChange={this.handleAuthenticationChange}
                                                        handleCompareListChange={this.handleCompareListChange}
                                                        {...this.state}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Route path="/vergleichsliste">
                                                <CompareList
                                                    handleCompareListChange={this.handleCompareListChange}
                                                    {...this.state}
                                                />
                                            </Route>
                                            <Route exact path="/hunde-katzen-ernaehrungsberater" component={Consultants} />
                                            <Route exact path="/tipp-der-woche" component={TipOfTheWeek} />
                                            <Route exact path="/tipp-der-woche/show" component={TipSingle} />
                                            <Route exact path="/mfc-als-app" component={MFCApp} />
                                            <Route exact path="/werben" component={AboutAds} />
                                            <Route exact path="/ueber-uns" component={AboutUs} />
                                            <Route exact path="/energiebedarf-und-futtermenge">
                                                <EnergyConservationNeeds />
                                            </Route>
                                            <Route exact path="/nfe-rechner">
                                                <NfeCalculator />
                                            </Route>
                                            <Route exact path="/naehrstoffbedarf">
                                                <NaehrstoffbedarfCalculator />
                                            </Route>
                                            <Route exact path="/trockenmasse">
                                                <TrockenmasseCalculator />
                                            </Route>
                                            <Route exact path="/calciumbedarf">
                                                <CalciumCalculator />
                                            </Route>
                                            <Route exact path="/jodbedarf">
                                                <IodCalculator />
                                            </Route>
                                            <Route exact path="/einheiten">
                                                <CalculatorUnits />
                                            </Route>
                                            <Route exact path="/fett">
                                                <CalculatorFats />
                                            </Route>
                                            <Route exact path="/kontakt" component={Contact} />
                                            <Route exact path="/agb" component={Terms} />
                                            {/* <Route exact path="/impressum" component={Imprint} /> */}
                                            {/* <Route exact path="/datenschutzerklaerung" component={PrivacyPolicy} /> */}
                                            <Route
                                                render={() => (
                                                    <React.Fragment>
                                                        <h1 className="text-center mt-50">404 Seite nicht gefunden</h1>
                                                        <p className="text-center">Hups, da ist etwas schief gegangen!</p>
                                                        <div className="text-center">
                                                            <Link to={'/produkte'}><span className="btn btn-primary">Zurück zur Futtersuche</span></Link>
                                                            <br />
                                                            <Link to={'/kontakt'} className='mt-20' style={{display: 'inline-block'}}><span className="btn btn-primary">Kontakt</span></Link>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </Router>
                    </MuiThemeProvider>
                </HttpsRedirect>
            </React.Fragment>
        );
    }
}

export default App;
