import React, { Component } from "react";
import { Link } from "react-router-dom";
import vars from "./../modules/vars";
import renderHTML from "react-render-html";
import LoadingOverlay from "./LoadingOverlay";

class TipOfTheWeek extends Component {
    state = {
        errorMsg: [],
        displayErrorMsg: false,
        postFetched: false,
        posts: [],
        isLoading: true,
    };

    componentDidMount() {
        fetch("/api/posts")
            .then((json) => json.json())
            .then((response) => {
                if (response.success === true) {
                    this.setState({ posts: response.posts });
                    this.setState({ postFetched: true });
                    this.setState({ isLoading: false });
                } else if (response.success === false) {
                    this.setState({
                        errorMsg: [
                            "Leider konnten wir den letzten Tipp nicht richtig laden. Bitte versuche es später erneut.",
                        ],
                    });
                    this.setState({ displayErrorMsg: true });
                    this.setState({ isLoading: false });
                }
            })
            .catch((error) => {
                this.setState({ errorMsg: ["Netzwerkfehler"] });
                this.setState({ displayErrorMsg: true });
                this.setState({ isLoading: false });
            });

        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    render() {
        return (
            <div id="posts-view">
                {this.state.isLoading && (
                    <LoadingOverlay message="Lade Beitrag..." />
                )}
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Aktuelle
                                Beiträge
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-12">
                            {this.state.displayErrorMsg && (
                                <p className="alert alert-danger">
                                    {this.state.errorMsg[0]}
                                </p>
                            )}
                        </div>
                    </div>
                    {this.state.postFetched && (
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body">
                                    <h1 class="color-primary">
                                        Aktuelle Beiträge
                                    </h1>
                                    <p>
                                        Hier findest Du aktuelle News, Tipps und
                                        andere Beiträge.
                                    </p>
                                    {this.state.posts &&
                                        this.state.posts.length >= 1 &&
                                        this.state.posts.map((post) => {
                                            return (
                                                <div className="post-overview">
                                                    <small
                                                        style={{
                                                            opacity: ".5",
                                                        }}
                                                    >
                                                        {post.createdAt.substr(
                                                            8,
                                                            2
                                                        ) +
                                                            "." +
                                                            post.createdAt.substr(
                                                                5,
                                                                2
                                                            ) +
                                                            "." +
                                                            post.createdAt.substr(
                                                                0,
                                                                4
                                                            )}{" "}
                                                        - {post.category}
                                                    </small>
                                                    <h3 className="h5">
                                                        {post.title}
                                                    </h3>
                                                    <Link
                                                        to={
                                                            "/tipp-der-woche/show?id=" +
                                                            post._id
                                                        }
                                                    >
                                                        Jetzt ansehen
                                                    </Link>{" "}
                                                    -{" "}
                                                    <a
                                                        href={
                                                            "https://bucket-mfc-wallride67.storage.googleapis.com/" +
                                                            post.file
                                                        }
                                                    >
                                                        Download
                                                    </a>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default TipOfTheWeek;
