import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "./../modules/Auth";
import { Helmet } from "react-helmet";
import SC from './../modules/StringConverter';

class TrockenmasseCalculator extends Component {
    state = {
        fTitle: "",
        fProteins: "",
        fFats: "",
        fFibers: "",
        fAsh: "",
        fMoisture: "",
        fEnergyKcal: "",
        fEnergyMj: "",
        favorites: [],
        favoritesSelected: "",
        customProducts: [],
        selectValue: 'none',
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetchFavorites();

        this.handleFavoriteSelect = this.handleFavoriteSelect.bind(this);
        this.handleFoodValueChange = this.handleFoodValueChange.bind(this);
        this.handleCustomProduct = this.handleCustomProduct.bind(this);
        this.handleAnimalValueChange = this.handleAnimalValueChange.bind(this);
        this.removeItemFromTable = this.removeItemFromTable.bind(this);
    }

    fetchFavorites = () => {
        if (Auth.isUserAuthenticated()) {
            let token = Auth.getToken();

            if (token) {
                fetch("/api/favorite-products", { headers: { token: token } })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data);
                        if (data.success) {
                            this.setState({ favorites: data.favorites });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    };

    calculateEnergy() {
        let { fAsh, fProteins, fMoisture, fFats, fFibers } = this.state;

        if (fProteins && fFats && fAsh && fFibers && fMoisture) {
            let proteins = parseFloat(fProteins);
            let ash = parseFloat(fAsh);
            let fibers = parseFloat(fFibers);
            let fats = parseFloat(fFats);
            let moisture = parseFloat(fMoisture);

            let nfe =
                Math.round(
                    (100 - (proteins + ash + fats + fibers + moisture)) * 100
                ) / 100;

            let proteinsKj = Math.round(proteins * 17 * 100) / 100;
            let fatsKj = Math.round(fats * 39 * 100) / 100;
            let nfeKj = Math.round(nfe * 17 * 100) / 100;
            let resultKj = proteinsKj + fatsKj + nfeKj;
            let resultMj = Math.round((resultKj / 1000) * 100) / 100;
            let resultKcal = Math.round((resultKj / 4.19) * 100) / 100;

            if (resultKcal) this.setState({ fEnergyKcal: resultKcal });
            if (resultMj) this.setState({ fEnergyMj: resultMj });
        }
    }

    handleFoodValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });

        if (this.state.fEnergyKcal) {
            let mj = (this.state.fEnergyKcal * 4.19) / 1000;
            this.setState({ fEnergyMj: mj });
        }

        setTimeout(() => {
            this.calculateEnergy();
        }, 200);
    };

    handleCustomProduct = () => {
        let { fAsh, fProteins, fMoisture, fFats, fFibers, fTitle } = this.state;

        if (!fAsh || !fProteins || !fMoisture || !fFats || !fFibers) {
            alert('Bitte fülle alle Felder richtig aus.');
        } else {
            let data = {
                proteins: parseFloat(fProteins),
                fats: parseFloat(fFats),
                fibers: parseFloat(fFibers),
                ash: parseFloat(fAsh),
                moisture: parseFloat(fMoisture)
            }

            if (fProteins && fFats && fFibers && fAsh && fMoisture) {
                data.nfe = 100-parseFloat(fProteins)-parseFloat(fFats)-parseFloat(fFibers)-parseFloat(fAsh)-parseFloat(fMoisture);
                data.tm = 100-parseFloat(fMoisture);

                let tmProteins = data.proteins/data.tm*100;
                let tmFats = data.fats/data.tm*100;
                let tmAsh = data.ash/data.tm*100;
                let tmFibers = data.fibers/data.tm*100;
                let tmNfe = data.nfe/data.tm*100;
                data.tmProteins = tmProteins;
                data.tmFats = tmFats;
                data.tmFibers = tmFibers;
                data.tmAsh = tmAsh;
                data.tmNfe = tmNfe;
                if (fTitle) data.name = fTitle;
            }

            this.setState({customProducts: [...this.state.customProducts, data]});
            this.setState({fTitle: "", fProteins: "", fFats: "", fAsh: "", fFibers: "", fMoisture: ""});
        }
    }

    handleAnimalValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });

        setTimeout(() => {
            this.calculateAnimal();
        }, 200);
    };

    removeItemFromTable = (i) => {
        let {customProducts} = this.state;

        customProducts.splice(i, 1);

        this.setState({customProducts: customProducts});
    }

    handleFavoriteSelect = (e) => {
        let { value } = e.target;
        this.setState({ selectValue: value });

        var {
            proteins,
            fats,
            fibers,
            ash,
            moisture,
            name
        } = e.target.options[e.target.selectedIndex].dataset;

        let data = {};

        if (proteins) data.proteins = proteins;
        if (fats) data.fats = fats;
        if (fibers) data.fibers = fibers;
        if (ash) data.ash = ash;
        if (moisture) data.moisture = moisture;
        if (name) data.name = name;

        if (proteins && fats && fibers && ash && moisture) {
            let nfe = 100-proteins-fats-fibers-ash-moisture;
            data.nfe = nfe;
            let tm = 100-moisture;
            data.tm = tm;

            let tmProteins = proteins/tm*100;
            let tmFats = fats/tm*100;
            let tmAsh = ash/tm*100;
            let tmFibers = fibers/tm*100;
            let tmNfe = nfe/tm*100;
            data.tmProteins = tmProteins;
            data.tmFats = tmFats;
            data.tmFibers = tmFibers;
            data.tmAsh = tmAsh;
            data.tmNfe = tmNfe;
        }

        this.setState({customProducts: [...this.state.customProducts, data]});
        this.setState({selectValue: 'none'})
    };

    favoritesSelectBox = () => {
        if (Auth.isUserAuthenticated()) {
            if (this.state.favorites && this.state.favorites.length >= 1) {
                let options = [];

                this.state.favorites.map((p) => {
                    options.push(
                        <option
                            value={p._id}
                            selected={this.state.selectValue === p._id}
                            key={p._id}
                            data-proteins={p.proteins}
                            data-fats={p.fats}
                            data-fibers={p.fibers}
                            data-ash={p.ash}
                            data-moisture={p.moisture}
                            data-energy={p.energy}
                            data-name={`${p.brand.name}, ${p.series}`}
                        >
                            {p.brand.name}, {p.series}
                        </option>
                    );
                });

                return (
                    <React.Fragment>
                        <label style={{fontWeight: 'bold'}}>Produkt aus Favoriten:</label>
                        <select
                            className="form-control"
                            name="favorites"
                            id="favorites"
                            onChange={this.handleFavoriteSelect}
                            value={this.state.selectValue}
                        >
                            <option disabled selected={this.state.value == 'none'} value="none">
                                Produkt auswählen
                            </option>
                            {options}
                        </select>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <label style={{fontWeight: 'bold'}}>Produkt aus Favoriten:</label>
                        <select className="form-control" disabled>
                            <option disabled selected={this.state.value == 'none'} value="none">
                                Keine Produkte vorhanden
                            </option>
                        </select>
                    </React.Fragment>
                );
            }
        } else {
            return (
                <React.Fragment>
                    <label style={{fontWeight: 'bold'}}>Produkt aus Favoriten:</label>
                    <select className="form-control" disabled>
                        <option disabled selected={this.state.value == 'none'} value="none">
                            Keine Produkte vorhanden
                        </option>
                    </select>
                    <p className="small">
                        Du musst eingeloggt sein, um Produkte aus deinen
                        Favoriten auswählen zu können.
                    </p>
                </React.Fragment>
            );
        }
    };

    render() {
        return (
            <div id="calculator-view" className="trockenmasse">
                <Helmet>
                    <meta
                        name="description"
                        content="Die Trockenmasse oder auch Trockensubstanz, wird herangenommen um Produkte, mit unterschiedlichem Feuchtigkeitsgehalt, miteinander zu vergleichen."
                        id="meta-desc-energy-calc"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Trockenmasserechner
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">
                            Berechne die Trockenmasse eines Produktes
                            </h1>
                            <p>
                            Die Trockenmasse oder auch Trockensubstanz, wird herangenommen um Produkte, mit unterschiedlichem Feuchtigkeitsgehalt, miteinander zu vergleichen.
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h2 className="color-primary">Produkte hinzufügen</h2>
                        </div>
                        <div className="form-group col-12">
                            {this.favoritesSelectBox()}
                        </div>
                        <div className="col-12" style={{fontWeight:'bold'}}><label>Eigenes Produkt hinzufügen:</label></div>
                        <div className="form-group col-12">
                            <div className="form-label">
                                <label for="proteins">Bezeichnung</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="fTtile"
                                    placeholder="Bezeichnung (optional)"
                                    onChange={e => this.setState({fTitle: e.target.value})}
                                    value={this.state.fTitle}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-4 col-sm-6">
                            <div className="form-label">
                                <label for="proteins">Rohprotein</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fProteins"
                                    placeholder="Rohprotein"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fProteins}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-4 col-sm-6">
                            <div className="form-label">
                                <label for="fatsRohfaser">Rohfett</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fFats"
                                    placeholder="Rohfett"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fFats}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-4 col-sm-6">
                            <div className="form-label">
                                <label for="fibers">Rohfaser</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fFibers"
                                    placeholder="Rohfaser"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fFibers}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-4 col-sm-6">
                            <div className="form-label">
                                <label for="ash">Rohasche</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fAsh"
                                    placeholder="Rohasche"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fAsh}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-4 col-sm-6">
                            <div className="form-label">
                                <label for="moisture">Feuchtigkeit</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fMoisture"
                                    placeholder="Feuchtigkeit"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fMoisture}
                                ></input>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 form-group" style={{display: 'flex', alignItems: 'flex-end'}}>
                            <span onClick={this.handleCustomProduct} className="btn btn-primary" style={{width: '100%', border: 'none', padding: '0.55rem 1rem', cursor: 'pointer'}}>Produkt hinzufügen</span>
                        </div>

                        <div className={`col-12 result ${this.state.customProducts.length >= 1 && 'active'}`}>
                            <h2 className="color-primary">Weender Analyse in der Trockenmasse</h2>
                            <div className="my_table">
                                <div className="my_thead">
                                    <div className="tr">
                                        <div className="td">&nbsp;</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return (
                                                <div className="td" key={'tr1-' + i} style={{lineHeight: 1.2}}>
                                                    {p.name ? p.name : <>Produkt {i+1}</>}
                                                    <br /><small style={{color: 'red', cursor: 'pointer'}} onClick={() => this.removeItemFromTable(i)}>löschen</small>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="my_tbody">
                                    {/* <div className="tr">
                                        <div className="td">Rohprotein</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr2-' + i}>{SC.round2Digits(p.proteins) || 'FEHLER'}{p.proteins && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Rohfett</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr3-' + i}>{SC.round2Digits(p.fats) || 'FEHLER'}{p.fats && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Rohasche</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr4-' + i}>{SC.round2Digits(p.ash) || 'FEHLER'}{p.ash && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Rohfaser</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr5-' + i}>{SC.round2Digits(p.fibers) || 'FEHLER'}{p.fibers && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Feuchtigkeit</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr6-' + i}>{SC.round2Digits(p.moisture) || 'FEHLER'}{p.moisture && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">NfE</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr7-' + i}>{SC.round2Digits(p.nfe) || 'FEHLER'}{p.nfe && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Trockenmasse</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr8-' + i}>{SC.round2Digits(p.tm) || 'FEHLER'}{p.tm && '%'}</div>
                                        })}
                                    </div> */}
                                    <div className="tr">
                                        <div className="td">Rohprotein</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr9-' + i}>{SC.round2Digits(p.tmProteins) || 'FEHLER'}{p.tmProteins && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Rohfett</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr10-' + i}>{SC.round2Digits(p.tmFats) || 'FEHLER'}{p.tmFats && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Rohasche</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr11-' + i}>{SC.round2Digits(p.tmAsh) || 'FEHLER'}{p.tmAsh && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Rohfaser</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr12-' + i}>{SC.round2Digits(p.tmFibers) || 'FEHLER'}{p.tmFibers && '%'}</div>
                                        })}
                                    </div>
                                    <div className="tr">
                                        <div className="td">Nfe</div>
                                        {this.state.customProducts.map((p, i) => {
                                            return <div className="td" key={'tr13-' + i}>{SC.round2Digits(p.tmNfe) || 'FEHLER'}{p.tmNfe && '%'}</div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrockenmasseCalculator;
