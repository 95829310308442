import React, {Component} from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { Close } from '@material-ui/icons';
import Reaptcha from 'reaptcha';
import Auth from './../modules/Auth';

function InfoPop(props) {
    return (
        <React.Fragment>
            <CSSTransition
                in={true}
                timeout={300}
                classNames="fade"
                unmountOnExit={true}>
                <div className="popup-overlay">
                    <div className="popup-wrapper">
                        <div className="close-btn" onClick={props.handleInfoPopClose}>
                            <Close />
                        </div>
                        <h3 className="color-primary">{props.heading}</h3>
                        <p>{props.message}</p>
                        <span className="btn btn-primary" onClick={props.handleInfoPopClose}>Fenster schließen</span>
                    </div>
                </div>
            </CSSTransition>
        </React.Fragment>
    );
}

export default InfoPop;
