import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "jquery-match-height/dist/jquery.matchHeight-min.js";
import { Helmet } from "react-helmet";
import { Checkbox, FormControlLabel, Radio, RadioGroup, withStyles } from "@material-ui/core";
import SC from "../modules/StringConverter";
import LoadingOverlay from "./LoadingOverlay";

const bucketURL = 'https://storage.googleapis.com/bucket-mfc-wallride67/'

const GreenCheckbox = withStyles({
    root: {
        color: "#666",
        "&$checked": {
            color: "#55AD59",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" style={{
    padding: 0,
    marginRight: ".5rem",
    marginLeft: "1rem",
}} {...props} />);

const CustomRadio = withStyles({
    root: {
        color: "#666",
        "&$checked": {
            color: "#55AD59",
        },
    },
    checked: {},
})((props) => <Radio color="default" style={{
    padding: 0,
    marginRight: ".5rem",
    marginLeft: "1rem",
}} {...props} />);

const filtersAttributes = [ {title: 'Aromaöltherapie', value: 'aromaOil'}, {title: 'Bachblüten', value: 'bachFlowers'}, {title: 'Mykotherapie', value: 'mycotherapy'}, {title: 'Phytotherapie', value: 'phytotherapy'}, {title: 'Tierheilpraktik', value: 'animalMedicine'} ]
const filtersSpecialties = [ {title: 'Futtermittelallergie', value: 'allergy'}, {title: 'Gelenke', value: 'joints'}, {title: 'Krebs', value: 'cancer'}, {title: 'Organerkrankung', value: 'organs'}, {title: 'säugend', value: 'lactating'}, {title: 'Senior', value: 'eb-senior'}, {title: 'trächtig', value: 'pregnant'}, {title: 'Umweltallergie', value: 'environmental'}, {title: 'Welpe & Junghund', value: 'eb-baby'}, {title: 'anderes', value: 'other'}]
const filtersConsultingType = [ {title: 'vor Ort', value: 'locally'}, {title: 'online', value: 'online'} ]

class Consultants extends Component {
    state = {
        consultants: [],
        filters: {},
        error: "",
        showResults: false,
        possibleFilters: [],
        isLoading: true,
    };

    componentDidMount() {
        this.fetchFilters()
    }
    
    fetchFilters = () => {
        axios
            .get("/api/consultant-filters")
            .then((res) => {
                let { data } = res;
                if (data.success) {
                    this.setState({ possibleFilters: data.data || [] });
                } else {
                    this.setState({
                        error: "Fehler beim Abrufen der möglichen Filter aufgetreten.",
                    });
                }
            })
            .catch((err) =>
                this.setState({
                    error: "Fehler beim Abrufen der möglichen Filter aufgetreten.",
                })
            )
            .finally(() => this.setState({isLoading: false}));
    }

    fetchConsultants = () => {
        this.setState({error: '', isLoading: true})

        let {animal, ageGroup, foodType} = this.state.filters

        if (!animal || !ageGroup || !foodType) {
            this.setState({error: 'Bitte fülle alle Pflichtfelder aus.', isLoading: false});
            return;
        }

        axios
            .post("/api/consultants", { filters: this.state.filters })
            .then((res) => {
                let { data } = res;
                if (data.success) {
                    this.setState({ consultants: data.data || [], showResults: true });
                    window.scrollTo({ top: 0, behavior: "smooth" });
                } else {
                    this.setState({
                        error: "Fehler beim Abrufen der Berater aufgetreten."
                    });
                }
            })
            .catch((err) =>
                this.setState({
                    error: "Fehler beim Abrufen der Berater aufgetreten."
                })
            )
            .finally(() => this.setState({isLoading: false}));
    };

    handleCheckboxChange = e => {
        let {value, name} = e.target
        let {filters} = this.state

        if (!filters[name]) {
            filters[name] = [value]
        } else if (Array.isArray(filters[name])) {
            if (filters[name].includes(value)) {
                let index = filters[name].indexOf(value)
                if (index > -1) filters[name].splice(index, 1)
                if (filters[name].length === 0) delete filters[name]
            } else {
                filters[name].push(value)
            }
        }

        this.setState({filters})
    }

    render() {
        return (
            <div id="consultants-view">
                <Helmet>
                    <meta
                        name="description"
                        content="Bei MyFeedingCheck ausgesuchte Ernährungsberater miteinander vergleichen."
                        id="meta-desc-compare"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Ernährungsberater
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-12">
                            <div className="main card card-body">
                                <h1 className="color-primary">Ernährungsberater Suche</h1>
                                {!this.state.showResults && (
                                    <React.Fragment>
                                        <p>
                                            Du hast Fragen rund um das Thema Ernährung von Hund & Katze?
                                            <br />Du benötigst Hilfe bei einer Futterumstellung? (z.B. bei Allergie, Übergewicht oder Trächtigkeit)
                                            <br />Oder möchtest du dein Tier bei einer Erkrankung diätisch unterstützen?
                                            <br />Dann bist du hier genau richtig!
                                            <br /><br />Nach einer kurzen Abfrage findest du hier eine Liste von zertifizierten Ernährungsberatern für Hunde und Katzen, welche dich genau bei deiner Angelegenheit unterstützen.
                                        </p>
                                        <h2 className="color-primary" style={{marginTop: 20}}>Abfrage starten</h2>
                                        <small>* Pflichtfelder</small>
                                        <div style={{marginTop: 15}}>
                                            <strong style={{display: 'block', marginBottom: 10}}>Für welche Tierart möchtest du eine Ernährungsberatung? *</strong>
                                            <RadioGroup
                                                row
                                                defaultValue=""
                                                name="animal"
                                                onChange={e => {
                                                    let {filters} = this.state
                                                    filters.animal = e.target.value
                                                    this.setState({filters})
                                                }}
                                                style={{marginLeft: '12px'}}
                                            >
                                                {this.state.possibleFilters.includes('cat') && <FormControlLabel value="cat" control={<CustomRadio />} label="Katze" />}
                                                {this.state.possibleFilters.includes('dog') && <FormControlLabel value="dog" control={<CustomRadio />} label="Hund" />}
                                            </RadioGroup>
                                        </div>
                                        <div style={{marginTop: 20}}>
                                            <strong style={{display: 'block', marginBottom: 10}}>Alter deines Tieres? *</strong>
                                            <RadioGroup
                                                row
                                                defaultValue=""
                                                name="ageGroup"
                                                onChange={e => {
                                                    let {filters} = this.state
                                                    filters.ageGroup = e.target.value
                                                    this.setState({filters})
                                                }}
                                                style={{marginLeft: '12px'}}
                                            >
                                                {this.state.possibleFilters.includes('junior') && <FormControlLabel value="junior" control={<CustomRadio />} label="< 1 Jahr" />}
                                                {this.state.possibleFilters.includes('adult') && <FormControlLabel value="adult" control={<CustomRadio />} label="Adult" />}
                                                {this.state.possibleFilters.includes('senior') && <FormControlLabel value="senior" control={<CustomRadio />} label="Senior" />}
                                            </RadioGroup>
                                        </div>
                                        <div style={{marginTop: 20}}>
                                            <strong style={{display: 'block', marginBottom: 10}}>Für welche Ernährungsformen interessierst du dich? *</strong>
                                            {[{title: 'roh', value: 'raw'}, {title: 'gekocht', value: 'cooked'}, {title: 'nass', value: 'wet'}, {title: 'trocken', value: 'dried'}].map(el => this.state.possibleFilters.includes(el.value) && (
                                                <FormControlLabel
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <GreenCheckbox
                                                            name="foodType"
                                                            value={el.value}
                                                            onChange={this.handleCheckboxChange}
                                                            checked={this.state.filters.foodType && this.state.filters.foodType.includes(el.value)}
                                                        />
                                                    }
                                                    label={el.title}
                                                ></FormControlLabel>
                                            ))}
                                        </div>
                                        <div style={{marginTop: 30}}>
                                            <strong style={{display: 'block', marginBottom: 10}}>Soll etwas bestimmtes berücksichtigt werden?</strong>
                                            {filtersSpecialties.map(el => this.state.possibleFilters.includes(el.value) && (
                                                <FormControlLabel
                                                    className="checkbox-column"
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <GreenCheckbox
                                                            name="specialty"
                                                            value={el.value}
                                                            onChange={this.handleCheckboxChange}
                                                            checked={this.state.filters.specialty && this.state.filters.specialty.includes(el.value)}
                                                        />
                                                    }
                                                    label={el.title}
                                                ></FormControlLabel>
                                            ))}
                                            {filtersSpecialties.filter(el => this.state.possibleFilters.includes(el.value)).length === 0 && 'keine Filter vorhanden'}
                                        </div>
                                        <div style={{marginTop: 30}}>
                                            <strong style={{display: 'block', marginBottom: 10}}>Die Suche auf weitere Tätigkeitsbereiche verfeinern?</strong>
                                            {filtersAttributes.map(el => this.state.possibleFilters.includes(el.value) && (
                                                <FormControlLabel
                                                    className="checkbox-column"
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <GreenCheckbox
                                                            name="attributes"
                                                            value={el.value}
                                                            onChange={this.handleCheckboxChange}
                                                            checked={this.state.filters.attributes && this.state.filters.attributes.includes(el.value)}
                                                        />
                                                    }
                                                    label={el.title}
                                                ></FormControlLabel>
                                            ))}
                                            {filtersAttributes.filter(el => this.state.possibleFilters.includes(el.value)).length === 0 && 'keine Filter vorhanden'}
                                        </div>
                                        <div style={{marginTop: 30}}>
                                            <strong style={{display: 'block', marginBottom: 10}}>Wo möchtest du beraten werden?</strong>
                                            {filtersConsultingType.map(el => this.state.possibleFilters.includes(el.value) && (
                                                <FormControlLabel
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <GreenCheckbox
                                                            name="consultingType"
                                                            value={el.value}
                                                            onChange={this.handleCheckboxChange}
                                                            checked={this.state.filters.consultingType && this.state.filters.consultingType.includes(el.value)}
                                                        />
                                                    }
                                                    label={el.title}
                                                ></FormControlLabel>
                                            ))}
                                            {filtersConsultingType.filter(el => this.state.possibleFilters.includes(el.value)).length === 0 && 'keine Filter vorhanden'}
                                        </div>
                                        <div style={{marginTop: 30}} className='submit-buttons'>
                                            <span className="btn btn-primary" onClick={this.fetchConsultants}>Ernährungsberater suchen</span>
                                            <a className="btn btn-secondary" style={{color: '#fff'}} target="_blank" href={'https://www.myfeedingcompass.at/myfeedingcheck-ernaehrungsberater'}>Ernährungsberater Registrierung</a>
                                        </div>
                                        {this.state.error && (
                                            <p style={{marginTop: 20}} className="alert alert-danger">{this.state.error}</p>
                                        )}
                                    </React.Fragment>
                                )}
                                {this.state.showResults && (
                                    <React.Fragment>
                                        <p>
                                            Hier findest du alle zertifizierten Ernährungsberater, welche deinen Anforderungen entsprechen.
                                            <br /><br />
                                            Deine Anforderungen:<br />
                                            {SC.convert(this.state.filters.animal || 'kein Tier')} -&nbsp;
                                            {SC.convert(this.state.filters.ageGroup || 'keine Altersgruppe')} -&nbsp;
                                            {this.state.filters.foodType && this.state.filters.foodType.length >= 1 ? (
                                                <span>
                                                    {this.state.filters.foodType.map((val, i) => {
                                                        if (i === 0) return SC.convert(val)
                                                        else return ', ' + SC.convert(val)
                                                    })}
                                                </span>
                                            ) : 'keine Futterart'} -&nbsp;
                                            {this.state.filters.consultingType && this.state.filters.consultingType.length >= 1 ? (
                                                <span>
                                                    {this.state.filters.consultingType.map((val, i) => {
                                                        if (i === 0) return SC.convert(val)
                                                        else return ', ' + SC.convert(val)
                                                    })}
                                                </span>
                                            ) : 'keine Beratungsform'} -&nbsp;
                                            {this.state.filters.specialty && this.state.filters.specialty.length >= 1 ? (
                                                <span>
                                                    {this.state.filters.specialty.map((val, i) => {
                                                        if (i === 0) return SC.convert(val)
                                                        else return ', ' + SC.convert(val)
                                                    })}
                                                </span>
                                            ) : 'kein Spezialgebiet'} -&nbsp;
                                            {this.state.filters.attributes && this.state.filters.attributes.length >= 1 ? (
                                                <span>
                                                    {this.state.filters.attributes.map((val, i) => {
                                                        if (i === 0) return SC.convert(val)
                                                        else return ', ' + SC.convert(val)
                                                    })}
                                                </span>
                                            ) : 'keine sonstigen Filter'}
                                            <br /><br />
                                            Die Berater sind nach Land & PLZ geordnet.
                                            <br />Die meisten Ernährungsberater bieten auch eine online Beratung bzw. über E-Mail & Telefon an. Dadurch können viele Berater ortsunabhängig bzw. auch länderübergreifend arbeiten.
                                        </p>
                                        <div className="strong" style={{marginBottom: 20}}>
                                            {['de', 'at', 'ch'].map(country => this.state.consultants.filter(c => c.country === country).length >= 1 && (
                                                <div style={{marginBottom: 10}}>
                                                    {country.toUpperCase()}:
                                                    {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].map(letter => this.state.consultants.filter(c => c.country === country && c.zip.startsWith(letter)).length >= 1 && (
                                                        <span style={{cursor: 'pointer', marginLeft: 10}} onClick={() => window.scrollTo({
                                                                behavior: 'smooth',
                                                                top:
                                                                document.querySelector(`#${country}-${letter}`).getBoundingClientRect().top -
                                                                document.body.getBoundingClientRect().top -
                                                                (window.innerWidth > 767 ? 60 : 100),
                                                            })}>&nbsp;PLZ {letter}&nbsp;</span>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <span className="btn btn-primary" onClick={() => this.setState({error: false, showResults: false, filters: {}}, () => window.scrollTo({ top: 0, behavior: "smooth" }))}>Suche neu starten</span>
                                        </div>
                                        {this.state.consultants && this.state.consultants.length >= 1 ? (
                                            <div>
                                                {['de', 'at', 'ch'].map(country => this.state.consultants.filter(c => c.country === country).length >= 1 && (
                                                    <React.Fragment>
                                                        <h3 className="color-primary" style={{margin: '60px 0 10px'}}>
                                                            {country === 'at' && 'Österreich'}
                                                            {country === 'de' && 'Deutschland'}
                                                            {country === 'ch' && 'Schweiz'}
                                                        </h3>
                                                        {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].map(letter => this.state.consultants.filter(c => c.country === country && c.zip.startsWith(letter)).length >= 1 && (
                                                            <React.Fragment>
                                                                <h2 id={`${country}-${letter}`}>PLZ {letter}</h2>
                                                                {this.state.consultants.filter(c => c.country === country && c.zip.startsWith(letter)).map(c => (
                                                                    <div className="consultant" key={c._id}>
                                                                        <div className="left text-center">
                                                                            {c.logo ? <a href={c.url || '#'} target='_blank' rel="noopener noreferrer" style={c.url ? {} : {pointerEvents: 'none'}}><img src={bucketURL + c.logo} alt={c.name} /></a> : <div>{c.name}</div>}
                                                                            {c.url && <a href={c.url} target='_blank' rel="noopener noreferrer">{c.url.split('//')[1]}</a>}
                                                                            <p style={{margin: 0}}>
                                                                                {c.email && <React.Fragment>{c.email}<br /></React.Fragment>}
                                                                                {c.country.toUpperCase()}-{c.zip}{c.phone && ` / ${c.phone}`}
                                                                            </p>
                                                                        </div>
                                                                        <div className="right">
                                                                            <div>
                                                                                <strong>{c.company}</strong><br />
                                                                            </div>
                                                                            <div style={{marginTop: 5}}>Tierarten: {c.animal.map((a, i) => {
                                                                                if (i === 0) return SC.convert(a)
                                                                                else return ', ' + SC.convert(a)
                                                                            })}</div>
                                                                            <div style={{marginTop: 5}}>Ernährungsformen: {c.foodType.length === 4 ? 'alle' : c.foodType.map((val, i) => {
                                                                                if (i === 0) return SC.convert(val)
                                                                                else return ', ' + SC.convert(val)
                                                                            })}</div>
                                                                            <div style={{marginTop: 5}}>Altersgruppen: {c.ageGroup.length === 3 ? 'alle' : c.ageGroup.map((val, i) => {
                                                                                if (i === 0) return val === 'junior' ? '< 1 Jahr' : SC.convert(val)
                                                                                else return ', ' + (val === 'junior' ? '< 1 Jahr' : SC.convert(val))
                                                                            })}</div>
                                                                            <div style={{marginTop: 5}}>Spezialgebiet:&nbsp;
                                                                                {!c.specialty || c.specialty.length === 0 ? 'keines' : c.specialty.map((val, i) => {
                                                                                    if (i === 0) return SC.convert(val)
                                                                                    else return ', ' + SC.convert(val)
                                                                                })}
                                                                            </div>
                                                                            <div style={{marginTop: 5}}>Zusätzliches:&nbsp;
                                                                                {!c.attributes || c.attributes.length === 0 ? 'nein' : c.attributes.map((val, i) => {
                                                                                    if (i === 0) return SC.convert(val)
                                                                                    else return ', ' + SC.convert(val)
                                                                                })}
                                                                            </div>
                                                                            <div style={{marginTop: 5}}>Beratung:&nbsp;
                                                                                {!c.consultingType || c.consultingType.length === 0 ? 'n/a' : c.consultingType.map((val, i) => {
                                                                                    if (i === 0) return SC.convert(val)
                                                                                    else return ', ' + SC.convert(val)
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </React.Fragment>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        ) : <p className="alert alert-danger" style={{marginTop: 20}}>Leider wurden keine Berater mit diesen Kriterien gefunden.</p>}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isLoading && (
                    <LoadingOverlay message="Lade Daten..." />
                )}
            </div>
        );
    }
}

export default Consultants;
