import React from 'react';
import { ClipLoader } from 'react-spinners';

function LoadingOverlay(props) {
    const loaderStyles = {
        display: 'block',
        margin: '0 auto'
    }

    return (
        <div id="loading-overlay">
            <div className="overlay-content">
                <ClipLoader
                    css={loaderStyles}
                    color={'white'}
                    sizeUnit={'px'}
                    size={20}
                />
                {props && props.message ? (<span>{props.message}</span>) : null}
            </div>
        </div>
    );
}

export default LoadingOverlay;
