import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import ReportProductPopup from "./ReportProductPopup";
import PopupContactBrand from "./PopupContactBrand";
import LoadingOverlay from "./LoadingOverlay";
import Auth from "./../modules/Auth";
import SC from "./../modules/StringConverter";
import axios from "axios";
import Reaptcha from "reaptcha";
import noImage from "../img/no-image.jpg";
import amazonLogo from "../img/amazon-logo.png";
import fressnapfLogo from "../img/fressnapf-logo.png";
import zooplusLogo from "../img/zooplus-logo.png";
import zooroyalLogo from "../img/zooroyal-logo.png";
import hundelandLogo from "../img/hundeland-logo.png"
import liebesgutLogo from "../img/liebesgut-logo.png"
import scheckerLogo from "../img/schecker-logo.png"
import wolfsblutLogo from "../img/wolfsblut-logo.png"
import petsPremiumLogo from "../img/pets-premium-logo.png"
import zoologoLogo from "../img/zoologo-logo.png"
import alsaHundeweltLogo from "../img/alsa-hundewelt.jpg"
import joseraLogo from "../img/josera-logo.png"
import wildbornLogo from "../img/wildborn-logo.png"
import terraCanisLogo from "../img/terra-canis-logo.png"
import checkGreen from "../img/check-green.png";
import { BookmarkBorder, FavoriteBorder, NotInterested, CompareArrows, Close } from "@material-ui/icons";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import ReactChartkick, { PieChart } from "react-chartkick";
import Chart from "chart.js";
import stringFilter from "./../modules/Filter";
import psl from "psl";
import ReactTooltip from "react-tooltip";
import { CSSTransition } from "react-transition-group";
ReactChartkick.addAdapter(Chart);

const bucket = "https://bucket-mfc-wallride67.storage.googleapis.com/";

class ProductSingle extends Component {
    state = {
        currentPage: 4,
        errorMessage: "",
        fetchingError: false,
        redirectToProducts: false,
        redirectToLogin: false,
        redirectToNotFound: false,
        packagingTypes: [],
        product: {},
        product_brand: {},
        product_producer: {},
        product_rating: 0,
        product_rating_count: 0,
        reviewText: "",
        reviewRating: 0,
        reviewTOS: false,
        reviewFormError: "",
        reviewFormSuccess: "",
        binderVote: "",
        binderFormError: "",
        binderFormSuccess: "",
        binderChartIsEmpty: false,
        binderChartYes: 0,
        binderChartNo: 0,
        variantsSort: "priceAsc",
        variants: [],
        listUpdateError: "",
        listUpdateSuccess: "",
        showReportPop: false,
        showContactPop: false,
        showShopTable: false,
        isLoading: true,
    };

    constructor(props) {
        super(props);

        this.fetchData = this.fetchData.bind(this);
        this.onStarClick = this.onStarClick.bind(this);
        this.handleReviewSubmit = this.handleReviewSubmit.bind(this);
        this.handleCompareListChange = this.handleCompareListChange.bind(this);
        this.handleListsChange = this.handleListsChange.bind(this);
        this.handleSortVariantsChange = this.handleSortVariantsChange.bind(this);
    }

    fetchData = () => {
        let { urlExtension } = this.props.match.params;
        if (!urlExtension) {
            this.setState({ errorMessage: "Produkt nicht gefunden." });
            this.setState({ redirectToNotFound: true });
            // } else if (this.state.product && this.state.product.id === parsed.id) {
            //     return;
        } else {
            fetch("/api/product?url=" + urlExtension)
                .then((res) => res.json())
                .then((json) => {
                    if (json.success === true) {
                        this.setState({ product: json.product });

                        setTimeout(function () {
                            let x;
                        }, 100);

                        let { variants } = this.state.product;

                        let variantsCleaned = [];
                        let packagingTypes = [];

                        variants.map((v) => {
                            if (
                                v.published &&
                                v.size &&
                                v.unit &&
                                v.is_for_sale &&
                                v.link &&
                                v.price &&
                                v.merchant_name &&
                                v.packaging &&
                                (v.network !== 'b2b' || (v.network === 'b2b' && v.owner && v.owner.shopName && v.owner.url && v.owner.status && v.owner.status === 'active'))
                            ) {
                                let amount = v.amount ? v.amount : 1;
                                let { size, unit, price } = v;

                                unit = unit.toLowerCase().trim();

                                v.ogSize = size;
                                v.ogUnit = unit;

                                if (unit == "kg") {
                                    size = size * 1000;
                                    unit = "g";
                                } else if (unit == "l") {
                                    size = size * 1000;
                                    unit = "ml";
                                }

                                //base price per 100g
                                let calcBasePrice = (vAmount, vSize, vUnit, vPrice) => {
                                    let bP;
                                    try {
                                        if (vUnit !== "g" && vUnit !== "ml") {
                                            bP = "???";
                                        } else {
                                            bP = Math.round((vPrice / (vSize * vAmount)) * 10000) / 100;
                                        }
                                    } catch {
                                        bP = "???";
                                    } finally {
                                        return bP;
                                    }
                                };
                                let basePrice = calcBasePrice(amount, size, unit, price);

                                let calcBaseSize = (vAmount, vSize, vUnit) => {
                                    let multiplied = undefined;

                                    if (vUnit == "g" || vUnit == "ml") {
                                        multiplied = vAmount * vSize;
                                    }

                                    return multiplied;
                                };
                                let baseSize = calcBaseSize(amount, size, unit);

                                if (
                                    amount &&
                                    size &&
                                    unit &&
                                    v.merchant_name &&
                                    v.link &&
                                    price &&
                                    basePrice &&
                                    baseSize &&
                                    v.packaging &&
                                    (v.network !== 'b2b' || (v.network === 'b2b' && v.owner && v.owner.shopName && v.owner.url && v.owner.status && v.owner.status === 'active'))
                                ) {

                                    if (!packagingTypes.includes(v.packaging)) packagingTypes.push(v.packaging);

                                    variantsCleaned.push({
                                        merchantName: v.merchant_name,
                                        owner: v.network === 'b2b' ? v.owner : undefined,
                                        amount: amount,
                                        ogSize: v.ogSize,
                                        ogUnit: v.ogUnit,
                                        size: size,
                                        unit: unit,
                                        link: v.network !== 'b2b' ? v.link : v.owner.url + v.link,
                                        packaging: v.packaging,
                                        price: price,
                                        basePrice: basePrice,
                                        baseSize: baseSize,
                                        deliveryTime: v.deliveryTime || "n/a",
                                        deliveryCost: v.deliveryCost || undefined,
                                    });
                                }
                            }
                        });

                        variantsCleaned = variantsCleaned.sort((a, b) => {
                            if (a.basePrice > b.basePrice) {
                                return 1;
                            } else if (a.basePrice < b.basePrice) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });

                        this.setState({ variants: variantsCleaned, packagingTypes: packagingTypes });

                        if (json.product.reviews && json.product.reviews.length >= 1) {
                            let ratingCount = 0;
                            let stars = 0;
                            json.product.reviews.forEach((r) => {
                                stars = stars + r.rating;
                                ratingCount++;
                            });
                            let productRating = Math.round(stars / ratingCount);
                            this.setState({ product_rating: productRating });
                            this.setState({
                                product_rating_count: ratingCount,
                            });
                        }

                        if (json.product.binderVotes && json.product.binderVotes.length >= 1) {
                            let votesYes = 0;
                            let votesNo = 0;

                            json.product.binderVotes.forEach((vote) => {
                                if (vote.vote === true) {
                                    votesYes++;
                                } else if (vote.vote === false) {
                                    votesNo++;
                                }
                            });
                            this.setState({ binderChartYes: votesYes });
                            this.setState({ binderChartNo: votesNo });
                        } else {
                            this.setState({ binderChartIsEmpty: true });
                        }

                        this.setState({ product_brand: json.product.brand });
                        this.setState({
                            product_producer: json.product.producer,
                        });
                        this.setState({ isLoading: false });
                    } else {
                        this.setState({ redirectToNotFound: true });
                        this.setState({
                            errorMessage: "Kein Produkt gefunden.",
                        });
                        this.setState({ fetchingError: true });
                        this.setState({ isLoading: false });
                    }
                })
                .catch((err) => {
                    this.setState({ redirectToNotFound: true });
                    this.setState({
                        errorMessage: "Kein Produkt mit dieser Produkt-ID gefunden.",
                    });
                    this.setState({ fetchingError: true });
                    this.setState({ isLoading: false });
                });
        }
    };

    componentDidMount() {
        this.fetchData();
        this.addProductToLastViewed();
        window.scrollTo({ top: 0, behavior: "smooth" });

        document.querySelectorAll('.image-gallery img').forEach(img => {
            img.addEventListener('error', e => {
                e.target.src = 'https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png';
            })
        })
    }

    addProductToLastViewed = () => {
        let lastViewedArray = JSON.parse(localStorage.getItem("lastViewedProducts"));
        let { urlExtension } = this.props.match.params;
        if (urlExtension) {
            if (lastViewedArray) {
                if (lastViewedArray.includes(urlExtension)) {
                    let index = lastViewedArray.indexOf(urlExtension);
                    if (index > -1) {
                        lastViewedArray.splice(index, 1);
                    }
                    lastViewedArray.splice(0, 0, urlExtension);
                } else {
                    lastViewedArray.splice(0, 0, urlExtension);
                }
                if (lastViewedArray.length >= 21) {
                    lastViewedArray.length = 20;
                }
            } else {
                lastViewedArray = [urlExtension];
            }

            localStorage.setItem("lastViewedProducts", JSON.stringify(lastViewedArray));
        }
    };

    componentDidUpdate(prevProps, prevState) {
        let { urlExtension } = this.props.match.params;
        if (prevState.product.urlExtension && urlExtension.toString() !== prevState.product.urlExtension.toString()) {
            this.fetchData();
            this.addProductToLastViewed();
        }
    }

    onStarClick(nextValue, prevValue, name) {
        this.setState({ reviewRating: nextValue });
    }

    async handleReviewSubmit() {
        let { urlExtension } = this.props.match.params;

        this.setState({ reviewFormError: "" });
        this.setState({ reviewFormSuccess: "" });

        let { reviewText, reviewRating, reviewTOS } = this.state;

        if (!reviewRating || reviewRating < 1 || reviewRating > 5) {
            this.captcha.reset();
            this.setState({
                reviewFormError:
                    "Bitte wähle wie viele Sterne dieses Produkt erhalten soll."
            });
            return;
        }

        let filterError = await stringFilter(reviewText);

        if (filterError) {
            this.captcha.reset();
            this.setState({
                reviewFormError:
                    "Fehler! Wir haben ein verbotenes Wort in deiner Eingabe gefunden: " +
                    filterError.toUpperCase() +
                    ". Bitte entferne das Wort bevor du das Formular erneut absendest.",
            });
            return;
        }

        if (!reviewTOS) {
            this.captcha.reset();
            this.setState({
                reviewFormError: "Bitte akzeptiere die Nutzungsbedingungen.",
            });
            return;
        }

        Auth.checkRecaptcha()
            .then((res) => {
                this.captcha.reset();
                let datas = res.data;

                if (datas.success) {
                    try {
                        Auth.getUserFromToken().then((user) => {
                            try {
                                user = user._id;
                            } catch {
                                this.props.handleAuthenticationChange(false);
                                this.setState({ redirectToLogin: true });
                            }
                            let token = Auth.getToken();

                            let reviewData = {
                                user: user,
                                product: urlExtension,
                                reviewText: reviewText,
                                reviewRating: reviewRating,
                            };

                            axios
                                .post("/api/new-review", reviewData, {
                                    headers: { token: token },
                                })
                                .then((res) => {
                                    let { data } = res;
                                    if (data.success === false) {
                                        this.setState({
                                            reviewFormError: data.message,
                                        });
                                        this.setState({ reviewRating: 3 });
                                        this.setState({ reviewText: "" });
                                    } else if (data.success === true) {
                                        this.setState({
                                            reviewFormSuccess: data.message,
                                        });
                                        this.setState({ reviewRating: 3 });
                                        this.setState({ reviewText: "" });
                                    }
                                })
                                .catch((err) => {
                                    this.setState({
                                        reviewFormError: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.",
                                    });
                                    this.setState({ reviewRating: 3 });
                                    this.setState({ reviewText: "" });
                                });
                        });
                    } catch (e) {
                        this.props.handleAuthenticationChange(false);
                        this.setState({ redirectToLogin: true });
                    }
                } else {
                    this.setState({
                        reviewFormError: [
                            "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal.",
                        ],
                    });
                }
            })
            .catch((err) => {
                this.captcha.reset();
                this.setState({
                    reviewFormError: [
                        "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal.",
                    ],
                });
            });
    }

    handleBinderFormSubmit = (e) => {
        e.preventDefault();

        let { urlExtension } = this.props.match.params;

        this.setState({ binderFormError: "" });
        this.setState({ binderFormSuccess: "" });

        let { binderVote } = this.state;

        if (!(this.state.binderVote === "true" || this.state.binderVote === "false")) {
            this.setState({
                binderFormError: "Bitte triff zuerst eine Auswahl.",
            });
            return;
        }

        try {
            Auth.getUserFromToken().then((user) => {
                try {
                    user = user._id;
                } catch {
                    this.props.handleAuthenticationChange(false);
                    this.setState({ redirectToLogin: true });
                }
                let token = Auth.getToken();

                let binderVoteData = {
                    user: user,
                    product: urlExtension,
                    binderVote: binderVote,
                };

                axios
                    .post("/api/new-binder-vote", binderVoteData, {
                        headers: { token: token },
                    })
                    .then((res) => {
                        let { data } = res;
                        if (data.success === false) {
                            this.setState({ binderFormError: data.message });
                            this.setState({ binderVote: "" });
                        } else if (data.success === true) {
                            this.setState({ binderFormSuccess: data.message });
                            this.setState({ binderVote: "" });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            binderFormError: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.",
                        });
                        this.setState({ binderVote: "" });
                    });
            });
        } catch (e) {
            this.props.handleAuthenticationChange(false);
            this.setState({ redirectToLogin: true });
        }
    };

    handleCompareListChange(e) {
        e.preventDefault();
        if (this.props.compareList.includes(this.state.product._id)) {
            let arr = this.props.compareList;
            var index = arr.indexOf(this.state.product._id);
            if (index !== -1) arr.splice(index, 1);
            this.props.handleCompareListChange(arr);
        } else {
            let arr = [...this.props.compareList, this.state.product._id];
            this.props.handleCompareListChange(arr);
        }
    }

    handleListsChange = (e) => {
        e.preventDefault();

        this.setState({ listUpdateError: "" });
        this.setState({ listUpdateSuccess: "" });

        let listType = e.target.dataset.list;

        try {
            Auth.getUserFromToken().then((user) => {
                try {
                    user = user._id;
                } catch {
                    this.props.handleAuthenticationChange(false);
                    this.setState({ redirectToLogin: true });
                }
                let token = Auth.getToken();

                let productId = this.state.product._id;

                let datas = {
                    user: user,
                    product: productId,
                    listType: listType,
                };

                axios
                    .post("/api/lists-update", datas, {
                        headers: { token: token },
                    })
                    .then((res) => {
                        let { data } = res;
                        if (data.success) {
                            this.setState({ listUpdateSuccess: data.message });
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        } else {
                            this.setState({
                                listUpdateError: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.",
                            });
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            listUpdateError: "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.",
                        });
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    });
            });
        } catch (e) {
            this.props.handleAuthenticationChange(false);
            this.setState({ redirectToLogin: true });
        }
    };

    handleReportPopClose = () => {
        this.setState({ showReportPop: false });
    };

    handleContactPopClose = () => {
        this.setState({ showContactPop: false });
    };

    reviewView() {
        if (this.props.isAuthenticated && !this.state.fetchingError) {
            return (
                <div className="row">
                    <div className="col-12">
                        <form
                            method="POST"
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.captcha.execute();
                            }}
                            className="review-form mt-30"
                        >
                            <h2>Neue Bewertung hinzufügen</h2>
                            <StarRatingComponent
                                name="review-rating"
                                starCount={5}
                                value={this.state.reviewRating}
                                onStarClick={this.onStarClick}
                            />
                            <div className="form-group">
                                <label for="review-textarea">
                                    <strong className="color-primary">Deine Produktbewertung</strong>
                                    <br />
                                    <small>
                                        Wie war Konsistenz, Geruch und / oder Akzeptanz von dem Produkt? Wie hat Dein
                                        Tier darauf reagiert? Größerer/kleinerer Output, mehr getrunken, etc..
                                        Preis/Leistung?
                                    </small>
                                </label>
                                <textarea
                                    className="form-control"
                                    id="review-textarea"
                                    placeholder="Deine Bewertung"
                                    rows="6"
                                    onChange={(e) =>
                                        this.setState({
                                            reviewText: e.target.value,
                                        })
                                    }
                                    value={this.state.reviewText}
                                ></textarea>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="tos"
                                        required
                                        checked={this.state.reviewTOS}
                                        onChange={() =>
                                            this.setState({
                                                reviewTOS: !this.state.reviewTOS,
                                            })
                                        }
                                    />
                                    <span>
                                        Ich akzeptiere die <Link to="">Nutzungsbedingungen</Link>.
                                    </span>
                                </label>
                            </div>
                            <Reaptcha
                                ref={(e) => (this.captcha = e)}
                                size="invisible"
                                sitekey="6LeEAaIUAAAAAHVNxEUt6ZjKHzvCA_6gPuwjjK0S"
                                onVerify={this.handleReviewSubmit}
                                onError={() => {
                                    this.setState({
                                        reviewFormError: ["ReCAPTCHA-Fehler aufgetreten! Bitte lade die Seite erneut."],
                                    });
                                }}
                            />
                            <button type="submit" className="btn btn-success">
                                Bewertung absenden
                            </button>
                        </form>
                        {this.state.reviewFormError && (
                            <p className="alert alert-danger" style={{ marginTop: '55px' }}>{this.state.reviewFormError}</p>
                        )}
                        {this.state.reviewFormSuccess && (
                            <p className="alert alert-success" style={{ marginTop: '55px' }}>{this.state.reviewFormSuccess}</p>
                        )}
                    </div>
                </div>
            );
        } else if (!this.props.isAuthenticated && !this.state.fetchingError) {
            return (
                <React.Fragment>
                    <h2>Neue Bewertung hinzufügen</h2>
                    <p className="alert alert-secondary">
                        Du musst angemeldet sein, um Bewertungen verfassen zu können.
                    </p>
                </React.Fragment>
            );
        }
        return null;
    }

    displayProductReviews() {
        if (this.state.product.reviews && this.state.product.reviews.length >= 1) {
            let reviews = [];
            this.state.product.reviews.forEach((review) => {
                reviews.push(
                    <div className="product-review" key={review._id}>
                        <StarRatingComponent starCount={5} value={review.rating} editing={false} />
                        <br />
                        <strong className="color-primary">Von:</strong> {review.from.username}
                        <br />
                        {review.review && (
                            <React.Fragment>
                                <strong className="color-primary">Bewertung:</strong>{" "}
                                <span>
                                    &#34;{SC.decodeHTMLEntities(review.review)}
                                    &#34;
                                </span>
                            </React.Fragment>
                        )}
                    </div>
                );
            });
            return (
                <div className="product-single-reviews">
                    <h2>Produktbewertungen</h2>
                    {reviews}
                    {this.reviewView()}
                </div>
            );
        }
        return (
            <React.Fragment>
                <h2>Produktbewertungen</h2>
                <p className="alert alert-secondary">Leider sind noch keine Bewertungen zu diesem Produkt vorhanden.</p>
                {this.reviewView()}
            </React.Fragment>
        );
    }

    binderVotesPieChart() {
        return (
            <div className="row">
                <div className="col-12 binder-votes">
                    <h4>
                        Binde-/
                        <wbr />
                        Verdickungsmittel-Verdacht
                    </h4>
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <p>Ergebnisse der Abstimmung:</p>
                            {!this.state.binderChartIsEmpty &&
                                (this.state.binderChartYes >= 1 || this.state.binderChartNo >= 1) ? (
                                <PieChart
                                    data={[
                                        ["Ja", this.state.binderChartYes],
                                        ["Nein", this.state.binderChartNo],
                                    ]}
                                    colors={["#F5CF63", "#28a745"]}
                                    suffix={" Stimme"}
                                    messages={{
                                        empty: "Keine Daten vorhanden.",
                                    }}
                                />
                            ) : (
                                <PieChart
                                    data={[]}
                                    colors={["#F5CF63", "#28a745"]}
                                    suffix={" Stimme"}
                                    messages={{
                                        empty: "Keine Daten vorhanden.",
                                    }}
                                />
                            )}
                        </div>
                        <div className="col-md-6">
                            <div className="card card-body">
                                <p>
                                    <strong className="color-primary">
                                        Denkst du dieses Produkt enthält Binde- bzw. Verdickungsmittel?
                                    </strong>
                                    <br />
                                    <small>
                                        Binde- bzw. Verdickungsmittel müssen erst ab gewissen Mengen vom Hersteller
                                        angegeben werden.
                                        <br />
                                        Im Prinzip sind sie Nichts schlechtes, jedoch gibt es viele Hunde & Katzen von
                                        denen sie nicht vertragen werden, diese reagieren dann mit Magen-Darm-Problemen
                                        darauf.
                                    </small>
                                </p>
                                <form method="post" onSubmit={this.handleBinderFormSubmit}>
                                    <div className="form-group">
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="binderVote"
                                                    value="true"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            binderVote: e.target.value,
                                                        })
                                                    }
                                                    checked={this.state.binderVote === "true"}
                                                />{" "}
                                                Ja
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="binderVote"
                                                    value="false"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            binderVote: e.target.value,
                                                        })
                                                    }
                                                    checked={this.state.binderVote === "false"}
                                                />{" "}
                                                Nein
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-success">
                                        Abstimmen
                                    </button>
                                    {this.state.binderFormError && (
                                        <p className="alert alert-danger mt-20">{this.state.binderFormError}</p>
                                    )}
                                    {this.state.binderFormSuccess && (
                                        <p className="alert alert-success mt-20">{this.state.binderFormSuccess}</p>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    productInformationsCard = () => {
        let { product } = this.state;

        let weenderHTML = [];
        let amountHTML = [];
        let productInformationHTML = [];

        if (product.proteins) {
            weenderHTML.push(
                <tr>
                    <td>Protein</td>
                    <td className="text-right">{product.proteins}%</td>
                </tr>
            );
        }
        if (product.fats) {
            weenderHTML.push(
                <tr>
                    <td>Rohfett</td>
                    <td className="text-right">{product.fats}%</td>
                </tr>
            );
        }
        if (product.fibers) {
            weenderHTML.push(
                <tr>
                    <td>Rohfaser</td>
                    <td className="text-right">{product.fibers}%</td>
                </tr>
            );
        }
        if (product.ash) {
            weenderHTML.push(
                <tr>
                    <td>Rohasche</td>
                    <td className="text-right">{product.ash}%</td>
                </tr>
            );
        }
        if (product.moisture) {
            weenderHTML.push(
                <tr>
                    <td>Feuchtigkeit</td>
                    <td className="text-right">{product.moisture}%</td>
                </tr>
            );
        }
        if (product.energy) {
            weenderHTML.push(
                <tr>
                    <td>Energie</td>
                    <td className="text-right">{product.energy / 10} kcal</td>
                </tr>
            );
        }
        if (product.nfe) {
            weenderHTML.push(
                <tr>
                    <td>Nfe-Gehalt</td>
                    <td className="text-right">{product.nfe}%</td>
                </tr>
            );
        }
        if (product.nfeTM) {
            weenderHTML.push(
                <tr>
                    <td>Nfe-Gehalt Trockenmasse</td>
                    <td className="text-right">{product.nfeTM}%</td>
                </tr>
            );
        }

        if (product.suggestedAmount) {
            product.suggestedAmount.map((pair) => {
                amountHTML.push(
                    <tr>
                        <td>{pair.weightKg}</td>
                        <td className="text-right">{pair.foodAmount}</td>
                    </tr>
                );
            });
        }

        if (product.declaration) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Deklaration</strong>: {SC.convert(product.declaration)}
                </p>
            );
        }
        if ((product.type1 == 'raw' || product.type1 == 'flakes' || product.type1 == 'suplement') && product.processing) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Verarbeitung</strong>: {SC.convert(product.processing).replace('& Co.', '')}
                </p>
            );
        }
        if (product.type1 === "wet" && product.animalShare >= 0) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Tierischer Anteil</strong>: {SC.convertAnimalShare(product.animalShare)}
                </p>
            );
        }
        if (product.type1 === "dried" && product.animalShareDriedOne >= 0) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Tierischer Trockenfleisch-Anteil</strong>:{" "}
                    {SC.convertAnimalShareDried(product.animalShareDriedOne)}
                </p>
            );
        }
        if (product.type1 === "dried" && product.animalShareDriedTwo >= 0) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Tierischer Frischfleisch-Anteil</strong>:{" "}
                    {SC.convertAnimalShareDried(product.animalShareDriedTwo)}
                </p>
            );
        }
        if (product.type1 != 'flakes' && product.animalComponents && product.animalComponents.length >= 1) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Tierbestandteile</strong>: {SC.convert(product.animalComponents).join(", ")}
                </p>
            );
        }
        if ((product.type1 == 'raw' || product.type1 == 'flakes' || product.type1 == 'suplement') && product.elements && product.elements.length >= 1) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>{product.type1 == 'flakes' ? 'Monosorten' : 'Bestandteile'}</strong>: {SC.convert(product.elements).join(", ")}
                </p>
            );
        }
        if (product.diseases && product.diseases.length >= 1) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Gesundheit</strong>: {SC.convert(product.diseases).join(", ")}
                </p>
            );
        }

        productInformationHTML.push(
            <p className="nomargin">
                <strong>Enthält technologische Zusatzstoffe</strong>:{" "}
                {product.technologicalAdditives !== undefined
                    ? SC.convert(product.technologicalAdditives)
                    : "nicht angegeben"}
            </p>
        );

        productInformationHTML.push(
            <p className="nomargin">
                <strong>Enthält ernährungsphysiologische Zusatzstoffe</strong>:{" "}
                {product.physiologicalAdditives !== undefined
                    ? SC.convert(product.physiologicalAdditives)
                    : "nicht angegeben"}
            </p>
        );

        if (product.attributes && product.attributes.length >= 1) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Sonstige Eigenschaften</strong>: {SC.convert(product.attributes).join(", ")}
                </p>
            );
        } else {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Sonstige Eigenschaften</strong>: keine
                </p>
            );
        }

        if ((product.type1 == 'raw') && product.countriesOfDelivery && product.countriesOfDelivery.length >= 1) {
            productInformationHTML.push(
                <p className="nomargin">
                    <strong>Zustellungsland</strong>: {SC.convert(product.countriesOfDelivery).join(", ")}
                </p>
            );
        }

        return (
            <div className="row product-information">
                <div className="col-12">
                    <h2>Produktinformationen</h2>
                </div>
                <div className="col-xl-4 col-md-6">
                    <strong className="color-primary">Weender Analyse</strong>
                    <button type="button" className="btn btn-tooltip">
                        <img src="/img/information.png" data-tip data-for="tip-15" />
                    </button>
                    <ReactTooltip id="tip-15" place="top" type="dark" effect="float" className="react-tooltip">
                        <span>
                            Da neue und alte Rezepturen eines Produktes im Umlauf sein können, kann es sein, dass hier
                            angegeben Produktdaten nicht eindeutig mit dem im Onlineshop übereinstimmen. Bitte vor einem
                            Kauf immer im Onlineshop vergewissern, dass es das benötigte Produkt ist.
                        </span>
                    </ReactTooltip>
                    {weenderHTML.length >= 1 ? (
                        <table className="table table-striped">
                            <thead>
                                <th>Inhalt</th>
                                <th className="text-right">pro 100g</th>
                            </thead>
                            <tbody>{weenderHTML}</tbody>
                        </table>
                    ) : (
                        <p className="card card-body text-center">Keine Daten vorhanden</p>
                    )}
                </div>
                <div className="col-xl-4 col-md-6 mt-30-sm">
                    <strong className="color-primary">Empfohlene Futtermenge vom Hersteller</strong>
                    {amountHTML.length >= 1 ? (
                        <table className="table table-striped">
                            <thead>
                                <th>&nbsp;</th>
                                <th className="text-right">Futtermenge pro Tag</th>
                            </thead>
                            <tbody>{amountHTML}</tbody>
                        </table>
                    ) : (
                        <p className="card card-body text-center">Keine Daten vorhanden</p>
                    )}
                </div>

                {this.state.packagingTypes.length >= 1 && (
                    <div className="col-xl-4 col-12 mt-20-lg">
                        <p>
                            <strong className="color-primary">Verpackung</strong>
                            <br />
                            <div className="mb-20">
                                <strong>{this.state.packagingTypes.length > 1 ? 'Verpackungsarten: ' : 'Verpackungsart: '}</strong>
                                {this.state.packagingTypes.map((p, i) => {
                                    if (i == this.state.packagingTypes.length - 1) {
                                        return `${SC.convert(p)}`
                                    } else {
                                        return `${SC.convert(p)}, `
                                    }
                                })}
                                <br />
                            </div>
                        </p>
                    </div>
                )}
                {product.description && (
                    <div className="col-12 mt-20">
                        <strong className="color-primary">Zusammensetzung</strong>
                        <button type="button" className="btn btn-tooltip">
                            <img src="/img/information.png" data-tip data-for="tip-16" />
                        </button>
                        <ReactTooltip id="tip-16" place="top" type="dark" effect="float" className="react-tooltip">
                            <span>
                                Da neue und alte Rezepturen eines Produktes im Umlauf sein können, kann es sein, dass
                                hier angegeben Produktdaten nicht eindeutig mit dem im Onlineshop übereinstimmen. Bitte
                                vor einem Kauf immer im Onlineshop vergewissern, dass es das benötigte Produkt ist.
                            </span>
                        </ReactTooltip>
                        <p>
                            {product.description.split("\r\n").map((line) => {
                                return <span style={{ display: "block" }}>{line}&nbsp;</span>;
                            })}
                        </p>
                        {product.physiologicalAdditives && product.physiologicalAdditivesText && (
                            <p>
                                {product.physiologicalAdditivesText.split("\r\n").map((line, i) => {
                                    if (i == 0) return <span style={{ display: "block" }}>Ernährungsphysiologische Zusatzstoffe: {line}&nbsp;</span>
                                    else return <span style={{ display: "block" }}>{line}&nbsp;</span>
                                })}
                            </p>
                        )}
                        {product.technologicalAdditives && product.technologicalAdditivesText && (
                            <p>
                                {product.technologicalAdditivesText.split("\r\n").map((line, i) => {
                                    if (i == 0) return <span style={{ display: "block" }}>Technologische Zusatzstoffe: {line}&nbsp;</span>
                                    else return <span style={{ display: "block" }}>{line}&nbsp;</span>
                                })}
                            </p>
                        )}
                        {product.analyticComponents && (
                            <p>
                                {product.analyticComponents.split("\r\n").map((line, i) => {
                                    if (i == 0) return <span style={{ display: "block" }}>Analytische Bestandteile: {line}&nbsp;</span>
                                    else return <span style={{ display: "block" }}>{line}&nbsp;</span>
                                })}
                            </p>
                        )}
                    </div>
                )}
                {productInformationHTML.length >= 1 && (
                    <div className="col-12 mt-20">
                        <strong className="color-primary">Inhalt &amp; Details</strong>
                        {productInformationHTML}
                    </div>
                )}
                {/* <div className="col-12 mt-20">
                    <p>
                        {this.state.product._id && (
                            <span
                                className="link-styles"
                                data-product-id={product._id}
                                onClick={() => this.setState({ showReportPop: true })}
                            >
                                Produkt melden
                            </span>
                        )}
                    </p>
                </div> */}
            </div>
        );
    };

    aboutProducerCard = () => {
        let product = this.state.product;
        let brand = this.state.product_brand;

        return (
            <div className="row">
                <div className="col-12">
                    <h2>Produkt- & Markenbeschreibung</h2>
                </div>
                <div className="col-md-6">
                    <strong className="color-primary">Über das Produkt</strong>
                    <p>
                        <strong className="">Beschreibung:</strong>
                        <br />
                        {product.productDescription ? product.productDescription.split("\r\n").map((line) => {
                            return <span style={{ display: "block" }}>{line}&nbsp;</span>;
                        }) : 'keine vorhanden'}
                    </p>
                </div>
                <div className="col-md-6">
                    <strong className="color-primary">Über die Marke</strong>
                    {brand.name && (
                        <p>
                            <strong className="">Name:</strong>
                            <br />
                            {brand.name}
                        </p>
                    )}
                    {brand.description && (
                        <p>
                            <strong className="">Beschreibung:</strong>
                            <br />
                            {brand.description}
                        </p>
                    )}
                </div>
            </div>
        );
    };

    productInformation = () => {
        if (this.state.currentPage == 0) {
            return this.productInformationsCard();
        }

        if (this.state.currentPage == 1) {
            return this.displayProductReviews();
        }

        if (this.state.currentPage == 2) {
            return this.aboutProducerCard();
        }

        if (this.state.currentPage == 3) {
            return this.binderVotesPieChart();
        }

        if (this.state.currentPage == 4) {
            return this.variantsView();
        }

        return null;
    };

    variantsView = () => {
        let replaceDecimal = (string) => {
            return string.toString().replace(".", ",");
        };

        if (this.state.variants && Array.isArray(this.state.variants) && this.state.variants.length > 0) {
            return (
                <div className="row variants-view">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-7 col-lg-8">
                                <h4 style={{ marginBottom: 0 }}>Preisvergleich</h4>
                            </div>
                            <div className="col-md-5 col-lg-4">
                                <div className="styled-select">
                                    <select name="variantsSort" onChange={this.handleSortVariantsChange}>
                                        <option value="priceAsc" selected={this.state.sortBy === "priceAsc"}>
                                            Preis aufsteigend
                                        </option>
                                        <option value="priceDesc" selected={this.state.sortBy === "priceDesc"}>
                                            Preis absteigend
                                        </option>
                                        <option value="baseSizeAsc" selected={this.state.sortBy === "baseSizeAsc"}>
                                            Menge aufsteigend
                                        </option>
                                        <option value="baseSizeDesc" selected={this.state.sortBy === "baseSizeDesc"}>
                                            Menge absteigend
                                        </option>
                                        <option value="sizeAsc" selected={this.state.sortBy === "sizeAsc"}>
                                            Verpackungsgröße aufsteigend
                                        </option>
                                        <option value="sizeDesc" selected={this.state.sortBy === "sizeDesc"}>
                                            Verpackungsgröße absteigend
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive" style={{ marginTop: "1rem" }}>
                            <div className="variants-table">
                                <div className="table-body">
                                    <div className="table-row table-head">
                                        <div>Shop</div>
                                        <div>Menge</div>
                                        <div>Preis</div>
                                        <div data-tip data-for="tip-17">
                                            Lieferkosten
                                        </div>
                                        <div>Gesamtpreis</div>
                                        <div>Lieferzeit</div>
                                        <div>&nbsp;</div>
                                    </div>
                                    {this.state.variants.map((v) => {
                                        return (
                                            <div className="table-row">
                                                <div>
                                                    <a href={v.link} target="_blank">
                                                        <img
                                                            src={
                                                                (v.merchantName.toLowerCase().includes("amazon") &&
                                                                    amazonLogo) ||
                                                                (v.merchantName.toLowerCase().includes("zooroyal") &&
                                                                    zooroyalLogo) ||
                                                                (v.merchantName.toLowerCase().includes("zooplus") &&
                                                                    zooplusLogo) ||
                                                                (v.merchantName.toLowerCase().includes("fressnapf") &&
                                                                    fressnapfLogo) ||
                                                                (v.merchantName.toLowerCase().includes("terracanis") &&
                                                                    terraCanisLogo) ||
                                                                (v.merchantName.toLowerCase().includes("wildborn") &&
                                                                    wildbornLogo) ||
                                                                (v.merchantName.toLowerCase().includes("josera") &&
                                                                    joseraLogo) ||
                                                                (v.merchantName.toLowerCase().includes("alsa-hundewelt") &&
                                                                    alsaHundeweltLogo) ||
                                                                (v.merchantName.toLowerCase().includes("hundeland") &&
                                                                    hundelandLogo) ||
                                                                (v.merchantName.toLowerCase().includes("zoologo") &&
                                                                    zoologoLogo) ||
                                                                (v.merchantName.toLowerCase().includes("petspremium") &&
                                                                    petsPremiumLogo) ||
                                                                (v.merchantName.toLowerCase().includes("wolfsblut") &&
                                                                    wolfsblutLogo) ||
                                                                (v.merchantName.toLowerCase().includes("schecker") &&
                                                                    scheckerLogo) ||
                                                                (v.merchantName.toLowerCase().includes("liebesgut") &&
                                                                    liebesgutLogo || 
                                                                v.merchantName === 'b2b' && v.owner && v.owner.shopLogo && bucket + v.owner.shopLogo ||
                                                                noImage)
                                                            }
                                                        />
                                                    </a>
                                                </div>
                                                <div>
                                                    {v.amount}x{v.ogSize.toString().replace(".", ",")}
                                                    {v.ogUnit}
                                                    {v.amount > 0 && (
                                                        <React.Fragment>
                                                            <br />
                                                            <small>
                                                                Gesamt: {v.unit == 'g' ? ((v.amount * v.size) / 1000).toString().replace('.', ',') : (v.amount * v.size)}
                                                                {v.unit == 'g' ? 'kg' : v.unit}
                                                                <br />
                                                                Verpackung: {SC.convert(v.packaging)}
                                                            </small>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                <div>
                                                    {replaceDecimal(v.price)}€
                                                    <br />
                                                    <small>
                                                        {replaceDecimal(v.basePrice.toFixed(2))}€ / 100{v.unit}
                                                    </small>
                                                </div>
                                                <div data-tip data-for="tip-17">
                                                    <span className="mobile-only">Lieferkosten: </span>
                                                    {v.deliveryCost
                                                        ? replaceDecimal(v.deliveryCost.toFixed(2)) + "€"
                                                        : "n/a"}
                                                    <br className="mobile-only" />
                                                    <small className="mobile-only">
                                                        Lieferzeit: {v.deliveryTime}
                                                        {!isNaN(v.deliveryTime) && " Tage"}
                                                    </small>
                                                </div>
                                                <div data-tip data-for="tip-17">
                                                    <span className="mobile-only">Gesamt: </span>
                                                    {typeof v.price == "number" && typeof v.deliveryCost == "number"
                                                        ? (Math.round((v.price + v.deliveryCost) * 100) / 100)
                                                            .toFixed(2)
                                                            .replace(".", ",") + "€"
                                                        : replaceDecimal(v.price) + "€"}
                                                </div>
                                                <div className="desktop-only">
                                                    <span className="mobile-only">Lieferzeit: </span>
                                                    {v.deliveryTime}
                                                    {!isNaN(v.deliveryTime) && " Tage"}
                                                </div>
                                                <div>
                                                    <a href={v.link} target="_blank" className="btn btn-primary">
                                                        Zum Shop
                                                    </a>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {this.state.product._id == '5cab7431a464630016c0b31b' && (
                                        <div className="table-row">
                                            <div>
                                                <a href='https://www.amazon.de/Animonda-Katzen-Nassfutter-erwachsene-Katzen/dp/B01IGVD394/ref=as_li_ss_tl?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=animonda+katzenfutter+nass&qid=1605522956&sr=8-11&th=1&linkCode=ll1&tag=myfeedingch00-21&linkId=31ad8156952bd8384544c03820375cfc&language=de_DE' target="_blank">
                                                    <img src={amazonLogo} />
                                                </a>
                                            </div>
                                            <div>
                                                6x800g
                                                <br />
                                                <small>
                                                    Gesamt: 4,8kg
                                                    <br />
                                                    Verpackung: n/a
                                                </small>

                                            </div>
                                            <div>
                                                n/a
                                            </div>
                                            <div data-tip data-for="tip-17">
                                                <span className="mobile-only">Lieferkosten: </span>
                                                n/a
                                                <br className="mobile-only" />
                                                <small className="mobile-only">
                                                    Lieferzeit: n/a
                                                </small>
                                            </div>
                                            <div data-tip data-for="tip-17">
                                                <span className="mobile-only">Gesamt: </span>n/a
                                            </div>
                                            <div className="desktop-only">
                                                <span className="mobile-only">Lieferzeit: </span>
                                                n/a
                                            </div>
                                            <div>
                                                <a href='https://www.amazon.de/Animonda-Katzen-Nassfutter-erwachsene-Katzen/dp/B01IGVD394/ref=as_li_ss_tl?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=animonda+katzenfutter+nass&qid=1605522956&sr=8-11&th=1&linkCode=ll1&tag=myfeedingch00-21&linkId=31ad8156952bd8384544c03820375cfc&language=de_DE' target="_blank" className="btn btn-primary">
                                                    Zum Shop
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.product._id == '5cab732ca464630016c0b31a' && (
                                        <div className="table-row">
                                            <div>
                                                <a href='https://www.amazon.de/MjAMjAM-leckeres-Rind-ged%C3%A4mpftem-K%C3%BCrbis/dp/B07D4S7PXJ/ref=as_li_ss_tl?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=mjamjam+rind+katzenfutter&qid=1605522682&sr=8-5&linkCode=ll1&tag=myfeedingch00-21&linkId=2c4cd1083c2b5d73c1ebfd631d89f524&language=de_DE' target="_blank">
                                                    <img src={amazonLogo} />
                                                </a>
                                            </div>
                                            <div>
                                                6x200g
                                                <br />
                                                <small>
                                                    Gesamt: 1,2kg
                                                    <br />
                                                    Verpackung: n/a
                                                </small>

                                            </div>
                                            <div>
                                                n/a
                                            </div>
                                            <div data-tip data-for="tip-17">
                                                <span className="mobile-only">Lieferkosten: </span>
                                                n/a
                                                <br className="mobile-only" />
                                                <small className="mobile-only">
                                                    Lieferzeit: n/a
                                                </small>
                                            </div>
                                            <div data-tip data-for="tip-17">
                                                <span className="mobile-only">Gesamt: </span>n/a
                                            </div>
                                            <div className="desktop-only">
                                                <span className="mobile-only">Lieferzeit: </span>
                                                n/a
                                            </div>
                                            <div>
                                                <a href='https://www.amazon.de/MjAMjAM-leckeres-Rind-ged%C3%A4mpftem-K%C3%BCrbis/dp/B07D4S7PXJ/ref=as_li_ss_tl?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=mjamjam+rind+katzenfutter&qid=1605522682&sr=8-5&linkCode=ll1&tag=myfeedingch00-21&linkId=2c4cd1083c2b5d73c1ebfd631d89f524&language=de_DE' target="_blank" className="btn btn-primary">
                                                    Zum Shop
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.product._id == '5f806046edd4d9b3ca5f9142' && (
                                        <div className="table-row">
                                            <div>
                                                <a href='https://www.amazon.de/MjAMjAM-leckeres-Rind-ged%C3%A4mpftem-K%C3%BCrbis/dp/B07D4S7PXJ/ref=as_li_ss_tl?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=mjamjam+rind+katzenfutter&qid=1605522682&sr=8-5&linkCode=ll1&tag=myfeedingch00-21&linkId=21a47e81ca728cc565102b86c1c1a3fb&language=de_DE' target="_blank">
                                                    <img src={amazonLogo} />
                                                </a>
                                            </div>
                                            <div>
                                                6x200g
                                                <br />
                                                <small>
                                                    Gesamt: 1,2kg
                                                    <br />
                                                    Verpackung: n/a
                                                </small>

                                            </div>
                                            <div>
                                                n/a
                                            </div>
                                            <div data-tip data-for="tip-17">
                                                <span className="mobile-only">Lieferkosten: </span>
                                                n/a
                                                <br className="mobile-only" />
                                                <small className="mobile-only">
                                                    Lieferzeit: n/a
                                                </small>
                                            </div>
                                            <div data-tip data-for="tip-17">
                                                <span className="mobile-only">Gesamt: </span>n/a
                                            </div>
                                            <div className="desktop-only">
                                                <span className="mobile-only">Lieferzeit: </span>
                                                n/a
                                            </div>
                                            <div>
                                                <a href='https://www.amazon.de/MjAMjAM-leckeres-Rind-ged%C3%A4mpftem-K%C3%BCrbis/dp/B07D4S7PXJ/ref=as_li_ss_tl?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=mjamjam+rind+katzenfutter&qid=1605522682&sr=8-5&linkCode=ll1&tag=myfeedingch00-21&linkId=21a47e81ca728cc565102b86c1c1a3fb&language=de_DE' target="_blank" className="btn btn-primary">
                                                    Zum Shop
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactTooltip id="tip-17" place="top" type="dark" effect="float" className="react-tooltip">
                        <span>Die Lieferkosten sind abhängig vom Gesamtbestellwert im jeweiligen Onlineshop.</span>
                    </ReactTooltip>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-12">
                        <p className="alert alert-danger">
                            Wir konnten leider keine Preise für dieses Produkt finden. Bitte versuche es später erneut.
                        </p>
                    </div>
                </div>
            );
        }
    };

    handleSortVariantsChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });

        let variantsCleaned = this.state.variants;

        if (value == "priceAsc") {
            variantsCleaned = variantsCleaned.sort((a, b) => {
                if (a.basePrice > b.basePrice) {
                    return 1;
                } else if (a.basePrice < b.basePrice) {
                    return -1;
                } else {
                    return 0;
                }
            });
        } else if (value == "priceDesc") {
            variantsCleaned = variantsCleaned.sort((a, b) => {
                if (a.basePrice > b.basePrice) {
                    return -1;
                } else if (a.basePrice < b.basePrice) {
                    return 1;
                } else {
                    return 0;
                }
            });
        } else if (value == "baseSizeAsc") {
            variantsCleaned = variantsCleaned.sort((a, b) => {
                if (a.baseSize > b.baseSize) {
                    return 1;
                } else if (a.baseSize < b.baseSize) {
                    return -1;
                } else {
                    if (a.basePrice > b.basePrice) {
                        return 1;
                    } else if (a.basePrice < b.basePrice) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            });
        } else if (value == "baseSizeDesc") {
            variantsCleaned = variantsCleaned.sort((a, b) => {
                if (a.baseSize > b.baseSize) {
                    return -1;
                } else if (a.baseSize < b.baseSize) {
                    return 1;
                } else {
                    if (a.basePrice > b.basePrice) {
                        return 1;
                    } else if (a.basePrice < b.basePrice) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            });
        } else if (value == "sizeAsc") {
            variantsCleaned = variantsCleaned.sort((a, b) => {
                if (a.size > b.size) {
                    return 1;
                } else if (a.size < b.size) {
                    return -1;
                } else {
                    if (a.basePrice > b.basePrice) {
                        return 1;
                    } else if (a.basePrice < b.basePrice) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            });
        } else if (value == "sizeDesc") {
            variantsCleaned = variantsCleaned.sort((a, b) => {
                if (a.size > b.size) {
                    return -1;
                } else if (a.size < b.size) {
                    return 1;
                } else {
                    if (a.basePrice > b.basePrice) {
                        return 1;
                    } else if (a.basePrice < b.basePrice) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            });
        }

        this.setState({ variants: variantsCleaned });
    };

    render() {
        if (this.state.redirectToProducts === true) {
            return <Redirect to="/produkte"></Redirect>;
        }
        if (this.state.redirectToLogin === true) {
            return <Redirect to="/login"></Redirect>;
        }
        if (this.state.redirectToNotFound === true) {
            return <Redirect to="/404-seite-nicht-gefunden"></Redirect>;
        }

        const images = [];
        if (this.state.product.imageFront || this.state.product.imageContent1 || this.state.product.imageContent2) {
            let { imageFront, imageContent1, imageContent2 } = this.state.product;

            if (imageFront) {
                images.push({
                    original: "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageFront.toLowerCase(),
                    thumbnail: "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageFront.toLowerCase(),
                });
            }
            if (imageContent1) {
                images.push({
                    original: "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageContent1.toLowerCase(),
                    thumbnail: "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageContent1.toLowerCase(),
                });
            }
            if (imageContent2) {
                images.push({
                    original: "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageContent2.toLowerCase(),
                    thumbnail: "https://bucket-mfc-wallride67.storage.googleapis.com/" + imageContent2.toLowerCase(),
                });
            }
        } else {
            images.push({
                original: "https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png",
                thumbnail: "https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png",
            });
        }

        let { sizes } = this.state.product;
        let buyButton;
        if (sizes && sizes.length >= 1) {
            // if (sizes.length == 1 && sizes[0].link) {
            //     buyButton = <a href={sizes[0].link} className="btn btn-primary" target="_blank">Produkt online kaufen</a>;
            // } else if (sizes.length >= 2) {
            let buyButtonLinks = [];
            let buyButtonTableRows = [];
            let buyButtonTableSizes = [];
            let tableData = {};
            sizes.forEach((size) => {
                if (size.size && size.link) {
                    if (!buyButtonTableSizes.includes(size.size)) buyButtonTableSizes.push(size.size);

                    let urls = size.link.split(",");
                    urls.forEach((u, i) => {
                        u = u.trim();
                        let u0 = u.split(" ")[0];
                        let u1 = u.split(" ")[1];

                        if (u0.split("/")[2]) {
                            let uTmp = u0.split("/")[2].trim();
                            if (!u1) {
                                u1 = psl.parse(uTmp).domain;
                            }

                            if (!tableData[u1]) {
                                tableData[u1] = [];
                                tableData[u1].push({
                                    shop: u1,
                                    link: u0,
                                    size: size.size,
                                });
                            } else {
                                tableData[u1].push({
                                    shop: u1,
                                    link: u0,
                                    size: size.size,
                                });
                            }
                        }
                    });
                }

                // if (size.size && size.link) {
                //     let urls = size.link.split(',');
                //     urls.forEach((u, i) => {
                //         u = u.trim();
                //         let u0 = u.split(' ')[0];
                //         let u1 = u.split(' ')[1];
                //         if (u0.split('/')[2]) {
                //             let uTmp = u0.split('/')[2].trim();
                //             if (u1) {
                //                 buyButtonLinks.push(<li key={u0 + i}><a href={u0} target="_blank" onMouseDown={e => e.preventDefault()}>Packung mit {size.size}g bei {u1}</a></li >);
                //             } else {
                //                 let publicUrl = psl.parse(uTmp);
                //                 buyButtonLinks.push(<li key={u0 + i}><a href={u0} target="_blank" onMouseDown={e => e.preventDefault()}>Packung mit {size.size}g bei {publicUrl.domain}</a></li >);
                //             }
                //         }
                //     });
                // }
            });

            if (Object.keys(tableData).length !== 0) {
                Object.keys(tableData).forEach((key) => {
                    // tableData[key].forEach((data, i) => {
                    //     console.log(data);
                    // });
                    let tableRow = [];

                    buyButtonTableSizes.forEach((s, k) => {
                        let sizeMatch = false;
                        tableData[key].forEach((item, j) => {
                            if (item.size == s) {
                                tableRow.push(
                                    <a href={item.link} target="_blank" onMouseDown={(e) => e.preventDefault()}>
                                        {item.size}g
                                    </a>
                                );
                                sizeMatch = true;
                            }
                        });

                        if (!sizeMatch) tableRow.push(s.toString() + "g");
                    });

                    buyButtonTableRows.push(
                        <tr>
                            <td className="thead">{key}</td>
                            {tableRow.map((td) => (
                                <td>{td}</td>
                            ))}
                        </tr>
                    );
                });
            }

            if (buyButtonTableRows.length >= 1)
                buyButton = (
                    <div className="buyBtnDropdownWrapper">
                        <div
                            id="myButton"
                            className="btn btn-primary"
                            onClick={() =>
                                this.setState({
                                    showShopTable: !this.state.showShopTable,
                                })
                            }
                            style={{ cursor: "pointer" }}
                        >
                            <span>{this.state.showShopTable ? "Tabelle schließen" : "Produkt online kaufen"}</span>
                            {this.state.showShopTable && (
                                <div className="buyBtnDropdown">
                                    <table>{buyButtonTableRows}</table>
                                </div>
                            )}
                        </div>
                    </div>
                );

            // if (buyButtonLinks.length >= 1) buyButton = <div className="buyBtnDropdownWrapper"><button type="button" className="btn btn-primary">Produkt online kaufen<div className="buyBtnDropdown"><ul>{buyButtonLinks}</ul></div></button></div>
        }

        return (
            <div id="product-single-view" onclick="this.focus()">
                {this.state.isLoading && <LoadingOverlay message="Lade Produktdaten..." />}
                {this.state.showReportPop ? (
                    <ReportProductPopup
                        product={this.state.product._id}
                        handleReportPopClose={this.handleReportPopClose}
                    />
                ) : null}
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / <Link to="/produkte">Produkte</Link> /{" "}
                                {this.state.product_brand.name} {this.state.product.series}
                            </span>
                        </div>
                        {this.state.listUpdateError && (
                            <p className="alert alert-danger">{this.state.listUpdateError}</p>
                        )}
                        {this.state.listUpdateSuccess && (
                            <p className="alert alert-success">{this.state.listUpdateSuccess}</p>
                        )}
                    </div>
                </div>
                <div className="main">
                    <div className="row product-single-overview">
                        {this.state.fetchingError && (
                            <div className="col-12">
                                <p className="alert alert-danger">{this.state.errorMessage}</p>
                            </div>
                        )}
                        {!this.state.fetchingError && (
                            <React.Fragment>
                                <div className="col-lg-3 col-md-5">
                                    <div className="product-single-image-title-container">
                                        <ImageGallery
                                            items={images}
                                            showNav={false}
                                            showPlayButton={false}
                                            showThumbnails={images.length >= 2 ? true : false}
                                        />
                                    </div>
                                    <div className="product-single-rating text-center">
                                        <StarRatingComponent
                                            name="rate1"
                                            starCount={5}
                                            value={this.state.product_rating}
                                            editing={false}
                                        />
                                        <span>({this.state.product_rating_count})</span>
                                    </div>
                                </div>
                                <div className="col-lg-8 offset-lg-1 col-md-7 posrelative">
                                    <h1>
                                        <span>
                                            {this.state.product_brand.name}
                                            {this.state.product_brand && this.state.product_brand.owner && this.state.product_brand.owner.status === 'active' && (
                                                <div className="owned-icon" data-tip data-for="tip-18">
                                                    <img src={checkGreen} />
                                                    <ReactTooltip id="tip-18" place="top" type="dark" effect="float" className="react-tooltip">
                                                        <span>Dieses Produkt wird von der Marke selbst verwaltet.</span>
                                                    </ReactTooltip>
                                                </div>
                                            )}
                                        </span>
                                        {this.state.product.series}
                                    </h1>
                                    <div className="compare-list-wrapper">
                                        <CompareArrows onClick={this.handleCompareListChange} className={this.props.compareList.includes(this.state.product._id) && 'color-primary'} />
                                    </div>
                                    <div className="lists-wrapper">
                                        <BookmarkBorder />
                                        <div className="lists-dropdown">
                                            <ul>
                                                <li className="title">Zu Liste hinzufügen</li>
                                                <li data-list="favorites" onClick={this.handleListsChange}>
                                                    <FavoriteBorder />
                                                    Favoriten
                                                </li>
                                                <li data-list="negFavorites" onClick={this.handleListsChange}>
                                                    <NotInterested />
                                                    Negative Erfahrungen
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row mt-20">
                                        <div className="col-sm-6">
                                            <p>
                                                <strong>Tierart</strong>
                                                {this.state.product.animal && this.state.product.animal.length >= 2
                                                    ? SC.convert(this.state.product.animal).join(" & ")
                                                    : SC.convert(this.state.product.animal)}
                                                <br />
                                                <strong>Futtermittelart</strong>
                                                {SC.convert(this.state.product.type1)},{" "}
                                                {this.state.product.type2 && this.state.product.type2.length >= 1
                                                    ? SC.convert(this.state.product.type2).join(", ")
                                                    : null}
                                                <br />
                                                {this.state.product.type1 != 'flakes' && (
                                                    <>
                                                        <strong>Altersgruppe</strong>
                                                        {this.state.product.ageGroup && this.state.product.ageGroup.length >= 1
                                                            ? SC.convert(this.state.product.ageGroup).join(", ")
                                                            : "n/a"}
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p>
                                                <strong>Hersteller</strong>
                                                {this.state.product_producer.name}
                                                <br />
                                                <strong>Herstellungsland</strong>
                                                {this.state.product.countryOfProduction
                                                    ? this.state.product.countryOfProduction
                                                    : "n/a"}
                                                <br />
                                                {this.state.product && this.state.product.brand && this.state.product.brand.owner && this.state.product.brand.supportEmail && <div style={{marginTop: '20px'}}>
                                                    <span className="btn btn-light" style={{width: '100%'}} onClick={() => this.setState({showContactPop: true})}>Frage zum Produkt stellen</span>
                                                    {this.state.showContactPop && <PopupContactBrand product={this.state.product} handleContactPopClose={this.handleContactPopClose} />}
                                                </div>}
                                            </p>
                                        </div>
                                        {/* <div className="col-lg-6 offset-lg-6 col-md-12 offset-md-0 col-sm-6 offset-sm-6">
                                            {buyButton}
                                        </div> */}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="row mt-30">
                        <div className="col-12">
                            <div className="card card-body">
                                <div className="sub-nav">
                                    <span
                                        className={this.state.currentPage === 4 ? "active" : ""}
                                        onClick={(e) =>
                                            this.setState({
                                                currentPage: 4,
                                            })
                                        }
                                    >
                                        Preisvergleich
                                    </span>
                                    <span
                                        className={this.state.currentPage === 0 ? "active" : ""}
                                        onClick={(e) => this.setState({ currentPage: 0 })}
                                    >
                                        Produktinformationen
                                    </span>
                                    {/* <span
                                        className={this.state.currentPage === 3 ? "active" : ""}
                                        onClick={(e) => this.setState({ currentPage: 3 })}
                                    >
                                        Bindemittelverdacht
                                    </span> */}
                                    <span
                                        className={this.state.currentPage === 2 ? "active" : ""}
                                        onClick={(e) => this.setState({ currentPage: 2 })}
                                    >
                                        Produkt- & Markenbeschreibung
                                    </span>
                                    <span
                                        className={this.state.currentPage === 1 ? "active" : ""}
                                        onClick={(e) => this.setState({ currentPage: 1 })}
                                    >
                                        Bewertungen
                                    </span>
                                </div>
                                {this.productInformation()}
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mt-30">
                        <div className="col-12">
                            <p className="alert alert-secondary">Es gibt eine neue Rezeptur oder Tippfehler? Möchtest ein Bild ergänzen?<br />Gerne kannst Du bereits vorhandene Produkte überarbeiten.
                            <br /><Link to={'/produkte/produkt-bearbeiten?id=' + this.state.product._id}>Produkt bearbeiten</Link></p>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default ProductSingle;
