import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "./../modules/Auth";
import { Helmet } from "react-helmet";

class EnergyConservationNeeds extends Component {
    state = {
        aType: "",
        aWeight: "",
        aBodyCondition: "",
        aNeedsKcal: "",
        aNeedsMj: "",
        fProteins: "",
        fFats: "",
        fFibers: "",
        fAsh: "",
        fMoisture: "",
        fEnergyKcal: "",
        fEnergyMj: "",
        resultFoodAmount: "",
        favorites: [],
        favoritesSelected: "",
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetchFavorites();

        this.handleFavoriteSelect = this.handleFavoriteSelect.bind(this);
        this.handleFoodValueChange = this.handleFoodValueChange.bind(this);
        this.handleAnimalValueChange = this.handleAnimalValueChange.bind(this);
    }

    fetchFavorites = () => {
        if (Auth.isUserAuthenticated()) {
            let token = Auth.getToken();

            if (token) {
                fetch("/api/favorite-products", { headers: { token: token } })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data);
                        if (data.success) {
                            this.setState({ favorites: data.favorites });
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    };

    calculateEnergy() {
        let { fAsh, fProteins, fMoisture, fFats, fFibers } = this.state;

        if (fProteins && fFats && fAsh && fFibers && fMoisture) {
            let proteins = parseFloat(fProteins);
            let ash = parseFloat(fAsh);
            let fibers = parseFloat(fFibers);
            let fats = parseFloat(fFats);
            let moisture = parseFloat(fMoisture);

            let nfe =
                Math.round(
                    (100 - (proteins + ash + fats + fibers + moisture)) * 100
                ) / 100;

            let proteinsKj = Math.round(proteins * 17 * 100) / 100;
            let fatsKj = Math.round(fats * 39 * 100) / 100;
            let nfeKj = Math.round(nfe * 17 * 100) / 100;
            let resultKj = proteinsKj + fatsKj + nfeKj;
            let resultMj = Math.round((resultKj / 1000) * 100) / 100;
            let resultKcal = Math.round((resultKj / 4.19) * 100) / 100;

            if (resultKcal) this.setState({ fEnergyKcal: resultKcal });
            if (resultMj) this.setState({ fEnergyMj: resultMj });
        }

        this.calculateEnergyNeeds();
    }

    handleFoodValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });

        if (this.state.fEnergyKcal) {
            let mj = (this.state.fEnergyKcal * 4.19) / 1000;
            this.setState({ fEnergyMj: mj });
        }

        setTimeout(() => {
            this.calculateEnergy();
        }, 200);
    };

    handleAnimalValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });

        setTimeout(() => {
            this.calculateAnimal();
        }, 200);
    };

    calculateAnimal = () => {
        let { aType, aWeight, aBodyCondition } = this.state;

        if (aType && aWeight && aBodyCondition) {
            let factor;
            let metaWeight;
            if (aType == "dog") {
                if (aBodyCondition == "thin") {
                    factor = 140;
                } else if (aBodyCondition == "normal") {
                    factor = 130;
                } else if (aBodyCondition == "thick") {
                    factor = 95;
                } else {
                    return;
                }

                metaWeight = Math.pow(parseFloat(aWeight), 0.75);
            } else if ((aType = "cat")) {
                if (aBodyCondition == "thin") {
                    factor = 100;

                    metaWeight = Math.pow(parseFloat(aWeight), 0.67);
                } else if (aBodyCondition == "normal") {
                    factor = 100;

                    metaWeight = Math.pow(parseFloat(aWeight), 0.67);
                } else if (aBodyCondition == "thick") {
                    factor = 130;

                    metaWeight = Math.pow(parseFloat(aWeight), 0.4);
                } else {
                    return;
                }
            } else {
                return;
            }

            if (metaWeight && factor) {
                if (aBodyCondition == "thick" && aType == "cat") {
                    let resultKcal =
                        Math.round(
                            Math.pow(parseFloat(aWeight), 0.4) * factor * 10
                        ) / 10;
                    let resultKj = Math.round(resultKcal * 4.19 * 10) / 10;
                    let resultMj = Math.round((resultKj / 1000) * 10) / 10;
                    this.setState({ aNeedsKcal: resultKcal });
                    this.setState({ aNeedsMj: resultMj });
                } else {
                    let resultKcal = Math.round(metaWeight * factor * 10) / 10;
                    let resultKj = Math.round(resultKcal * 4.19 * 10) / 10;
                    let resultMj = Math.round((resultKj / 1000) * 10) / 10;
                    this.setState({ aNeedsKcal: resultKcal });
                    this.setState({ aNeedsMj: resultMj });
                }
            }
        }

        this.calculateEnergyNeeds();
    };

    calculateEnergyNeeds = () => {
        let { aNeedsKcal, aNeedsMj, fEnergyKcal, fEnergyMj } = this.state;

        let result;

        if (aNeedsKcal && fEnergyKcal) {
            result = Math.round((aNeedsKcal / fEnergyKcal) * 10000) / 100;
        } else if (aNeedsMj && fEnergyMj) {
            result = Math.round((aNeedsMj / fEnergyMj) * 10000) / 100;
        } else {
            return;
        }

        if (result) this.setState({ resultFoodAmount: result });
    };

    handleFavoriteSelect = (e) => {
        let { value } = e.target;
        this.setState({ favoritesSelected: value });

        var {
            proteins,
            fats,
            fibers,
            ash,
            moisture,
            energy,
        } = e.target.options[e.target.selectedIndex].dataset;
        if (proteins) this.setState({ fProteins: proteins });
        if (fats) this.setState({ fFats: fats });
        if (fibers) this.setState({ fFibers: fibers });
        if (ash) this.setState({ fAsh: ash });
        if (moisture) this.setState({ fMoisture: moisture });
        if (energy) this.setState({ fEnergyKcal: energy / 10 });

        if (energy) {
            let mj = (energy * 4.19) / 1000;
            this.setState({ fEnergyMj: mj });
        }

        setTimeout(() => {
            this.calculateEnergy();
        }, 200);
    };

    favoritesSelectBox = () => {
        if (Auth.isUserAuthenticated()) {
            if (this.state.favorites && this.state.favorites.length >= 1) {
                let options = [];

                this.state.favorites.map((p) => {
                    options.push(
                        <option
                            value={p._id}
                            selected={this.state.favoritesSelected === p._id}
                            key={p._id}
                            data-proteins={p.proteins}
                            data-fats={p.fats}
                            data-fibers={p.fibers}
                            data-ash={p.ash}
                            data-moisture={p.moisture}
                            data-energy={p.energy}
                        >
                            {p.brand.name}, {p.series}
                        </option>
                    );
                });

                return (
                    <React.Fragment>
                        <label style={{fontWeight: 'bold'}}>Produkt aus Favoriten:</label>
                        <select
                            className="form-control"
                            name="favorites"
                            onChange={this.handleFavoriteSelect}
                        >
                            <option disabled selected value>
                                Produkt auswählen
                            </option>
                            {options}
                        </select>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        <label style={{fontWeight: 'bold'}}>Produkt aus Favoriten:</label>
                        <select className="form-control" disabled>
                            <option disabled selected value>
                                Keine Produkte vorhanden
                            </option>
                        </select>
                    </React.Fragment>
                );
            }
        } else {
            return (
                <React.Fragment>
                    <label style={{fontWeight: 'bold'}}>Produkt aus Favoriten:</label>
                    <select className="form-control" disabled>
                        <option disabled selected value>
                            Keine Produkte vorhanden
                        </option>
                    </select>
                    <p className="small">
                        Du musst eingeloggt sein, um Produkte aus deinen
                        Favoriten auswählen zu können.
                    </p>
                </React.Fragment>
            );
        }
    };

    render() {
        return (
            <div id="calculator-view">
                <Helmet>
                    <meta
                        name="description"
                        content="MyFeedingCheck ermöglicht es Dir einen Richtwert für die Futtermenge für Dein Tier zu errechnen."
                        id="meta-desc-energy-calc"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Energiebedarf
                                &amp; Futtermenge
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">
                                Berechne den Energiebedarf und die benötigte
                                Futtermenge für Dein Tier
                            </h1>
                            <p>
                                Der Energiebedarf & Futtermengen - Rechner, gibt
                                Dir einen Überblick darüber, wie viel Energie
                                Dein Tier mindestens am Tag benötigt und mit
                                welcher Futtermenge eines Produktes Du sie
                                erreichst.
                                <br />
                                Bitte bedenke, die hier ausgerechneten Werte
                                dienen Dir nur als Richtwert!
                                <br />
                                Die Faktoren Alter, Gesundheit, Rasse und vor
                                allem Bewegung spielen hier eine große Rolle.
                                <br />
                                <small style={{ fontWeight: 300 }}>
                                    Die Berechnung erfolgt nach
                                    NRC-Bedarfswerten.
                                </small>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="color-primary">Dein Haustier</h2>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-label">
                                <label for="proteins">Hund / Katze</label>
                            </div>
                            <div className="form-input">
                                <select
                                    className="form-control"
                                    name="aType"
                                    onChange={this.handleAnimalValueChange}
                                >
                                    <option disabled selected value>
                                        Tierart auswählen
                                    </option>
                                    <option
                                        value="cat"
                                        selected={this.state.aType === "cat"}
                                    >
                                        Katze
                                    </option>
                                    <option
                                        value="dog"
                                        selected={this.state.aType === "dog"}
                                    >
                                        Hund
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-label">
                                <label for="aWeight">Gewicht in kg</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="aWeight"
                                    placeholder="Gewicht in kg"
                                    onChange={this.handleAnimalValueChange}
                                    value={this.state.aWeight}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-label">
                                <label for="aBodyCondition">
                                    Body-Condition
                                </label>
                            </div>
                            <div className="form-input">
                                <select
                                    className="form-control"
                                    name="aBodyCondition"
                                    onChange={this.handleAnimalValueChange}
                                >
                                    <option disabled selected value>
                                        Körperart auswählen
                                    </option>
                                    {this.state.aType == "dog" ? (
                                        <option
                                            value="thin"
                                            selected={
                                                this.state.aBodyCondition ===
                                                "thin"
                                            }
                                        >
                                            Untergewicht / sehr aktiv
                                        </option>
                                    ) : null}
                                    <option
                                        value="normal"
                                        selected={
                                            this.state.aBodyCondition ===
                                            "normal"
                                        }
                                    >
                                        Idealgewicht / normal aktiv
                                    </option>
                                    <option
                                        value="thick"
                                        selected={
                                            this.state.aBodyCondition ===
                                            "thick"
                                        }
                                    >
                                        Übergewicht / wenig aktiv
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-label">
                                <label for="aNeedsKcal">
                                    Energiebedarf in kcal/Tag
                                </label>
                            </div>
                            <div className="form-input">
                                <input
                                    disabled
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="aNeedsKcal"
                                    placeholder="Energiebedarf in kcal/Tag"
                                    onChange={this.calculateEnergyNeeds}
                                    value={this.state.aNeedsKcal}
                                ></input>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h2 className="color-primary" style={{marginTop:20}}>Nahrungsmittel</h2>
                        </div>
                        <div className="form-group col-12">
                            {this.favoritesSelectBox()}
                        </div>
                        <div className="col-12" style={{fontWeight:'bold'}}><label>Eigenes Produkt hinzufügen:</label></div>
                        <div className="form-group col-md-4 col-sm-6">
                            <div className="form-label">
                                <label for="proteins">Rohprotein</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fProteins"
                                    placeholder="Rohprotein"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fProteins}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-4 col-sm-6">
                            <div className="form-label">
                                <label for="fatsRohfaser">Rohfett</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fFats"
                                    placeholder="Rohfett"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fFats}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-4 col-sm-6">
                            <div className="form-label">
                                <label for="fibers">Rohfaser</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fFibers"
                                    placeholder="Rohfaser"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fFibers}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-6 col-sm-6">
                            <div className="form-label">
                                <label for="ash">Rohasche</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fAsh"
                                    placeholder="Rohasche"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fAsh}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className="form-label">
                                <label for="moisture">Feuchtigkeit</label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fMoisture"
                                    placeholder="Feuchtigkeit"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fMoisture}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className="form-label">
                                <label for="fEnergyKcal">
                                    Energie [kcal &frasl; 100g]
                                </label>
                            </div>
                            <div className="form-input">
                                <input
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fEnergyKcal"
                                    placeholder="Energielieferung"
                                    onChange={this.handleFoodValueChange}
                                    value={this.state.fEnergyKcal}
                                ></input>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <div className="form-label">
                                <label for="fEnergyMJ">
                                    Energie [MJ &frasl; 100g]
                                </label>
                            </div>
                            <div className="form-input">
                                <input
                                    disabled
                                    className="form-control"
                                    type="number"
                                    step="0.01"
                                    name="fEnergyMJ"
                                    placeholder="Energielieferung"
                                    onChange={this.calculateEnergyNeeds}
                                    value={this.state.fEnergyMj}
                                ></input>
                            </div>
                        </div>
                    </div>
                    {this.state.resultFoodAmount && (
                        <div className="row mt-50">
                            <div className="col-12">
                                <span className="calculator-result card card-body">
                                    {this.state.aType == "dog"
                                        ? "Dein Hund "
                                        : "Deine Katze "}
                                    benötigt von diesem Futter{" "}
                                    <strong className="color-primary">
                                        {this.state.resultFoodAmount} Gramm
                                    </strong>{" "}
                                    pro Tag.
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default EnergyConservationNeeds;
