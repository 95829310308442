import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import queryString from "query-string";
import ItemCard from "./ItemCard";
import LoadingOverlay from "./LoadingOverlay";
import $ from "jquery";
import "jquery-match-height/dist/jquery.matchHeight-min.js";
import SC from "./../modules/StringConverter";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import ReactTooltip from "react-tooltip";
import { Grid, FormControl, Tooltip, Checkbox, FormControlLabel, FormLabel } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

class Products extends Component {
    state = {
        currentPage: 1,
        productsPerPage: 12,
        sortBy: "brand",
        filterOption: "1",
        filterAnimal: ["dog"],
        filterAgeGroup: [],
        filterType1: ["wet"],
        filterType2: [],
        filterPackaging: [],
        filterPackagingSize: [],
        filterDeclaration: [],
        filterAnimalComponents: [],
        filterDiseases: [],
        filterAnimalShare: [],
        filterAnimalShareDriedOne: [],
        filterAnimalShareDriedTwo: [],
        filterAttributes: [],
        filterBrand: [],
        filterProducers: [],
        filterCountriesOfProduction: [],
        filterCountriesOfDelivery: [],
        filterElements: [],
        filterProcessings: [],
        filterBinder: [],
        filterTechnologicalAdditives: false,
        filterPhysiologicalAdditives: false,
        filterBasePriceMin: "",
        filterBasePriceMax: "",
        filterProteinsMin: "",
        filterProteinsMax: "",
        filterFibersMin: "",
        filterFibersMax: "",
        filterFatsMin: "",
        filterFatsMax: "",
        filterAshMin: "",
        filterAshMax: "",
        filterMoistureMin: "",
        filterMoistureMax: "",
        filterNfe: "",
        filterNfeTM: "",
        errorMessage: "",
        filterFetchingError: false,
        filters: {},
        products: [],
        nOfProductsTotal: "",
        nOfPages: "",
        isLoadingProducts: true,
        openFilterPop: "",
        searchTerm: "",
        initial: true,
        hasFilterChanges: false,
    };

    constructor(props) {
        super(props);

        this.pagination = this.pagination.bind(this);
        this.fetchFilters = this.fetchFilters.bind(this);
        this.setProps = this.setProps.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.handlePPPChange = this.handlePPPChange.bind(this);
        this.handleSortByChange = this.handleSortByChange.bind(this);
        this.onFilterSubmitDebounced = AwesomeDebouncePromise(this.onFilterSubmit, 500);
    }

    componentDidMount() {
        this.setProps();
        // this.fetchFilters();
        this.fetchProducts();

        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    componentDidUpdate(nextProps) {
        if (JSON.stringify(this.props.location.search) !== JSON.stringify(nextProps.location.search)) {
            // Check if it's a new user, you can also use some unique property, like the ID
            this.setState({ errorMessage: "" });
            this.setProps();
            this.fetchProducts();

            if (this.props.location.search == "?animal=dog&currentPage=1&filterOption=1&productsPerPage=12&sortBy=brand&type1=wet") this.resetFilters();
        }
    }

    removeItemFromFilters = (e, filter) => {
        let { val } = e.target.dataset;

        if (val && filter) {
            let array = [...this.state[filter]];
            let index = this.state[filter].indexOf(val);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ [filter]: array });

                this.onFilterSubmitDebounced();
            }
        }
    };

    resetFilters = () => {
        this.setState({ isLoadingProducts: true });

        this.setState({ currentPage: 1 });
        this.setState({ productsPerPage: 12 });
        this.setState({ sortBy: "brand" });
        this.setState({ filterOption: "1" });
        this.setState({ filterAnimal: ["dog"] });
        this.setState({ filterAgeGroup: [] });
        this.setState({ filterType1: ["wet"] });
        this.setState({ filterType2: [] });
        this.setState({ filterPackaging: [] });
        this.setState({ filterPackagingSize: [] });
        this.setState({ filterBrand: [] });
        this.setState({ filterDeclaration: [] });
        this.setState({ filterAnimalComponents: [] });
        this.setState({ filterDiseases: [] });
        this.setState({ filterAnimalShare: [] });
        this.setState({ filterAnimalShareDriedOne: [] });
        this.setState({ filterAnimalShareDriedTwo: [] });
        this.setState({ filterAttributes: [] });
        this.setState({ filterProducers: [] });
        this.setState({ filterCountriesOfProduction: [] });
        this.setState({ filterCountriesOfDelivery: [] });
        this.setState({ filterElements: [] });
        this.setState({ filterProcessings: [] });
        this.setState({ filterBinder: [] });
        this.setState({ filterTechnologicalAdditives: false });
        this.setState({ filterPhysiologicalAdditives: false });
        this.setState({ filterBasePriceMin: "" });
        this.setState({ filterBasePriceMax: "" });
        this.setState({ filterProteinsMin: "" });
        this.setState({ filterProteinsMax: "" });
        this.setState({ filterFibersMin: "" });
        this.setState({ filterFibersMax: "" });
        this.setState({ filterFatsMin: "" });
        this.setState({ filterFatsMax: "" });
        this.setState({ filterAshMin: "" });
        this.setState({ filterAshMax: "" });
        this.setState({ filterMoistureMin: "" });
        this.setState({ filterMoistureMax: "" });
        this.setState({ filterNfe: "" });
        this.setState({ filterNfeTM: "" });
        this.setState({ errorMessage: "" });
        this.setState({ filterFetchingError: false });
        this.setState({ products: [] });
        this.setState({ nOfProductsTotal: "" });
        this.setState({ nOfPages: "" });
        this.setState({ searchTerm: "" });

        let filters = {
            sortBy: "brand",
            productsPerPage: 12,
            currentPage: 1,
            animal: ["dog"],
            type1: ["wet"],
        };

        let filterString = queryString.stringify(filters);

        this.fetchProducts();
    };

    setProps = () => {
        let filters = queryString.parse(window.location.search) || {};

        if (filters.animal && !Array.isArray(filters.animal)) filters.animal = [filters.animal];
        if (filters.ageGroup && !Array.isArray(filters.ageGroup)) filters.ageGroup = [filters.ageGroup];
        if (filters.type1 && !Array.isArray(filters.type1)) filters.type1 = [filters.type1];
        if (filters.type2 && !Array.isArray(filters.type2)) filters.type2 = [filters.type2];
        if (filters.packaging && !Array.isArray(filters.packaging)) filters.packaging = [filters.packaging];
        if (filters.packagingSize && !Array.isArray(filters.packagingSize))
            filters.packagingSize = [filters.packagingSize];
        if (filters.brand && !Array.isArray(filters.brand)) filters.brand = [filters.brand];
        if (filters.declaration && !Array.isArray(filters.declaration)) filters.declaration = [filters.declaration];
        if (filters.animalComponents && !Array.isArray(filters.animalComponents))
            filters.animalComponents = [filters.animalComponents];
        if (filters.elements && !Array.isArray(filters.elements))
            filters.elements = [filters.elements];
        if (filters.processing && !Array.isArray(filters.processing))
            filters.processing = [filters.processing];
        if (filters.countriesOfDelivery && !Array.isArray(filters.countriesOfDelivery))
            filters.countriesOfDelivery = [filters.countriesOfDelivery];
        if (filters.diseases && !Array.isArray(filters.diseases)) filters.diseases = [filters.diseases];
        if (filters.animalShare && !Array.isArray(filters.animalShare)) filters.animalShare = [filters.animalShare];
        if (filters.animalShareDriedOne && !Array.isArray(filters.animalShareDriedOne))
            filters.animalShareDriedOne = [filters.animalShareDriedOne];
        if (filters.animalShareDriedTwo && !Array.isArray(filters.animalShareDriedTwo))
            filters.animalShareDriedTwo = [filters.animalShareDriedTwo];
        if (filters.attributes && !Array.isArray(filters.attributes)) filters.attributes = [filters.attributes];
        if (filters.producer && !Array.isArray(filters.producer)) filters.producer = [filters.producer];
        if (filters.countryOfProduction && !Array.isArray(filters.countryOfProduction))
            filters.countryOfProduction = [filters.countryOfProduction];
        if (filters.binder && !Array.isArray(filters.binder)) filters.binder = [filters.binder];

        if (filters.currentPage) filters.currentPage = parseInt(filters.currentPage);
        if (filters.productsPerPage) filters.productsPerPage = parseInt(filters.productsPerPage);

        if (
            filters.sortBy == "brand" ||
            filters.sortBy == "rating_dec" ||
            filters.sortBy == "proteins_inc" ||
            filters.sortBy == "fats_inc" ||
            filters.sortBy == "nfeTM_inc"
        ) {
            this.setState({ sortBy: filters.sortBy });
        } else {
            filters.sortBy = this.state.sortBy;
        }

        if (filters.productsPerPage == 12 || filters.productsPerPage == 24 || filters.productsPerPage == 36) {
            this.setState({ productsPerPage: filters.productsPerPage });
        } else {
            filters.productsPerPage = parseInt(this.state.productsPerPage);
        }

        if (
            typeof filters.currentPage == "number" &&
            filters.currentPage >= 1 &&
            filters.currentPage <= this.state.nOfProductsTotal
        ) {
            this.setState({ currentPage: filters.currentPage });
        } else {
            filters.currentPage = parseInt(this.state.currentPage);
        }

        if (filters.filterOption && (filters.filterOption == "1" || filters.filterOption == "2")) {
            this.setState({ filterOption: filters.filterOption });
        } else {
            filters.filterOption = this.state.filterOption;
        }

        if (filters.animal && (filters.animal.includes("dog") || filters.animal.includes("cat"))) {
            this.setState({ filterAnimal: filters.animal });
        } else {
            filters.animal = this.state.filterAnimal;
        }

        if (filters.type1 && (filters.type1.includes("wet") || filters.type1.includes("dried") || filters.type1.includes("raw") || filters.type1.includes("flakes") || filters.type1.includes("suplement"))) {
            this.setState({ filterType1: filters.type1 });
        } else {
            filters.type1 = this.state.filterType1;
        }

        if (filters.basePriceMin) this.setState({ filterBasePriceMin: filters.basePriceMin });
        if (filters.basePriceMax) this.setState({ filterBasePriceMax: filters.basePriceMax });
        if (filters.proteinsMin) this.setState({ filterProteinsMin: filters.proteinsMin });
        if (filters.proteinsMax) this.setState({ filterProteinsMax: filters.proteinsMax });
        if (filters.fatsMin) this.setState({ filterFatsMin: filters.fatsMin });
        if (filters.fatsMax) this.setState({ filterFatsMax: filters.fatsMax });
        if (filters.fibersMin) this.setState({ filterFibersMin: filters.fibersMin });
        if (filters.fibersMax) this.setState({ filterFibersMax: filters.fibersMax });
        if (filters.ashMin) this.setState({ filterAshMin: filters.ashMin });
        if (filters.ashMax) this.setState({ filterAshMax: filters.ashMax });
        if (filters.moistureMin) this.setState({ filterMoistureMin: filters.moistureMin });
        if (filters.moistureMax) this.setState({ filterMoistureMax: filters.moistureMax });
        if (filters.nfe) this.setState({ filterNfe: filters.nfe });
        if (filters.nfeTM) this.setState({ filterNfeTM: filters.nfeTM });
        if (filters.animal) this.setState({ filterAnimal: filters.animal });
        if (filters.ageGroup) this.setState({ filterAgeGroup: filters.ageGroup });
        if (filters.type1) this.setState({ filterType1: filters.type1 });
        if (filters.type2) this.setState({ filterType2: filters.type2 });
        if (filters.packaging) this.setState({ filterPackaging: filters.packaging });
        if (filters.packagingSize) this.setState({ filterPackagingSize: filters.packagingSize });
        if (filters.countryOfProduction)
            this.setState({
                filterCountriesOfProduction: filters.countryOfProduction,
            });
        if (filters.filterCountriesOfDelivery)
            this.setState({
                filterCountriesOfDelivery: filters.countriesOfDelivery,
            });
        if (filters.brand) this.setState({ filterBrand: filters.brand });
        if (filters.declaration) this.setState({ filterDeclaration: filters.declaration });
        if (filters.animalComponents) this.setState({ filterAnimalComponents: filters.animalComponents });
        if (filters.elements) this.setState({ filterElements: filters.elements });
        if (filters.processing) this.setState({ filterProcessings: filters.processing });
        if (filters.diseases) this.setState({ filterDiseases: filters.diseases });
        if (filters.animalShare) this.setState({ filterAnimalShare: filters.animalShare });
        if (filters.animalShareDriedOne)
            this.setState({
                filterAnimalShareDriedOne: filters.animalShareDriedOne,
            });
        if (filters.animalShareDriedTwo)
            this.setState({
                filterAnimalShareDriedTwo: filters.animalShareDriedTwo,
            });
        if (filters.attributes) this.setState({ filterAttributes: filters.attributes });
        if (filters.producer) this.setState({ filterProducers: filters.producer });
        if (filters.binder) this.setState({ filterBinder: filters.binder });
        if (filters.technologicalAdditives)
            this.setState({
                filterTechnologicalAdditives: filters.technologicalAdditives,
            });
        if (filters.physiologicalAdditives)
            this.setState({
                filterPhysiologicalAdditives: filters.physiologicalAdditives,
            });

        let filterString = queryString.stringify(filters);
        this.props.history.push({
            pathname: window.history.pathname,
            search: filterString,
        });
    };

    fetchProducts = () => {
        this.setState({ isLoadingProducts: true })

        let filters = queryString.parse(window.location.search) || {
            currentPage: 1,
            productsPerPage: 12,
            sortBy: "brand",
        };

        if (filters.query) {
            // this.setState({ filterAnimal: [] });
            this.setState({ filterAgeGroup: [] });
            // this.setState({ filterType1: [] });
            this.setState({ filterType2: [] });
            this.setState({ filterPackaging: [] });
            this.setState({ filterPackagingSize: [] });
            this.setState({ filterBrand: [] });
            this.setState({ filterDeclaration: [] });
            this.setState({ filterAnimalComponents: [] });
            this.setState({ filterDiseases: [] });
            this.setState({ filterAnimalShare: [] });
            this.setState({ filterAnimalShareDriedOne: [] });
            this.setState({ filterAnimalShareDriedTwo: [] });
            this.setState({ filterAttributes: [] });
            this.setState({ filterProducers: [] });
            this.setState({ filterCountriesOfProduction: [] });
            this.setState({ filterCountriesOfDelivery: [] });
            this.setState({ filterElements: [] });
            this.setState({ filterProcessings: [] });
            this.setState({ filterBinder: [] });
            this.setState({ filterTechnologicalAdditives: false });
            this.setState({ filterPhysiologicalAdditives: false });
            this.setState({ filterBasePriceMin: "" });
            this.setState({ filterBasePriceMax: "" });
            this.setState({ filterProteinsMin: "" });
            this.setState({ filterProteinsMax: "" });
            this.setState({ filterFibersMin: "" });
            this.setState({ filterFibersMax: "" });
            this.setState({ filterFatsMin: "" });
            this.setState({ filterFatsMax: "" });
            this.setState({ filterAshMin: "" });
            this.setState({ filterAshMax: "" });
            this.setState({ filterMoistureMin: "" });
            this.setState({ filterMoistureMax: "" });
            this.setState({ filterNfe: "" });
            this.setState({ filterNfeTM: "" });

            this.setState({ searchTerm: filters.query })
        } else {
            // this.setState({ searchTerm: "" })
        }

        axios
            .post("/api/filter-products", { filters: filters, initial: this.state.initial })
            .then((json) => {
                if (json.data.success == true) {
                    if (json.data.products.length > 0) {
                        if (json.data.newFilters) {
                            let { newFilters } = json.data;
                            let { filters } = this.state;

                            if (newFilters.availableBrands) {
                                filters.availableBrands = newFilters.availableBrands;
                            }
                            if (newFilters.availableProducers) {
                                filters.availableProducers = newFilters.availableProducers;
                            }
                            if (newFilters.availableType1) {
                                filters.availableType1 = newFilters.availableType1;
                            }
                            if (newFilters.availableType2) {
                                filters.availableType2 = newFilters.availableType2;
                            }
                            if (newFilters.availableAgeGroups) {
                                filters.availableAgeGroups = newFilters.availableAgeGroups;
                            }
                            if (newFilters.availableAnimalComponents) {
                                filters.availableAnimalComponents = newFilters.availableAnimalComponents;
                            }
                            if (newFilters.availableDiseases) {
                                filters.availableDiseases = newFilters.availableDiseases;
                            }
                            if (newFilters.availableAttributes) {
                                filters.availableAttributes = newFilters.availableAttributes;
                            }
                            if (newFilters.availableCountriesOfProduction) {
                                filters.availableCountriesOfProduction = newFilters.availableCountriesOfProduction;
                            }
                            if (newFilters.availableCountriesOfDelivery) {
                                filters.availableCountriesOfDelivery = newFilters.availableCountriesOfDelivery;
                            }
                            if (newFilters.availableElements) {
                                filters.availableElements = newFilters.availableElements;
                            }
                            if (newFilters.availableProcessings) {
                                filters.availableProcessings = newFilters.availableProcessings;
                            }
                            if (newFilters.availableDeclarations) {
                                filters.availableDeclarations = newFilters.availableDeclarations;
                            }
                            if (newFilters.availableAnimalShares) {
                                filters.availableAnimalShares = newFilters.availableAnimalShares;
                            }
                            if (newFilters.availableAnimalSharesDriedOne) {
                                filters.availableAnimalSharesDriedOne = newFilters.availableAnimalSharesDriedOne;
                            }
                            if (newFilters.availableAnimalSharesDriedTwo) {
                                filters.availableAnimalSharesDriedTwo = newFilters.availableAnimalSharesDriedTwo;
                            }
                            if (newFilters.availablePackagings) {
                                filters.availablePackagings = newFilters.availablePackagings;
                            }
                            if (newFilters.availablePackagingSizes) {
                                // let ps = newFilters.availablePackagingSizes;
                                // let sizesArr = []

                                // for (let key in ps) {
                                //     sizesArr.push(`${key}||${ps[key]}`);
                                // }

                                filters.availablePackagingSizes = newFilters.availablePackagingSizes;
                            }

                            this.setState({ filters: filters });
                        }
                        this.setState({ products: json.data.products });
                        this.setState({
                            nOfProductsTotal: json.data.nOfProducts,
                        });
                        this.setState({
                            nOfPages: Math.ceil(json.data.nOfProducts / this.state.productsPerPage),
                        });
                        this.matchHeight();
                        this.setState({ isLoadingProducts: false });
                    } else {
                        this.setState({ products: json.data.products });
                        this.setState({ nOfPages: 1 });
                        this.setState({ nOfProductsTotal: 0 });
                        this.setState({
                            errorMessage: "Keine Produkte mit diesen Kriterien gefunden.",
                        });
                        this.setState({ isLoadingProducts: false });
                    }
                } else {
                    this.setState({
                        errorMessage: "Leider ist ein Fehler beim Abrufen der Produkte aufgetreten.",
                    });
                    this.setState({ isLoadingProducts: false });
                }
            })
            .catch((error) => {
                this.setState({
                    errorMessage: "Leider ist ein Fehler beim Abrufen der Produkte aufgetreten.",
                });
                this.setState({ isLoadingProducts: false });
            })
            .finally(() => this.setState({initial: false}));
    };

    matchHeight() {
        $(function () {
            $(".card__info").matchHeight();
        });
    }

    fetchFilters() {
        fetch("/api/filters")
            .then((res) => res.json())
            .then((json) => {
                if (json.success == true) {
                    if (json.filters.availablePackagingSizes) {
                        let ps = json.filters.availablePackagingSizes;
                        let sizesArr = []

                        for (let key in ps) {
                            sizesArr.push(`${key}||${ps[key]}`);
                        }

                        json.filters.availablePackagingSizes = sizesArr;
                    }
                    this.setState({ filters: json.filters });
                } else {
                    this.setState({ filterFetchingError: true });
                }
            })
            .catch((err) => {
                this.setState({ filterFetchingError: true });
            });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });

        // this.onFilterSubmitDebounced();
    };

    handleCheckboxChange = (event) => {
        const { name, value } = event.target;
        let list = this.state[name];
        if (list.includes(value)) {
            var index = list.indexOf(value);
            if (index > -1) {
                list.splice(index, 1);
                this.setState({ [name]: list });
            }
        } else {
            this.setState({
                [name]: [...this.state[name], value],
                hasFilterChanges: true
            });
        }

        // setTimeout(() => this.onFilterSubmit(), 200);
    };

    handleRadioChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: [value] });

        if (value == 'wet' || value == 'dried' || value == 'raw' || value == 'flakes' || value == 'suplement') {
            this.setState({
                filterAnimalShare: [],
                filterAnimalShareDriedOne: [],
                filterAnimalShareDriedTwo: [],
                filterElements: [],
                filterProcessings: [],
            })
        }
        if (value == 'flakes') {
            this.setState({
                filterAnimalComponents: [],
                filterAgeGroup: [],
            })
        }
        if (value != 'raw') {
            this.setState({
                filterCountriesOfDelivery: [],
            })
        }
    };

    onFilterSubmit = () => {
        this.setState({ openFilterPop: "", hasFilterChanges: false });
        this.setState({ isLoadingProducts: true });
        this.setState({ errorMessage: undefined });

        let filters = {query: this.state.searchTerm || ''};

        filters.sortBy = this.state.sortBy;
        filters.currentPage = 1;
        filters.productsPerPage = parseInt(this.state.productsPerPage);

        this.setState({ currentPage: 1 });

        if (this.state.filterOption && (this.state.filterOption == "1" || this.state.filterOption == "2")) {
            filters.filterOption = this.state.filterOption;
        } else {
            this.setState({ filterOption: "1" });
            filters.filterOption = this.state.filterOption;
        }

        if (this.state.filterAnimal.length > 0) filters.animal = this.state.filterAnimal;
        if (this.state.filterAgeGroup.length > 0) filters.ageGroup = this.state.filterAgeGroup;
        if (this.state.filterType1.length > 0) filters.type1 = this.state.filterType1;
        if (this.state.filterType2.length > 0) filters.type2 = this.state.filterType2;
        if (this.state.filterPackaging.length > 0) filters.packaging = this.state.filterPackaging;
        if (this.state.filterPackagingSize.length > 0) filters.packagingSize = this.state.filterPackagingSize;
        if (this.state.filterBrand.length > 0) filters.brand = this.state.filterBrand;
        if (this.state.filterDeclaration.length > 0) filters.declaration = this.state.filterDeclaration;
        if (this.state.filterAnimalComponents.length > 0) filters.animalComponents = this.state.filterAnimalComponents;
        if (this.state.filterDiseases.length > 0) filters.diseases = this.state.filterDiseases;
        if (this.state.filterAnimalShare.length > 0) filters.animalShare = this.state.filterAnimalShare;
        if (this.state.filterAnimalShareDriedOne.length > 0)
            filters.animalShareDriedOne = this.state.filterAnimalShareDriedOne;
        if (this.state.filterAnimalShareDriedTwo.length > 0)
            filters.animalShareDriedTwo = this.state.filterAnimalShareDriedTwo;
        if (this.state.filterAttributes.length > 0) filters.attributes = this.state.filterAttributes;
        if (this.state.filterProducers.length > 0) filters.producer = this.state.filterProducers;
        if (this.state.filterCountriesOfProduction.length > 0)
            filters.countryOfProduction = this.state.filterCountriesOfProduction;
        if (this.state.filterCountriesOfDelivery.length > 0)
            filters.countriesOfDelivery = this.state.filterCountriesOfDelivery;
        if (this.state.filterElements.length > 0)
            filters.elements = this.state.filterElements;
        if (this.state.filterProcessings.length > 0)
            filters.processing = this.state.filterProcessings;
        if (this.state.filterBinder.length > 0) filters.binder = this.state.filterBinder;
        if (this.state.filterPhysiologicalAdditives) filters.physiologicalAdditives = true;
        if (this.state.filterTechnologicalAdditives) filters.technologicalAdditives = true;
        if (this.state.filterBasePriceMin) filters.basePriceMin = parseFloat(this.state.filterBasePriceMin);
        if (this.state.filterBasePriceMax) filters.basePriceMax = parseFloat(this.state.filterBasePriceMax);
        if (this.state.filterProteinsMin) filters.proteinsMin = parseFloat(this.state.filterProteinsMin);
        if (this.state.filterProteinsMax) filters.proteinsMax = parseFloat(this.state.filterProteinsMax);
        if (this.state.filterFatsMin) filters.fatsMin = parseFloat(this.state.filterFatsMin);
        if (this.state.filterFatsMax) filters.fatsMax = parseFloat(this.state.filterFatsMax);
        if (this.state.filterFibersMin) filters.fibersMin = parseFloat(this.state.filterFibersMin);
        if (this.state.filterFibersMax) filters.fibersMax = parseFloat(this.state.filterFibersMax);
        if (this.state.filterAshMin) filters.ashMin = parseFloat(this.state.filterAshMin);
        if (this.state.filterAshMax) filters.ashMax = parseFloat(this.state.filterAshMax);
        if (this.state.filterMoistureMin) filters.moistureMin = parseFloat(this.state.filterMoistureMin);
        if (this.state.filterMoistureMax) filters.moistureMax = parseFloat(this.state.filterMoistureMax);
        if (this.state.filterNfe) filters.nfe = parseFloat(this.state.filterNfe);
        if (this.state.filterNfeTM) filters.nfeTM = parseFloat(this.state.filterNfeTM);

        let filterString = queryString.stringify(filters);

        this.props.history.push({
            pathname: window.history.pathname,
            search: filterString,
        });

        setTimeout(() => this.fetchProducts(), 200);
    };

    handlePPPChange = (event) => {
        event.preventDefault();

        this.setState({ isLoadingProducts: true });
        this.setState({ errorMessage: "" });

        const { name, value } = event.target;
        this.setState({
            [name]: parseInt(value),
        });

        let productsPerPage = parseInt(value);

        let filters = queryString.parse(window.location.search) || {};
        let newPagesTotal;

        if (filters.currentPage) filters.currentPage = parseInt(filters.currentPage);
        if (filters.productsPerPage) filters.productsPerPage = parseInt(filters.productsPerPage);

        if (productsPerPage == 12 || productsPerPage == 24 || productsPerPage == 36) {
            filters.productsPerPage = productsPerPage;
            newPagesTotal = Math.ceil(this.state.nOfProductsTotal / productsPerPage);
            this.setState({ nOfPages: newPagesTotal });
        } else {
            filters.productsPerPage = 12;
            this.setState({ productsPerPage: 12 });
            newPagesTotal = Math.ceil(this.state.nOfProductsTotal / 12);
        }

        if (
            !(
                filters.sortBy == "brand" ||
                filters.sortBy == "rating_dec" ||
                filters.sortBy == "proteins_inc" ||
                filters.sortBy == "fats_inc" ||
                filters.sortBy == "nfeTM_inc"
            )
        ) {
            this.setState({ sortBy: "brand" });
            filters.sortBy = "brand";
        }

        if (
            !(
                typeof filters.currentPage == "number" &&
                filters.currentPage >= 1 &&
                filters.currentPage <= newPagesTotal
            )
        ) {
            this.setState({ currentPage: 1 });
            filters.currentPage = 1;
        }

        let filterString = queryString.stringify(filters);
        this.props.history.push({
            pathname: window.history.pathname,
            search: filterString,
        });

        this.fetchProducts();
    };

    handleSortByChange = (event) => {
        event.preventDefault();

        this.setState({ isLoadingProducts: true });
        this.setState({ errorMessage: "" });

        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });

        let filters = queryString.parse(window.location.search) || {};

        if (filters.currentPage) filters.currentPage = parseInt(filters.currentPage);
        if (filters.productsPerPage) filters.productsPerPage = parseInt(filters.productsPerPage);

        if (
            value == "brand" ||
            value == "rating_dec" ||
            value == "proteins_inc" ||
            value == "fats_inc" ||
            value == "nfeTM_inc" ||
            value == "price_asc" ||
            value == "price_desc"
        ) {
            filters.sortBy = value;
        } else {
            this.setState({ sortBy: "brand" });
            filters.sortBy = "brand";
        }

        if (!(filters.productsPerPage == 12 || filters.productsPerPage == 24 || filters.productsPerPage == 36)) {
            this.setState({ productsPerPage: 12 });
            filters.productsPerPage = 12;
        }

        if (
            !(
                typeof filters.currentPage == "number" &&
                filters.currentPage >= 1 &&
                filters.currentPage <= this.state.nOfProductsTotal
            )
        ) {
            this.setState({ currentPage: 1 });
            filters.currentPage = 1;
        }

        let filterString = queryString.stringify(filters);
        this.props.history.push({
            pathname: window.history.pathname,
            search: filterString,
        });

        this.fetchProducts();
    };

    onPaginationChange = (event) => {
        event.preventDefault();

        this.setState({ isLoadingProducts: true });
        window.scrollTo({ top: 0, behavior: "smooth" });

        let { page } = event.target.dataset;

        if (page) {
            let toPage;

            if (page == "next" && this.state.currentPage < this.state.nOfPages) {
                toPage = this.state.currentPage + 1;

                let filters = queryString.parse(window.location.search) || {};
            } else if (page == "prev" && this.state.currentPage >= 2) {
                toPage = this.state.currentPage - 1;
            } else {
                toPage = parseInt(page);
            }

            let filters = queryString.parse(window.location.search) || {};

            if (filters.currentPage) filters.currentPage = parseInt(filters.currentPage);
            if (filters.productsPerPage) filters.productsPerPage = parseInt(filters.productsPerPage);

            if (
                filters.sortBy == "brand" ||
                filters.sortBy == "rating_dec" ||
                filters.sortBy == "proteins_inc" ||
                filters.sortBy == "fats_inc" ||
                filters.sortBy == "nfeTM_inc"
            ) {
                this.setState({ sortBy: filters.sortBy });
            } else {
                filters.sortBy = this.state.sortBy;
            }

            if (filters.productsPerPage == 12 || filters.productsPerPage == 24 || filters.productsPerPage == 36) {
                this.setState({ productsPerPage: filters.productsPerPage });
            } else {
                filters.productsPerPage = parseInt(this.state.productsPerPage);
            }

            this.setState({ currentPage: toPage });
            filters.currentPage = toPage;

            let filterString = queryString.stringify(filters);
            this.props.history.push({
                pathname: window.history.pathname,
                search: filterString,
            });

            return this.fetchProducts();
        } else {
            return;
        }
    };

    pagination = () => {
        if (this.state.nOfProductsTotal && this.state.productsPerPage) {
            let jsx = [];

            for (let i = 1; i < this.state.nOfPages + 1; i++) {
                jsx.push(
                    <li className={this.state.currentPage == i ? "page-item active" : "page-item"} key={"page-" + i}>
                        <a className="page-link" href="" data-page={i} onClick={this.onPaginationChange}>
                            {i}
                        </a>
                    </li>
                );
            }

            return (
                <div id="pagination">
                    <div className="pagination-wrapper">
                        <ul className="pagination">
                            <li className={this.state.currentPage == 1 ? "page-item disabled" : "page-item"}>
                                <a
                                    className="page-link"
                                    href=""
                                    aria-label="Letzte"
                                    data-page="prev"
                                    onClick={this.onPaginationChange}
                                >
                                    &laquo;
                                </a>
                            </li>
                            {jsx}
                            <li
                                className={
                                    this.state.currentPage >= this.state.nOfPages ? "page-item disabled" : "page-item"
                                }
                            >
                                <a
                                    className="page-link"
                                    href=""
                                    aria-label="Nächste"
                                    data-page="next"
                                    onClick={this.onPaginationChange}
                                >
                                    &raquo;
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }
        return null;
    };

    filterPopAge = () => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;

        const GreenCheckbox = withStyles({
            root: {
                color: "#666",
                "&$checked": {
                    color: "#55AD59",
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" {...props} />);

        if (Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes')) {
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12} className="mui-select">
                            <h3 className="color-primary">Alter &amp; Futterart</h3>
                            <p style={{margin: 0}}>Alle Produkte der Kategorie Flocken & Co sind für jede Altersgruppe geeignet und dienen immer nur als Ergänzungsfutter.</p>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} className="mui-select">
                        <h3 className="color-primary">Alter &amp; Futterart</h3>
                    </Grid>
                    <Grid item xs={12} sm={6} className="form-group mui-select">
                        <FormControl>
                            <FormLabel>Alter</FormLabel>
                            <div className="select-div">
                                {this.state.filters.availableAgeGroups &&
                                    this.state.filters.availableAgeGroups.length >= 1 ? (
                                    this.state.filters.availableAgeGroups.map((name) => (
                                        (name == 'adult' || name == 'baby' || name == 'junior' || name == 'senior') &&
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterAgeGroup"
                                                    value={name}
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.filterAgeGroup.includes(name)}
                                                />
                                            }
                                            label={SC.convert(name)}
                                            style={{ margin: 0 }}
                                        />
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className="form-group mui-select">
                        <FormControl>
                            <FormLabel>
                                Futterart
                                <button type="button" className="btn btn-tooltip">
                                    <img src="/img/information.png" data-tip data-for="tip-15" />
                                </button>
                                <ReactTooltip
                                    id="tip-15"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="react-tooltip"
                                >
                                    <span>
                                        <strong>Alleinfuttermittel:</strong> deckt in der Theorie den Nährstoffbedarf ab; kann als alleiniges Hauptfuttermittel verwendet werden.
                                        <br /><strong>Einzelfuttermittel:</strong> nur eine Komponente enthalten z.B.: Leber
                                        <br /><strong>Ergänzungsfuttermittel:</strong> mehrere Einzelfuttermittel; deckt alleine den Nährstoffbedarf nicht ab
                                    </span>
                                </ReactTooltip>
                            </FormLabel>
                            <div className="select-div">
                                {this.state.filters.availableType2 && this.state.filters.availableType2.length >= 1 ? (
                                    this.state.filters.availableType2.map((name) => (
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterType2"
                                                    value={name}
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.filterType2.includes(name)}
                                                />
                                            }
                                            label={SC.convert(name)}
                                            style={{ margin: 0 }}
                                        />
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" className="mui-select-buttons">
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span className="btn btn-primary btn-solid" onClick={this.onFilterSubmit}>
                            Filter anwenden
                        </span>
                    </Grid>
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span
                            className="btn btn-light btn-solid"
                            onClick={() => {
                                this.setState({ filterAgeGroup: [] });
                                this.setState({ filterType2: [] });
                                this.setState({hasFilterChanges: true});
                            }}
                        >
                            Auswahl löschen
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    filterPopIngredients = (type1) => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;

        const GreenCheckbox = withStyles({
            root: {
                color: "#666",
                "&$checked": {
                    color: "#55AD59",
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" {...props} />);

        const attributes = [
            "biological",
            "baked",
            "freezedried",
            "grainfree",
            "glutenfree",
            "hypoallergenic",
            "meatFirst",
            "lactosefree",
            "foodquality",
            "monoprotein",
            "purinarm",
            "sensitive",
            "taurin",
            "vegan",
            "vegetarian",
            "wheatfree",
            "woanimalexperiments",
        ];
        const animalShares = ["0", "1", "30", "40", "50", "60", "70", "80", "90", "100"];
        const animalSharesDried = ["0", "1", "20", "30", "40", "50", "60", "70", "80", "90", "100"];

        let ingredientsGridWidthLg = '';

        if (type1 == 'wet') ingredientsGridWidthLg = 3;

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} className="mui-select">
                        <h3 className="color-primary">Produktinhalt</h3>
                    </Grid>
                    {Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes') && (
                        <Grid item xs={12} sm={6} lg className="form-group mui-select"
                        style={Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes') ? {order: 1} : {}}
                        >
                            <FormControl>
                                <FormLabel>
                                    Flockenart
                                </FormLabel>
                                <div className="select-div">
                                    {this.state.filters.availableType2 && this.state.filters.availableType2.length >= 1 ? (
                                        this.state.filters.availableType2.map((name) => (
                                            <FormControlLabel
                                                control={
                                                    <GreenCheckbox
                                                        style={{
                                                            padding: 0,
                                                            marginRight: ".5rem",
                                                        }}
                                                        name="filterType2"
                                                        value={name}
                                                        onChange={this.handleCheckboxChange}
                                                        checked={this.state.filterType2.includes(name)}
                                                    />
                                                }
                                                label={SC.convert(name)}
                                                style={{ margin: 0 }}
                                            />
                                        ))
                                    ) : (
                                        <i>keine Filter vorhanden</i>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6} lg className="form-group mui-select"
                        style={Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes') ? {order: 3} : {}}
                    >
                        <FormControl>
                            <FormLabel>
                                Deklaration
                                <button type="button" className="btn btn-tooltip">
                                    <img src="/img/information.png" data-tip data-for="tip-16" />
                                </button>
                                <ReactTooltip
                                    id="tip-16"
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="react-tooltip"
                                >
                                    <span>
                                        Geschlossen: nicht erkennbar, welche Komponenten im Detail verarbeitet wurden
                                        <br />Halboffen: einzelne verarbeitete Komponenten in absteigender Mengenreihenfolge aufgelistet
                                        <br />Offen: einzelne Komponenten inkl. Mengenangabe angegeben.
                                    </span>
                                </ReactTooltip>
                            </FormLabel>
                            <div className="select-div">
                                {this.state.filters.availableDeclarations &&
                                    this.state.filters.availableDeclarations.length >= 1 ? (
                                    this.state.filters.availableDeclarations.map((name) => (
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterDeclaration"
                                                    value={name}
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.filterDeclaration.includes(name)}
                                                />
                                            }
                                            label={SC.convert(name)}
                                        ></FormControlLabel>
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg className="form-group mui-select"
                        style={Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes') ? {order: 5} : {}}
                    >
                        <FormControl>
                            <FormLabel>Eigenschaften</FormLabel>
                            <div className="select-div">
                                {this.state.filters.availableAttributes &&
                                    this.state.filters.availableAttributes.length >= 1 ? (
                                    this.state.filters.availableAttributes.map((name) => (
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterAttributes"
                                                    value={name}
                                                    onChange={(e) => {
                                                        if (name == 'taurin' && !this.state.filterAttributes.includes('taurin') && this.state.filterPhysiologicalAdditives) {
                                                            this.setState({
                                                                filterPhysiologicalAdditives: false,
                                                            });
                                                        }
                                                        this.handleCheckboxChange(e)
                                                    }}
                                                    checked={this.state.filterAttributes.includes(name)}
                                                />
                                            }
                                            label={(
                                                <div>
                                                    {SC.convert(name)}
                                                    {name == 'meatFirst' && (
                                                        <>
                                                            <button type="button" className="btn btn-tooltip">
                                                                <img src="/img/information.png" data-tip data-for="tip-18" />
                                                            </button>
                                                            <ReactTooltip
                                                                id="tip-18"
                                                                place="top"
                                                                type="dark"
                                                                effect="float"
                                                                className="react-tooltip"
                                                            >
                                                                <span>
                                                                    Qualitätsmerkmal, wichtiger Nährstofflieferant für Hunde &amp; Katzen
                                                                </span>
                                                            </ReactTooltip>
                                                        </>
                                                    )}
                                                    {name == 'taurin' && (
                                                        <>
                                                            <button type="button" className="btn btn-tooltip">
                                                                <img src="/img/information.png" data-tip data-for="tip-19" />
                                                            </button>
                                                            <ReactTooltip
                                                                id="tip-19"
                                                                place="top"
                                                                type="dark"
                                                                effect="float"
                                                                className="react-tooltip"
                                                            >
                                                                <span>
                                                                    essenzieller Bestandteil für Katzen
                                                                </span>
                                                            </ReactTooltip>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        ></FormControlLabel>
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                                <FormControlLabel
                                    style={{ margin: 0 }}
                                    control={
                                        <GreenCheckbox
                                            style={{
                                                padding: 0,
                                                marginRight: ".5rem",
                                            }}
                                            name="filterTechnologicalAdditives"
                                            onChange={() => {
                                                this.setState({
                                                    filterTechnologicalAdditives: !this.state
                                                        .filterTechnologicalAdditives,
                                                });
                                            }}
                                            checked={this.state.filterTechnologicalAdditives}
                                        />
                                    }
                                    label={
                                        <React.Fragment>
                                            ohne techn. Zusatzstoffe
                                            <button type="button" className="btn btn-tooltip">
                                                <img src="/img/information.png" data-tip data-for="tip-11" />
                                            </button>
                                            <ReactTooltip
                                                id="tip-11"
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                className="react-tooltip"
                                            >
                                                <span>
                                                    = Zusatzstoff, welcher das Futter haltbar, unverderblich und formbar
                                                    (z.B. viereckige Stückchen) macht.
                                                    <br />
                                                    Beispiele: Cassia Gum, E-Nummern, BHA/BHT, Konservierungsstoffe, ...
                                                </span>
                                            </ReactTooltip>
                                        </React.Fragment>
                                    }
                                ></FormControlLabel>
                                <FormControlLabel
                                    style={{ margin: 0 }}
                                    control={
                                        <GreenCheckbox
                                            style={{
                                                padding: 0,
                                                marginRight: ".5rem",
                                            }}
                                            name="filterPhysiologicalAdditives"
                                            onChange={() => {
                                                let { filterAttributes } = this.state;
                                                if (filterAttributes.includes('taurin') && !this.state.filterPhysiologicalAdditives) {
                                                    let index = filterAttributes.indexOf('taurin');
                                                    if (index > -1) {
                                                        filterAttributes.splice(index, 1);
                                                        this.setState({ filterAttributes: filterAttributes });
                                                    }
                                                }
                                                this.setState({
                                                    filterPhysiologicalAdditives: !this.state.filterPhysiologicalAdditives,
                                                });
                                            }}
                                            checked={this.state.filterPhysiologicalAdditives}
                                        />
                                    }
                                    label={
                                        <React.Fragment>
                                            ohne ern. Zusatzstoffe
                                            <button type="button" className="btn btn-tooltip">
                                                <img src="/img/information.png" data-tip data-for="tip-12" />
                                            </button>
                                            <ReactTooltip
                                                id="tip-12"
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                className="react-tooltip"
                                            >
                                                <span>= künstliche Nährstoffe zur Bedarfsdeckung</span>
                                            </ReactTooltip>
                                        </React.Fragment>
                                    }
                                ></FormControlLabel>
                                {/* <FormControlLabel
                                    style={{ margin: 0 }}
                                    control={
                                        <GreenCheckbox
                                            style={{
                                                padding: 0,
                                                marginRight: ".5rem",
                                            }}
                                            name="filterBinder"
                                            value="75"
                                            onChange={this.handleCheckboxChange}
                                            checked={this.state.filterBinder.includes("75")}
                                        />
                                    }
                                    label={"Bindemittelverdacht weniger als 75%"}
                                ></FormControlLabel> */}
                            </div>
                        </FormControl>
                    </Grid>
                    {this.state.filterType1 && this.state.filterType1.includes("wet") && (
                        <Grid item xs={12} sm={6} lg className="form-group mui-select">
                            <FormControl>
                                <FormLabel>
                                    Tierischer Anteil
                                    <button type="button" className="btn btn-tooltip">
                                        <img src="/img/information.png" data-tip data-for="tip-17" />
                                    </button>
                                    <ReactTooltip
                                        id="tip-17"
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        className="react-tooltip"
                                    >
                                        <span>
                                            Hund, gesund &amp; ausgewachsen -&gt; 70-80%
                                            <br />Katze, gesund &amp; ausgewachsen -&gt; 90-100%
                                        </span>
                                    </ReactTooltip>
                                </FormLabel>
                                <div className="select-div">
                                    {this.state.filters.availableAnimalShares &&
                                        this.state.filters.availableAnimalShares.length >= 1 ? (
                                        this.state.filters.availableAnimalShares.map((name) => (
                                            <FormControlLabel
                                                style={{ margin: 0 }}
                                                control={
                                                    <GreenCheckbox
                                                        style={{
                                                            padding: 0,
                                                            marginRight: ".5rem",
                                                        }}
                                                        name="filterAnimalShare"
                                                        value={name}
                                                        onChange={this.handleCheckboxChange}
                                                        checked={this.state.filterAnimalShare.includes(name.toString())}
                                                    />
                                                }
                                                label={SC.convertAnimalShare(name)}
                                            ></FormControlLabel>
                                        ))
                                    ) : (
                                        <i>keine Filter vorhanden</i>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                    )}
                    {type1 != 'flakes' && (
                        <Grid item xs={12} sm={6} lg className="form-group mui-select">
                            <FormControl>
                                <FormLabel>Tierische Proteinquelle</FormLabel>
                                <div className="select-div">
                                    {this.state.filters.availableAnimalComponents &&
                                        this.state.filters.availableAnimalComponents.length >= 1 ? (
                                        this.state.filters.availableAnimalComponents.map((name) => (
                                            <FormControlLabel
                                                style={{ margin: 0 }}
                                                control={
                                                    <GreenCheckbox
                                                        style={{
                                                            padding: 0,
                                                            marginRight: ".5rem",
                                                        }}
                                                        name="filterAnimalComponents"
                                                        value={name}
                                                        onChange={this.handleCheckboxChange}
                                                        checked={this.state.filterAnimalComponents.includes(name)}
                                                    />
                                                }
                                                label={SC.convert(name)}
                                            ></FormControlLabel>
                                        ))
                                    ) : (
                                        <i>keine Filter vorhanden</i>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                    )}
                    {this.state.filterType1 && this.state.filterType1.includes("dried") && (
                        <React.Fragment>
                            <Grid item xs={12} sm={6} lg className="form-group mui-select">
                                <FormControl>
                                    <FormLabel>
                                        Trockener tierischer Anteil
                                        <button type="button" className="btn btn-tooltip">
                                            <img src="/img/information.png" data-tip data-for="tip-13" />
                                        </button>
                                        <ReactTooltip
                                            id="tip-13"
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            className="react-tooltip"
                                        >
                                            <span>Entspricht nicht immer dem gesamten tierischen Anteil. (z.B.: getrocknetes Fleisch, Tierproteine, ...)</span>
                                        </ReactTooltip>
                                    </FormLabel>
                                    <div className="select-div">
                                        {this.state.filters.availableAnimalSharesDriedOne &&
                                            this.state.filters.availableAnimalSharesDriedOne.length >= 1 ? (
                                            this.state.filters.availableAnimalSharesDriedOne.map((name) => (
                                                <FormControlLabel
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <GreenCheckbox
                                                            style={{
                                                                padding: 0,
                                                                marginRight: ".5rem",
                                                            }}
                                                            name="filterAnimalShareDriedOne"
                                                            value={name}
                                                            onChange={this.handleCheckboxChange}
                                                            checked={this.state.filterAnimalShareDriedOne.includes(name.toString())}
                                                        />
                                                    }
                                                    label={SC.convertAnimalShareDried(name)}
                                                ></FormControlLabel>
                                            ))
                                        ) : (
                                            <i>keine Filter vorhanden</i>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg className="form-group mui-select">
                                <FormControl>
                                    <FormLabel>
                                        Frischer tierischer Anteil
                                        <button type="button" className="btn btn-tooltip">
                                            <img src="/img/information.png" data-tip data-for="tip-14" />
                                        </button>
                                        <ReactTooltip
                                            id="tip-14"
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            className="react-tooltip"
                                        >
                                            <span>Entspricht nicht immer dem gesamten tierischen Anteil. (z.B.: frisches Fleisch, Fette, Öle, ...)</span>
                                        </ReactTooltip>
                                    </FormLabel>
                                    <div className="select-div">
                                        {this.state.filters.availableAnimalSharesDriedTwo &&
                                            this.state.filters.availableAnimalSharesDriedTwo.length >= 1 ? (
                                            this.state.filters.availableAnimalSharesDriedTwo.map((name) => (
                                                <FormControlLabel
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <GreenCheckbox
                                                            style={{
                                                                padding: 0,
                                                                marginRight: ".5rem",
                                                            }}
                                                            name="filterAnimalShareDriedTwo"
                                                            value={name}
                                                            onChange={this.handleCheckboxChange}
                                                            checked={this.state.filterAnimalShareDriedTwo.includes(name.toString())}
                                                        />
                                                    }
                                                    label={SC.convertAnimalShareDried(name)}
                                                ></FormControlLabel>
                                            ))
                                        ) : (
                                            <i>keine Filter vorhanden</i>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                        </React.Fragment>
                    )}
                    {(type1 == 'raw' || type1 == 'flakes' || type1 == 'suplement') && (
                        <>
                            <Grid item xs={12} sm={6} lg className="form-group mui-select"
                                style={Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes') ? {order: 4} : {}}
                            >
                                <FormControl>
                                    <FormLabel>{type1 == 'flakes' ? 'Monosorten' : 'Bestandteile'}</FormLabel>
                                    <div className="select-div">
                                        {this.state.filters.availableElements &&
                                            this.state.filters.availableElements.length >= 1 ? (
                                            this.state.filters.availableElements.map((name) => (
                                                <FormControlLabel
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <GreenCheckbox
                                                            style={{
                                                                padding: 0,
                                                                marginRight: ".5rem",
                                                            }}
                                                            name="filterElements"
                                                            value={name}
                                                            onChange={this.handleCheckboxChange}
                                                            checked={this.state.filterElements.includes(name)}
                                                        />
                                                    }
                                                    label={SC.convert(name)}
                                                ></FormControlLabel>
                                            ))
                                        ) : (
                                            <i>keine Filter vorhanden</i>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg className="form-group mui-select"
                                style={Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes') ? {order: 2} : {}}
                            >
                                <FormControl>
                                    <FormLabel>Verarbeitung</FormLabel>
                                    <div className="select-div">
                                        {this.state.filters.availableProcessings &&
                                            this.state.filters.availableProcessings.length >= 1 ? (
                                            this.state.filters.availableProcessings.map((name) => (
                                                <FormControlLabel
                                                    style={{ margin: 0 }}
                                                    control={
                                                        <GreenCheckbox
                                                            style={{
                                                                padding: 0,
                                                                marginRight: ".5rem",
                                                            }}
                                                            name="filterProcessings"
                                                            value={name}
                                                            onChange={this.handleCheckboxChange}
                                                            checked={this.state.filterProcessings.includes(name)}
                                                        />
                                                    }
                                                    label={SC.convert(name).replace('& Co.', '')}
                                                ></FormControlLabel>
                                            ))
                                        ) : (
                                            <i>keine Filter vorhanden</i>
                                        )}
                                    </div>
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container className="mui-select-buttons" alignItems="center">
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span className="btn btn-primary btn-solid" onClick={this.onFilterSubmit}>
                            Filter anwenden
                        </span>
                    </Grid>
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span
                            className="btn btn-light btn-solid"
                            onClick={() => {
                                this.setState({ filterAttributes: [] });
                                this.setState({ filterDeclaration: [] });
                                this.setState({ filterAnimalShare: [] });
                                this.setState({
                                    filterAnimalShareDriedOne: [],
                                });
                                this.setState({
                                    filterAnimalShareDriedTwo: [],
                                });
                                this.setState({ filterAnimalComponents: [] });
                                this.setState({ filterBinder: [] });
                                this.setState({
                                    filterTechnologicalAdditives: false,
                                });
                                this.setState({
                                    filterPhysiologicalAdditives: false,
                                });
                                this.setState({ filterProcessings: [] });
                                this.setState({ filterElements: [] });
                                this.setState({hasFilterChanges: true});
                            }}
                        >
                            Auswahl löschen
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    filterPopDiseases = () => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;

        const GreenCheckbox = withStyles({
            root: {
                color: "#666",
                "&$checked": {
                    color: "#55AD59",
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" {...props} />);

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} className="mui-select">
                        <h3 className="color-primary">Gesundheit</h3>
                    </Grid>
                    <Grid item xs={12} className="form-group mui-select">
                        <FormControl>
                            <FormLabel>Gesundheit</FormLabel>
                            <div className="select-div">
                                {this.state.filters.availableDiseases &&
                                    this.state.filters.availableDiseases.length >= 1 ? (
                                    this.state.filters.availableDiseases.map((name) => (
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterDiseases"
                                                    value={name}
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.filterDiseases.includes(name)}
                                                />
                                            }
                                            label={SC.convert(name)}
                                        ></FormControlLabel>
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className="mui-select-buttons" alignItems="center">
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span className="btn btn-primary btn-solid" onClick={this.onFilterSubmit}>
                            Filter anwenden
                        </span>
                    </Grid>
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span
                            className="btn btn-light btn-solid"
                            onClick={() => {
                                this.setState({ filterDiseases: [] });
                                this.setState({hasFilterChanges: true});
                            }}
                        >
                            Auswahl löschen
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    filterPopPackaging = () => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;

        const GreenCheckbox = withStyles({
            root: {
                color: "#666",
                "&$checked": {
                    color: "#55AD59",
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" {...props} />);

        let packagings = ["box", "can", "bag", "bowl", "pouch", "glas", "pvcBottle", "tetrapack", "tube", "sausage"];
        packagings = packagings.sort(function (a, b) {
            a = SC.convert(a);
            b = SC.convert(b);
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }

            return 0;
        });

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="mui-select">
                        <h3 className="color-primary">Verpackung</h3>
                    </Grid>
                    <Grid item xs={12} sm={6} className="form-group mui-select">
                        <FormControl>
                            <FormLabel>Verpackungsart</FormLabel>
                            <div className="select-div">
                                {this.state.filters.availablePackagings &&
                                this.state.filters.availablePackagings.length >= 1 ? (
                                this.state.filters.availablePackagings.map((name) => (
                                    <FormControlLabel
                                        style={{ margin: 0 }}
                                        control={
                                            <GreenCheckbox
                                                style={{
                                                    padding: 0,
                                                    marginRight: ".5rem",
                                                }}
                                                name="filterPackaging"
                                                value={name}
                                                onChange={this.handleCheckboxChange}
                                                checked={this.state.filterPackaging.includes(name)}
                                            />
                                        }
                                        label={SC.convert(name)}
                                    ></FormControlLabel>
                                ))) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className="form-group mui-select">
                        <FormControl>
                            <FormLabel>Verpackungsgröße</FormLabel>
                            <div className="select-div">
                                {this.state.filters.availablePackagingSizes &&
                                    this.state.filters.availablePackagingSizes.length >= 1 ? (
                                    this.state.filters.availablePackagingSizes.map((name) => (
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterPackagingSize"
                                                    value={name}
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.filterPackagingSize.includes(name)}
                                                />
                                            }
                                            label={name.split('||')[0]}
                                        ></FormControlLabel>
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className="mui-select-buttons" alignItems="center">
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span className="btn btn-primary btn-solid" onClick={this.onFilterSubmit}>
                            Filter anwenden
                        </span>
                    </Grid>
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span
                            className="btn btn-alert btn-solid"
                            onClick={() => {
                                this.setState({ filterPackaging: [] });
                                this.setState({ filterPackagingSize: [] });
                                this.setState({hasFilterChanges: true});
                            }}
                        >
                            Auswahl löschen
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    filterPopOrigin = (type1) => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: "auto",
                    maxWidth: "100%",
                },
            },
        };

        const GreenCheckbox = withStyles({
            root: {
                color: "#666",
                "&$checked": {
                    color: "#55AD59",
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" {...props} />);

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="mui-select">
                        <h3 className="color-primary">Marke &amp; Herkunft</h3>
                    </Grid>
                    <Grid item xs={12} sm={type1 == 'raw' ? 3 : 4} className="form-group mui-select">
                        <FormControl>
                            <FormLabel>Marke</FormLabel>
                            <div className="select-div">
                                {this.state.filters.availableBrands &&
                                    this.state.filters.availableBrands.length >= 1 ? (
                                    this.state.filters.availableBrands.map((name) => (
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterBrand"
                                                    value={name._id}
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.filterBrand.includes(name._id)}
                                                />
                                            }
                                            label={name.name}
                                        ></FormControlLabel>
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={type1 == 'raw' ? 3 : 4} className="form-group mui-select">
                        <FormControl>
                            <FormLabel>Herkunftsland</FormLabel>
                            <div className="select-div">
                                {this.state.filters.availableCountriesOfProduction &&
                                    this.state.filters.availableCountriesOfProduction.length >= 1 ? (
                                    this.state.filters.availableCountriesOfProduction.map((name) => (
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterCountriesOfProduction"
                                                    value={name}
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.filterCountriesOfProduction.includes(name)}
                                                />
                                            }
                                            label={name}
                                        ></FormControlLabel>
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={type1 == 'raw' ? 3 : 4} className="form-group mui-select">
                        <FormControl>
                            <FormLabel>Hersteller</FormLabel>
                            <div className="select-div">
                                {this.state.filters.availableProducers &&
                                    this.state.filters.availableProducers.length >= 1 ? (
                                    this.state.filters.availableProducers.map((name) => (
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={
                                                <GreenCheckbox
                                                    style={{
                                                        padding: 0,
                                                        marginRight: ".5rem",
                                                    }}
                                                    name="filterProducers"
                                                    value={name._id}
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.filterProducers.includes(name._id)}
                                                />
                                            }
                                            label={name.name}
                                        ></FormControlLabel>
                                    ))
                                ) : (
                                    <i>keine Filter vorhanden</i>
                                )}
                            </div>
                        </FormControl>
                    </Grid>
                    {type1 == 'raw' && (
                        <Grid item xs={12} sm={3} className="form-group mui-select">
                            <FormControl>
                                <FormLabel>Zustellungsland</FormLabel>
                                <div className="select-div">
                                    {this.state.filters.availableCountriesOfDelivery &&
                                        this.state.filters.availableCountriesOfDelivery.length >= 1 ? (
                                        this.state.filters.availableCountriesOfDelivery.map((name) => name && name.length >= 1 ? (
                                            <FormControlLabel
                                                style={{ margin: 0 }}
                                                control={
                                                    <GreenCheckbox
                                                        style={{
                                                            padding: 0,
                                                            marginRight: ".5rem",
                                                        }}
                                                        name="filterCountriesOfDelivery"
                                                        value={name}
                                                        onChange={this.handleCheckboxChange}
                                                        checked={this.state.filterCountriesOfDelivery.includes(name)}
                                                    />
                                                }
                                                label={SC.convert(name)}
                                            ></FormControlLabel>
                                        ) : null)
                                    ) : (
                                        <i>keine Filter vorhanden</i>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                <Grid container className="mui-select-buttons" alignItems="center">
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span className="btn btn-primary btn-solid" onClick={this.onFilterSubmit}>
                            Filter anwenden
                        </span>
                    </Grid>
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span
                            className="btn btn-alert btn-solid"
                            onClick={() => {
                                this.setState({ filterProducers: [] });
                                this.setState({ filterBrand: [] });
                                this.setState({
                                    filterCountriesOfProduction: [],
                                });
                                this.setState({
                                    filterCountriesOfDelivery: [],
                                });
                                this.setState({hasFilterChanges: true});
                            }}
                        >
                            Auswahl löschen
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    filterPopWeender = () => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: "auto",
                    maxWidth: "100%",
                },
            },
        };

        const GreenCheckbox = withStyles({
            root: {
                color: "#666",
                "&$checked": {
                    color: "#55AD59",
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" {...props} />);

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="mui-select">
                        <h3 className="color-primary">Weender Analyse</h3>
                    </Grid>
                    <Grid item xs={12} className="form-group mui-select">
                        <FormControl>
                            <div className="form-group">
                                <FormLabel>Rohprotein</FormLabel>
                                <div className="row">
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterProteinsMin"
                                            step="0.01"
                                            placeholder="Min"
                                            onChange={this.handleChange}
                                            value={this.state.filterProteinsMin}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterProteinsMax"
                                            step="0.01"
                                            placeholder="Max"
                                            onChange={this.handleChange}
                                            value={this.state.filterProteinsMax}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <FormLabel>Rohfett</FormLabel>
                                <div className="row">
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterFatsMin"
                                            step="0.01"
                                            placeholder="Min"
                                            onChange={this.handleChange}
                                            value={this.state.filterFatsMin}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterFatsMax"
                                            step="0.01"
                                            placeholder="Max"
                                            onChange={this.handleChange}
                                            value={this.state.filterFatsMax}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <FormLabel>Rohfaser</FormLabel>
                                <div className="row">
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterFibersMin"
                                            step="0.01"
                                            placeholder="Min"
                                            onChange={this.handleChange}
                                            value={this.state.filterFibersMin}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterFibersMax"
                                            step="0.01"
                                            placeholder="Max"
                                            onChange={this.handleChange}
                                            value={this.state.filterFibersMax}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <FormLabel>Rohasche</FormLabel>
                                <div className="row">
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterAshMin"
                                            step="0.01"
                                            placeholder="Min"
                                            onChange={this.handleChange}
                                            value={this.state.filterAshMin}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterAshMax"
                                            step="0.01"
                                            placeholder="Max"
                                            onChange={this.handleChange}
                                            value={this.state.filterAshMax}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <FormLabel>Feuchtigkeit</FormLabel>
                                <div className="row">
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterMoistureMin"
                                            step="0.01"
                                            placeholder="Min"
                                            onChange={this.handleChange}
                                            value={this.state.filterMoistureMin}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterMoistureMax"
                                            step="0.01"
                                            placeholder="Max"
                                            onChange={this.handleChange}
                                            value={this.state.filterMoistureMax}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <FormLabel for="filterNfe">Nfe-Gehalt</FormLabel>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="filterNfe"
                                    placeholder="Max"
                                    step="0.01"
                                    onChange={this.handleChange}
                                    value={this.state.filterNfe}
                                />
                            </div>
                            <div className="form-group">
                                <FormLabel for="filterNfeTM">Nfe-Gehalt Trockenmasse</FormLabel>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="filterNfeTM"
                                    placeholder="Max"
                                    step="0.01"
                                    onChange={this.handleChange}
                                    value={this.state.filterNfeTM}
                                />
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className="mui-select-buttons" alignItems="center">
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span className="btn btn-primary btn-solid" onClick={this.onFilterSubmit}>
                            Filter anwenden
                        </span>
                    </Grid>
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span
                            className="btn btn-alert btn-solid"
                            onClick={() => {
                                this.setState({ filterProteinsMin: "" });
                                this.setState({ filterProteinsMax: "" });
                                this.setState({ filterFibersMin: "" });
                                this.setState({ filterFibersMax: "" });
                                this.setState({ filterFatsMin: "" });
                                this.setState({ filterFatsMax: "" });
                                this.setState({ filterAshMin: "" });
                                this.setState({ filterAshMax: "" });
                                this.setState({ filterMoistureMin: "" });
                                this.setState({ filterMoistureMax: "" });
                                this.setState({ filterNfe: "" });
                                this.setState({ filterNfeTM: "" });
                                this.setState({hasFilterChanges: true});
                            }}
                        >
                            Auswahl löschen
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    filterPopBasePrice = () => {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: "auto",
                    maxWidth: "100%",
                },
            },
        };

        const GreenCheckbox = withStyles({
            root: {
                color: "#666",
                "&$checked": {
                    color: "#55AD59",
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" {...props} />);

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="mui-select">
                        <h3 className="color-primary">Basispreis</h3>
                    </Grid>
                    <Grid item xs={12} className="form-group mui-select">
                        <FormControl>
                            <div className="form-group">
                                <FormLabel>Basispreis in €</FormLabel>
                                <div className="row">
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterBasePriceMin"
                                            step="0.01"
                                            placeholder="Min"
                                            onChange={this.handleChange}
                                            value={this.state.filterBasePriceMin}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="filterBasePriceMax"
                                            step="0.01"
                                            placeholder="Max"
                                            onChange={this.handleChange}
                                            value={this.state.filterBasePriceMax}
                                        />
                                    </div>
                                </div>
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className="mui-select-buttons" alignItems="center">
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span className="btn btn-primary btn-solid" onClick={this.onFilterSubmit}>
                            Filter anwenden
                        </span>
                    </Grid>
                    <Grid item xs={6} className="pop-btn-wrapper">
                        <span
                            className="btn btn-alert btn-solid"
                            onClick={() => {
                                this.setState({ filterProteinsMin: "" });
                                this.setState({ filterProteinsMax: "" });
                                this.setState({ filterFibersMin: "" });
                                this.setState({ filterFibersMax: "" });
                                this.setState({ filterFatsMin: "" });
                                this.setState({ filterFatsMax: "" });
                                this.setState({ filterAshMin: "" });
                                this.setState({ filterAshMax: "" });
                                this.setState({ filterMoistureMin: "" });
                                this.setState({ filterMoistureMax: "" });
                                this.setState({ filterNfe: "" });
                                this.setState({ filterNfeTM: "" });
                                this.setState({hasFilterChanges: true});
                            }}
                        >
                            Auswahl löschen
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    render() {
        let popClasses = "pop";
        if (this.state.openFilterPop == "age") {
            popClasses = popClasses + " age-pop";
        } else if (this.state.openFilterPop == "ingredients") {
            popClasses = popClasses + " ingredients-pop";
        } else if (this.state.openFilterPop == "packaging") {
            popClasses = popClasses + " packaging-pop";
        } else if (this.state.openFilterPop == "origin") {
            popClasses = popClasses + " origin-pop";
        } else if (this.state.openFilterPop == "weender") {
            popClasses = popClasses + " weender-pop";
        } else if (this.state.openFilterPop == "diseases") {
            popClasses = popClasses + " diseases-pop";
        } else if (this.state.openFilterPop == "baseprice") {
            popClasses = popClasses + " baseprice-pop";
        }

        return (
            <div id="products-view">
                <Helmet>
                    <meta
                        name="description"
                        content="Nutze den speziellen Suchfilter von MyFeedingCheck, um das richtige Futter für Deinen Hund oder Deine Katze zu finden."
                        id="meta-desc-products"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb bc-products">
                            <span>
                                <Link to="/">Startseite</Link> / Futtermittel
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4">
                            <button
                                class="btn"
                                type="button"
                                data-toggle="collapse"
                                data-target="#filter-section"
                                aria-expanded="false"
                                aria-controls="filter-section"
                                id="filter-collapse-btn"
                            >
                                Produkte filtern
                            </button>
                            <div id="filter-section" className="collapse dont-collapse-sm">
                                {/* <h4 className="color-primary">Futtermittel:</h4> */}
                                <form
                                    method="get"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                    style={{ marginTop: "1rem" }}
                                >
                                    <div className="form-group">
                                        {/* <label>Tierart</label> */}
                                        <div style={{ padding: "0 1rem" }}>
                                            <div className="filterToggle">
                                                <div
                                                    className={
                                                        this.state.filterAnimal.includes("dog") ||
                                                            this.state.filterAnimal.length <= 0
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <label for="filterAnimal-dog">Hund</label>
                                                        <input
                                                            className="display-none"
                                                            id="filterAnimal-dog"
                                                            type="checkbox"
                                                            name="filterAnimal"
                                                            value="dog"
                                                            onChange={(e) => {
                                                                this.state.filterOption == 2 ? this.handleCheckboxChange(e) : this.setState({ filterAnimal: ['dog'] });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterAnimal.includes("dog")}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        this.state.filterAnimal.includes("cat") ||
                                                            this.state.filterAnimal.length <= 0
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <label for="filterAnimal-cat">Katze</label>
                                                        <input
                                                            className="display-none"
                                                            id="filterAnimal-cat"
                                                            type="checkbox"
                                                            name="filterAnimal"
                                                            value="cat"
                                                            onChange={(e) => {
                                                                this.state.filterOption == 2 ? this.handleCheckboxChange(e) : this.setState({ filterAnimal: ['cat'] });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterAnimal.includes("cat")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div style={{ padding: "0 1rem" }}>
                                            <div className="filterToggle filterToggle-type1">
                                                <div
                                                    className={
                                                        this.state.filterType1.includes("wet") ||
                                                            this.state.filterType1.length <= 0
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <label for="filterType1-wet">Nass</label>
                                                        <input
                                                            className="display-none"
                                                            id="filterType1-wet"
                                                            type="radio"
                                                            name="filterType1"
                                                            value="wet"
                                                            onClick={(e) => {
                                                                this.handleRadioChange(e);
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterType1.includes("wet")}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        this.state.filterType1.includes("dried") ||
                                                            this.state.filterType1.length <= 0
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <label for="filterType1-dried">Trocken</label>
                                                        <input
                                                            className="display-none"
                                                            id="filterType1-dried"
                                                            type="radio"
                                                            name="filterType1"
                                                            value="dried"
                                                            onClick={(e) => {
                                                                this.handleRadioChange(e);
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterType1.includes("dried")}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        this.state.filterType1.includes("raw") ||
                                                            this.state.filterType1.length <= 0
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <label for="filterType1-raw">Rohes</label>
                                                        <input
                                                            className="display-none"
                                                            id="filterType1-raw"
                                                            type="radio"
                                                            name="filterType1"
                                                            value="raw"
                                                            onClick={(e) => {
                                                                this.handleRadioChange(e);
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterType1.includes("raw")}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        this.state.filterType1.includes("flakes") ||
                                                            this.state.filterType1.length <= 0
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <label for="filterType1-flakes">Flocken &amp; Co.</label>
                                                        <input
                                                            className="display-none"
                                                            id="filterType1-flakes"
                                                            type="radio"
                                                            name="filterType1"
                                                            value="flakes"
                                                            onClick={(e) => {
                                                                this.handleRadioChange(e);
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterType1.includes("flakes")}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        this.state.filterType1.includes("suplement") ||
                                                            this.state.filterType1.length <= 0
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <label for="filterType1-suplement">Nahrungsergänzung</label>
                                                        <input
                                                            className="display-none"
                                                            id="filterType1-suplement"
                                                            type="radio"
                                                            name="filterType1"
                                                            value="suplement"
                                                            onClick={(e) => {
                                                                this.handleRadioChange(e);
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterType1.includes("suplement")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        {/* <label>
                                            Filtermethode
                                            <button type="button" className="btn btn-tooltip"><img src="/img/information.png" data-tip data-for="tip-3" /></button>
                                            <ReactTooltip id="tip-3" place="top" type="dark" effect="float" className="react-tooltip">
                                                <span>
                                                    <strong>Normal:</strong> Zeigt alle Produkte, auf die mindestens einer der gewählten Filter zutrifft. Beispiel: Wenn Tierbestandteil "Elch" und "Hase" gewählt wurden, werden alle Produkte angezeigt, die ENTWEDER Elch ODER Hase ODER beides beinhalten.
                                                    <br /><br />
                                                    <strong>Exakt:</strong> Zeigt alle Produkte, auf die die gewählten Filter exakt zutreffen. Beispiel: Wenn Tierbestandteil "Elch" und "Hase" gewählt wurden, werden NUR Produkte angezeigt, die Elch UND Hase UND sonst keinen Tierbestandteil beinhalten.
                                                </span>
                                            </ReactTooltip>
                                        </label> */}
                                        <div style={{ padding: "0 1rem .75rem" }}>
                                            <div className="filterToggle">
                                                <div
                                                    className={
                                                        this.state.filterOption == 1
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <Tooltip title='Zeigt alle Produkte, auf die mindestens einer der gewählten Filter zutrifft. Beispiel: Wenn Tierbestandteil "Elch" und "Hase" gewählt wurden, werden alle Produkte angezeigt, die ENTWEDER Elch ODER Hase ODER beides beinhalten.'>
                                                            <label for="filterOption-1">
                                                                Normal
                                                                {/* <button type="button" className="btn btn-tooltip"><img src="/img/information.png" data-tip data-for="tip-33" /></button>
                                                            <ReactTooltip id="tip-33" place="top" type="dark" effect="float" className="react-tooltip">
                                                                <span>
                                                                    Zeigt alle Produkte, auf die mindestens einer der gewählten Filter zutrifft. Beispiel: Wenn Tierbestandteil "Elch" und "Hase" gewählt wurden, werden alle Produkte angezeigt, die ENTWEDER Elch ODER Hase ODER beides beinhalten.
                                                                </span>
                                                            </ReactTooltip> */}
                                                            </label>
                                                        </Tooltip>
                                                        <input
                                                            className="display-none"
                                                            id="filterOption-1"
                                                            type="checkbox"
                                                            name="filterOption"
                                                            value={1}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterOption == 1}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        this.state.filterOption == 2
                                                            ? "filterToggle-option active"
                                                            : "filterToggle-option"
                                                    }
                                                >
                                                    <div className="checkbox">
                                                        <Tooltip title='Zeigt alle Produkte, auf die die gewählten Filter exakt zutreffen. Beispiel: Wenn Tierbestandteil "Elch" und "Hase" gewählt wurden, werden NUR Produkte angezeigt, die Elch UND Hase UND sonst keinen Tierbestandteil beinhalten.'>
                                                            <label for="filterOption-2">
                                                                Exakt
                                                                {/* <button type="button" className="btn btn-tooltip"><img src="/img/information.png" data-tip data-for="tip-3" /></button>
                                                            <ReactTooltip id="tip-3" place="top" type="dark" effect="float" className="react-tooltip">
                                                                <span>
                                                                    Zeigt alle Produkte, auf die die gewählten Filter exakt zutreffen. Beispiel: Wenn Tierbestandteil "Elch" und "Hase" gewählt wurden, werden NUR Produkte angezeigt, die Elch UND Hase UND sonst keinen Tierbestandteil beinhalten.
                                                                </span>
                                                            </ReactTooltip> */}
                                                            </label>
                                                        </Tooltip>
                                                        <input
                                                            className="display-none"
                                                            id="filterOption-2"
                                                            type="checkbox"
                                                            name="filterOption"
                                                            value={2}
                                                            onChange={(e) => {
                                                                this.handleChange(e);
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                            checked={this.state.filterOption == 2}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group filter-group">
                                        <div
                                            className="filter-veil"
                                            onClick={() =>
                                                this.setState({
                                                    openFilterPop: "age",
                                                })
                                            }
                                        ></div>
                                        <div className="filter-wrapper">
                                            <label
                                                onClick={() =>
                                                    this.setState({
                                                        openFilterPop: "age",
                                                    })
                                                }
                                            >
                                                Alter & Futterart
                                            </label>
                                            {((this.state.filterAgeGroup && this.state.filterAgeGroup.length >= 1) ||
                                                !(Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes')) && (this.state.filterType2 && this.state.filterType2.length >= 1)) && (
                                                    <span
                                                        className="add-filters-btn"
                                                        onClick={() => {
                                                            this.setState({
                                                                filterAgeGroup: [],
                                                            });
                                                            this.setState({
                                                                filterType2: [],
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close />
                                                    </span>
                                                )}
                                            <div className="active-filters">
                                                {this.state.filterAgeGroup &&
                                                    this.state.filterAgeGroup.length >= 1 &&
                                                    this.state.filterAgeGroup.map((age) => (
                                                        <span
                                                            data-val={age}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterAgeGroup")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={age}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(e, "filterAgeGroup")
                                                                }
                                                            />{" "}
                                                            {SC.convert(age)}
                                                        </span>
                                                    ))}
                                                {!(Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes')) && this.state.filterType2 &&
                                                    this.state.filterType2.length >= 1 &&
                                                    this.state.filterType2.map((x) => (
                                                        <span
                                                            data-val={x}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterType2")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={x}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(e, "filterType2")
                                                                }
                                                            />{" "}
                                                            {SC.convert(x)}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group filter-group">
                                        <div
                                            className="filter-veil"
                                            onClick={() =>
                                                this.setState({
                                                    openFilterPop: "ingredients",
                                                })
                                            }
                                        ></div>
                                        <div className="filter-wrapper">
                                            <label
                                                onClick={() =>
                                                    this.setState({
                                                        openFilterPop: "ingredients",
                                                    })
                                                }
                                            >
                                                Produktinhalt
                                            </label>
                                            {((this.state.filterDeclaration &&
                                                this.state.filterDeclaration.length >= 1) ||
                                                (this.state.filterAttributes &&
                                                    this.state.filterAttributes.length >= 1) ||
                                                this.state.filterTechnologicalAdditives ||
                                                this.state.filterPhysiologicalAdditives ||
                                                (this.state.filterBinder && this.state.filterBinder.length >= 1) ||
                                                (this.state.filterAnimalShare &&
                                                    this.state.filterAnimalShare.length >= 1) ||
                                                (this.state.filterAnimalShareDriedOne &&
                                                    this.state.filterAnimalShareDriedOne.length >= 1) ||
                                                (this.state.filterAnimalShareDriedTwo &&
                                                    this.state.filterAnimalShareDriedTwo.length >= 1) ||
                                                (this.state.filterAnimalComponents &&
                                                    this.state.filterAnimalComponents.length >= 1) ||
                                                (this.state.filterElements &&
                                                    this.state.filterElements.length >= 1) ||
                                                (this.state.filterProcessings &&
                                                    this.state.filterProcessings.length >= 1) ||
                                                (Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes')) && (this.state.filterType2 && this.state.filterType2.length >= 1)) && (
                                                    <span
                                                        className="add-filters-btn"
                                                        onClick={() => {
                                                            this.setState({
                                                                filterAttributes: [],
                                                            });
                                                            this.setState({
                                                                filterDeclaration: [],
                                                            });
                                                            this.setState({
                                                                filterAnimalShare: [],
                                                            });
                                                            this.setState({
                                                                filterAnimalShareDriedOne: [],
                                                            });
                                                            this.setState({
                                                                filterAnimalShareDriedTwo: [],
                                                            });
                                                            this.setState({
                                                                filterAnimalComponents: [],
                                                            });
                                                            this.setState({
                                                                filterBinder: [],
                                                            });
                                                            this.setState({
                                                                filterElements: [],
                                                            });
                                                            this.setState({
                                                                filterProcessings: [],
                                                            });
                                                            this.setState({
                                                                filterTechnologicalAdditives: false,
                                                            });
                                                            this.setState({
                                                                filterPhysiologicalAdditives: false,
                                                            });

                                                            if (Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes')) {
                                                                this.setState({
                                                                    filterType2: [],
                                                                });
                                                            }

                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close />
                                                    </span>
                                                )}
                                            <div className="active-filters">
                                                {(Array.isArray(this.state.filterType1) && this.state.filterType1.length == 1 && this.state.filterType1.includes('flakes')) && this.state.filterType2 &&
                                                    this.state.filterType2.length >= 1 &&
                                                    this.state.filterType2.map((x) => (
                                                        <span
                                                            data-val={x}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterType2")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={x}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(e, "filterType2")
                                                                }
                                                            />{" "}
                                                            {SC.convert(x)}
                                                        </span>
                                                    ))}
                                                {this.state.filterDeclaration &&
                                                    this.state.filterDeclaration.length >= 1 &&
                                                    this.state.filterDeclaration.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterDeclaration")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(e, "filterDeclaration")
                                                                }
                                                            />{" "}
                                                            {SC.convert(d)}
                                                        </span>
                                                    ))}
                                                {this.state.filterAttributes &&
                                                    this.state.filterAttributes.length >= 1 &&
                                                    this.state.filterAttributes.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterAttributes")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(e, "filterAttributes")
                                                                }
                                                            />{" "}
                                                            {SC.convert(d)}
                                                        </span>
                                                    ))}
                                                {this.state.filterTechnologicalAdditives && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterTechnologicalAdditives: !this.state
                                                                    .filterTechnologicalAdditives,
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterTechnologicalAdditives: !this.state
                                                                        .filterTechnologicalAdditives,
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        ohne techn. Zusatzstoffe
                                                    </span>
                                                )}
                                                {this.state.filterPhysiologicalAdditives && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterPhysiologicalAdditives: !this.state
                                                                    .filterPhysiologicalAdditives,
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterPhysiologicalAdditives: !this.state
                                                                        .filterPhysiologicalAdditives,
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        ohne ern. Zusatzstoffe
                                                    </span>
                                                )}
                                                {this.state.filterBinder && this.state.filterBinder.length >= 1 && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterBinder: [],
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterBinder: [],
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Bindemittelverdacht &gt;= 75%
                                                    </span>
                                                )}
                                                {this.state.filterAnimalShare &&
                                                    this.state.filterAnimalShare.length >= 1 &&
                                                    this.state.filterAnimalShare.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterAnimalShare")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(e, "filterAnimalShare")
                                                                }
                                                            />{" "}
                                                            Tierischer Anteil {SC.convertAnimalShare(d)}
                                                        </span>
                                                    ))}
                                                {this.state.filterAnimalShareDriedOne &&
                                                    this.state.filterAnimalShareDriedOne.length >= 1 &&
                                                    this.state.filterAnimalShareDriedOne.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(
                                                                    e,
                                                                    "filterAnimalShareDriedOne"
                                                                )
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(
                                                                        e,
                                                                        "filterAnimalShareDriedOne"
                                                                    )
                                                                }
                                                            />{" "}
                                                            Tierischer Trockenfleisch-Anteil{" "}
                                                            {SC.convertAnimalShareDried(d)}
                                                        </span>
                                                    ))}
                                                {this.state.filterAnimalShareDriedTwo &&
                                                    this.state.filterAnimalShareDriedTwo.length >= 1 &&
                                                    this.state.filterAnimalShareDriedTwo.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(
                                                                    e,
                                                                    "filterAnimalShareDriedTwo"
                                                                )
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(
                                                                        e,
                                                                        "filterAnimalShareDriedTwo"
                                                                    )
                                                                }
                                                            />{" "}
                                                            Tierischer Frischfleisch-Anteil{" "}
                                                            {SC.convertAnimalShareDried(d)}
                                                        </span>
                                                    ))}
                                                {this.state.filterAnimalComponents &&
                                                    this.state.filterAnimalComponents.length >= 1 &&
                                                    this.state.filterAnimalComponents.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterAnimalComponents")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(
                                                                        e,
                                                                        "filterAnimalComponents"
                                                                    )
                                                                }
                                                            />{" "}
                                                            {SC.convert(d)}
                                                        </span>
                                                    ))}
                                                {this.state.filterElements &&
                                                    this.state.filterElements.length >= 1 &&
                                                    this.state.filterElements.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterElements")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(
                                                                        e,
                                                                        "filterElements"
                                                                    )
                                                                }
                                                            />{" "}
                                                            {SC.convert(d)}
                                                        </span>
                                                    ))}
                                                {this.state.filterProcessings &&
                                                    this.state.filterProcessings.length >= 1 &&
                                                    this.state.filterProcessings.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterProcessings")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(
                                                                        e,
                                                                        "filterProcessings"
                                                                    )
                                                                }
                                                            />{" "}
                                                            {SC.convert(d).replace('& Co.', '')}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group filter-group">
                                        <div
                                            className="filter-veil"
                                            onClick={() =>
                                                this.setState({
                                                    openFilterPop: "packaging",
                                                })
                                            }
                                        ></div>
                                        <div className="filter-wrapper">
                                            <label
                                                onClick={() =>
                                                    this.setState({
                                                        openFilterPop: "packaging",
                                                    })
                                                }
                                            >
                                                Verpackung
                                            </label>
                                            {((this.state.filterPackaging && this.state.filterPackaging.length >= 1) ||
                                                (this.state.filterPackagingSize &&
                                                    this.state.filterPackagingSize.length >= 1)) && (
                                                    <span
                                                        className="add-filters-btn"
                                                        onClick={() => {
                                                            this.setState({
                                                                filterPackaging: [],
                                                            });
                                                            this.setState({
                                                                filterPackagingSize: [],
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close />
                                                    </span>
                                                )}
                                            <div className="active-filters">
                                                {this.state.filterPackagingSize &&
                                                    this.state.filterPackagingSize.length >= 1 &&
                                                    this.state.filterPackagingSize.map((p) => (
                                                        <span
                                                            data-val={p}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterPackagingSize")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={p}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(e, "filterPackagingSize")
                                                                }
                                                            />{" "}
                                                            {p.split('||')[0]}
                                                        </span>
                                                    ))}
                                                {this.state.filterPackaging &&
                                                    this.state.filterPackaging.length >= 1 &&
                                                    this.state.filterPackaging.map((p) => (
                                                        <span
                                                            data-val={p}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterPackaging")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={p}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(e, "filterPackaging")
                                                                }
                                                            />{" "}
                                                            {SC.convert(p)}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group filter-group">
                                        <div
                                            className="filter-veil"
                                            onClick={() =>
                                                this.setState({
                                                    openFilterPop: "origin",
                                                })
                                            }
                                        ></div>
                                        <div className="filter-wrapper">
                                            <label
                                                onClick={() =>
                                                    this.setState({
                                                        openFilterPop: "origin",
                                                    })
                                                }
                                            >
                                                Marke & Herkunft
                                            </label>
                                            {((this.state.filterBrand && this.state.filterBrand.length >= 1) ||
                                                (this.state.filterProducers &&
                                                    this.state.filterProducers.length >= 1) ||
                                                (this.state.filterCountriesOfProduction &&
                                                    this.state.filterCountriesOfProduction.length >= 1) ||
                                                (this.state.filterCountriesOfDelivery &&
                                                    this.state.filterCountriesOfDelivery.length >= 1)) && (
                                                    <span
                                                        className="add-filters-btn"
                                                        onClick={() => {
                                                            this.setState({
                                                                filterProducers: [],
                                                            });
                                                            this.setState({
                                                                filterBrand: [],
                                                            });
                                                            this.setState({
                                                                filterCountriesOfProduction: [],
                                                            });
                                                            this.setState({
                                                                filterCountriesOfDelivery: [],
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close />
                                                    </span>
                                                )}
                                            <div className="active-filters">
                                                {this.state.filterBrand &&
                                                    this.state.filterBrand.length >= 1 &&
                                                    this.state.filterBrand.map((b) => {
                                                        if (
                                                            this.state.filters.availableBrands &&
                                                            this.state.filters.availableBrands.length >= 1
                                                        ) {
                                                            let result = this.state.filters.availableBrands.filter(
                                                                (obj) => {
                                                                    return obj._id == b;
                                                                }
                                                            );
                                                            if (result[0])
                                                                return (
                                                                    <span
                                                                        data-val={result[0]._id}
                                                                        onClick={(e) =>
                                                                            this.removeItemFromFilters(e, "filterBrand")
                                                                        }
                                                                    >
                                                                        <Close
                                                                            data-val={result[0]._id}
                                                                            onClick={(e) =>
                                                                                this.removeItemFromFilters(
                                                                                    e,
                                                                                    "filterBrand"
                                                                                )
                                                                            }
                                                                        />{" "}
                                                                        {result[0].name}
                                                                    </span>
                                                                );
                                                        }
                                                    })}
                                                {this.state.filterProducers &&
                                                    this.state.filterProducers.length >= 1 &&
                                                    this.state.filterProducers.map((b) => {
                                                        if (
                                                            this.state.filters.availableProducers &&
                                                            this.state.filters.availableProducers.length >= 1
                                                        ) {
                                                            let result = this.state.filters.availableProducers.filter(
                                                                (obj) => {
                                                                    return obj._id == b;
                                                                }
                                                            );
                                                            if (result[0])
                                                                return (
                                                                    <span
                                                                        data-val={result[0]._id}
                                                                        onClick={(e) =>
                                                                            this.removeItemFromFilters(
                                                                                e,
                                                                                "filterProducers"
                                                                            )
                                                                        }
                                                                    >
                                                                        <Close
                                                                            data-val={result[0]._id}
                                                                            onClick={(e) =>
                                                                                this.removeItemFromFilters(
                                                                                    e,
                                                                                    "filterProducers"
                                                                                )
                                                                            }
                                                                        />{" "}
                                                                        {result[0].name}
                                                                    </span>
                                                                );
                                                        }
                                                    })}
                                                {this.state.filterCountriesOfProduction &&
                                                    this.state.filterCountriesOfProduction.length >= 1 &&
                                                    this.state.filterCountriesOfProduction.map((p) => (
                                                        <span
                                                            data-val={p}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(
                                                                    e,
                                                                    "filterCountriesOfProduction"
                                                                )
                                                            }
                                                        >
                                                            <Close
                                                                data-val={p}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(
                                                                        e,
                                                                        "filterCountriesOfProduction"
                                                                    )
                                                                }
                                                            />{" "}
                                                            Herkunftsland: {p}
                                                        </span>
                                                    ))}
                                                {this.state.filterCountriesOfDelivery &&
                                                    this.state.filterCountriesOfDelivery.length >= 1 &&
                                                    this.state.filterCountriesOfDelivery.map((p) => (
                                                        <span
                                                            data-val={p}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(
                                                                    e,
                                                                    "filterCountriesOfDelivery"
                                                                )
                                                            }
                                                        >
                                                            <Close
                                                                data-val={p}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(
                                                                        e,
                                                                        "filterCountriesOfDelivery"
                                                                    )
                                                                }
                                                            />{" "}
                                                            Zustellung: {SC.convert(p)}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group filter-group">
                                        <div
                                            className="filter-veil"
                                            onClick={() =>
                                                this.setState({
                                                    openFilterPop: "diseases",
                                                })
                                            }
                                        ></div>
                                        <div className="filter-wrapper">
                                            <label
                                                onClick={() =>
                                                    this.setState({
                                                        openFilterPop: "diseases",
                                                    })
                                                }
                                            >
                                                Gesundheit
                                            </label>
                                            {this.state.filterDiseases &&
                                                this.state.filterDiseases.length >= 1 && (
                                                    <span
                                                        className="add-filters-btn"
                                                        onClick={() => {
                                                            this.setState({
                                                                filterDiseases: [],
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close />
                                                    </span>
                                                )}
                                            <div className="active-filters">
                                                {this.state.filterDiseases &&
                                                    this.state.filterDiseases.length >= 1 &&
                                                    this.state.filterDiseases.map((d) => (
                                                        <span
                                                            data-val={d}
                                                            onClick={(e) =>
                                                                this.removeItemFromFilters(e, "filterDiseases")
                                                            }
                                                        >
                                                            <Close
                                                                data-val={d}
                                                                onClick={(e) =>
                                                                    this.removeItemFromFilters(
                                                                        e,
                                                                        "filterDiseases"
                                                                    )
                                                                }
                                                            />{" "}
                                                            {SC.convert(d)}
                                                        </span>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group filter-group">
                                        <div
                                            className="filter-veil"
                                            onClick={() =>
                                                this.setState({
                                                    openFilterPop: "weender",
                                                })
                                            }
                                        ></div>
                                        <div className="filter-wrapper">
                                            <label
                                                onClick={() =>
                                                    this.setState({
                                                        openFilterPop: "weender",
                                                    })
                                                }
                                            >
                                                Weender Analyse
                                            </label>
                                            {(this.state.filterProteinsMin ||
                                                this.state.filterProteinsMax ||
                                                this.state.filterFatsMin ||
                                                this.state.filterFatsMax ||
                                                this.state.filterFibersMin ||
                                                this.state.filterFibersMax ||
                                                this.state.filterAshMin ||
                                                this.state.filterAshMax ||
                                                this.state.filterMoistureMin ||
                                                this.state.filterMoistureMax ||
                                                this.state.filterNfe ||
                                                this.state.filterNfeTM) && (
                                                    <span
                                                        className="add-filters-btn"
                                                        onClick={() => {
                                                            this.setState({
                                                                filterProteinsMin: "",
                                                            });
                                                            this.setState({
                                                                filterProteinsMax: "",
                                                            });
                                                            this.setState({
                                                                filterFibersMin: "",
                                                            });
                                                            this.setState({
                                                                filterFibersMax: "",
                                                            });
                                                            this.setState({
                                                                filterFatsMin: "",
                                                            });
                                                            this.setState({
                                                                filterFatsMax: "",
                                                            });
                                                            this.setState({
                                                                filterAshMin: "",
                                                            });
                                                            this.setState({
                                                                filterAshMax: "",
                                                            });
                                                            this.setState({
                                                                filterMoistureMin: "",
                                                            });
                                                            this.setState({
                                                                filterMoistureMax: "",
                                                            });
                                                            this.setState({
                                                                filterNfe: "",
                                                            });
                                                            this.setState({
                                                                filterNfeTM: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close />
                                                    </span>
                                                )}
                                            <div className="active-filters">
                                                {this.state.filterProteinsMin && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterProteinsMin: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterProteinsMin: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Protein Min: {this.state.filterProteinsMin}g
                                                    </span>
                                                )}
                                                {this.state.filterProteinsMax && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterProteinsMax: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterProteinsMax: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Protein Max: {this.state.filterProteinsMax}g
                                                    </span>
                                                )}
                                                {this.state.filterFatsMin && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterFatsMin: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterFatsMin: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Rohfett Min: {this.state.filterFatsMin}g
                                                    </span>
                                                )}
                                                {this.state.filterFatsMax && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterFatsMax: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterFatsMax: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Rohfett Max: {this.state.filterFatsMax}g
                                                    </span>
                                                )}
                                                {this.state.filterFibersMin && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterFibersMin: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterFibersMin: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Rohfaser Min: {this.state.filterFibersMin}g
                                                    </span>
                                                )}
                                                {this.state.filterFibersMax && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterFibersMax: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterFibersMax: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Rohfaser Max: {this.state.filterFibersMax}g
                                                    </span>
                                                )}
                                                {this.state.filterAshMin && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterAshMin: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterAshMin: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Rohasche Min: {this.state.filterAshMin}g
                                                    </span>
                                                )}
                                                {this.state.filterAshMax && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterAshMax: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterAshMax: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Rohasche Max: {this.state.filterAshMax}g
                                                    </span>
                                                )}
                                                {this.state.filterMoistureMin && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterMoistureMin: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterMoistureMin: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Feuchtigkeit Min: {this.state.filterMoistureMin}g
                                                    </span>
                                                )}
                                                {this.state.filterMoistureMax && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterMoistureMax: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterMoistureMax: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Feuchtigkeit Max: {this.state.filterMoistureMax}g
                                                    </span>
                                                )}
                                                {this.state.filterNfe && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterNfe: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterNfe: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Nfe-Gehalt Max: {this.state.filterNfe}g
                                                    </span>
                                                )}
                                                {this.state.filterNfeTM && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterNfeTM: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterNfeTM: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Nfe-Gehalt (TM) Max: {this.state.filterNfeTM}g
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group filter-group">
                                        <div
                                            className="filter-veil"
                                            onClick={() =>
                                                this.setState({
                                                    openFilterPop: "weender",
                                                })
                                            }
                                        ></div>
                                        <div className="filter-wrapper">
                                            <label
                                                onClick={() =>
                                                    this.setState({
                                                        openFilterPop: "baseprice",
                                                    })
                                                }
                                            >
                                                Basispreis
                                            </label>
                                            {(this.state.filterBasePriceMin || this.state.filterBasePriceMax) && (
                                                <span
                                                    className="add-filters-btn"
                                                    onClick={() => {
                                                        this.setState({
                                                            filterBasePriceMin: "",
                                                        });
                                                        this.setState({
                                                            filterBasePriceMax: "",
                                                        });
                                                        this.onFilterSubmitDebounced();
                                                    }}
                                                >
                                                    <Close />
                                                </span>
                                            )}
                                            <div className="active-filters">
                                                {this.state.filterBasePriceMin && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterBasePriceMin: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterBasePriceMin: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Basispreis Min: {this.state.filterBasePriceMin}€
                                                    </span>
                                                )}
                                                {this.state.filterBasePriceMax && (
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                filterBasePriceMax: "",
                                                            });
                                                            this.onFilterSubmitDebounced();
                                                        }}
                                                    >
                                                        <Close
                                                            onClick={() => {
                                                                this.setState({
                                                                    filterBasePriceMax: "",
                                                                });
                                                                this.onFilterSubmitDebounced();
                                                            }}
                                                        />{" "}
                                                        Basispreis Max: {this.state.filterBasePriceMax}€
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/produkte?animal=dog&currentPage=1&filterOption=1&productsPerPage=12&sortBy=brand&type1=wet" id="reset-filters">
                                        Filter zurücksetzen
                                    </Link>
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8">
                            <div id="products-section">
                                {this.state.isLoadingProducts && <LoadingOverlay message="Suche Produkte..." />}
                                <div className="row mb-30 mt-30-md" id="view-selection">
                                    <div className="col-sm-4 col-12">
                                        <label for="sortBy">Sortieren nach</label>
                                        <div className="styled-select">
                                            <select name="sortBy" onChange={this.handleSortByChange}>
                                                <option value="price_asc" selected={this.state.sortBy == "price_asc"}>
                                                    Basispreis aufsteigend
                                                </option>
                                                <option value="price_desc" selected={this.state.sortBy == "price_desc"}>
                                                    Basispreis absteigend
                                                </option>
                                                <option
                                                    value="rating_dec"
                                                    selected={this.state.sortBy == "rating_dec"}
                                                >
                                                    Bewertung absteigend
                                                </option>
                                                <option value="fats_inc" selected={this.state.sortBy == "fats_inc"}>
                                                    Fett aufsteigend
                                                </option>
                                                <option value="brand" selected={this.state.sortBy == "brand"}>
                                                    Marke A-Z
                                                </option>
                                                <option value="nfeTM_inc" selected={this.state.sortBy == "nfeTM_inc"}>
                                                    Nfe Gehalt TM aufsteigend
                                                </option>
                                                <option
                                                    value="proteins_inc"
                                                    selected={this.state.sortBy == "proteins_inc"}
                                                >
                                                    Protein aufsteigend
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 order-sm-2 col-12 float-right">
                                        <label for="productsPerPage" className="second-label">
                                            Produkte pro Seite
                                        </label>
                                        <div className="styled-select">
                                            <select name="productsPerPage" onChange={this.handlePPPChange}>
                                                <option value="12" selected={this.state.productsPerPage == 12}>
                                                    12 Produkte
                                                </option>
                                                <option value="24" selected={this.state.productsPerPage == 24}>
                                                    24 Produkte
                                                </option>
                                                <option value="36" selected={this.state.productsPerPage == 36}>
                                                    36 Produkte
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-12 products-count" style={{fontSize: '90%'}}>
                                        {this.state.isLoadingProducts ? (
                                            "Lade Produkte..."
                                        ) : (
                                            <React.Fragment>
                                                {this.state.nOfProductsTotal ? this.state.nOfProductsTotal : "0"}{" "}
                                                Produkte{this.state.searchTerm ? (' für "' + this.state.searchTerm + '"') : null}
                                                {this.state.searchTerm && (<Link to="/produkte?animal=dog&currentPage=1&filterOption=1&productsPerPage=12&sortBy=brand&type1=wet">&nbsp;<Close style={{color: 'red', cursor:'pointer', fontSize: '1.1em', position: 'relative', bottom: '3px'}} /></Link>)}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                <div className="row" id="product-search-results">
                                    {this.state.errorMessage && (
                                        <div className="col-12 nopadding">
                                            <p className="alert alert-danger">{this.state.errorMessage}</p>
                                        </div>
                                    )}
                                    {this.state.products.map((p) => {
                                        let styles = {
                                            backgroundColor: "#eee",
                                        };
                                        p.imageFront
                                            ? (styles.backgroundImage =
                                                "url('https://bucket-mfc-wallride67.storage.googleapis.com/" +
                                                p.imageFront.toLowerCase() +
                                                "')")
                                            : (styles.backgroundImage =
                                                "url('https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png')");

                                        return (
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 nopadding" key={p._id}>
                                                <ItemCard
                                                    product={p}
                                                    handleCompareListChange={this.props.handleCompareListChange}
                                                    handleFavoritesListChange={this.props.handleFavoritesListChange}
                                                    compareList={this.props.compareList}
                                                    favoritesList={this.props.favoritesList}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className="col-12 nopadding" style={{ zIndex: "888" }}>
                                        {this.pagination()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.openFilterPop && (
                        <div className="filter-pop animated fadeIn">
                            <div className="veil" onClick={() => {this.setState({ openFilterPop: "" }, this.state.hasFilterChanges ? this.onFilterSubmit() : undefined)}}></div>
                            <div className={popClasses}>
                                <span
                                    onClick={() => this.setState({ openFilterPop: "" }, this.state.hasFilterChanges ? this.onFilterSubmit() : undefined)}
                                    style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        opacity: ".6",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Close />
                                </span>
                                {this.state.openFilterPop == "age" && this.filterPopAge()}
                                {this.state.openFilterPop == "ingredients" && this.filterPopIngredients(this.state.filterType1)}
                                {this.state.openFilterPop == "packaging" && this.filterPopPackaging()}
                                {this.state.openFilterPop == "origin" && this.filterPopOrigin(this.state.filterType1)}
                                {this.state.openFilterPop == "weender" && this.filterPopWeender()}
                                {this.state.openFilterPop == "diseases" && this.filterPopDiseases()}
                                {this.state.openFilterPop == "baseprice" && this.filterPopBasePrice()}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(Products);
