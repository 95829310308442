import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ads from './../img/mfc-als-app.png';

class MFCApp extends Component {
    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        return (
            <div id="about-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / MyFeedingCheck als App verwenden</span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <h1 className="color-primary">Du möchtest MyFeedingCheck als App verwenden?</h1>
                            <p>
                                Ganz einfach ohne App-/Playstore.
                                                <br /><br />
                                Schritt für Schritt Erklärung:
                                <br /><br />
                                <span className="strong">Android Betriebssystem</span>
                                <ul>
                                    <li>auf dem Smartphone www.myfeedingcheck.com aufrufen</li>
                                    <li>rechts neben der Adressleiste das drei Punktesymbole anklicken</li>
                                    <li>"Zum Startbildschirm hinzufügen"</li>
                                    <li>und fertig!</li>
                                </ul>
                                <span className="strong">iOS Betriebssystem</span>
                                <ul className="mb-0">
                                    <li>auf dem Smartphone www.myfeedingcheck.com aufrufen</li>
                                    <li>am Seitenende in der Mitte Symbol "Teilen" verwenden</li>
                                    <li>"Zum Home-Bildschirm hinzufügen"</li>
                                    <li>und fertig!</li>
                                </ul>
                            </p>
                        </div>
                        <div className="col-lg-5 text-center">
                            <img src={Ads} alt="Werben auf MyFeedingCheck" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MFCApp;
