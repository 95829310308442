import React, { Component } from 'react';
import { BrowserRouter as Router, Link, NavLink, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Auth from './../modules/Auth';
import noImage from '../img/no-image.png';
import { CancelPresentation, FavoriteBorder, NotInterested } from '@material-ui/icons';
import { copyFileSync } from 'fs';
import SC from "./../modules/StringConverter";

let appDomain = "http://localhost:5000";

class Profile extends Component {
    state = {
        errorMsg: [],
        displayErrorMsg: false,
        successMsg: [],
        displaySuccessMsg: false,
        passErrorMsg: [],
        passDisplayErrorMsg: false,
        passSuccessMsg: [],
        passDisplaySuccessMsg: false,
        username: "",
        email: "",
        createdAt: "",
        _id: "",
        newEmail: "",
        newEmailConfirm: "",
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        redirectToLogin: false,
        currentPage: 0,
        favoritesList: [],
        negFavoritesList: [],
        listFetchingError: ""
    }

    constructor(props) {
        super(props);

        this.handleChangeEmailForm = this.handleChangeEmailForm.bind(this);
        this.handleChangePasswordForm = this.handleChangePasswordForm.bind(this);
        this.handleDeletionFromList = this.handleDeletionFromList.bind(this);
        this.handleProfileDeletion = this.handleProfileDeletion.bind(this);
    }

    componentDidMount() {
        try {
            Auth.getUserFromToken().then(result => {
                if (result && result.username && result.email && result._id) {
                    this.setState({ username: result.username });
                    this.setState({ email: result.email });
                    this.setState({ createdAt: result.createdAt.substr(8, 2) + '.' + result.createdAt.substr(5, 2) + '.' + result.createdAt.substr(0, 4) });
                    this.setState({ _id: result._id });

                    this.fetchProductLists();
                } else {
                    Auth.deauthenticateUser();
                    setTimeout(() => {
                        this.setState({ redirectToLogin: true });
                        this.props.handleAuthenticationChange(false);
                    }, 500);
                }
            });
        } catch (e) {
            this.props.handleAuthenticationChange(false);
            this.setState({ redirectToLogin: true });
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    fetchProductLists = userId => {
        this.setState({ listFetchingError: "" });

        let token = Auth.getToken();

        fetch('/api/product-lists', { headers: { token: token } })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    this.setState({ favoritesList: data.favorites });
                    this.setState({ negFavoritesList: data.negFavorites });
                } else {
                    this.setState({ favoritesList: [] });
                    this.setState({ negFavoritesList: [] });
                    this.setState({ listFetchingError: "Leider ist ein Fehler beim Abrufen der Produktlisten aufgetreten. Bitte versuche es später erneut." });
                }
            })
            .catch(err => {
                this.setState({ favoritesList: [] });
                this.setState({ negFavoritesList: [] });
                this.setState({ listFetchingError: "Leider ist ein Fehler beim Abrufen der Produktlisten aufgetreten. Bitte versuche es später erneut." });
            });
    }

    handleChangeEmailForm(event) {
        event.preventDefault();

        let newEmail = this.state.newEmail.trim();
        let newEmailConfirm = this.state.newEmailConfirm.trim();

        this.state.errorMsg = [];
        this.setState({ displayErrorMsg: false });
        this.state.successMsg = [];
        this.setState({ displaySuccessMsg: false });

        if (newEmail.length <= 0) {
            this.state.errorMsg.push("Bitte geben Sie eine neue E-Mail Adresse ein.");
        }
        if (newEmailConfirm.length <= 0) {
            this.state.errorMsg.push("Bitte E-Mail Adresse bestätigen.");
        }
        if (newEmailConfirm !== newEmail) {
            this.state.errorMsg.push("Die E-Mail Adressen sind nicht identisch.");
        }

        if (this.state.errorMsg.length > 0) {
            this.setState({ displayErrorMsg: true });
        } else {
            this.setState({ displayErrorMsg: false });

            axios.post('auth/update-email', {
                userId: this.state._id,
                newEmail: newEmail
            })
                .then(response => {
                    if (response.data.success === true) {
                        this.state.successMsg.push("E-Mail Adresse erfolgreich geändert.");
                        this.setState({ email: response.data.user.email });
                        this.setState({ displaySuccessMsg: true });
                    } else {
                        this.state.errorMsg.push("Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
                        this.setState({ displayErrorMsg: true });
                    }

                })
                .catch(error => {
                    this.setState({ errorMsg: ["Ein Fehler ist aufgetreten! Möglicherweise wird die E-Mail Adresse schon von einem anderen Nutzer verwendet."] });
                    this.setState({ displayErrorMsg: true });
                });
        }
    }

    handleChangePasswordForm(event) {
        event.preventDefault();

        let oldPassword = this.state.oldPassword.trim();
        let newPassword = this.state.newPassword.trim();
        let newPasswordConfirm = this.state.newPasswordConfirm.trim();

        this.state.passErrorMsg = [];
        this.setState({ passDisplayErrorMsg: false });
        this.state.passSuccessMsg = [];
        this.setState({ passDisplaySuccessMsg: false });

        if (newPassword.length <= 0) {
            this.state.passErrorMsg.push("Bitte geben Sie eine neues Passwort ein.");
        }
        if (newPasswordConfirm.length <= 0) {
            this.state.passErrorMsg.push("Bitte das Passwort bestätigen.");
        }
        if (newPasswordConfirm !== newPassword) {
            this.state.passErrorMsg.push("Die Passwörter sind nicht identisch.");
        }

        if (this.state.passErrorMsg.length > 0) {
            this.setState({ passDisplayErrorMsg: true });
        } else {
            this.setState({ passDisplayErrorMsg: false });

            axios.post('auth/update-password', {
                userId: this.state._id,
                oldPassword: oldPassword,
                newPassword: newPassword,
                newPasswordConfirm: newPasswordConfirm
            })
                .then(response => {
                    if (response.data.success === true) {
                        this.state.passSuccessMsg.push(response.data.message);
                        this.setState({ passDisplaySuccessMsg: true });
                    } else {
                        this.state.passErrorMsg.push(response.data.message);
                        this.setState({ passDisplayErrorMsg: true });
                    }

                })
                .catch(error => {
                    this.setState({ passErrorMsg: ["Ein Fehler ist aufgetreten und Ihr Passwort wurde nicht geändert!"] });
                    this.setState({ passDisplayErrorMsg: true });
                });
        }
    }

    async handleProfileDeletion() {
        let m = window.confirm('Bist du sicher, dass du dein Konto schließen möchtest?');
        if (m) {
            let token = await Auth.getToken();
            axios.post('/api/delete-user', {}, { headers: { token: token } })
                .then(res => {
                    let { success } = res.data;

                    if (success) {
                        alert('Benutzer gelöscht.')
                        Auth.deauthenticateUser();
                        setTimeout(() => {
                            this.setState({ redirectToLogin: true });
                            this.props.handleAuthenticationChange(false);
                        }, 500);
                    } else {
                        alert('Es gab einen Fehler. Bitte versuche es erneut oder kontaktiere uns.');
                    }
                })
                .catch(err => {
                    console.log(err);
                    alert('Es gab einen Fehler. Bitte versuche es erneut oder kontaktiere uns.');
                });
        };
    }


    profileSettings = () => {
        if (this.state.currentPage === 1) {
            return (
                <div className="row" id="settings-page">
                    <div className="col-lg-6 mb-20">
                        <div className="card card-body">
                            <p><strong className="color-primary">Hallo, {this.state.username}!</strong><br />Hier kannst Du Deine Kontodaten ansehen und bearbeiten.</p>
                            <p><strong>Benutzername</strong>: {this.state.username}<br />
                                <strong>E-Mail Adresse</strong>: {this.state.email}<br />
                                <br />
                                Angemeldet seit: {this.state.createdAt}</p>
                            <span className="btn btn-danger" onClick={this.handleProfileDeletion}>Konto schließen</span>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <form method="post" onSubmit={this.handleChangeEmailForm} className="card card-body">
                            {this.state.displayErrorMsg && (
                                <p className="alert alert-danger">{this.state.errorMsg[0]}</p>
                            )}
                            {this.state.displaySuccessMsg && (
                                <p className="alert alert-success">{this.state.successMsg[0]}</p>
                            )}
                            <div class="form-group">
                                <label for="change-email">E-Mail Adresse ändern</label>
                                <input type="email" className="form-control" name="change-email" id="change-email" placeholder="Neue E-Mail" required onChange={e => this.setState({ newEmail: e.target.value })}></input>
                            </div>
                            <div class="form-group">
                                <label for="change-email-confirm">E-Mail Adresse bestätigen</label>
                                <input type="email" className="form-control" name="change-email-confirm" id="change-email-confirm" placeholder="E-Mail bestätigen" required onChange={e => this.setState({ newEmailConfirm: e.target.value })}></input>
                            </div>
                            <input type="submit" value="E-Mail Adresse ändern" className="btn btn-primary"></input>
                        </form>
                        <form method="post" onSubmit={this.handleChangePasswordForm} className="card card-body mt-30">
                            {this.state.passDisplayErrorMsg && (
                                <p className="alert alert-danger">{this.state.passErrorMsg[0]}</p>
                            )}
                            {this.state.passDisplaySuccessMsg && (
                                <p className="alert alert-success">{this.state.passSuccessMsg[0]}</p>
                            )}
                            <div class="form-group">
                                <label for="old-password">Altes Passwort</label>
                                <input type="password" className="form-control" name="old-password" id="old-password" placeholder="Altes Passwort" required onChange={e => this.setState({ oldPassword: e.target.value })}></input>
                            </div>
                            <div class="form-group">
                                <label for="change-password">Neues Passwort</label>
                                <input type="password" className="form-control" name="change-password" id="change-password" placeholder="Neues Passwort" required onChange={e => this.setState({ newPassword: e.target.value })}></input>
                            </div>
                            <div class="form-group">
                                <label for="change-password-confirm">Neues Passwort bestätigen</label>
                                <input type="password" className="form-control" name="change-password-confirm" id="change-password-confirm" placeholder="Neues Passwort bestätigen" required onChange={e => this.setState({ newPasswordConfirm: e.target.value })}></input>
                            </div>
                            <input type="submit" value="Passwort ändern" className="btn btn-primary"></input>
                        </form>
                    </div>
                </div>
            );
        }
        return null;
    }

    productLists = () => {
        if (this.state.currentPage === 0) {
            return (
                <div className="row" id="product-lists">
                    <div className="col-12 list-wrapper">
                        {this.state.listFetchingError && (
                            <p className="alert alert-danger">{this.state.listFetchingError}</p>
                        )}
                        <a className="list-button card card-body" data-toggle="collapse" href="#favoritesList" role="button" aria-expanded="false" aria-controls="favoritesList">
                            <div className="list-title"><FavoriteBorder />Favoriten</div>
                            <div className="list-info">{this.state.favoritesList.length} {this.state.favoritesList.length === 1 ? "Produkt" : "Produkte"}</div>
                        </a>
                        <div className="collapse" id="favoritesList">
                            <div class="list-results">
                                {this.state.favoritesList && this.state.favoritesList.length >= 1 ? (this.state.favoritesList.map(p => {

                                    // calculate best variant
                                    let { variants } = p;
                                    let bestVariant;
                                    if (variants && Array.isArray(variants) && variants.length > 0) {
                                        variants.forEach((v) => {
                                            let { unit, size, amount, price, is_for_sale, published, in_stock, updatedByBot, owner, merchant_name } = v;
                                            if (unit && size && amount && price && is_for_sale && published && in_stock && updatedByBot && (merchant_name !== 'b2b' || (owner && owner.status && owner.status === 'active'))) {
                                                if (unit == "kg") {
                                                    size = size * 1000;
                                                    unit = "g";
                                                } else if (unit == "l") {
                                                    size = size * 1000;
                                                    unit = "ml";
                                                }

                                                if (unit == "g" || unit == "ml") {
                                                    v.basePrice = (price / (amount * size)) * 100;
                                                }
                                            } else {
                                                v.basePrice = undefined
                                            }
                                        });

                                        variants = variants.sort((a, b) => {
                                            if (a.basePrice > b.basePrice) {
                                                return 1;
                                            } else if (a.basePrice < b.basePrice) {
                                                return -1;
                                            } else {
                                                return 0;
                                            }
                                        });

                                        bestVariant = variants[0];
                                    }

                                    return (
                                        <div className="item" key={p._id}>
                                            <div className="item-image-wrapper">
                                                {p.imageFront ? <img src={'https://bucket-mfc-wallride67.storage.googleapis.com/' + p.imageFront.toLowerCase()} /> : <img src={noImage} />}
                                            </div>
                                            <div className="item-title">
                                                <Link to={"/produkte/" + p.urlExtension}><span>{p.brand.name}</span><br />{p.series}</Link>
                                            </div>
                                            <div className="item-button">
                                                {bestVariant && bestVariant.link && bestVariant.size && bestVariant.price && bestVariant.unit && bestVariant.amount && bestVariant.merchant_name ? (
                                                    <div>
                                                        <a className="btn btn-light btn-sm" href={bestVariant.link} target="_blank">
                                                            zum besten Angebot
                                                            <br />
                                                            <small>{`${SC.merchant(bestVariant.merchant_name)} ${bestVariant.price
                                                                .toFixed(2)
                                                                .toString()
                                                                .replace(".", ",")}€ ${bestVariant.amount}x${bestVariant.size
                                                                    .toString()
                                                                    .replace(".", ",")}${bestVariant.unit}`}</small>
                                                        </a>
                                                    </div>
                                                ) : ' '}
                                            </div>
                                            <div className="item-delete-button">
                                                <p style={{marginBottom: 0}} data-list="favorites" data-product={p._id} onClick={this.handleDeletionFromList}><CancelPresentation /></p>
                                            </div>
                                        </div>
                                    );
                                })) : (<p className="text-center">Keine Produkte vorhanden.</p>)}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 list-wrapper">
                        <a className="list-button card card-body" data-toggle="collapse" href="#negFavoritesList" role="button" aria-expanded="false" aria-controls="negFavoritesList">
                            <div className="list-title"><NotInterested />Negative Erfahrungen</div>
                            <div className="list-info">{this.state.negFavoritesList.length} {this.state.negFavoritesList.length === 1 ? "Produkt" : "Produkte"}</div>
                        </a>
                        <div className="collapse" id="negFavoritesList">
                            <div class="list-results">
                                {this.state.negFavoritesList && this.state.negFavoritesList.length >= 1 ? (this.state.negFavoritesList.map(p => {

                                    // calculate best variant
                                    let { variants } = p;
                                    let bestVariant;
                                    if (variants && Array.isArray(variants) && variants.length > 0) {
                                        variants.forEach((v) => {
                                            let { unit, size, amount, price, is_for_sale, published, in_stock, updatedByBot } = v;
                                            if (unit && size && amount && price && is_for_sale && published && in_stock && updatedByBot) {
                                                if (unit == "kg") {
                                                    size = size * 1000;
                                                    unit = "g";
                                                } else if (unit == "l") {
                                                    size = size * 1000;
                                                    unit = "ml";
                                                }

                                                if (unit == "g" || unit == "ml") {
                                                    v.basePrice = (price / (amount * size)) * 100;
                                                }
                                            }
                                        });

                                        variants = variants.sort((a, b) => {
                                            if (a.basePrice > b.basePrice) {
                                                return 1;
                                            } else if (a.basePrice < b.basePrice) {
                                                return -1;
                                            } else {
                                                return 0;
                                            }
                                        });

                                        bestVariant = variants[0] && variants[0].basePrice ? variants[0] : undefined;
                                    }
                                    
                                    return (
                                        <div className="item" key={p._id}>
                                            <div className="item-image-wrapper">
                                                {p.imageFront ? <img src={'https://bucket-mfc-wallride67.storage.googleapis.com/' + p.imageFront.toLowerCase()} /> : <img src={noImage} />}
                                            </div>
                                            <div className="item-title">
                                                <Link to={"/produkte/" + p.urlExtension}><span>{p.brand.name}</span><br />{p.series}</Link>
                                            </div>
                                            <div className="item-button">
                                                {bestVariant && bestVariant.link && bestVariant.size && bestVariant.price && bestVariant.unit && bestVariant.amount && bestVariant.merchant_name ? (
                                                    <div>
                                                        <a className="btn btn-light btn-sm" href={bestVariant.link} target="_blank">
                                                            zum besten Angebot
                                                            <br />
                                                            <small>{`${SC.merchant(bestVariant.merchant_name)} ${bestVariant.price
                                                                .toFixed(2)
                                                                .toString()
                                                                .replace(".", ",")}€ ${bestVariant.amount}x${bestVariant.size
                                                                    .toString()
                                                                    .replace(".", ",")}${bestVariant.unit}`}</small>
                                                        </a>
                                                    </div>
                                                ) : ' '}
                                            </div>
                                            <div className="item-delete-button">
                                                <CancelPresentation data-list="negFavorites" data-product={p._id} onClick={this.handleDeletionFromList} />
                                            </div>
                                        </div>
                                    );
                                })) : (<p className="text-center">Keine Produkte vorhanden.</p>)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    handleDeletionFromList = e => {
        e.preventDefault();

        this.setState({ listFetchingError: "" });
        let { list, product } = e.currentTarget.dataset;

        let token = Auth.getToken();

        if (list && product && token) {

            axios.post('/api/delete-from-list', { listType: list, productId: product }, { headers: { token: token } })
                .then(res => {
                    let { data } = res;

                    if (!data.success) {
                        this.setState({ listFetchingError: "Beim Entfernen des Produktes ist ein Fehler aufgetreten. Bitte später erneut versuchen." });
                    }

                    this.fetchProductLists();

                    if (list == 'favorites') this.props.removeFromFavorites(product);
                })
                .catch(err => {
                    this.setState({ listFetchingError: "Beim Entfernen des Produktes ist ein Fehler aufgetreten. Bitte später erneut versuchen." });
                });

        } else if (!token) {
            Auth.deauthenticateUser();
            setTimeout(() => {
                this.setState({ redirectToLogin: true });
                this.props.handleAuthenticationChange(false);
            }, 500);
        }
    }

    render() {
        if (this.state.redirectToLogin === true) {
            return <Redirect to='/login' />
        }

        return (
            <div id="profile-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Profil</span>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">Dein Profil</h1>
                            <div className="sub-nav">
                                <span className={this.state.currentPage === 0 ? "active" : ""} onClick={e => this.setState({ currentPage: 0 })}>Produktlisten</span>
                                <span className={this.state.currentPage === 1 ? "active" : ""} onClick={e => this.setState({ currentPage: 1 })}>Konto</span>
                            </div>
                        </div>
                    </div>
                    {this.profileSettings()}
                    {this.productLists()}
                </div>
            </div>
        );
    }
}

export default Profile;
