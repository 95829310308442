import React, { Component } from 'react';
import { BrowserRouter as Router, Link, NavLink, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Auth from './../modules/Auth';
import Reaptcha from 'reaptcha';


class ResetPassword extends Component {
    state = {
        email: "",
        displayErrorMsg: false,
        errorMsg: [],
        displaySuccessMsg: false,
        successMsg: []
    }

    constructor(props) {
        super(props);

        this.handlePasswordResetForm = this.handlePasswordResetForm.bind(this);
    }

    componentDidMount() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    handlePasswordResetForm() {
        let email = this.state.email.trim();

        this.state.displayErrorMsg = false;
        this.state.errorMsg = [];
        this.state.displaySuccessMsg = false;
        this.state.successMsg = [];

        if (email.length <= 0) {
            this.state.errorMsg.push("Bitte geben Sie eine E-Mail Adresse ein.");
        }

        if (this.state.errorMsg.length > 0) {
            this.setState({displayErrorMsg: true});
        } else {
            this.setState({displayErrorMsg: false});

            Auth.checkRecaptcha()
            .then(res => {
                this.captcha.reset();
                let {data} = res;

                if (data.success) {
                    axios.post('auth/create-reset-password-link', {
                        email: email
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.setState({email: ""});
                            this.state.successMsg.push(response.data.message);
                            this.setState({displaySuccessMsg: true});
                        } else {
                            this.state.errorMsg.push(response.data.message);
                            this.setState({displayErrorMsg: true});
                        }

                    })
                    .catch(error => {
                        this.setState({errorMsg: ["Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns."]});
                        this.setState({displayErrorMsg: true});
                    });
                } else {
                    this.setState({formError: "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal."});
                }
            })
            .catch(err => {
                this.setState({formError: "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal."});
            });
        }
    }

    render() {
        return (
            <div id="login-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Passwort vergessen</span>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-12 col-md-10 col-lg-8">
                            <h1 class="color-primary">Du hast Dein Passwort vergessen?</h1>
                            <p>
                                    Trage bitte die E-Mail-Adresse ein, die Du bei der Einrichtung Deines Kontos verwendet hast, dann werden wir Dir einen Link zum Zurücksetzen des Passworts zu schicken.
                                    <br /><br />
                                    Bitte kontrolliere auch den Spam-Ordner Deines Postfaches.
                                    <br /><br />
                                    Sollte es mit dem Link Probleme geben, kannst Du uns gerne <Link to='/kontakt'>kontaktieren</Link>.
                            </p>
                            {this.state.displayErrorMsg && (
                                <p className="alert alert-danger">{this.state.errorMsg[0]}</p>
                            )}
                            {this.state.displaySuccessMsg && (
                                <p className="alert alert-success">{this.state.successMsg[0]}</p>
                            )}
                            <form method="post" onSubmit={(e) => { e.preventDefault();this.captcha.execute(); }}>
                                <div class="form-group">
                                    <label for="email" className="col-12 col-md-4 nopadding">Deine Email Adresse</label>
                                    <input type="email" className="col-12 col-md-8 nopadding form-control" name="email" id="email" placeholder="Email" required onChange={e => this.setState({email: e.target.value})}></input>
                                </div>
                                <Reaptcha
                                    ref={e => (this.captcha = e)}
                                    size="invisible"
                                    sitekey="6LeEAaIUAAAAAHVNxEUt6ZjKHzvCA_6gPuwjjK0S"
                                    onVerify={this.handlePasswordResetForm}
                                    onError={() => {this.setState({formError: "ReCAPTCHA-Fehler aufgetreten! Bitte versuche es erneut."});}}
                                />
                                <input type="submit" value="Link anfordern" className="btn btn-primary"></input>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;
