import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Imprint extends Component {
    componentDidMount() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    render() {
        return (
            <div id="imprint-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Impressum</span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="color-primary">Impressum</h1>
                            Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.
                            <br />
                            <br />MyFeedingCompass e.U.
                            <br />Alexandra Gubernat
                            <br />Neusiedlerstr. 29,
                            <br />2340 Mödling,
                            <br />Österreich
                            <br />
                            <br /><strong>Unternehmensgegenstand</strong>: Ernährungsberatung für Hunde und Katzen
                            <br /><strong>Firmenbuchnummer</strong>: 507859b
                            <br /><strong>Firmenbuchgericht</strong>: Wiener Neustadt
                            <br /><strong>Firmensitz</strong>: 2340 Mödling
                            <br />
                            <br /><strong>Tel.</strong>: +43 676 4 235 235
                            <br /><strong>E-Mail</strong>: info@myfeedingcheck.com
                            <br /><strong>Mitglied bei</strong>: WKO
                            <br /><strong>Berufsrecht</strong>: Gewerbeordnung: www.ris.bka.gv.at
                            <br /><strong>Berufsbezeichnung</strong>: Ernährungsberatung für Hunde und Katzen
                            <br /><strong>Verleihungsstaat</strong>: Österreich
                            <br /><strong>Quelle</strong>: Erstellt mit dem Impressum Generator von <a href="https://adsimple.at" target="_blank">adsimple.at</a> in Kooperation mit <a href="https://huno.at" target="_blank">huno.at</a>.

                            <h2 className="mt-30">EU-Streitschlichtung</h2>
                            Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
                            <br />Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter <a href="http://ec.europa.eu/odr?tid=121111728" target="_blank">ec.europa.eu/odr?tid=121111728</a> zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
                            <br />
                            <br />Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

                            <h2 className="mt-30">Haftung für Inhalte dieser Webseite</h2>
                            Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.
                            <br />Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum.

                            <h2 className="mt-30">Haftung für Links auf dieser Webseite</h2>
                            Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht laut <a href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&Dokumentnummer=NOR40025813&tid=121111728" target="_blank">§ 17 ECG</a> für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
                            <br />
                            <br />Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.

                            <h2 className="mt-30">Urheberrechtshinweis</h2>
                            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
                            <br />
                            <br />Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.

                            <h2 className="mt-30">Bildernachweis</h2>
                            Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.
                            <br />
                            <br />Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
                            <ul>
                                <li>Alexandra Gubernat</li>
                                <li><a href="https://shutterstock.com" target="_blank">Shutterstock</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Imprint;
