import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FiFacebook, FiInstagram, FiMail } from 'react-icons/fi';
import { Close } from '@material-ui/icons';

function SocialPop(props) {

    const [showMessage, setShowMessage] = useState(false);

    let timeClosed = localStorage.getItem('mfc-so-pop');
    if (timeClosed) timeClosed = parseInt(timeClosed) + (1000 * 60 * 60 * 12);

    if (!timeClosed || timeClosed < Date.now()) {
        setTimeout(function () {
            setShowMessage(true);

            // Setup isScrolling variable
            var isScrolling;

            // Listen for scroll events
            window.addEventListener('scroll', function (event) {
                let pop = document.getElementById('social-pop-up');
                if (pop) pop.classList.add('scroll-effect');

                // Clear our timeout throughout the scroll
                window.clearTimeout(isScrolling);

                // Set a timeout to run after scrolling ends
                isScrolling = setTimeout(function () {
                    // Run the callback
                    if (pop) pop.classList.remove('scroll-effect');
                }, 200);
            }, false);
        }, 15000);
    }

    return (
        <React.Fragment>
            <CSSTransition
                in={showMessage}
                timeout={300}
                classNames="fade"
                unmountOnExit={true}
            >
                <div>
                    <div id="social-pop-up" onScroll="console.log('scroll')">
                        <Close className="close-btn" onClick={() => { setShowMessage(false); props.changeSocialPopStatus(); }} />
                        <p>
                            Folge MyFeedingCheck auf
                        </p>
                        <div className="flex-row">
                            <a href="https://www.facebook.com/myfeedingcheck/" target="_blank" className="flex-column">
                                <div><FiFacebook /></div>
                            </a>
                            <a href="https://www.instagram.com/myfeedingcheck/" target="_blank" className="flex-column">
                                <div><FiInstagram /></div>
                            </a>
                            {/* <a href="https://mailchi.mp/a4ce5c65ec6c/myfeedingcheck-newsletter-anmledung" target="_blank" className="flex-column">
                                <div><FiMail /></div>
                            </a> */}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </React.Fragment>
    )
}

export default SocialPop;
