/*----------------------------------------------------
ATTENTION: ABONDONED FILE (not needed anymore)
----------------------------------------------------*/

import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Auth from "./../modules/Auth";
import bucketURL from "./../modules/GCS";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import queryString from "query-string";
import { DeleteOutline, Close } from "@material-ui/icons";
import Reaptcha from "reaptcha";
import stringFilter from "./../modules/Filter";
import vars from "./../modules/vars";
import { CSSTransition } from "react-transition-group";

class EditProductForm extends Component {
    state = {
        fetchedProduct: null,
        username: null,
        _id: null,
        formError: null,
        formSuccess: null,
        redirectToHome: false,
        fetchingError: null,
        currentStep: 1,
        animals: [],
        ageGroup: [],
        type1: "",
        type2: "",
        producers_list: [],
        brands_list: [],
        brand: "",
        series: "",
        countryOfProduction: "",
        packaging: "",
        size1: "",
        size2: "",
        size3: "",
        size4: "",
        size5: "",
        packaging1: "",
        packaging2: "",
        packaging3: "",
        packaging4: "",
        packaging5: "",
        barcode1: "",
        barcode2: "",
        barcode3: "",
        barcode4: "",
        barcode5: "",
        proteins: "",
        fats: "",
        fibers: "",
        ash: "",
        moisture: "",
        energy: "",
        suggestedAmount1: "",
        suggestedAmount2: "",
        suggestedAmount3: "",
        suggestedAmount4: "",
        suggestedAmount5: "",
        suggestedAmount6: "",
        suggestedAmount7: "",
        suggestedAmount8: "",
        suggestedAmount9: "",
        suggestedAmount10: "",
        description: "",
        declaration: "",
        animalShare: "",
        animalShareDriedOne: "",
        animalShareDriedTwo: "",
        animalComponents: [],
        diseases: [],
        physiologicalAdditives: "",
        technologicalAdditives: "",
        additionalFeatures: [],
        imageFront: "",
        imageContent1: "",
        imageContent2: "",
        newProducerName: "",
        newBrandName: "",
        redirectToProduct: false,
        newProductId: "",
        showNewBrandPop: false,
        newBrandFormSuccess: false,
        newBrandFormError: "",
    };

    constructor(props) {
        super(props);

        this.fetchProduct = this.fetchProduct.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAnimalSelect = this.handleAnimalSelect.bind(this);
        this.handleAgeGroupSelect = this.handleAgeGroupSelect.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleImageDeletion = this.handleImageDeletion.bind(this);
    }

    fetchProduct = () => {
        const parsed = queryString.parse(window.location.search);
        if (!parsed.id) {
            this.setState({ fetchingError: "Keine Produkt-ID angegeben." });
        } else {
            Auth.getUserFromToken().then((result) => {
                if (result && result.username && result.email && result._id) {
                    this.setState({ username: result.username });
                    this.setState({ _id: result._id });
                } else {
                    Auth.deauthenticateUser();
                    setTimeout(() => {
                        this.props.handleAuthenticationChange(false);
                        this.setState({
                            fetchingError:
                                "Leider ist beim Abrufen der Daten ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                        });
                    }, 500);
                }
            });

            fetch("/api/product?id=" + parsed.id)
                .then((res) => res.json())
                .then((json) => {
                    if (json.success === true) {
                        this.setState({ fetchedProduct: json.product });

                        let {
                            ageGroup,
                            animal,
                            type1,
                            type2,
                            producer,
                            brand,
                            series,
                            sizes,
                            countryOfProduction,
                            packaging,
                            proteins,
                            fats,
                            fibers,
                            ash,
                            moisture,
                            energy,
                            description,
                            imageFront,
                            imageContent1,
                            imageContent2,
                            suggestedAmount,
                            animalShare,
                            animalShareDriedOne,
                            animalShareDriedTwo,
                            animalComponents,
                            diseases,
                            declaration,
                            physiologicalAdditives,
                            technologicalAdditives,
                            grainfree,
                            sugarfree,
                            glutenfree,
                            biological,
                            hypoallergenic,
                            attributes,
                        } = json.product;

                        if (ageGroup && ageGroup.length >= 1) ageGroup.forEach((g) => this.state.ageGroup.push(g));
                        if (animal && animal.length >= 1) animal.forEach((a) => this.state.animals.push(a));
                        if (type1) this.setState({ type1: type1 });
                        if (type1) this.setState({ type2: type2 });

                        if (this.state.producer === "newProducer") {
                            this.setState({ brands_list: [] });
                            this.setState({ brand: "newBrand" });
                        } else {
                            fetch("/api/food-producers")
                                .then((res) => res.json())
                                .then((json) => {
                                    this.setState({
                                        producers_list: json.producers_list,
                                    });
                                    this.setState({
                                        brands_list: json.brands_list,
                                    });
                                })
                                .catch((error) => {
                                    this.setState({
                                        fetchingError:
                                            "Leider ist beim Abrufen der Marken für diesen Hersteller ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                                    });
                                });
                        }

                        if (brand) this.setState({ brand: brand._id });
                        if (countryOfProduction)
                            this.setState({
                                countryOfProduction: countryOfProduction,
                            });

                        if (series) {
                            this.setState({ series: series });
                        } else {
                            this.setState({
                                fetchingError:
                                    "Leider ist beim Abrufen ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                            });
                        }

                        if (sizes[0] && sizes[0].size) this.setState({ size1: sizes[0].size });
                        if (sizes[0] && sizes[0].packaging) this.setState({ packaging1: sizes[0].packaging });
                        if (sizes[0] && sizes[0].barcode) this.setState({ barcode1: sizes[0].barcode });
                        if (sizes[1] && sizes[1].size) this.setState({ size2: sizes[1].size });
                        if (sizes[1] && sizes[1].packaging) this.setState({ packaging2: sizes[1].packaging });
                        if (sizes[1] && sizes[1].barcode) this.setState({ barcode2: sizes[1].barcode });
                        if (sizes[2] && sizes[2].size) this.setState({ size3: sizes[2].size });
                        if (sizes[2] && sizes[2].packaging) this.setState({ packaging3: sizes[2].packaging });
                        if (sizes[2] && sizes[2].barcode) this.setState({ barcode3: sizes[2].barcode });
                        if (sizes[3] && sizes[3].size) this.setState({ size4: sizes[3].size });
                        if (sizes[3] && sizes[3].packaging) this.setState({ packaging4: sizes[3].packaging });
                        if (sizes[3] && sizes[3].barcode) this.setState({ barcode4: sizes[3].barcode });
                        if (sizes[4] && sizes[4].size) this.setState({ size5: sizes[4].size });
                        if (sizes[4] && sizes[4].packaging) this.setState({ packaging5: sizes[4].packaging });
                        if (sizes[4] && sizes[4].barcode) this.setState({ barcode5: sizes[4].barcode });
                        if (proteins) this.setState({ proteins: proteins });
                        if (fats) this.setState({ fats: fats });
                        if (fibers) this.setState({ fibers: fibers });
                        if (ash) this.setState({ ash: ash });
                        if (moisture) this.setState({ moisture: moisture });
                        if (energy) this.setState({ energy: energy });

                        if (
                            suggestedAmount &&
                            suggestedAmount[0] &&
                            suggestedAmount[0].weightKg &&
                            suggestedAmount[0].foodAmount
                        ) {
                            this.setState({
                                suggestedAmount1: suggestedAmount[0].weightKg,
                            });
                            this.setState({
                                suggestedAmount2: suggestedAmount[0].foodAmount,
                            });
                        }
                        if (
                            suggestedAmount &&
                            suggestedAmount[1] &&
                            suggestedAmount[1].weightKg &&
                            suggestedAmount[1].foodAmount
                        ) {
                            this.setState({
                                suggestedAmount3: suggestedAmount[1].weightKg,
                            });
                            this.setState({
                                suggestedAmount4: suggestedAmount[1].foodAmount,
                            });
                        }
                        if (
                            suggestedAmount &&
                            suggestedAmount[2] &&
                            suggestedAmount[2].weightKg &&
                            suggestedAmount[2].foodAmount
                        ) {
                            this.setState({
                                suggestedAmount5: suggestedAmount[2].weightKg,
                            });
                            this.setState({
                                suggestedAmount6: suggestedAmount[2].foodAmount,
                            });
                        }
                        if (
                            suggestedAmount &&
                            suggestedAmount[3] &&
                            suggestedAmount[3].weightKg &&
                            suggestedAmount[3].foodAmount
                        ) {
                            this.setState({
                                suggestedAmount7: suggestedAmount[3].weightKg,
                            });
                            this.setState({
                                suggestedAmount8: suggestedAmount[3].foodAmount,
                            });
                        }
                        if (
                            suggestedAmount &&
                            suggestedAmount[4] &&
                            suggestedAmount[4].weightKg &&
                            suggestedAmount[4].foodAmount
                        ) {
                            this.setState({
                                suggestedAmount9: suggestedAmount[4].weightKg,
                            });
                            this.setState({
                                suggestedAmount10: suggestedAmount[4].foodAmount,
                            });
                        }

                        if (description) this.setState({ description: description });
                        if (declaration) this.setState({ declaration: declaration });
                        if (animalShare) this.setState({ animalShare: animalShare });
                        if (animalShareDriedOne)
                            this.setState({
                                animalShareDriedOne: animalShareDriedOne,
                            });
                        if (animalShareDriedTwo)
                            this.setState({
                                animalShareDriedTwo: animalShareDriedTwo,
                            });

                        if (animalComponents && animalComponents.length >= 1)
                            animalComponents.forEach((c) => this.state.animalComponents.push(c));
                        if (diseases && diseases.length >= 1) diseases.forEach((c) => this.state.diseases.push(c));

                        if (typeof physiologicalAdditives === "boolean")
                            this.setState({
                                physiologicalAdditives: physiologicalAdditives.toString(),
                            });
                        if (typeof technologicalAdditives === "boolean")
                            this.setState({
                                technologicalAdditives: technologicalAdditives.toString(),
                            });

                        if (attributes && attributes.length >= 1)
                            attributes.forEach((a) => this.state.additionalFeatures.push(a));

                        fetch("/api/food-producers")
                            .then((response) => response.json())
                            .then((json) => {
                                if (json.success) {
                                    this.setState({
                                        producers_list: json.producers_list,
                                    });
                                    this.setState({
                                        brands_list: json.brands_list,
                                    });
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    fetchingError:
                                        "Leider ist beim Abrufen der Hersteller ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                                });
                            });
                    } else {
                        this.setState({
                            fetchingError:
                                "Leider ist beim Abrufen des Produktes ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        fetchingError:
                            "Leider ist beim Abrufen des Produktes ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                    });
                });
        }
    };

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (!parsed.id) {
            this.setState({ fetchingError: "Keine Produkt-ID angegeben." });
        } else {
            Auth.getUserFromToken().then((result) => {
                if (result && result.username && result.email && result._id) {
                    this.setState({ username: result.username });
                    this.setState({ _id: result._id });
                } else {
                    Auth.deauthenticateUser();
                    setTimeout(() => {
                        this.props.handleAuthenticationChange(false);
                        this.setState({
                            fetchingError:
                                "Leider ist beim Abrufen der Daten ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                        });
                    }, 500);
                }
            });

            this.fetchProduct();
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    handleFileChange = (event) => {
        const { name } = event.target;
        this.setState({
            [name]: event.target.files[0],
        });
    };

    handleCheckboxChange = (event) => {
        const { name, value } = event.target;
        let list = this.state[name];
        if (list.includes(value)) {
            var index = list.indexOf(value);
            if (index > -1) {
                list.splice(index, 1);
                this.setState({ [name]: list });
            }
        } else {
            this.setState({
                [name]: [...this.state[name], value],
            });
        }
    };

    handleAnimalSelect(event) {
        let animal_list = this.state.animals;
        let check = event.target.checked;
        let checked_animal = event.target.value;
        if (check) {
            this.setState({
                animals: [...this.state.animals, checked_animal],
            });
        } else {
            var index = animal_list.indexOf(checked_animal);
            if (index > -1) {
                animal_list.splice(index, 1);
                this.setState({
                    animals: animal_list,
                });
            }
        }
    }

    handleAgeGroupSelect(event) {
        let ageGroup_list = this.state.ageGroup;
        let check = event.target.checked;
        let checked_ageGroup = event.target.value;
        if (check) {
            this.setState({
                ageGroup: [...this.state.ageGroup, checked_ageGroup],
            });
        } else {
            var index = ageGroup_list.indexOf(checked_ageGroup);
            if (index > -1) {
                ageGroup_list.splice(index, 1);
                this.setState({
                    ageGroup: ageGroup_list,
                });
            }
        }
    }

    handleImageDeletion = (event) => {
        event.preventDefault();

        this.setState({ formError: null });
        this.setState({ formSuccess: null });

        let imageType = event.target.dataset.image;
        let productId = event.target.dataset.product;
        let token = Auth.getToken();

        Auth.getUserFromToken().then((result) => {
            if (result && result.username && result.email && result._id) {
                axios
                    .post(
                        "/api/edit-product/delete-image",
                        {
                            image: imageType,
                            product: productId,
                        },
                        { headers: { token: token } }
                    )
                    .then((res) => {
                        let { data } = res;
                        if (data.success === true) {
                            this.setState({
                                formSuccess: "Das Foto wurde erfolgreich gelöscht.",
                            });
                            this.setState({
                                fetchedProduct: Object.assign({}, this.state.fetchedProduct, {
                                    [imageType]: undefined,
                                }),
                            });
                        } else {
                            this.setState({
                                formError: "Fehler beim Löschen des Fotos aufgetreten. Das Foto wurde nicht gelöscht.",
                            });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            formError: "Fehler beim Löschen des Fotos aufgetreten. Das Foto wurde nicht gelöscht.",
                        });
                    });
            } else {
                Auth.deauthenticateUser();
                setTimeout(() => {
                    this.props.handleAuthenticationChange(false);
                    this.setState({ redirectToHome: true });
                }, 500);
            }
        });
    };

    async handleNewBrandSubmit() {
        let { producer, newProducerName, newBrandName } = this.state;

        let token = await Auth.getToken();

        if (
            (producer === "newProducer" && newProducerName && newBrandName) ||
            (producer !== "newProducer" && producer && newBrandName)
        ) {
            axios
                .post(
                    "/api/create-brand",
                    {
                        producer: producer,
                        newProducerName: newProducerName,
                        newBrandName: newBrandName,
                    },
                    { headers: { token: token } }
                )
                .then((res) => {
                    let { data } = res;
                    console.log(data);

                    if (data.success) {
                        this.setState({ newBrandFormSuccess: true });

                        let xxx = this;

                        setTimeout(function () {
                            fetch("/api/food-producers")
                                .then((res) => res.json())
                                .then((json) => {
                                    xxx.setState({
                                        producers_list: json.producers_list,
                                    });
                                    xxx.setState({
                                        brands_list: json.brands_list,
                                    });
                                })
                                .catch((error) => {
                                    xxx.setState({
                                        fetchingError:
                                            "Leider ist beim Abrufen der Marken für diesen Hersteller ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                                    });
                                });
                        }, 100);
                    } else {
                        this.setState({
                            newBrandFormError: data.message || "Es gab einen Fehler. Bitte versuche es erneut.",
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        newBrandFormError: "Es gab einen Fehler. Bitte versuche es erneut.",
                    });
                });
        } else {
            this.setState({
                newBrandFormError: "Bitte gib alle geforderten Daten an.",
            });
        }
    }

    _next = () => {
        this.setState({ formError: "" });
        let currentStep = this.state.currentStep;

        let {
            animals,
            type1,
            type2,
            brand,
            series,
            packaging1,
            size1,
            proteins,
            fats,
            fibers,
            ash,
            description,
            _id,
        } = this.state;

        if (
            (currentStep == 1 &&
                (animals.length < 1 || !type1 || !type2 || !brand || !series || !packaging1 || !size1)) ||
            (currentStep == 2 && (!proteins || !fibers || !fats || !ash)) ||
            (currentStep == 3 && !description)
        ) {
            this.setState({
                formError: "Bitte füllen Sie alle Pflichtfelder aus.",
            });
            return;
        }

        currentStep = currentStep >= 4 ? 4 : currentStep + 1;
        this.setState({ currentStep: currentStep });
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    _prev = () => {
        this.setState({ formError: "" });
        let currentStep = this.state.currentStep;
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({ currentStep: currentStep });
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    nextButton() {
        let currentStep = this.state.currentStep;
        if (currentStep < 4) {
            return (
                <button className="btn btn-primary" type="button" onClick={this._next}>
                    Nächster Schritt
                </button>
            );
            return null;
        }
    }

    prevButton() {
        let currentStep = this.state.currentStep;
        if (currentStep > 1) {
            return (
                <button className="btn btn-secondary" type="button" onClick={this._prev}>
                    Schritt zurück
                </button>
            );
            return null;
        }
    }

    submitButton() {
        let currentStep = this.state.currentStep;
        if (currentStep >= 4) {
            return <button className="btn btn-success">Produkt aktualisieren</button>;
            return null;
        }
    }

    handleSubmit = () => {
        this.setState({ formError: null });
        this.setState({ formSuccess: null });
        let formError = "";

        Auth.checkRecaptcha()
            .then((res) => {
                this.captcha.reset();
                let { data } = res;

                if (data.success) {
                    Auth.getUserFromToken().then((result) => {
                        if (result && result.username && result.email && result._id) {
                            this.setState({ username: result.username });
                            this.setState({ _id: result._id });
                        } else {
                            Auth.deauthenticateUser();
                            setTimeout(() => {
                                this.props.handleAuthenticationChange(false);
                                this.setState({
                                    fetchingError:
                                        "Leider ist beim Abrufen der Daten ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                                });
                                return;
                            }, 500);
                        }
                    });

                    let {
                        animals,
                        type1,
                        type2,
                        brand,
                        series,
                        packaging1,
                        size1,
                        proteins,
                        fats,
                        fibers,
                        ash,
                        description,
                        _id,
                    } = this.state;

                    if (animals.length < 1 || !type1 || !type2 || !brand || !series || !packaging1 || !size1) {
                        formError = "Bitte füllen Sie alle Pflichtfelder aus.";
                        this.setState({ formError: formError });
                        this.setState({ currentStep: 1 });
                        return;
                    } else if (!proteins || !fibers || !fats || !ash) {
                        formError = "Bitte füllen Sie alle Pflichtfelder aus.";
                        this.setState({ formError: formError });
                        this.setState({ currentStep: 2 });
                        return;
                    } else if (!description) {
                        formError = "Bitte füllen Sie alle Pflichtfelder aus.";
                        this.setState({ formError: formError });
                        this.setState({ currentStep: 3 });
                        return;
                    } else if (!_id) {
                        this.setState({
                            fetchingError:
                                "Leider ist beim Abrufen der Daten ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                        });
                        return;
                    } else {
                        let formData = new FormData();

                        formData.set("author", this.state._id);
                        formData.set("animals", this.state.animals);
                        formData.set("ageGroup", this.state.ageGroup);
                        formData.set("type1", this.state.type1);
                        formData.set("type2", this.state.type2);
                        formData.set("brand", this.state.brand);
                        formData.set("series", this.state.series);
                        formData.set("countryOfProduction", this.state.countryOfProduction);
                        formData.set("packaging", this.state.packaging);
                        formData.set("size1", this.state.size1);
                        formData.set("size2", this.state.size2);
                        formData.set("size3", this.state.size3);
                        formData.set("size4", this.state.size4);
                        formData.set("size5", this.state.size5);
                        formData.set("packaging1", this.state.packaging1);
                        formData.set("packaging2", this.state.packaging2);
                        formData.set("packaging3", this.state.packaging3);
                        formData.set("packaging4", this.state.packaging4);
                        formData.set("packaging5", this.state.packaging5);
                        formData.set("barcode1", this.state.barcode1);
                        formData.set("barcode2", this.state.barcode2);
                        formData.set("barcode3", this.state.barcode3);
                        formData.set("barcode4", this.state.barcode4);
                        formData.set("barcode5", this.state.barcode5);
                        formData.set("proteins", this.state.proteins);
                        formData.set("fats", this.state.fats);
                        formData.set("fibers", this.state.fibers);
                        formData.set("ash", this.state.ash);
                        formData.set("moisture", this.state.moisture);
                        formData.set("energy", this.state.energy);
                        formData.set("suggestedAmount1", this.state.suggestedAmount1);
                        formData.set("suggestedAmount2", this.state.suggestedAmount2);
                        formData.set("suggestedAmount3", this.state.suggestedAmount3);
                        formData.set("suggestedAmount4", this.state.suggestedAmount4);
                        formData.set("suggestedAmount5", this.state.suggestedAmount5);
                        formData.set("suggestedAmount6", this.state.suggestedAmount6);
                        formData.set("suggestedAmount7", this.state.suggestedAmount7);
                        formData.set("suggestedAmount8", this.state.suggestedAmount8);
                        formData.set("suggestedAmount9", this.state.suggestedAmount9);
                        formData.set("suggestedAmount10", this.state.suggestedAmount10);
                        formData.set("description", this.state.description);
                        formData.set("declaration", this.state.declaration);
                        formData.set("animalShare", this.state.animalShare);
                        formData.set("animalShareDriedOne", this.state.animalShareDriedOne);
                        formData.set("animalShareDriedTwo", this.state.animalShareDriedTwo);
                        formData.set("animalComponents", this.state.animalComponents);
                        formData.set("diseases", this.state.diseases);
                        formData.set("physiologicalAdditives", this.state.physiologicalAdditives);
                        formData.set("technologicalAdditives", this.state.technologicalAdditives);
                        formData.set("additionalFeatures", this.state.additionalFeatures);
                        formData.append("imageFront", this.state.imageFront);
                        formData.append("imageContent1", this.state.imageContent1);
                        formData.append("imageContent2", this.state.imageContent2);
                        formData.append("productId", this.state.fetchedProduct._id);

                        let filterError = null;

                        for (let data of formData) {
                            filterError = stringFilter(data[1]);

                            if (filterError) {
                                this.setState({
                                    formError:
                                        "Fehler! Wir haben ein verbotenes Wort in deiner Eingabe gefunden: " +
                                        filterError.toUpperCase() +
                                        ". Bitte entferne das Wort bevor du das Formular erneut absendest.",
                                });
                                return;
                            }
                        }

                        axios
                            .post("/api/edit-product", formData)
                            .then((response) => {
                                let data = response.data;
                                if (data.success !== true) {
                                    if (data.errorMessages) {
                                        this.setState({
                                            formError: data.errorMessages[0],
                                        });
                                        return;
                                    } else {
                                        this.setState({
                                            formError:
                                                "Bei der Aktualisierung des Produktes ist ein unbekannter Fehler aufgetreten.",
                                        });
                                        return;
                                    }
                                } else {
                                    this.props.handleInfoPopOpen(
                                        "Vielen Dank!",
                                        "Wir werden deine Änderungen sobald wie möglich überprüfen."
                                    );
                                    this.setState({ redirectToProduct: true });
                                    this.setState({
                                        newProductId: data.product._id,
                                    });
                                    return;
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    formError: "Netzwerkfehler aufgetreten. Produkt nicht aktualisiert.",
                                });
                                return;
                            });
                    }
                } else {
                    this.setState({
                        formError:
                            "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal.",
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    formError:
                        "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal.",
                });
            });
    };

    render() {
        if (this.state.redirectToHome === true) {
            return <Redirect to="/" />;
        }

        if (this.state.redirectToProduct && this.state.newProductId) {
            return <Redirect to={"/produkte/produkt?id=" + this.state.newProductId} />;
        }

        return (
            <div className="form-view" id="edit-product-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Produkt bearbeiten (Schritt {this.state.currentStep})
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">Produkt bearbeiten</h1>
                            <p>
                                Hier kannst Du Produkte Schritt für Schritt bearbeiten.
                                <br />
                                <small>
                                    Alle Felder mit einer Markierung sind Pflichtfelder, da diese direkt auf der
                                    Verpackung zu finden sind.
                                </small>
                            </p>
                            {this.state.formError && <p className="alert alert-danger">{this.state.formError}</p>}
                            {this.state.formSuccess && <p className="alert alert-success">{this.state.formSuccess}</p>}
                        </div>
                    </div>
                    {!this.state.fetchingError && (
                        <React.Fragment>
                            <React.Fragment>
                                {this.state.showNewBrandPop ? (
                                    <React.Fragment>
                                        <CSSTransition in={true} timeout={300} classNames="fade" unmountOnExit={true}>
                                            <div className="popup-overlay">
                                                <div className="popup-wrapper">
                                                    <div
                                                        className="close-btn"
                                                        onClick={() =>
                                                            this.setState({
                                                                showNewBrandPop: false,
                                                            })
                                                        }
                                                    >
                                                        <Close />
                                                    </div>
                                                    {this.state.newBrandFormSuccess ? (
                                                        <React.Fragment>
                                                            <h3 className="color-primary">Marke erstellt!</h3>
                                                            <p>Du kannst die Marke jetzt auswählen.</p>
                                                            <span
                                                                className="btn btn-primary"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        showNewBrandPop: false,
                                                                    })
                                                                }
                                                            >
                                                                Fenster schließen
                                                            </span>
                                                        </React.Fragment>
                                                    ) : (
                                                            <React.Fragment>
                                                                <h3 className="color-primary">Neue Marke erstellen</h3>
                                                                <p>
                                                                    Bitte wähle einen Hersteller und gib den Namen der Marke
                                                                    an.
                                                            </p>
                                                                <form
                                                                    method="post"
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleNewBrandSubmit();
                                                                    }}
                                                                >
                                                                    <div className="form-group">
                                                                        <div className="form-label">
                                                                            <label>Herstellers wählen*</label>
                                                                        </div>
                                                                        <select
                                                                            className="form-control"
                                                                            id="producer"
                                                                            type="select"
                                                                            name="producer"
                                                                            required
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    producer: e.target.value,
                                                                                })
                                                                            }
                                                                        >
                                                                            <option disabled selected value>
                                                                                Hersteller wählen
                                                                        </option>
                                                                            <option
                                                                                value="newProducer"
                                                                                selected={
                                                                                    this.state.producer === "newProducer"
                                                                                }
                                                                            >
                                                                                Neuen Hersteller hinzufügen
                                                                        </option>
                                                                            {this.state.producers_list.map((p) => (
                                                                                <option
                                                                                    value={p._id}
                                                                                    selected={this.state.producer === p._id}
                                                                                    key={p._id}
                                                                                >
                                                                                    {p.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    {this.state.producer === "newProducer" && (
                                                                        <React.Fragment>
                                                                            <div className="form-group">
                                                                                <div className="form-label">
                                                                                    <label for="newProducerName">
                                                                                        Name des Herstellers*
                                                                                </label>
                                                                                </div>
                                                                                <div className="form-input">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="newProducerName"
                                                                                        id="newProducerName"
                                                                                        placeholder="Name des Herstellers"
                                                                                        onChange={this.handleChange}
                                                                                        value={this.state.newProducerName}
                                                                                    ></input>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <div className="form-group">
                                                                        <div className="form-group">
                                                                            <div className="form-label">
                                                                                <label for="newBrandName">
                                                                                    Name der Marke*
                                                                            </label>
                                                                            </div>
                                                                            <div className="form-input">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    name="newBrandName"
                                                                                    id="newBrandName"
                                                                                    placeholder="Name der Marke"
                                                                                    onChange={this.handleChange}
                                                                                    value={this.state.newBrandName}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary">
                                                                        Marke erstellen
                                                                </button>
                                                                </form>
                                                                {this.state.newBrandFormError ? (
                                                                    <p className="alert alert-danger mt-20">
                                                                        {this.state.newBrandFormError}
                                                                    </p>
                                                                ) : null}
                                                            </React.Fragment>
                                                        )}
                                                </div>
                                            </div>
                                        </CSSTransition>
                                    </React.Fragment>
                                ) : null}
                            </React.Fragment>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this.captcha.execute();
                                }}
                                enctype="multipart/form-data"
                            >
                                <Step1
                                    onAnimalSelect={this.handleAnimalSelect}
                                    onAgeGroupSelect={this.handleAgeGroupSelect}
                                    handleChange={this.handleChange}
                                    currentStep={this.state.currentStep}
                                    displayNewBrandPop={() => {
                                        this.setState({
                                            showNewBrandPop: true,
                                        });
                                    }}
                                    {...this.state}
                                />

                                <Step2
                                    handleChange={this.handleChange}
                                    currentStep={this.state.currentStep}
                                    {...this.state}
                                />

                                <Step3
                                    handleChange={this.handleChange}
                                    handleCheckboxChange={this.handleCheckboxChange}
                                    {...this.state}
                                />

                                <Step4
                                    handleChange={this.handleChange}
                                    handleFileChange={this.handleFileChange}
                                    handleImageDeletion={this.handleImageDeletion}
                                    {...this.state}
                                />

                                <Reaptcha
                                    ref={(e) => (this.captcha = e)}
                                    size="invisible"
                                    sitekey="6LeEAaIUAAAAAHVNxEUt6ZjKHzvCA_6gPuwjjK0S"
                                    onVerify={this.handleSubmit}
                                    onError={() => {
                                        this.setState({
                                            formError: "ReCAPTCHA-Fehler aufgetreten! Bitte versuche es erneut.",
                                        });
                                    }}
                                />

                                <div className="submit-buttons">
                                    {this.prevButton()}
                                    {this.nextButton()}
                                    {this.submitButton()}
                                </div>
                                {this.state.formError && (
                                    <p className="alert alert-danger mt-20 mb-0">{this.state.formError}</p>
                                )}
                            </form>
                        </React.Fragment>
                    )}
                    {this.state.fetchingError && <p className="alert alert-danger">{this.state.fetchingError}</p>}
                </div>
            </div>
        );
    }
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <h3 className="mt-1rem">Allgemeine Informationen</h3>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Tierart*</label>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="animal1"
                                    id="animal1"
                                    value="cat"
                                    onChange={props.onAnimalSelect}
                                    checked={props.animals.includes("cat")}
                                />
                                <span>Katze</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="animal2"
                                    id="animal2"
                                    value="dog"
                                    onChange={props.onAnimalSelect}
                                    checked={props.animals.includes("dog")}
                                />
                                <span>Hund</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Altersgruppe</label>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="ageGroup1"
                                    id="ageGroup1"
                                    value="baby"
                                    onChange={props.onAgeGroupSelect}
                                    checked={props.ageGroup.includes("baby")}
                                ></input>
                                <span>Welpe/Kitten</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="ageGroup2"
                                    id="ageGroup2"
                                    value="junior"
                                    onChange={props.onAgeGroupSelect}
                                    checked={props.ageGroup.includes("junior")}
                                ></input>
                                <span>Junior</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="ageGroup3"
                                    id="ageGroup3"
                                    value="adult"
                                    onChange={props.onAgeGroupSelect}
                                    checked={props.ageGroup.includes("adult")}
                                ></input>
                                <span>Adult</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    name="ageGroup4"
                                    id="ageGroup4"
                                    value="senior"
                                    onChange={props.onAgeGroupSelect}
                                    checked={props.ageGroup.includes("senior")}
                                ></input>
                                <span>Senior</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Trocken-/Nassfutter*</label>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="type1"
                                    value="wet"
                                    onChange={props.handleChange}
                                    checked={props.type1 === "wet"}
                                ></input>
                                <span>Nassfutter</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="type1"
                                    value="dried"
                                    onChange={props.handleChange}
                                    checked={props.type1 === "dried"}
                                ></input>
                                <span>Trockenfutter</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Allein-/Ergänzungsfutter*</label>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="type2"
                                    value="complete"
                                    onChange={props.handleChange}
                                    checked={props.type2 === "complete"}
                                ></input>
                                <span>Alleinfutter</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="type2"
                                    value="supplement-pure"
                                    onChange={props.handleChange}
                                    checked={props.type2 === "supplement-pure"}
                                ></input>
                                <span>Reinfleisch</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="type2"
                                    value="supplement-snack"
                                    onChange={props.handleChange}
                                    checked={props.type2 === "supplement-snack"}
                                ></input>
                                <span>Ergänzungsfutter</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="type2"
                                    value="snack"
                                    onChange={props.handleChange}
                                    checked={props.type2 === "snack"}
                                ></input>
                                <span>Snack</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="type2"
                                    value="single-food"
                                    onChange={props.handleChange}
                                    checked={props.type2 === "single-food"}
                                ></input>
                                <span>Einzelfuttermittel</span>
                            </label>
                        </div>
                        {props.type1 == 'dried' && (<div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="type2"
                                    value="dry-barf"
                                    onChange={props.handleChange}
                                    checked={props.type2 === "dry-barf"}
                                ></input>
                                <span>Trockenbarf</span>
                            </label>
                        </div>)}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="countryOfProduction">
                                Herstellungsland
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            Es muss auf der Verpackung/Homepage explizit geschrieben sein: made in … /
                                            Product of … / produced in … / hergestellt in … / Produkt aus … oder
                                            ähnliches.
                                        </Tooltip>
                                    }
                                >
                                    <button type="button" className="btn btn-tooltip">
                                        <img src="/img/information.png" />
                                    </button>
                                </OverlayTrigger>
                            </label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="countryOfProduction"
                                id="countryOfProduction"
                                placeholder="Herstellungsland"
                                onChange={props.handleChange}
                                value={props.countryOfProduction}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="brand">Marke*</label>
                        </div>
                        <div className="form-input">
                            <select
                                className="form-control"
                                id="brand"
                                type="select"
                                name="brand"
                                required
                                onChange={props.handleChange}
                            >
                                <option disabled selected value>
                                    Marke wählen
                                </option>
                                {props.brands_list &&
                                    props.brands_list.map((brand) => (
                                        <option value={brand._id} selected={props.brand === brand._id} key={brand._id}>
                                            {brand.name}
                                        </option>
                                    ))}
                            </select>
                            <small>
                                Marke nicht gefunden?{" "}
                                <span className="color-primary" onClick={props.displayNewBrandPop}>
                                    Marke hinzufügen
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="series">Serie, Zusatz, Hauptbestandteil*</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="series"
                                id="series"
                                placeholder="Serie"
                                required
                                onChange={props.handleChange}
                                value={props.series}
                            ></input>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3 className="mt-2rem">Verpackung</h3>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="size1">Größe [g]*</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="size1"
                                id="size1"
                                placeholder="Verpackungsgröße in Gramm"
                                required
                                onChange={props.handleChange}
                                value={props.size1}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="packaging1">Verpackungsart*</label>
                        </div>
                        <div className="form-input">
                            <select
                                id="packaging"
                                className="form-control"
                                type="select"
                                name="packaging1"
                                required
                                onChange={props.handleChange}
                            >
                                <option disabled selected value>
                                    Verpackungsart wählen
                                </option>
                                <option value="box" selected={props.packaging1 === "box"}>
                                    Box
                                </option>
                                <option value="can" selected={props.packaging1 === "can"}>
                                    Dose
                                </option>
                                <option value="glas" selected={props.packaging1 === "glas"}>
                                    Glas
                                </option>
                                <option value="pouch" selected={props.packaging1 === "pouch"}>
                                    Pouch
                                </option>
                                <option value="bag" selected={props.packaging1 === "bag"}>
                                    Sack
                                </option>
                                <option value="bowl" selected={props.packaging1 === "bowl"}>
                                    Schale
                                </option>
                                <option value="tetrapack" selected={props.packaging1 === "tetrapack"}>
                                    Tetrapack
                                </option>
                                <option value="sausage" selected={props.packaging1 === "sausage"}>
                                    Wurstform
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="barcode1">Strichcode-Nummer</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="barcode1"
                                id="barcode1"
                                placeholder="Strichcode"
                                onChange={props.handleChange}
                                value={props.barcode1}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <hr />
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="size2">Größe [g]</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="size2"
                                id="size2"
                                placeholder="Verpackungsgröße in Gramm"
                                onChange={props.handleChange}
                                value={props.size2}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="packaging2">Verpackungsart</label>
                        </div>
                        <div className="form-input">
                            <select
                                id="packaging"
                                className="form-control"
                                type="select"
                                name="packaging2"
                                onChange={props.handleChange}
                            >
                                <option disabled selected value>
                                    Verpackungsart wählen
                                </option>
                                <option value="box" selected={props.packaging2 === "box"}>
                                    Box
                                </option>
                                <option value="can" selected={props.packaging2 === "can"}>
                                    Dose
                                </option>
                                <option value="glas" selected={props.packaging2 === "glas"}>
                                    Glas
                                </option>
                                <option value="pouch" selected={props.packaging2 === "pouch"}>
                                    Pouch
                                </option>
                                <option value="bag" selected={props.packaging2 === "bag"}>
                                    Sack
                                </option>
                                <option value="bowl" selected={props.packaging2 === "bowl"}>
                                    Schale
                                </option>
                                <option value="tetrapack" selected={props.packaging2 === "tetrapack"}>
                                    Tetrapack
                                </option>
                                <option value="sausage" selected={props.packaging2 === "sausage"}>
                                    Wurstform
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="barcode2">Strichcode-Nummer</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="barcode2"
                                id="barcode2"
                                placeholder="Strichcode"
                                onChange={props.handleChange}
                                value={props.barcode2}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <hr />
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="size3">Größe [g]</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="size3"
                                id="size3"
                                placeholder="Verpackungsgröße in Gramm"
                                onChange={props.handleChange}
                                value={props.size3}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="packaging3">Verpackungsart</label>
                        </div>
                        <div className="form-input">
                            <select
                                id="packaging"
                                className="form-control"
                                type="select"
                                name="packaging3"
                                onChange={props.handleChange}
                            >
                                <option disabled selected value>
                                    Verpackungsart wählen
                                </option>
                                <option value="box" selected={props.packaging3 === "box"}>
                                    Box
                                </option>
                                <option value="can" selected={props.packaging3 === "can"}>
                                    Dose
                                </option>
                                <option value="glas" selected={props.packaging3 === "glas"}>
                                    Glas
                                </option>
                                <option value="pouch" selected={props.packaging3 === "pouch"}>
                                    Pouch
                                </option>
                                <option value="bag" selected={props.packaging3 === "bag"}>
                                    Sack
                                </option>
                                <option value="bowl" selected={props.packaging3 === "bowl"}>
                                    Schale
                                </option>
                                <option value="tetrapack" selected={props.packaging3 === "tetrapack"}>
                                    Tetrapack
                                </option>
                                <option value="sausage" selected={props.packaging3 === "sausage"}>
                                    Wurstform
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="barcode3">Strichcode-Nummer</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="barcode3"
                                id="barcode3"
                                placeholder="Strichcode"
                                onChange={props.handleChange}
                                value={props.barcode3}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <hr />
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="size4">Größe [g]</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="size4"
                                id="size4"
                                placeholder="Verpackungsgröße in Gramm"
                                onChange={props.handleChange}
                                value={props.size4}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="packaging4">Verpackungsart</label>
                        </div>
                        <div className="form-input">
                            <select
                                id="packaging"
                                className="form-control"
                                type="select"
                                name="packaging4"
                                onChange={props.handleChange}
                            >
                                <option disabled selected value>
                                    Verpackungsart wählen
                                </option>
                                <option value="box" selected={props.packaging4 === "box"}>
                                    Box
                                </option>
                                <option value="can" selected={props.packaging4 === "can"}>
                                    Dose
                                </option>
                                <option value="glas" selected={props.packaging4 === "glas"}>
                                    Glas
                                </option>
                                <option value="pouch" selected={props.packaging4 === "pouch"}>
                                    Pouch
                                </option>
                                <option value="bag" selected={props.packaging4 === "bag"}>
                                    Sack
                                </option>
                                <option value="bowl" selected={props.packaging4 === "bowl"}>
                                    Schale
                                </option>
                                <option value="tetrapack" selected={props.packaging4 === "tetrapack"}>
                                    Tetrapack
                                </option>
                                <option value="sausage" selected={props.packaging4 === "sausage"}>
                                    Wurstform
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="barcode4">Strichcode-Nummer</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="barcode4"
                                id="barcode4"
                                placeholder="Strichcode"
                                onChange={props.handleChange}
                                value={props.barcode4}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <hr />
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="size5">Größe [g]</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="size5"
                                id="size5"
                                placeholder="Verpackungsgröße in Gramm"
                                onChange={props.handleChange}
                                value={props.size5}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="packaging5">Verpackungsart</label>
                        </div>
                        <div className="form-input">
                            <select
                                id="packaging"
                                className="form-control"
                                type="select"
                                name="packaging5"
                                onChange={props.handleChange}
                            >
                                <option disabled selected value>
                                    Verpackungsart wählen
                                </option>
                                <option value="box" selected={props.packaging5 === "box"}>
                                    Box
                                </option>
                                <option value="can" selected={props.packaging5 === "can"}>
                                    Dose
                                </option>
                                <option value="glas" selected={props.packaging5 === "glas"}>
                                    Glas
                                </option>
                                <option value="pouch" selected={props.packaging5 === "pouch"}>
                                    Pouch
                                </option>
                                <option value="bag" selected={props.packaging5 === "bag"}>
                                    Sack
                                </option>
                                <option value="bowl" selected={props.packaging5 === "bowl"}>
                                    Schale
                                </option>
                                <option value="tetrapack" selected={props.packaging5 === "tetrapack"}>
                                    Tetrapack
                                </option>
                                <option value="sausage" selected={props.packaging5 === "sausage"}>
                                    Wurstform
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="barcode5">Strichcode-Nummer</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="text"
                                name="barcode5"
                                id="barcode5"
                                placeholder="Strichcode"
                                onChange={props.handleChange}
                                value={props.barcode5}
                            ></input>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step2(props) {
    if (props.currentStep !== 2) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <h3 className="mt-1rem">Weender Analyse</h3>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="proteins">Rohprotein*</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="number"
                                step="0.01"
                                name="proteins"
                                id="proteins"
                                placeholder="Rohprotein"
                                required
                                onChange={props.handleChange}
                                value={props.proteins}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="fatsRohfaser">Rohfett*</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="number"
                                step="0.01"
                                name="fats"
                                id="fats"
                                placeholder="Rohfett"
                                required
                                onChange={props.handleChange}
                                value={props.fats}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="fibers">Rohfaser*</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="number"
                                step="0.01"
                                name="fibers"
                                id="fibers"
                                placeholder="Rohfaser"
                                required
                                onChange={props.handleChange}
                                value={props.fibers}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="ash">Rohasche*</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="number"
                                step="0.01"
                                name="ash"
                                id="ash"
                                placeholder="Rohasche"
                                required
                                onChange={props.handleChange}
                                value={props.ash}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="moisture">
                                Feuchtigkeit
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            Dieser Wert muss erst ab 14% vom Hersteller angegeben werden. Richtwerte:
                                            <br />
                                            Trockenfutter 8-10% (harte Pellets)
                                            <br />
                                            Halbfeuchtes Futter: 14-20% (weichere Pellets, "Soft", "Moist")
                                        </Tooltip>
                                    }
                                >
                                    <button type="button" className="btn btn-tooltip">
                                        <img src="/img/information.png" />
                                    </button>
                                </OverlayTrigger>
                            </label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="number"
                                step="0.01"
                                name="moisture"
                                id="moisture"
                                placeholder="Feuchtigkeit"
                                onChange={props.handleChange}
                                value={props.moisture}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="energy">Energie vom Hersteller angegeben [kcal/kg]</label>
                        </div>
                        <div className="form-input">
                            <input
                                className="form-control"
                                type="number"
                                step="0.01"
                                name="energy"
                                id="energy"
                                placeholder="Energie"
                                onChange={props.handleChange}
                                value={props.energy}
                            ></input>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3 className="mt-2rem">Empfohlene Futtermenge vom Hersteller</h3>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount1"
                            id="suggestedAmount1"
                            placeholder="Gewicht/Alter"
                            onChange={props.handleChange}
                            value={props.suggestedAmount1}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount2"
                            placeholder="Futtermenge"
                            onChange={props.handleChange}
                            value={props.suggestedAmount2}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount3"
                            id="suggestedAmount3"
                            placeholder="Gewicht/Alter"
                            onChange={props.handleChange}
                            value={props.suggestedAmount3}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount4"
                            placeholder="Futtermenge"
                            onChange={props.handleChange}
                            value={props.suggestedAmount4}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount5"
                            id="suggestedAmount5"
                            placeholder="Gewicht/Alter"
                            onChange={props.handleChange}
                            value={props.suggestedAmount5}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount6"
                            placeholder="Futtermenge"
                            onChange={props.handleChange}
                            value={props.suggestedAmount6}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount7"
                            id="suggestedAmount7"
                            placeholder="Gewicht/Alter"
                            onChange={props.handleChange}
                            value={props.suggestedAmount7}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount8"
                            placeholder="Futtermenge"
                            onChange={props.handleChange}
                            value={props.suggestedAmount8}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount9"
                            id="suggestedAmount9"
                            placeholder="Gewicht/Alter"
                            onChange={props.handleChange}
                            value={props.suggestedAmount9}
                        ></input>
                    </div>
                </div>
                <div className="col-6 suggested-amount-input">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            name="suggestedAmount10"
                            placeholder="Futtermenge"
                            onChange={props.handleChange}
                            value={props.suggestedAmount10}
                        ></input>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function DiseasesFilter(props) {
    let n = vars.diseases.length;
    let groupSize = Math.ceil(n / 4);

    let checkboxes = [];

    vars.diseases.map((d, i) => {
        checkboxes.push(
            <div className="checkbox">
                <label>
                    <input
                        type="checkbox"
                        name="diseases"
                        value={d.value}
                        onChange={props.handleCheckboxChange}
                        checked={props.diseases.includes(d.value)}
                    />
                    <span>{d.label}</span>
                </label>
            </div>
        );
    });

    checkboxes = checkboxes
        .reduce((r, checkbox, index) => {
            if (index % groupSize === 0) r.push([]);
            r[r.length - 1].push(checkbox);
            return r;
        }, [])
        .map((group) => {
            return <div className="col-sm-6 col-xl-3">{group}</div>;
        });

    return checkboxes;
}

function Step3(props) {
    if (props.currentStep !== 3) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <h3 className="mt-1rem">Zusammensetzung*</h3>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-input">
                            <textarea
                                className="form-control"
                                name="description"
                                id="description"
                                placeholder="Zusammensetzung"
                                rows="4"
                                required
                                onChange={props.handleChange}
                            >
                                {props.description}
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h3 className="mt-2rem">Inhalt & Zusammensetzung auf einen Blick</h3>
                </div>
                <div className={props.type1 === "dried" ? "col-12" : "col-lg-6"}>
                    <div className="form-group">
                        <div className="form-label">
                            <label for="declaration">
                                Deklaration
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            = Zusammensetzung
                                            <br />
                                            offen: einzelne Komponente erkennbar z.B.: Herz, Leber = Einzeldeklaration;
                                            <br />
                                            geschlossen: Komponenten in Gruppen zusammengefasst z.B: Fleisch & tier.
                                            Nebenerzeugnisse = Gruppendeklaration
                                        </Tooltip>
                                    }
                                >
                                    <button type="button" className="btn btn-tooltip">
                                        <img src="/img/information.png" />
                                    </button>
                                </OverlayTrigger>
                            </label>
                        </div>
                        <div className="form-input">
                            <select
                                id="declaration"
                                className="form-control"
                                type="select"
                                name="declaration"
                                required
                                onChange={props.handleChange}
                            >
                                <option disabled selected value>
                                    Deklaration wählen
                                </option>
                                <option value="open-2" selected={props.declaration === "open-2"}>
                                    offene Deklaration
                                </option>
                                <option value="open" selected={props.declaration === "open"}>
                                    halboffene Deklaration
                                </option>
                                <option value="closed" selected={props.declaration === "closed"}>
                                    geschlossene Deklaration
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                {props.type1 !== "dried" ? (
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="form-label">
                                <label for="animalShare">
                                    Tierischer Anteil
                                    {/* <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>
                                                Beim Trockenfutter bitte nur den Trockenfleischanteil angeben. Wird Frischfleisch vom Hersteller angegeben, muss dieser Anteil mit -50% angegeben werden.
                                    </Tooltip>
                                        }
                                    >
                                        <button type="button" className="btn btn-tooltip"><img src="/img/information.png" /></button>
                                    </OverlayTrigger> */}
                                </label>
                            </div>
                            <div className="form-input">
                                <select
                                    id="animalShare"
                                    className="form-control"
                                    type="select"
                                    name="animalShare"
                                    required
                                    onChange={props.handleChange}
                                >
                                    <option disabled selected value>
                                        Menge wählen
                                    </option>
                                    <option value="0" selected={props.animalShare === 0}>
                                        nicht angegeben/erkennbar
                                    </option>
                                    <option value="1" selected={props.animalShare === 1}>
                                        0%
                                    </option>
                                    <option value="30" selected={props.animalShare === 30}>
                                        1-29%
                                    </option>
                                    <option value="40" selected={props.animalShare === 40}>
                                        30-39%
                                    </option>
                                    <option value="50" selected={props.animalShare === 50}>
                                        40-49%
                                    </option>
                                    <option value="60" selected={props.animalShare === 60}>
                                        50-59%
                                    </option>
                                    <option value="70" selected={props.animalShare === 70}>
                                        60-69%
                                    </option>
                                    <option value="80" selected={props.animalShare === 80}>
                                        70-79%
                                    </option>
                                    <option value="90" selected={props.animalShare === 90}>
                                        80-89%
                                    </option>
                                    <option value="100" selected={props.animalShare === 100}>
                                        90-100%
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                ) : (
                        <React.Fragment>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="form-label">
                                        <label for="animalShareDriedOne">Tierischer Trockenfleisch-Anteil</label>
                                    </div>
                                    <div className="form-input">
                                        <select
                                            id="animalShareDriedOne"
                                            className="form-control"
                                            type="select"
                                            name="animalShareDriedOne"
                                            required
                                            onChange={props.handleChange}
                                        >
                                            <option disabled selected value>
                                                Menge wählen
                                        </option>
                                            <option value="0" selected={props.animalShareDriedOne === 0}>
                                                nicht angegeben/erkennbar
                                        </option>
                                            <option value="1" selected={props.animalShareDriedOne === 1}>
                                                0%
                                        </option>
                                            <option value="30" selected={props.animalShareDriedOne === 20}>
                                                1-19%
                                        </option>
                                            <option value="30" selected={props.animalShareDriedOne === 30}>
                                                20-29%
                                        </option>
                                            <option value="40" selected={props.animalShareDriedOne === 40}>
                                                30-39%
                                        </option>
                                            <option value="50" selected={props.animalShareDriedOne === 50}>
                                                40-49%
                                        </option>
                                            <option value="60" selected={props.animalShareDriedOne === 60}>
                                                50-59%
                                        </option>
                                            <option value="70" selected={props.animalShareDriedOne === 70}>
                                                60-69%
                                        </option>
                                            <option value="80" selected={props.animalShareDriedOne === 80}>
                                                70-79%
                                        </option>
                                            <option value="90" selected={props.animalShareDriedOne === 90}>
                                                80-89%
                                        </option>
                                            <option value="100" selected={props.animalShareDriedOne === 100}>
                                                90-100%
                                        </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <div className="form-label">
                                        <label for="animalShareDriedTwo">Tierischer Frischfleisch-Anteil</label>
                                    </div>
                                    <div className="form-input">
                                        <select
                                            id="animalShareDriedTwo"
                                            className="form-control"
                                            type="select"
                                            name="animalShareDriedTwo"
                                            required
                                            onChange={props.handleChange}
                                        >
                                            <option disabled selected value>
                                                Menge wählen
                                        </option>
                                            <option value="0" selected={props.animalShareDriedTwo === 0}>
                                                nicht angegeben/erkennbar
                                        </option>
                                            <option value="1" selected={props.animalShareDriedTwo === 1}>
                                                0%
                                        </option>
                                            <option value="30" selected={props.animalShareDriedTwo === 20}>
                                                1-19%
                                        </option>
                                            <option value="30" selected={props.animalShareDriedTwo === 30}>
                                                20-29%
                                        </option>
                                            <option value="40" selected={props.animalShareDriedTwo === 40}>
                                                30-39%
                                        </option>
                                            <option value="50" selected={props.animalShareDriedTwo === 50}>
                                                40-49%
                                        </option>
                                            <option value="60" selected={props.animalShareDriedTwo === 60}>
                                                50-59%
                                        </option>
                                            <option value="70" selected={props.animalShareDriedTwo === 70}>
                                                60-69%
                                        </option>
                                            <option value="80" selected={props.animalShareDriedTwo === 80}>
                                                70-79%
                                        </option>
                                            <option value="90" selected={props.animalShareDriedTwo === 90}>
                                                80-89%
                                        </option>
                                            <option value="100" selected={props.animalShareDriedTwo === 100}>
                                                90-100%
                                        </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                <div className="col-12 form-group">
                    <label>Tierbestandteile</label>
                    <div className="row">
                        <div className="col-sm-6 col-xl-3">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="barsch"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("barsch")}
                                    />
                                    <span>Barsch</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="bison"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("bison")}
                                    />
                                    <span>Bison</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="buffel"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("buffel")}
                                    />
                                    <span>Büffel</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="dorsch"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("dorsch")}
                                    />
                                    <span>Dorsch</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="elch"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("elch")}
                                    />
                                    <span>Elch</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="ente"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("ente")}
                                    />
                                    <span>Ente</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="fasan"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("fasan")}
                                    />
                                    <span>Fasan</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="fisch"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("fisch")}
                                    />
                                    <span>Fisch</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="flunder"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("flunder")}
                                    />
                                    <span>Flunder</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="forelle"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("forelle")}
                                    />
                                    <span>Forelle</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="gans"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("gans")}
                                    />
                                    <span>Gans</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="garnele"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("garnele")}
                                    />
                                    <span>Garnele</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="geflugel"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("geflugel")}
                                    />
                                    <span>Geflügel</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="hase"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("hase")}
                                    />
                                    <span>Hase</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="hering"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("hering")}
                                    />
                                    <span>Hering</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="hirsch"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("hirsch")}
                                    />
                                    <span>Hirsch</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="huhn"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("huhn")}
                                    />
                                    <span>Huhn</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="insekten"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("insekten")}
                                    />
                                    <span>Insekten</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="kabeljau"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("kabeljau")}
                                    />
                                    <span>Kabeljau</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="kalb"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("kalb")}
                                    />
                                    <span>Kalb</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="kamel"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("kamel")}
                                    />
                                    <span>Kamel</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="kanguru"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("kanguru")}
                                    />
                                    <span>Känguru</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="lachs"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("lachs")}
                                    />
                                    <span>Lachs</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="kaninchen"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("kaninchen")}
                                    />
                                    <span>Kaninchen</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="lamm"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("lamm")}
                                    />
                                    <span>Lamm</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="lodde"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("lodde")}
                                    />
                                    <span>Lodde</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="makrelle"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("makrelle")}
                                    />
                                    <span>Makrelle</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="meeresfruechte"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("meeresfruechte")}
                                    />
                                    <span>Meeresfrüchte</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="ochse"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("ochse")}
                                    />
                                    <span>Ochse</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="pferd"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("pferd")}
                                    />
                                    <span>Pferd</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="pute"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("pute")}
                                    />
                                    <span>Pute</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="reh"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("reh")}
                                    />
                                    <span>Reh</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="rentier"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("rentier")}
                                    />
                                    <span>Rentier</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="rind"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("rind")}
                                    />
                                    <span>Rind</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="sardelle"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("sardelle")}
                                    />
                                    <span>Sardelle</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="sardine"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("sardine")}
                                    />
                                    <span>Sardine</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="schaf"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("schaf")}
                                    />
                                    <span>Schaf</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="schwein"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("schwein")}
                                    />
                                    <span>Schwein</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="seelachs"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("seelachs")}
                                    />
                                    <span>Seelachs</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="seewolf"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("seewolf")}
                                    />
                                    <span>Seewolf</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="seezunge"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("seezunge")}
                                    />
                                    <span>Seezunge</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="stint"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("stint")}
                                    />
                                    <span>Stint</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="stoer"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("stoer")}
                                    />
                                    <span>Stör</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="strauß"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("strauß")}
                                    />
                                    <span>Strauß</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="thunfisch"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("thunfisch")}
                                    />
                                    <span>Thunfisch</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="truthahn"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("truthahn")}
                                    />
                                    <span>Truthahn</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="unbekannt"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("unbekannt")}
                                    />
                                    <span>
                                        Unbekannt
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>
                                                    Da nicht immer alle Tierarten angegeben werden, dient "Unbekannt"
                                                    als Lückenfüller. So wird unser Suchfilter nicht verfälscht.
                                                </Tooltip>
                                            }
                                        >
                                            <button type="button" className="btn btn-tooltip">
                                                <img src="/img/information.png" />
                                            </button>
                                        </OverlayTrigger>
                                    </span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="wachtel"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("wachtel")}
                                    />
                                    <span>Wachtel</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="wild"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("wild")}
                                    />
                                    <span>Wild</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="wildlachs"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("wildlachs")}
                                    />
                                    <span>Wildlachs</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="wildschwein"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("wildschwein")}
                                    />
                                    <span>Wildschwein</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="animalComponents"
                                        value="ziege"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.animalComponents.includes("ziege")}
                                    />
                                    <span>Ziege</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 form-group">
                    <label>Gesundheit</label>
                    <div className="row">
                        <DiseasesFilter {...props} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>
                            Enthält das Produkt ernährungsphysiologische Zusatzstoffe?
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>= künstliche Nährstoffe zur Bedarfsdeckung</Tooltip>}
                            >
                                <button type="button" className="btn btn-tooltip">
                                    <img src="/img/information.png" />
                                </button>
                            </OverlayTrigger>
                        </label>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="physiologicalAdditives"
                                    value="true"
                                    onChange={props.handleChange}
                                    checked={props.physiologicalAdditives === "true"}
                                ></input>
                                <span>Ja</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="physiologicalAdditives"
                                    value="false"
                                    onChange={props.handleChange}
                                    checked={props.physiologicalAdditives === "false"}
                                ></input>
                                <span>Nein</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>
                            Sind technologische Zusatzstoffe vom Hersteller aufgelistet?
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        = Zusatzstoff, welcher das Futter haltbar, unverderblich und formbar (z.B.
                                        viereckige Stückchen) macht.
                                        <br />
                                        Beispiele: Cassia Gum, E-Nummern, BHA/BHT, Konservierungsstoffe, ...
                                    </Tooltip>
                                }
                            >
                                <button type="button" className="btn btn-tooltip">
                                    <img src="/img/information.png" />
                                </button>
                            </OverlayTrigger>
                        </label>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="technologicalAdditives"
                                    value="true"
                                    onChange={props.handleChange}
                                    checked={props.technologicalAdditives === "true"}
                                ></input>
                                <span>Ja</span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <label>
                                <input
                                    type="radio"
                                    name="technologicalAdditives"
                                    value="false"
                                    onChange={props.handleChange}
                                    checked={props.technologicalAdditives === "false"}
                                ></input>
                                <span>Nein</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12 form-group">
                    <label>Weitere Eigenschaften (auf der Homepage/Verpackung angegeben)</label>
                    <div className="row">
                        <div className="col-md-6 col-xl-3">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="biological"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("biological")}
                                    />
                                    <span>Bio-Qualität (Fleisch)</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="extruded"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("extruded")}
                                    />
                                    <span>extrudiert</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="baked"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("baked")}
                                    />
                                    <span>gebacken</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="freezedried"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("freezedried")}
                                    />
                                    <span>gefriergetrocknet</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="gmfree"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("gmfree")}
                                    />
                                    <span>gentechnikfrei</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="grainfree"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("grainfree")}
                                    />
                                    <span>getreidefrei</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="glutenfree"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("glutenfree")}
                                    />
                                    <span>glutenfrei</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="hypoallergenic"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("hypoallergenic")}
                                    />
                                    <span>hypoallergen</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="bottledcold"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("bottledcold")}
                                    />
                                    <span>kalt abgefüllt</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="pressedcold"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("pressedcold")}
                                    />
                                    <span>kaltgepresst</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="lactosefree"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("lactosefree")}
                                    />
                                    <span>laktosefrei</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="foodquality"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("foodquality")}
                                    />
                                    <span>Lebensmittelqualit</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="airdried"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("airdried")}
                                    />
                                    <span>luftgetrocknet</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="monoprotein"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("monoprotein")}
                                    />
                                    <span>mono- / singleprotein</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="natural"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("natural")}
                                    />
                                    <span>naturbelassen</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="notextruded"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("notextruded")}
                                    />
                                    <span>nicht extrudiert</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="taurin"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("taurin")}
                                    />
                                    <span>nur Taurin als Zusatzstoff</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="woantioxidants"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("woantioxidants")}
                                    />
                                    <span>ohne Antioxidantien</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="woflavors"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("woflavors")}
                                    />
                                    <span>ohne Aromen</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="wochemicaladditives"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("wochemicaladditives")}
                                    />
                                    <span>ohne chem. / künstl. Zusatzstoffe</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="woemulsifiers"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("woemulsifiers")}
                                    />
                                    <span>ohne Emulgatoren</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="wodyes"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("wodyes")}
                                    />
                                    <span>ohne Farbstoffe</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="womeatflour"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("womeatflour")}
                                    />
                                    <span>ohne Fleischmehle</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="womoldmeat"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("womoldmeat")}
                                    />
                                    <span>ohne Formfleisch</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="wofillers"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("wofillers")}
                                    />
                                    <span>ohne Füllstoffe</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="woflavorenhancers"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("woflavorenhancers")}
                                    />
                                    <span>ohne Geschmacksverstärker</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="wobonemeal"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("wobonemeal")}
                                    />
                                    <span>ohne Knochenmehl</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="wobinder"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("wobinder")}
                                    />
                                    <span>ohne (künstliche) Bindemittel</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="wopreservativesubstances"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("wopreservativesubstances")}
                                    />
                                    <span>ohne Konservierungsstoffe</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="woattractants"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("woattractants")}
                                    />
                                    <span>ohne Lockstoffe</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="woanimalmeal"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("woanimalmeal")}
                                    />
                                    <span>ohne Tiermehl</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="woanimalexperiments"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("woanimalexperiments")}
                                    />
                                    <span>ohne Tierversuche</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="noaddedsugar"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("noaddedsugar")}
                                    />
                                    <span>ohne Zuckerzusatz</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="purinarm"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("purinarm")}
                                    />
                                    <span>purinarm</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="cookedgently"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("cookedgently")}
                                    />
                                    <span>schonend gegart / dampf gegart</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="sensitive"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("sensitive")}
                                    />
                                    <span>sensitive</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="soyfree"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("soyfree")}
                                    />
                                    <span>sojafrei</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="vegan"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("vegan")}
                                    />
                                    <span>vegan</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="vegetarian"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("vegetarian")}
                                    />
                                    <span>vegetarisch</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="wheatfree"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("wheatfree")}
                                    />
                                    <span>weizenfrei</span>
                                </label>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="additionalFeatures"
                                        value="sugarfree"
                                        onChange={props.handleCheckboxChange}
                                        checked={props.additionalFeatures.includes("sugarfree")}
                                    />
                                    <span>zuckerfrei</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step4(props) {
    if (props.currentStep !== 4) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="imageFront">
                                Foto der Produktvorderseite (verfügbare Formate: PNG, JPEG, JPG, GIF, SVG)
                            </label>
                        </div>
                        {props.fetchedProduct && props.fetchedProduct.imageFront ? (
                            <div className="image-preview">
                                <img src={bucketURL + props.fetchedProduct.imageFront} />
                                <a
                                    href=""
                                    onClick={props.handleImageDeletion}
                                    data-image="imageFront"
                                    data-product={props.fetchedProduct._id}
                                >
                                    <DeleteOutline />
                                    Foto löschen
                                </a>
                            </div>
                        ) : (
                                <div className="form-input">
                                    <input
                                        className="form-control"
                                        type="file"
                                        name="imageFront"
                                        accept=".png, .jpg, .jpeg, .gif, .svg"
                                        id="imageFront"
                                        onChange={props.handleFileChange}
                                    ></input>
                                </div>
                            )}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="imageContent1">
                                Foto des Inhalts 1 (verfügbare Formate: PNG, JPEG, JPG, GIF, SVG)
                                <small>
                                    Wie sieht das Produkt direkt nach dem Öffnen aus? (noch in der Dose, frisch aus der
                                    Schale/Beutel gekippt/gedrückt)
                                </small>
                            </label>
                        </div>
                        {props.fetchedProduct && props.fetchedProduct.imageContent1 ? (
                            <div className="image-preview">
                                <img src={bucketURL + props.fetchedProduct.imageContent1} />
                                <a
                                    href=""
                                    onClick={props.handleImageDeletion}
                                    data-image="imageContent1"
                                    data-product={props.fetchedProduct._id}
                                >
                                    <DeleteOutline />
                                    Foto löschen
                                </a>
                            </div>
                        ) : (
                                <div className="form-input">
                                    <input
                                        className="form-control"
                                        type="file"
                                        name="imageContent1"
                                        accept=".png, .jpg, .jpeg, .gif, .svg"
                                        id="imageContent1"
                                        onChange={props.handleFileChange}
                                    ></input>
                                </div>
                            )}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <div className="form-label">
                            <label for="imageContent2">
                                Foto des Inhalts 2 (verfügbare Formate: PNG, JPEG, JPG, GIF, SVG)
                                <small>Wie sieht die Konsistenz nach dem Portionieren aus? (Schnittfestigkeit)</small>
                            </label>
                        </div>
                        {props.fetchedProduct && props.fetchedProduct.imageContent2 ? (
                            <div className="image-preview">
                                <img src={bucketURL + props.fetchedProduct.imageContent2} />
                                <a
                                    href=""
                                    onClick={props.handleImageDeletion}
                                    data-image="imageContent2"
                                    data-product={props.fetchedProduct._id}
                                >
                                    <DeleteOutline />
                                    Foto löschen
                                </a>
                            </div>
                        ) : (
                                <div className="form-input">
                                    <input
                                        className="form-control"
                                        type="file"
                                        name="imageContent2"
                                        accept=".png, .jpg, .jpeg, .gif, .svg"
                                        id="imageContent2"
                                        onChange={props.handleFileChange}
                                    ></input>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EditProductForm;
