import React, { Component } from "react";
import { Link } from "react-router-dom";
import CompassLogo from "../img/myfeedingcompass_logo.png";

class Footer extends Component {
    render() {
        return (
            <div id="footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12 text-center small">
                            <a
                                href="https://myfeedingcompass.at"
                                target="_blank"
                            >
                                <img src={CompassLogo} />
                                <span>powered by MyFeedingCompass.at</span>
                            </a>
                        </div>
                    </div>
                    <div className="row second">
                        <div className="col-12 small text-center">
                            <ul>
                                <li>
                                    <a
                                        href="https://www.instagram.com/myfeedingcheck/"
                                        target="_blank"
                                    >
                                        Über MyFeedingCheck
                                    </a>
                                </li>
                                <li>
                                    <Link to="/werben">
                                        Werben auf MyFeedingCheck
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mfc-als-app">
                                        MyFeedingCheck als App
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/kontakt">Kontakt</Link>
                                </li>
                                <li>
                                    <Link to="/agb">Nutzungsbedingungen</Link>
                                </li>
                                <li>
                                    <a href="https://www.myfeedingcompass.at/datenschutz" target="_blank">
                                        Datenschutz
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.myfeedingcompass.at/impressum" target="_blank">Impressum</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
