import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SC from './../modules/StringConverter';
import ReactTooltip from "react-tooltip";

class CalciumCalculator extends Component {
    state = {
        animal: null,
        weight: null,
        calcium: null,
        metaWeight: null,
        calciumPerDay: null,
        calciumPerWeek: null,
        foodPerDay: null,
        foodPerWeek: null,
        result: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    calculate() {
        let { animal, weight, calcium } = this.state;
        let metaWeight, calciumPerDay, calciumPerWeek, foodPerDay, foodPerWeek;

        this.setState({
            result: false
        });

        if (animal && weight && calcium) {
            weight = parseFloat(weight);

            if (animal == 'dog') {
                metaWeight = Math.pow(weight, 0.75);

                calciumPerDay = metaWeight * .13;
            } else if (animal == 'cat') {
                metaWeight = Math.pow(weight, 0.67);

                calciumPerDay = metaWeight * .071;
            }

            calciumPerWeek = calciumPerDay * 7;
            foodPerDay = calciumPerDay / calcium * 100;
            foodPerWeek = calciumPerWeek / calcium * 100;

            this.setState({result: true, metaWeight, calciumPerDay, calciumPerWeek, foodPerDay, foodPerWeek});
        }
    }

    handleValueChange = (e) => {
        let { name, value } = e.target;
        this.setState({ [name]: value });

        setTimeout(() => {
            this.calculate();
        }, 200);
    };

    render() {
        return (
            <div id="calculator-view" className="calcium">
                <Helmet>
                    <meta
                        name="description"
                        content="Diesen Calcium Rechner kannst du für alle Calcium-Supplemente verwenden, wie zum Beispiel Knochenmehl und Calciumcitrat / Calciumcarbonat."
                        id="meta-desc-compare"
                    />
                </Helmet>
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span>
                                <Link to="/">Startseite</Link> / Calciumbedarfs&shy;rechner
                            </span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 class="color-primary">
                                Calciumbedarfsrechner
                            </h1>
                            <p>
                            Calcium ist ein äußerst wichtiger Bestandteil in der Fütterung und muss, falls keine Knochen gefüttert werden bzw. nicht im Fertigfutter zugesetzt wurde, immer ergänzt werden. Bitte bedenke Calcium in der richtigen Menge zu dosieren.
                            <br /><br />
                            Diesen Calcium Rechner kannst du für alle Calcium-Supplemente verwenden, wie zum Beispiel Knochenmehl und Calciumcitrat / Calciumcarbonat.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <h2 className="color-primary">
                                Daten eingeben
                            </h2>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="animal">
                                        Tierart
                                    </label>
                                </div>
                                <div className="form-input">
                                    <select
                                        className="form-control"
                                        name="animal"
                                        onChange={this.handleValueChange}
                                    >
                                        <option disabled selected value>
                                            Tierart auswählen
                                        </option>
                                        <option
                                            value="dog"
                                            selected={
                                                this.state.animal ===
                                                "dog"
                                            }
                                        >
                                            Hund
                                        </option>
                                        <option
                                            value="cat"
                                            selected={
                                                this.state.animal ===
                                                "cat"
                                            }
                                        >
                                            Katze
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Gewicht deines Tieres [kg]</label>
                                </div>
                                <div className="form-input">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="weight"
                                        placeholder="Gewicht in KG"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.weight}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label">
                                    <label for="proteins">Calciumgehalt im Präparat [%]</label>
                                </div>
                                <div className="form-input">
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="calcium"
                                        placeholder="Calciumgehalt in %"
                                        onChange={
                                            this.handleValueChange
                                        }
                                        value={this.state.calcium}
                                    ></input>
                                </div>
                            </div>
                        </div>

                        {this.state.calciumPerDay && this.state.calciumPerWeek && this.state.foodPerDay && this.state.foodPerWeek ? (
                            <div className="col-lg-6 mt-30-md result-2">
                                <div className="flex">
                                    <h2 className="color-primary">
                                        Calciumbedarf
                                    </h2>
                                    <div>
                                        <div><span>{SC.replaceDecimal(SC.round2Digits(this.state.calciumPerDay)) || 'FEHLER'}{this.state.calciumPerDay && ' g'}</span><span>pro Tag</span></div>
                                        <div><span>{SC.replaceDecimal(SC.round2Digits(this.state.calciumPerWeek)) || 'FEHLER'}{this.state.calciumPerWeek && ' g'}</span><span>pro Woche</span></div>
                                    </div>
                                </div>
                                <div className="flex">
                                    <h2 className="color-primary">
                                        Präparatmenge
                                    </h2>
                                    <div>
                                        <div><span>{SC.replaceDecimal(SC.round2Digits(this.state.foodPerDay)) || 'FEHLER'}{this.state.foodPerDay && ' g'}</span><span>pro Tag</span></div>
                                        <div><span>{SC.replaceDecimal(SC.round2Digits(this.state.foodPerWeek)) || 'FEHLER'}{this.state.foodPerWeek && ' g'}</span><span>pro Woche</span></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-6 text-center mt-30-md">
                                <span>Daten eingeben.</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default CalciumCalculator;
