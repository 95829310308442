import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Slider from "react-slick";
import ItemCard from "./ItemCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import noImage from "../img/no-image.jpg";
import mfc12 from "../img/mfc-12.jpg";
import mfc13 from "../img/mfc-13.jpg";
import mfc14 from "../img/mfc-14.jpg";
import axios from "axios";
import StarRatingComponent from "react-star-rating-component";
import { BookmarkBorder, FavoriteBorder, CompareArrows } from "@material-ui/icons";
import $ from "jquery";
import "jquery-match-height/dist/jquery.matchHeight-min.js";

function NavBlocks(props) {
    return (
        <div className="nav-blocks">
            <div className="nav-block-wrapper">
                <Link to="/nfe-rechner" className="nav-block">
                    <div className="aspect-ratio-1-1">
                        <img src={mfc14} />
                        <div className="overlay"></div>
                        <div className="title">Nfe-Rechner</div>
                    </div>
                </Link>
            </div>
            <div className="nav-block-wrapper">
                <a href="https://www.myfeedingcompass.at/" target="_blank" className="nav-block">
                    <div className="aspect-ratio-1-1">
                        <img src={mfc13} />
                        <div className="overlay"></div>
                        <div className="title">Blog</div>
                    </div>
                </a>
            </div>
            <div className="nav-block-wrapper">
                <Link to="/energiebedarf-und-futtermenge" className="nav-block">
                    <div className="aspect-ratio-1-1">
                        <img src={mfc12} />
                        <div className="overlay"></div>
                        <div className="title">Energiebedarf &amp; Futtermenge</div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

class Home extends Component {
    state = {
        latestProducts: [],
        bestProducts: [],
        lastViewedProducts: [],
        redirectTo: "",
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetchHomepageProducts();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    matchHeight() {
        $(function () {
            $(".card__info").matchHeight();
        });
    }

    fetchHomepageProducts = () => {
        //get last viewed items from localStorage
        let lastViewed = JSON.parse(localStorage.getItem("lastViewedProducts"));

        axios
            .post("/api/homepage-products", { lastViewed: lastViewed })
            .then((res) => {
                let { data } = res;
                if (data.success) {
                    if (data.latestProducts) this.setState({ latestProducts: data.latestProducts });
                    if (data.bestProducts) this.setState({ bestProducts: data.bestProducts });
                    if (data.lastViewedProducts)
                        this.setState({
                            lastViewedProducts: data.lastViewedProducts,
                        });
                    this.matchHeight();
                }
            })
            .catch((err) => console.log("Error while fetching products."));
    };

    carousels = () => {
        let obj = {
            // latestProducts: "Zuletzt aktualisierte Produkte", // uncomment to show slider
            bestProducts: "Am besten bewertete Produkte",
            lastViewedProducts: "Zuletzt angesehene Produkte",
        };
        let sliders = [];
        let i = 0;
        for (var prop in obj) {
            if (this.state[prop] && this.state[prop].length >= 1) {
                const settings = {
                    dots: false,
                    infinite: false,
                    autoplay: false,
                    lazyload: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 1199,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                            },
                        },
                    ],
                };

                let sliderContent = [];

                this.state[prop].forEach((p) =>
                    sliderContent.push(
                        <ItemCard
                            product={p}
                            handleCompareListChange={this.props.handleCompareListChange}
                            compareList={this.props.compareList}
                            key={p._id}
                            handleFavoritesListChange={this.props.handleFavoritesListChange}
                            favoritesList={this.props.favoritesList}
                        />
                    )
                );

                sliders.push(
                    <div className="carousel">
                        <h2>{obj[prop]}</h2>
                        <Slider {...settings}>{sliderContent}</Slider>
                    </div>
                );

                if (i === 0) {
                    sliders.push(<NavBlocks />);
                }

                i++;
            }
        }

        return sliders;
    };

    render() {
        if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

        return (
            <div id="home-view">
                <div className="main">
                    <div className="header">
                        <div className="layer"></div>
                        <div className="row">
                            <div className="col-12">
                                <h1 style={{marginBottom: 0}}>
                                    Willkommen bei<br />MyFeedingCheck!<br />
                                </h1>
                                <h2 className="h1">
                                    <span>Dein Vergleichs&shy;portal<br />für Hunde- &amp; Katzenfutter.</span>
                                </h2>
                                <p>
                                    <ul>
                                        <li>suche entspannt nach Futter</li>
                                        <li>entdecke neue Sorten &amp; Marken</li>
                                        <li>vergleiche Produkte</li>
                                        <li>kaufe Produkte zum Bestpreis</li>
                                    </ul>
                                </p>
                                <Link to="/produkte" className="btn btn-primary btn-lg">
                                    Produkte entdecken
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">{this.carousels()}</div>
                    </div>
                    <div className="cta">
                        <div className="row">
                            <div className="col-lg-8 col-md-7 col-sm-12">
                                <p>
                                    Ein Produkt entdeckt, welches noch nicht auf MyFeedingCheck enthalten ist?
                                    <br />
                                    <br />
                                    Du hast Wünsche, Anregungen oder ein öffentliches Ereignis{" "}
                                    <nobr>(z.B. Produktrückruf)</nobr> zu melden?
                                </p>
                                <div>
                                    Dann schreib uns!
                                    <br />
                                    <Link to="/kontakt" className="btn btn-primary">
                                        Zum Kontaktformular
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
