import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Link,
    NavLink,
    Route,
    Redirect,
} from "react-router-dom";
import logo from "../img/myfeedingcheck_logo.png";
import arrowDown from "../img/arrow-down.png";
import magnifyingGlass from "../img/magnifying-glass.png";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Menu, Close } from "@material-ui/icons";
import { FiFacebook, FiInstagram } from 'react-icons/fi';

class NavBarMain extends Component {
    state = {
        search: "",
        searchResults: [],
        searchIsActive: false,
        showOverlayNav: false,
        redirectTo: "",
        tipOfTheWeek: null,
    };

    constructor(props) {
        super(props);

        this.handleSearchBarChange = this.handleSearchBarChange.bind(this);
        this.onSearchBarBlur = this.onSearchBarBlur.bind(this);
        this.onSearchBarFocus = this.onSearchBarFocus.bind(this);

        this.searchProducts = (query) => {
            if (query) {
                fetch("/api/search-products?query=" + encodeURIComponent(query))
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.success === true) {
                            this.setState({ searchResults: data.products });
                        }
                    })
                    .catch((err) => this.setState({ search: "" }));
            } else {
                return;
            }
        };

        this.searchProductsDebounced = AwesomeDebouncePromise(
            this.searchProducts,
            500
        );
    }

    onSearchBarFocus = () => {
        this.setState({ searchIsActive: true });
    };

    onSearchBarBlur = () => {
        setTimeout(
            function () {
                this.setState({ searchIsActive: false });
            }.bind(this),
            100
        );
    };

    handleSearchBarChange = async (e) => {
        this.setState({ searchResults: [] });
        this.setState({ search: e.target.value });
        let result = await this.searchProductsDebounced(e.target.value);
    };

    handleSearchBarSubmit = (e) => {
        e.preventDefault();

        let { search } = this.state;

        this.setState({ redirectTo: "/produkte?query=" + search + "&animal=dog&animal=cat&type1=dried&type1=wet&type1=raw&type1=flakes&type1=suplement" });

        this.onSearchBarBlur();

        this.setState({ search: "" });
    };

    searchBarResults = () => {
        if (this.state.searchIsActive && this.state.searchResults.length >= 1) {
            let results = [];
            this.state.searchResults.forEach((r) => {
                let styles = {};

                r.imageFront
                    ? (styles.backgroundImage =
                        "url('https://bucket-mfc-wallride67.storage.googleapis.com/" +
                        r.imageFront.toLowerCase() +
                        "')")
                    : (styles.backgroundImage =
                        "url('https://bucket-mfc-wallride67.storage.googleapis.com/no-image.png')");

                results.push(
                    <Link
                        to={"/produkte/" + r.urlExtension}
                        key={r._id}
                        style={styles}
                    >
                        <li>
                            {r.brand.name}, {r.series}
                        </li>
                    </Link>
                );
            });

            return (
                <div className="search-bar-results">
                    <ul>{results}</ul>
                </div>
            );
        } else if (
            this.state.searchIsActive &&
            this.state.searchResults.length < 1 &&
            this.state.search
        ) {
            return (
                <div className="search-bar-results">
                    <p>Keine Produkte gefunden.</p>
                </div>
            );
        } else if (
            this.state.searchIsActive &&
            this.state.searchResults.length < 1 &&
            !this.state.search
        ) {
            return (
                <div className="search-bar-results">
                    <p>Suchbegriff eingeben...</p>
                </div>
            );
        }
        return null;
    };

    isLinkMatch = (link) => {
        if (link === 'rechner' && (window.location.href.includes('rechner') || window.location.href.includes('bedarf') || window.location.href.includes('trockenmasse'))) return true
        if (link === 'futtersuche' && (window.location.href.includes('produkte'))) return true

        return false
    }

    render() {
        return (
            <React.Fragment>
                <nav className="navbar sticky-nav">
                    <div className="main-nav">
                        <div className="container">
                            <div className="nav">
                                <NavLink
                                    to="/produkte?animal=dog&currentPage=1&filterOption=1&productsPerPage=12&sortBy=brand&type1=wet"
                                    className={this.isLinkMatch('futtersuche') ? 'active' : ''}
                                >
                                    Futtersuche
                                </NavLink>
                                <NavLink
                                    to="/vergleichsliste"
                                    activeClassName="active"
                                    className="display-none-max-599"
                                >
                                    Vergleichsliste
                                </NavLink>
                                <NavLink
                                    to="/hunde-katzen-ernaehrungsberater"
                                    activeClassName="active"
                                    className="display-none-max-599"
                                >
                                    Ernährungsberater
                                </NavLink>
                                <a
                                    href="https://www.myfeedingcompass.at/"
                                    target="_blank"
                                    activeClassName="active"
                                    className="display-none-max-599"
                                >
                                    Blog
                                </a>
                                <a href="#" id="more-links-btn" className={this.isLinkMatch('rechner') ? 'active' : ''}>
                                    Rechner <img src={arrowDown}></img>
                                    <div className="dropdown">
                                        <ul className="dropdown-list">
                                            <li className="dropdown-item">
                                                <Link to="/calciumbedarf">
                                                    Calciumbedarf
                                                </Link>
                                            </li>
                                            <li className="dropdown-item">
                                                <Link to="/einheiten">
                                                    Einheiten
                                                </Link>
                                            </li>
                                            <li className="dropdown-item">
                                                <Link to="/energiebedarf-und-futtermenge">
                                                    Energiebedarf &amp;
                                                    Futtermenge
                                                </Link>
                                            </li>
                                            <li className="dropdown-item">
                                                <Link to="/fett">
                                                    Fettmenge
                                                </Link>
                                            </li>
                                            <li className="dropdown-item">
                                                <Link to="/jodbedarf">
                                                    Jodbedarf
                                                </Link>
                                            </li>
                                            <li className="dropdown-item">
                                                <Link to="/naehrstoffbedarf">
                                                    Nährstoffbedarf
                                                </Link>
                                            </li>
                                            <li className="dropdown-item">
                                                <Link to="/nfe-rechner">
                                                    Nfe-Rechner
                                                </Link>
                                            </li>
                                            <li className="dropdown-item">
                                                <Link to="/trockenmasse">
                                                    Trockenmasse
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </a>
                                <a
                                    href="https://www.myfeedingcompass.at/ratgeber"
                                    target="_blank"
                                    activeClassName="active"
                                    className="display-none-max-599"
                                >
                                    Ratgeber
                                </a>
                                <span
                                    onClick={() =>
                                        this.setState({ showOverlayNav: true })
                                    }
                                    id="overlay-nav-btn"
                                >
                                    <Menu />
                                </span>
                            </div>
                            <div className="search-container">
                                <form
                                    method="post"
                                    onSubmit={this.handleSearchBarSubmit}
                                    style={{ position: "relative" }}
                                >
                                    <input
                                        type="search"
                                        placeholder="Produkt suchen"
                                        className="form-control"
                                        onChange={this.handleSearchBarChange}
                                        onFocus={this.onSearchBarFocus}
                                        onBlur={this.onSearchBarBlur}
                                        value={this.state.search}
                                    ></input>
                                    {this.state.search && (
                                        <button
                                            type="submit"
                                            style={{
                                                opacity: "0",
                                                position: "absolute",
                                                right: "5px",
                                                top: "0",
                                                bottom: "0",
                                            }}
                                        >
                                            &nbsp;
                                        </button>
                                    )}
                                    {this.searchBarResults()}
                                </form>
                            </div>
                        </div>
                    </div>
                </nav>
                <div
                    style={
                        this.state.showOverlayNav
                            ? { opacity: "1", visibility: "visible" }
                            : { opacity: "0", visibility: "hidden" }
                    }
                    className="overlay-nav"
                >
                    <ul>
                        {!this.props.isAuthenticated ? (
                            <React.Fragment>
                                <li>
                                    <Link
                                        to="/login"
                                        onClick={() =>
                                            this.setState({
                                                showOverlayNav: false,
                                            })
                                        }
                                    >
                                        Anmelden
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/registrieren"
                                        onClick={() =>
                                            this.setState({
                                                showOverlayNav: false,
                                            })
                                        }
                                    >
                                        Registrieren
                                    </Link>
                                </li>
                            </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    <li>
                                        <Link
                                            to="/profil"
                                            onClick={() =>
                                                this.setState({
                                                    showOverlayNav: false,
                                                })
                                            }
                                        >
                                            Profil
                                    </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/logout"
                                            onClick={() =>
                                                this.setState({
                                                    showOverlayNav: false,
                                                })
                                            }
                                        >
                                            Abmelden
                                    </Link>
                                    </li>
                                </React.Fragment>
                            )}
                        {/* <li><Link to="/produkte/neues-produkt-erstellen" onClick={() => this.setState({ showOverlayNav: false })}>Produkt hinzufügen</Link></li> */}
                        <hr />
                        <li className="display-none-min-600">
                            <Link
                                to="/vergleichsliste"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Vergleichsliste
                            </Link>
                        </li>
                        <li className="display-none-min-600">
                            <Link
                                to="/hunde-katzen-ernaehrungsberater"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Ernährungsberater
                            </Link>
                        </li>
                        <li className="display-none-min-600">
                            <a
                                href="https://www.myfeedingcompass.at/"
                                target="_blank"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Blog
                            </a>
                        </li>
                        <li className="display-none-min-600">
                            <a
                                href="https://www.myfeedingcompass.at/ratgeber"
                                target="_blank"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Ratgeber
                            </a>
                        </li>
                        <li>
                            <Link
                                to="/calciumbedarf"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Calciumbedarf
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/einheiten"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Einheiten
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/energiebedarf-und-futtermenge"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Energiebedarf &amp; Futtermenge
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/fett"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Fettmenge
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/jodbedarf"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Jodbedarf
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/naehrstoffbedarf"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Nährstoffbedarfsrechner
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/nfe-rechner"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Nfe-Rechner
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/trockenmasse"
                                onClick={() =>
                                    this.setState({ showOverlayNav: false })
                                }
                            >
                                Trockenmasserechner
                            </Link>
                        </li>
                        <li style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <a href="https://www.facebook.com/myfeedingcheck/" target="_blank" style={{ padding: '10px' }}>
                                <div><FiFacebook /></div>
                            </a>
                            <a href="https://www.instagram.com/myfeedingcheck/" target="_blank" style={{ padding: '10px' }}>
                                <div><FiInstagram /></div>
                            </a>
                        </li>
                    </ul>
                    <span
                        onClick={() => this.setState({ showOverlayNav: false })}
                        id="close-overlay-nav-btn"
                    >
                        <Close />
                    </span>
                    {this.state.redirectTo
                        ? [
                            <Redirect to={this.state.redirectTo} />,
                            this.setState({ redirectTo: "" }),
                        ]
                        : null}
                </div>
            </React.Fragment>
        );
    }
}

export default NavBarMain;
