import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Auth from '../modules/Auth';
import Reaptcha from 'reaptcha';
import queryString from 'query-string';


class Contact extends Component {
    state = {
        name: "",
        email: "",
        message: "",
        topic: "",
        formError: "",
        formSuccess: ""
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const parsed = queryString.parse(window.location.search);
        if (parsed.topic) this.setState({ topic: parsed.topic });
    }

    handleChange = event => {
        let { name, value } = event.target;

        this.setState({ [name]: value });
    }

    handleFormSubmit = () => {
        this.setState({ formError: "" });
        this.setState({ formSuccess: "" });

        let formIsValid = true;

        let { name, email, topic, message } = this.state;

        if (!name) {
            formIsValid = false;
            this.setState({ formError: "Bitte gib einen Namen ein." });
            return;
        }
        if (!email) {
            formIsValid = false;
            this.setState({ formError: "Bitte gib eine gültige E-Mail Adresse ein." });
            return;
        }
        if (!topic) {
            formIsValid = false;
            this.setState({ formError: "Bitte wähle einen Betreff aus." });
            return;
        }
        if (!message) {
            formIsValid = false;
            this.setState({ formError: "Bitte gib eine Nachricht ein." });
            return;
        }

        if (formIsValid) {
            Auth.checkRecaptcha()
                .then(res => {
                    this.captcha.reset();
                    let { data } = res;

                    if (data.success) {
                        let formData = {
                            name: name,
                            email: email,
                            topic: topic,
                            message: message
                        }

                        axios.post('/api/send-mail', formData)
                            .then(res => {
                                let { data } = res;

                                if (data.success) {
                                    this.setState({ name: "" });
                                    this.setState({ email: "" });
                                    this.setState({ topic: "" });
                                    this.setState({ message: "" });
                                    this.setState({ formSuccess: "Nachricht gesendet." });
                                } else {
                                    this.setState({ formError: "Leider ist ein Fehler aufgetreten. Bitte versuche es später erneut." });
                                }
                            })
                            .catch(err => this.setState({ formError: "Leider ist ein Fehler aufgetreten. Bitte versuche es später erneut." }));
                    } else {
                        this.setState({ formError: "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal." });
                    }
                })
                .catch(err => {
                    this.setState({ formError: "Fehler bei der ReCAPTCHA-Validitierung aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal." });
                });
        }
    }

    render() {
        return (
            <div id="contact-view">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb">
                            <span><Link to="/">Startseite</Link> / Kontakt</span>
                        </div>
                    </div>
                </div>
                <div className="main card card-body">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="color-primary">Schreibe uns eine Nachricht!</h1>
                            <p>
                                Du möchtest uns etwas Fragen oder aus einem anderen Grund kontaktieren?
                                <br />
                                Dann verwende am besten unser Kontaktformular - Wir freuen uns über jede Nachricht!
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <form method="POST" className="" action="#" onSubmit={(e) => { e.preventDefault(); this.captcha.execute(); }}>
                                {this.state.formError && (
                                    <p className="alert alert-danger">{this.state.formError}</p>
                                )}
                                {this.state.formSuccess && (
                                    <p className="alert alert-success">{this.state.formSuccess}</p>
                                )}
                                <div className="form-group">
                                    <label for="name" className="strong">Name</label>
                                    <input type="text" required placeholder="Dein Name" name="name" className="form-control" value={this.state.name} onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="email" className="strong">E-Mail</label>
                                    <input type="email" required placeholder="E-Mail Adresse" name="email" className="form-control" value={this.state.email} onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="topic" className="strong">Betreff</label>
                                    <select id="packaging" className="form-control" type='select' name='topic' required onChange={this.handleChange}>
                                        <option disabled selected={this.state.topic === ""} value>Betreff wählen</option>
                                        <option value='Anregungen' selected={this.state.topic === 'Anregungen'}>Wünsche / Anregungen</option>
                                        <option value='Ereignis' selected={this.state.topic === 'Ereignis'}>öffentliches Ereignis (z.B.: Produktrückruf)</option>
                                        <option value='Werben' selected={this.state.topic === 'Werben'}>Werben auf MyFeedingCheck</option>
                                        <option value='Produkt' selected={this.state.topic === 'Produkt'}>Bitte Produkt hinzufügen</option>
                                        <option value='Allgemein' selected={this.state.topic === 'Allgemein'}>Allgemein</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label for="message" className="strong">Nachricht</label>
                                    <textarea name="message" required placeholder="Deine Nachricht" rows="5" className="form-control" onChange={this.handleChange} value={this.state.message} />
                                </div>
                                <Reaptcha
                                    ref={e => (this.captcha = e)}
                                    size="invisible"
                                    sitekey="6LeEAaIUAAAAAHVNxEUt6ZjKHzvCA_6gPuwjjK0S"
                                    onVerify={this.handleFormSubmit}
                                    onError={() => { this.setState({ formError: "ReCAPTCHA-Fehler aufgetreten! Bitte versuche es erneut." }); }}
                                />
                                <button type="submit" className="btn btn-primary">Nachricht senden</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
